import round from '../../../helpers/round';

const HansenResultBaseFactors = ({ result }) => {
  const { nu } = result.input;
  const { isInclinedLoading, isPhiZero, phips, nu_radian, bc, bq, bGamma } = result.output;

  const case1 = isInclinedLoading && isPhiZero;
  const case2 = isInclinedLoading && !isPhiZero;
  const case3 = !isInclinedLoading && isPhiZero;
  const case4 = !isInclinedLoading && !isPhiZero;

  const Case1Case3bc = () => (
    <div className="meyerhof-factors">
      <h4>
        b'<sub>c</sub>
      </h4>
      <p>
        &phi; = 0 &rarr; Only b'<sub>c</sub> should be calculated.
      </p>
      <p>
        b'<sub>c</sub> = &eta; / 147 (&eta; in degrees)
      </p>
      <p>
        b'<sub>c</sub> = {round(nu, 2)} / 147
      </p>
      <p>
        b'<sub>c</sub>={round(bc, 2)}
      </p>
    </div>
  );

  const Case2Case4bq = () => (
    <div className="meyerhof-factors">
      <h4>
        b<sub>q</sub>
      </h4>
      <p>
        &eta; = {round(nu, 2)}
        <sup>o</sup> = {round(nu_radian, 3)} rad.
      </p>
      <p>
        b<sub>q</sub> = e<sup>-2 &eta; tan&phi;</sup> (&eta; in radians)
      </p>

      <p>
        b<sub>q</sub> = e
        <sup>
          (-2) ({round(nu_radian, 2)}) (tan{round(phips, 2)})
        </sup>
      </p>
      <p>
        b<sub>q</sub>={round(bq, 2)}
      </p>
    </div>
  );

  const Case2Case4bc = () => (
    <div className="meyerhof-factors">
      <h4>
        b<sub>c</sub>
      </h4>
      <p>
        b<sub>c</sub> = 1 - &eta; / 147 (&eta; in degrees)
      </p>
      <p>
        b<sub>c</sub> = 1 - {round(nu, 2)} / 147
      </p>
      <p>
        b<sub>c</sub>={round(bc, 2)}
      </p>
    </div>
  );

  const Case2Case4bgamma = () => (
    <div className="meyerhof-factors">
      <h4>
        b<sub>&gamma;</sub>
      </h4>
      <p>
        &eta; = {round(nu, 2)}
        <sup>o</sup> = {round(nu_radian, 3)} rad.
      </p>
      <p>
        b<sub>&gamma;</sub> = e<sup>-2.7 &eta; tan&phi;</sup> (&eta; in radians)
      </p>

      <p>
        b<sub>&gamma;</sub> = e
        <sup>
          (-2.7) ({round(nu_radian, 2)}) (tan{round(phips, 2)})
        </sup>
      </p>
      <p>
        b<sub>&gamma;</sub>={round(bGamma, 2)}
      </p>
    </div>
  );
  return (
    <div className="checks">
      <h3>
        Base Factors (b<sub>c</sub>, b<sub>q</sub>, b<sub>&gamma;</sub>)
      </h3>
      <div className="factors-wrapper">
        {case1 && <Case1Case3bc />}

        {case2 && <Case2Case4bq />}
        {case2 && <Case2Case4bc />}
        {case2 && <Case2Case4bgamma />}

        {case3 && <Case1Case3bc />}

        {case4 && <Case2Case4bq />}
        {case4 && <Case2Case4bc />}
        {case4 && <Case2Case4bgamma />}
      </div>
    </div>
  );
};
export default HansenResultBaseFactors;
