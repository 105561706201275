import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import Header from '../../common/Header';
import Footer from '../../common/Footer';
import LayeredSoilSketch from '../../../assets/calculations/layeredSoil/layeredSoil.png';
import TransitionsModal from '../../modals/TransitionsModal';

const LayeredSoilTheory = () => {
  // Google Ads & Set page title
  useEffect(() => {
    document.title = 'GeoCalc | Layered Soil Theory';
  }, []);

  return (
    <>
      <Header />
      <div className="theory">
        <div className="theoryContainer">
          <h1>Layered Soils | Theory & Formulations</h1>
          <p>
            The calculation tool given above for calculating average c-&phi; for layered soils follows the procedure given in
            "Foundation Analysis and Design, 5th Edition (Joseph E. Bowles) - Chapter 4. The details, formulations and procedure
            of the calculations are given below.
          </p>
          <h2>
            c<sub>av</sub> - &phi;<sub>av</sub> Formulations
          </h2>
          <p>The average cohesion and internal friction angle of the soil profile may be calculated as follows:</p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>c</mi>
                <mi>av</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>c</mi>
                    <mn>1</mn>
                  </msub>
                  <msub>
                    <mi>H</mi>
                    <mn>1</mn>
                  </msub>
                  <mo>+</mo>
                  <msub>
                    <mi>c</mi>
                    <mn>2</mn>
                  </msub>
                  <msub>
                    <mi>H</mi>
                    <mn>2</mn>
                  </msub>
                  <mo>+</mo>

                  <mi>. . . </mi>
                  <msub>
                    <mi>c</mi>
                    <mi>n</mi>
                  </msub>
                  <msub>
                    <mi>H</mi>
                    <mi>n</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mi>&sum;</mi>
                  <msub>
                    <mi>H</mi>
                    <mi>n</mi>
                  </msub>
                </mrow>
              </mfrac>
            </math>
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&phi;</mi>
                <mi>av</mi>
              </msub>
              <mo>=</mo>
              <msup>
                <mi>tan</mi>
                <mn>-1</mn>
              </msup>
              <mo>(</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>H</mi>
                    <mn>1</mn>
                  </msub>
                  <msub>
                    <mi>tan&phi;</mi>
                    <mn>1</mn>
                  </msub>
                  <mo>+</mo>
                  <msub>
                    <mi>H</mi>
                    <mn>2</mn>
                  </msub>
                  <msub>
                    <mi>tan&phi;</mi>
                    <mn>2</mn>
                  </msub>
                  <mo>+</mo>
                  <mi>. . . </mi>
                  <msub>
                    <mi>H</mi>
                    <mi>n</mi>
                  </msub>
                  <msub>
                    <mi>tan&phi;</mi>
                    <mi>n</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mi>&sum;</mi>
                  <msub>
                    <mi>H</mi>
                    <mi>n</mi>
                  </msub>
                </mrow>
              </mfrac>
              <mo>)</mo>
            </math>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>
            c<sub>av</sub>: Average cohesion of entire soil profile
          </p>
          <p>
            &phi;<sub>av</sub>: Average friction angle of entire soil profile
          </p>
          <p>
            c<sub>n</sub>: Cohesion of single soil layer
          </p>
          <p>
            &phi;<sub>n</sub>: Friction angle of single soil layer
          </p>
          <p>
            H<sub>n</sub>: Thickness of single soil layer
          </p>
          <TransitionsModal
            image={LayeredSoilSketch}
            title="Layered Soils"
            description={'Soil parameters and dimensions are shown on the sketch'}
          ></TransitionsModal>
          <h2>
            Effective Shear Depth (H<sub>e</sub>) and Iterations
          </h2>
          <h3>
            Effective Shear Depth (H<sub>e</sub>)
          </h3>
          <p>The effective shear depth is approxiamtely:</p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>H</mi>
                <mi>e</mi>
              </msub>
              <mo>=</mo>

              <mn>0.5</mn>
              <mi>B</mi>
              <mo>(</mo>
              <mn>45</mn>
              <mo>+</mo>
              <mfrac>
                <mi>&phi;</mi>
                <mn>2</mn>
              </mfrac>
              <mo>)</mo>
            </math>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>B: Foundation width</p>
          <p>&phi;: Friction angle of the soil layer under the foundation</p>
          <h3>Iterations</h3>
          <p>
            Firstly, H<sub>e</sub> is calculated using the friction angle of the soil layer just under the foundation to determine
            the contributing soil layers. After that, using the formulations given above and the c-&phi; values of the soil layers
            inside the effective shear zone (H<sub>e</sub>), first set of c<sub>av</sub> and &phi;<sub>av</sub> vaules are
            calculated (Iteration-0).
          </p>
          <p>
            Secondly, another H<sub>e</sub> is calculated using the &phi;<sub>av</sub> value calculated in iteration-0. Using the
            new H<sub>e</sub>, a new set of c<sub>av</sub> and &phi;<sub>av</sub> are calculated (Iteration-1). This procedure
            continues until the difference between the new H<sub>e</sub> and previous H<sub>e</sub> is less than 0.0001.
          </p>
          <p>
            The c<sub>av</sub> and &phi;<sub>av</sub> values calculated in the last iteration are the final results.
          </p>
          <div className="theoryButtonContainer">
            <Link to={'/calculations/layered-soil'}>
              <Button variant="outlined" size="small">
                GO TO LAYERED SOIL CALCULATOR
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LayeredSoilTheory;
