import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InputAdornment from '@mui/material/InputAdornment';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuItem from '@mui/material/MenuItem';
import MuiSnackbar from '../../common/MuiSnackbar';
import CircularProgress from '@mui/material/CircularProgress';

import CalculateButton from '../../common/calculateButton';
import SaveButton from '../../common/saveButton';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Sidebar from '../../sidebar/Sidebar';
import DecisionModal from '../../modals/DecisionModal';
import PilerockBcResult from './PileRockBcResult';
import TransitionsModal from '../../modals/TransitionsModal';
import figure1 from '../../../assets/calculations/pilerockbc/1.png';
import figure2 from '../../../assets/calculations/pilerockbc/2.png';

import { useCalculationContext, CalculationContextProvider } from '../../context/calculationContext';

const PileRockBc = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Bearing Capacity of Pile Foundations in Rock, AASHTO(2002)';
  }, []);

  const {
    MAX_NAME_LENGTH,
    isLoadingCalculate,
    isLoadingLoadAll,
    isLoadingLoad,
    isLoadingSave,
    result,
    calculations,
    lastData,
    showOutput,
    setShowOutput,
    calculateButtonDisabled,
    setCalculateButtonDisabled,
    saveButtonDisabled,
    setSaveButtonDisabled,
    errorMessage,
    displaySidebar,
    setDisplaySidebar,
    decisionModalIsOpen,
    decision,
    setDecision,
    handleSidebarDisplay,
    setDecisionModalIsOpenToFalse,
    showMuiSnack,
    open,
    setOpen,
    alertMessage,
    severity,
    onChangeWarningForPositiveDecimals,
    calculate,
    loadOneCalculation,
    loadAllCalculations,
    update,
    checkCalculationNameforSaving,
    checkCalculationNameforSavingAs,
    deleteCalculations,
    calculationCountReached,
    saveCountReached,
  } = useCalculationContext();

  //SET STATES FOR INPUT VARIABLES
  const [name, setName] = useState('');
  const [socketDiameter, setSocketDiameter] = useState('');
  const [socketLength, setSocketLength] = useState('');
  const [c0, setC0] = useState('');
  const [rqd, setRqd] = useState('');
  const [pileLength, setPileLength] = useState('');
  const [gammaConcrete, setGammaConcrete] = useState('');
  const [sigmaConcrete, setSigmaConcrete] = useState('');
  const [nms, setNms] = useState('');
  const [qsrOption, setQsrOption] = useState(0);
  const [FsSt, setFsSt] = useState('');
  const [FsSs, setFsSs] = useState('');
  const [FSt, setFSt] = useState('');
  const [FSs, setFSs] = useState('');
  const [FTension, setFTension] = useState('');

  // SET THE LOADED INPUTS
  const setInputs = (inputs) => {
    setName(inputs.name);
    setSocketDiameter(inputs.socketDiameter);
    setSocketLength(inputs.socketLength);
    setC0(inputs.c0);
    setRqd(inputs.rqd);
    setPileLength(inputs.pileLength);
    setGammaConcrete(inputs.gammaConcrete);
    setSigmaConcrete(inputs.sigmaConcrete);
    setNms(inputs.nms);
    setQsrOption(inputs.qsrOption);
    setFsSt(inputs.FsSt);
    setFsSs(inputs.FsSs);
    setFSt(inputs.FSt);
    setFSs(inputs.FSs);
    setFTension(inputs.FTension);
  };

  //INPUTS FOR CALCULATION
  const calculationInput = {
    name,
    socketDiameter,
    socketLength,
    c0,
    rqd,
    pileLength,
    gammaConcrete,
    sigmaConcrete,
    nms,
    qsrOption,
    FsSt,
    FsSs,
    FSt,
    FSs,
    FTension,
  };

  // MANAGE SAVE & CALCULATE BUTTONS DISABLED/ENABLED STATES
  useEffect(() => {
    setCalculateButtonDisabled(false);
    setSaveButtonDisabled(false);

    if (
      (calculationInput.name || calculationInput.name === 0) &&
      (calculationInput.socketDiameter || calculationInput.socketDiameter === 0) &&
      (calculationInput.socketLength || calculationInput.socketLength === 0) &&
      (calculationInput.c0 || calculationInput.c0 === 0) &&
      (calculationInput.rqd || calculationInput.rqd === 0) &&
      (calculationInput.pileLength || calculationInput.pileLength === 0) &&
      (calculationInput.gammaConcrete || calculationInput.gammaConcrete === 0) &&
      (calculationInput.sigmaConcrete || calculationInput.sigmaConcrete === 0) &&
      (calculationInput.nms || calculationInput.nms === 0) &&
      (calculationInput.qsrOption || calculationInput.qsrOption === 0) &&
      (calculationInput.FsSt || calculationInput.FsSt === 0) &&
      (calculationInput.FsSs || calculationInput.FsSs === 0) &&
      (calculationInput.FSt || calculationInput.FSt === 0) &&
      (calculationInput.FSs || calculationInput.FSs === 0) &&
      (calculationInput.FTension || calculationInput.FTension === 0)
    ) {
      setCalculateButtonDisabled(false);
      setSaveButtonDisabled(false);
    } else {
      setCalculateButtonDisabled(true);
      setSaveButtonDisabled(true);
    }
  }, [
    calculationInput.name,
    calculationInput.socketDiameter,
    calculationInput.socketLength,
    calculationInput.c0,
    calculationInput.rqd,
    calculationInput.pileLength,
    calculationInput.gammaConcrete,
    calculationInput.sigmaConcrete,
    calculationInput.nms,
    calculationInput.qsrOption,
    calculationInput.FsSt,
    calculationInput.FsSs,
    calculationInput.FSt,
    calculationInput.FSs,
    calculationInput.FTension,
    setCalculateButtonDisabled,
    setSaveButtonDisabled,
  ]);

  // Do not render output if any input parameter except name changes
  useEffect(() => {
    setShowOutput(false);
  }, [
    calculationInput.socketDiameter,
    calculationInput.socketLength,
    calculationInput.c0,
    calculationInput.rqd,
    calculationInput.pileLength,
    calculationInput.gammaConcrete,
    calculationInput.sigmaConcrete,
    calculationInput.nms,
    calculationInput.qsrOption,
    calculationInput.FsSt,
    calculationInput.FsSs,
    calculationInput.FSt,
    calculationInput.FSs,
    calculationInput.FTension,
    setShowOutput,
  ]);

  //CLEAR INPUTS AND CLEAR OUTPUT
  const createNewCalculation = () => {
    setName('');
    setSocketDiameter('');
    setSocketLength('');
    setC0('');
    setRqd('');
    setPileLength('');
    setGammaConcrete('');
    setSigmaConcrete('');
    setNms('');
    setQsrOption(0);
    setFsSt('');
    setFsSs('');
    setFSt('');
    setFSs('');
    setFTension('');
    setShowOutput(false);
  };

  //API URLS
  const URL = {
    CALCULATE: '/api/v1/calculations/pilerockbc',
    LOADONE: `/api/v1/calculations/pilerockbc/loadOne/`,
    LOADALL: '/api/v1/calculations/pilerockbc/loadAll',
    UPDATE: `/api/v1/calculations/pilerockbc/update/`,
    SAVE: '/api/v1/calculations/pilerockbc/save',
    CHECKUNIQUENAME: `/api/v1/calculations/pilerockbc/checkUniqueName/`,
    DELETE: '/api/v1/calculations/pilerockbc/delete',
  };

  const handleChangeName = (e) => {
    if (e.target.value.length >= MAX_NAME_LENGTH) {
      showMuiSnack(`Calculation name can be max ${MAX_NAME_LENGTH} characters.`, 'warning');
    }
    setName(e.target.value);
  };

  return (
    <>
      <Header />
      <div className="container">
        <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
        {displaySidebar ? (
          <Sidebar
            URL={URL}
            loadAllCalculations={loadAllCalculations}
            calculations={calculations}
            loadOneCalculation={loadOneCalculation}
            calculate={calculate}
            setInputs={setInputs}
            deleteCalculations={deleteCalculations}
            createNewCalculation={createNewCalculation}
            isLoading={isLoadingLoadAll}
            handleSidebarDisplay={handleSidebarDisplay}
            displaySidebar={displaySidebar}
            setDisplaySidebar={setDisplaySidebar}
          />
        ) : (
          <MenuOpenIcon
            color="primary"
            fontSize="large"
            className="sidebar-open-icon"
            alt="side bar menu icon"
            label="Show my calculations"
            onClick={handleSidebarDisplay}
          />
        )}
        <DecisionModal
          URL={URL}
          data={calculationInput}
          decisionModalIsOpen={decisionModalIsOpen}
          setDecisionModalIsOpenToFalse={setDecisionModalIsOpenToFalse}
          decision={decision}
          setDecision={setDecision}
          checkCalculationNameforSavingAs={checkCalculationNameforSavingAs}
          name={name}
          setName={setName}
          errorMessage={errorMessage}
          update={update}
          saveButtonDisabled={saveButtonDisabled}
        />
        <div className="right-calculation">
          <section className="input pilerocksettleInputs">
            <h1>Bearing Capacity of Pile Foundations in Rock, AASHTO(2002)</h1>
            <form>
              <div className="inputContainer">
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label="Name"
                  variant="outlined"
                  inputProps={{
                    maxLength: `${MAX_NAME_LENGTH}`,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={`Your calculation name (Max. ${MAX_NAME_LENGTH} characters)`}
                          arrow
                          placement="bottom"
                          TransitionComponent={Zoom}
                        >
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={name}
                  onChange={handleChangeName}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={
                    <span key={'br'}>
                      B<sub>r</sub> (m)
                    </span>
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Socket diameter" arrow placement="bottom" TransitionComponent={Zoom}>
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={socketDiameter}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Socket Diameter', setSocketDiameter)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={
                    <span key={'dr'}>
                      D<sub>r</sub> (m)
                    </span>
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Socket length" arrow placement="bottom" TransitionComponent={Zoom}>
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={socketLength}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Socket Length', setSocketLength)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={
                    <span key={'czero'}>
                      C<sub>0</sub> (MPa)
                    </span>
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title="Uniaxial compressive strength of intact rock"
                          arrow
                          placement="bottom"
                          TransitionComponent={Zoom}
                        >
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={c0}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'C0', setC0)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={'RQD (%)'}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Rock quality designation" arrow placement="bottom" TransitionComponent={Zoom}>
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={rqd}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'RQD', setRqd)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={'L (m)'}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Total pile length" arrow placement="bottom" TransitionComponent={Zoom}>
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={pileLength}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Pile Length', setPileLength)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={
                    <span key={'gammac'}>
                      &gamma;<sub>c</sub> (kN/m<sup>3</sup>)
                    </span>
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Unit weight of pile concrete" arrow placement="bottom" TransitionComponent={Zoom}>
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={gammaConcrete}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Gamma Concrete', setGammaConcrete)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={
                    <span key={'sigmac'}>
                      &sigma;<sub>c</sub> (MPa)
                    </span>
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Uniaxial strength of pile concrete" arrow placement="bottom" TransitionComponent={Zoom}>
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={sigmaConcrete}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Sigma Concrete', setSigmaConcrete)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={
                    <span key={'nms'}>
                      N<sub>ms</sub>
                    </span>
                  }
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title="Tip resistance factor. Select from Table 4.4.8.1.2A"
                          arrow
                          placement="bottom"
                          TransitionComponent={Zoom}
                        >
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={nms}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'nms', setNms)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />

                <TextField
                  size="small"
                  style={{ minWidth: 80, maxWidth: 120 }}
                  select
                  label={
                    <span key={'qsr'}>
                      q<sub>sr</sub>
                    </span>
                  }
                  value={qsrOption}
                  onChange={(e) => setQsrOption(e.target.value)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                >
                  <MenuItem value={0} disabled={true}>
                    <span key={'selectqsr'}>
                      Select q<sub>sr</sub>
                    </span>
                  </MenuItem>
                  <MenuItem value={1}>Maximum</MenuItem>
                  <MenuItem value={2}>Average</MenuItem>
                  <MenuItem value={3}>Minimum</MenuItem>
                </TextField>
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={'FsSt'}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Factor of safety (Static)" arrow placement="bottom" TransitionComponent={Zoom}>
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={FsSt}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'FsSt', setFsSt)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />

                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={'FsSs'}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Factor of safety (Seismic)" arrow placement="bottom" TransitionComponent={Zoom}>
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={FsSs}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'FsSs', setFsSs)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={'FSt(kN)'}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Static load (Compression)" arrow placement="bottom" TransitionComponent={Zoom}>
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={FSt}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'FSt', setFSt)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={'FSs(kN)'}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Seismic load (Compression)" arrow placement="bottom" TransitionComponent={Zoom}>
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={FSs}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'FSs', setFSs)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />
                <TextField
                  style={{ minWidth: 80, maxWidth: 120 }}
                  size="small"
                  label={'Ft(kN)'}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Seismic load (Tension)" arrow placement="bottom" TransitionComponent={Zoom}>
                          <InfoTwoToneIcon fontSize={'string'} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={FTension}
                  onChange={(e) => onChangeWarningForPositiveDecimals(e, 'F Tension', setFTension)}
                  disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                />
                <TransitionsModal
                  image={figure1}
                  title="Bearing Capacity of Pile Foundations in Rock, AASHTO(2002)"
                  description={'Determine qsr using the weaker of unconfined compressive strength of rock or concrete'}
                  buttonText={'Open Figure 4.6.5.3.1A'}
                ></TransitionsModal>
                <TransitionsModal
                  image={figure2}
                  title="Bearing Capacity of Pile Foundations in Rock, AASHTO(2002)"
                  description={'Choose Nms using rock properties'}
                  buttonText={'Open Table 4.4.8.1.2A'}
                ></TransitionsModal>
              </div>

              <div className="buttonContainer">
                <CalculateButton
                  calculationCountReached={calculationCountReached}
                  calculate={calculate}
                  calculationInput={calculationInput}
                  URL={URL}
                  calculateButtonDisabled={calculateButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
                {isLoadingLoad && <CircularProgress size={30} />}
                <SaveButton
                  saveCountReached={saveCountReached}
                  checkCalculationNameforSaving={checkCalculationNameforSaving}
                  calculationInput={calculationInput}
                  URL={URL}
                  saveButtonDisabled={saveButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
              </div>
            </form>
          </section>
          {showOutput && <PilerockBcResult data={lastData} result={result} />}
          {isLoadingCalculate && (
            <div className="centerLoading">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

const PilerockbcWithProvider = () => {
  return (
    <CalculationContextProvider>
      <PileRockBc />
    </CalculationContextProvider>
  );
};
export default PilerockbcWithProvider;
