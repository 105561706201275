import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import stressIncrease_A from '../../../assets/calculations/stressIncrease/stressIncrease_A_001.jpg';
import stressIncrease_B from '../../../assets/calculations/stressIncrease/stressIncrease_B_001.jpg';
import TransitionsModal from '../../modals/TransitionsModal';

import Header from '../../common/Header';
import Footer from '../../common/Footer';

const StressIncreaseTheory = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Vertical Stress Increase Due to Surface Loads | Theory';
  }, []);

  return (
    <>
      <Header />
      <div className="theory">
        <div className="theoryContainer">
          <h1>Vertical Stress Increase Due to Surface Loads | Theory & Formulations</h1>
          <h3>2H:1V Method</h3>
          <p>
            An approximate stress distribution assumes that the total applied load on the surface of the soil is distributed over
            an area of the same shape as the loaded area on the surface, but with dimensions that increase by an amount equal to
            the depth below the surface, Figure A.
          </p>
          <TransitionsModal
            image={stressIncrease_A}
            title="Stress increase | 2V:1H Method"
            description={'Calculate vertical stress increase using 2V:1H Method.'}
            buttonText={'Figure A'}
          ></TransitionsModal>
          <p>
            At a depth z, the total load q applied at the ground surface is assumed to be uniformly distributed over an area (B +
            z) by (L + z). The increase in vertical pressure &sigma;<sub>z</sub> at depth z for an applied load q is given by
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&sigma;</mi>
                <mi>z</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mi>q</mi>
                  <mi>B</mi>
                  <mi>L</mi>
                </mrow>
                <mrow>
                  <mo>(</mo>
                  <mi>B</mi>
                  <mo>+</mo>
                  <mi>z</mi>
                  <mo>)</mo>
                  <mo>x</mo>
                  <mo>(</mo>
                  <mi>L</mi>
                  <mo>+</mo>
                  <mi>z</mi>
                  <mo>)</mo>
                </mrow>
              </mfrac>
            </math>
          </p>
          <p>
            where B and L are the width and length of the load, respectively. Vertical stresses calculated with this approximation
            agree reasonably well with the Boussinesq method for depths between B and 4B below the foundation. (USACE
            EM1110-1-1904)
          </p>
          <h3>
            30<sup>o</sup> Method
          </h3>
          <p>
            Several methods can be used to estimate the increased pressure at some depth in the strata below the loaded area. An
            early method is to use a 2H : 1V slope as shown in Figure A. This had a great advantage of simplicity. Others have
            proposed the slope angle be anywhere from 30° to 45°, Figure B. (Foundation analysis and design, Joseph E. Bowles, 5th
            Edition, Chapter: 5.2)
          </p>
          <TransitionsModal
            image={stressIncrease_B}
            title="Stress increase | 30 Degrees Method"
            description={'Calculate vertical stress increase using 30 degrees Method.'}
            buttonText={'Figure B'}
          ></TransitionsModal>
          <p>
            The stress increase using 30<sup>o</sup> method may be calculated as follows:
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&sigma;</mi>
                <mi>z</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mi>q</mi>
                  <mi>B</mi>
                  <mi>L</mi>
                </mrow>
                <mrow>
                  <mo>(</mo>
                  <mi>B</mi>
                  <mo>+</mo>
                  <mi>2ztan30</mi>
                  <mo>)</mo>
                  <mo>x</mo>
                  <mo>(</mo>
                  <mi>L</mi>
                  <mo>+</mo>
                  <mi>2ztan30</mi>
                  <mo>)</mo>
                </mrow>
              </mfrac>
            </math>
          </p>
          <div className="theoryButtonContainer">
            <Link to={'/calculations/stressIncrease'}>
              <Button variant="outlined" size="small">
                GO TO SRESS INCREASE CALCULATOR
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StressIncreaseTheory;
