import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import Header from '../../common/Header';
import Footer from '../../common/Footer';

const CvLLUsNavyTheory = () => {
  // Google Ads & Set page title
  useEffect(() => {
    document.title = 'GeoCalc | Liquid Limit (LL) vs Cv Relationship | US Navy 1982 | Theory';
  }, []);

  return (
    <>
      <Header />
      <div className="theory">
        <div className="theoryContainer">
          <h1>Liquid Limit (LL) vs Cv Relationship | US Navy 1982 | Theory & Formulations</h1>
          <div className="theoryComingSoon">Coming Soon!</div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CvLLUsNavyTheory;
