import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

import UserTable from './UserTable';

const AdminUsers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState('');

  const handleQuery = (e) => {
    if (query === '') {
      getAllUsers();
    } else if (query.includes('@')) {
      getUserByEmail(query);
    } else {
      getUserByID(query);
    }
  };

  const getAllUsers = async () => {
    setLoading(true);
    setInfo('');
    setAllUsers([]);
    try {
      const response = await axios({
        method: 'GET',
        url: '/api/v1/admin/getAllUsers',
      });

      setAllUsers(response.data.data.users);
    } catch (err) {
      setInfo(err.response.data.message);
      console.log('Error->', err.response.data.message);
    }
    setLoading(false);
  };

  const getUserByID = async (ID) => {
    setLoading(true);
    setInfo('');
    setAllUsers([]);
    try {
      const response = await axios({
        method: 'GET',
        url: `/api/v1/admin/getUserById/${ID}`,
      });
      setAllUsers([response.data.data.user]);
    } catch (err) {
      setAllUsers([]);
      setInfo(err.response.data.message);
      console.log('Error->', err.response.data.message);
    }
    setLoading(false);
  };

  const getUserByEmail = async (email) => {
    setLoading(true);
    setInfo('');
    setAllUsers([]);
    try {
      const response = await axios({
        method: 'GET',
        url: `/api/v1/admin/getUserByEmail/${email}`,
      });

      setAllUsers([response.data.data.user]);
    } catch (err) {
      setInfo(err.response.data.message);
      console.log('Error->', err.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div className="adminUsers">
      <h1>Users</h1>
      <div className="adminUsersSearch">
        <TextField
          id="outlined-basic"
          label="Type user email or user ID. Leave blank for all users."
          variant="outlined"
          fullWidth
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </div>
      <div className="adminUsersSearchButton">
        <Button variant="contained" onClick={handleQuery}>
          Search users
        </Button>
      </div>
      {loading ? <CircularProgress sx={{ m: 3 }} /> : allUsers.length > 0 && <UserTable allUsers={allUsers} />}
      {info && <div className="AdminUserInfo">{info}</div>}
    </div>
  );
};

export default AdminUsers;
