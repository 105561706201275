import React from 'react';
import round from '../../../helpers/round';

const ConsolidationResult = ({ data, result }) => {
  const { b, cv, drainageType, l, mv, q, t } = data;

  const {
    midDepth,
    stressIncrease,
    compressibleLayerThickness,
    soedM,
    soedCm,
    sbcf,
    clayTypeText,
    s,
    drainagePathInitial,
    drainagePath,
    drainageTypeText,
    tv,
    u,
    sFinished,
    sRemaining,
  } = result;

  return (
    <section className="output">
      <h2>Output </h2>
      <div className="formulaBox">
        Stress increase (q<sub>z</sub>) at midpoint of compressible layer due to foundation load (q)
        is calculated by 2/1 method as follows:
      </div>
      <div className="formulaBox">
        q<sub>z</sub> = (q x B x L) / [(B + z) x (L + z)]
      </div>
      <div className="formulaBox">
        q<sub>z</sub> = {round(q, 2)} x {round(b, 2)} x {round(l, 2)} / [(
        {b} + {round(midDepth, 2)}) x ({l} + {round(midDepth, 2)})]
      </div>
      <div className="formulaBox">
        q<sub>z</sub> = {round(stressIncrease, 2)} kPa
      </div>
      <br />
      <div className="formulaBox">
        Compressible layer thickness considering foundation depth (D<sub>f</sub>) is calculated as H
        = {round(compressibleLayerThickness, 2)} m. So;
      </div>
      <div className="formulaBox">
        s<sub>oed</sub> = H x m<sub>v</sub> x q<sub>z</sub> = {round(compressibleLayerThickness, 2)}
        x {mv} x 10<sup>-5</sup> x {round(stressIncrease, 2)}
      </div>
      <div className="formulaBox">
        s<sub>oed</sub> = {round(soedM, 2)} m = {round(soedCm, 2)} cm
      </div>
      <br />
      <div className="formulaBox">
        Skemton ve Bjerrum correction factor for {clayTypeText} is selected as {sbcf} and
        consolidation settlement is calculated as;
      </div>
      <div className="formulaBox">
        s = &mu; x s<sub>oed</sub>
      </div>
      <div className="formulaBox">
        s= {sbcf} x {round(soedCm, 2)} = {round(s, 2)} cm
      </div>
      <br />
      <div className="formulaBox">
        In order to calculate the consolidation settlement in {t} months, Tv parameter should be
        calculated first.
      </div>
      <div className="formulaBox">
        Since drainage path is {drainageTypeText}, H<sub>dr</sub> = {round(drainagePathInitial, 2)}{' '}
        /{drainageType} = {round(drainagePath, 2)} m
      </div>
      <div className="formulaBox">
        T<sub>v</sub> = c<sub>v</sub> x t / H<sub>dr</sub>
        <sup>2</sup>
      </div>
      <div className="formulaBox">
        T<sub>v</sub> = ({cv} x 10<sup>-8</sup>) x ({t} x 30 x 24 x 60 x 60) /
        {round(drainagePath, 2)}
        <sup>2</sup>
      </div>
      <div className="formulaBox">
        T<sub>v</sub> = {round(tv, 5)}
      </div>
      <br />
      <div className="formulaBox">
        After calculating T<sub>v</sub> parameter, U (%) can be calculated as follows:
      </div>
      <div className="formulaBox">
        0 &nbsp; &le; U(%) &le; 60 &nbsp; &rarr; U(%) = 100 x (4 x T<sub>v</sub> / &pi;)
        <sup>0.5</sup>
      </div>
      <div className="formulaBox">
        60 &lt; U(%) &le; 100 &rarr; U(%) = 100- 10<sup>(1.781-Tv)/0.933</sup>
      </div>
      <div className="formulaBox">U(%) = {round(u, 2)}</div>
      <br />
      Finally, results are calculated and tabulated as follows:
      <div className="formulaBox consolidationResultsTable">
        <div className="cell">
          <span>Total (cm)</span>
          <span>{round(s, 2)}</span>
        </div>
        <div className="cell">
          <span>Time (month)</span>
          <span>{round(t, 2)}</span>
        </div>
        <div className="cell">
          <span>Completed (cm)</span>
          <span>{round(sFinished, 2)}</span>
        </div>
        <div className="cell">
          <span>Remaining (cm)</span>
          <span>{round(sRemaining, 2)}</span>
        </div>
      </div>
    </section>
  );
};

export default ConsolidationResult;
