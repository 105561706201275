import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import MenuIcon from '@mui/icons-material/Menu';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import ThemeSwitch from './ThemeSwitch';
import { Context } from '../../App';
import Logo from './Logo';
import OutsideAlerter from './outsideAlerter';
import { apiLogout } from '../../helpers/apiRequests';

const Header = () => {
  const { user, setUser, isResolved, setTheme } = useContext(Context);

  const logout = async () => {
    try {
      const response = await apiLogout();
      if (response.status === 'success') {
        setAlertMessage(`Goodbye ${user.name}.`);
        setOpen(true);
        setUser(null);
      }
    } catch (err) {
      /* console.log('Error logging out, Try again!'); */
    }
  };

  //handle active class for navbar to display
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };
  const active = isActive ? 'active' : null;
  const classes = `navbar-links ${active}`;

  //close mobile menu on escape keydown
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setActive(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} variant="standard" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const location = useLocation();

  return (
    <OutsideAlerter isActive={isActive} setActive={setActive}>
      {isResolved ? (
        <header className="appHeader">
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>
          <Logo />
          <div className="toggle-button">
            <MenuIcon onClick={toggleClass} fontSize="large" color="primary" />
          </div>

          <nav className={classes}>
            <ul>
              {location.pathname === '/' ? (
                <li className="activeClass">
                  <Link to="/">Home</Link>
                </li>
              ) : (
                <li>
                  <Link to="/">Home</Link>
                </li>
              )}

              {location.pathname === '/about' ? (
                <li className="activeClass">
                  <Link to="/about">About</Link>
                </li>
              ) : (
                <li>
                  <Link to="/about">About</Link>
                </li>
              )}

              {location.pathname === '/contact' ? (
                <li className="activeClass">
                  <Link to="/contact">Contact</Link>
                </li>
              ) : (
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              )}

              {location.pathname === '/pricing' ? (
                <li className="activeClass">
                  <Link to="/pricing">Pricing</Link>
                </li>
              ) : (
                <li>
                  <Link to="/pricing">Pricing</Link>
                </li>
              )}

              {user && (
                <>
                  {location.pathname === '/account' ? (
                    <li className="activeClass">
                      <Link to="/account">Account</Link>
                    </li>
                  ) : (
                    <li>
                      <Link to="/account">Account</Link>
                    </li>
                  )}
                </>
              )}

              {user && user.role === 'admin' && (
                <>
                  {location.pathname === '/admin' ? (
                    <li className="headerAdminLink activeClass">
                      <Link to="/admin">Admin</Link>
                      <AdminPanelSettingsIcon color="primary" className="headerAdminLinkIcon" />
                    </li>
                  ) : (
                    <li className="headerAdminLink">
                      <Link to="/admin">Admin</Link>
                      <AdminPanelSettingsIcon color="primary" className="headerAdminLinkIcon" />
                    </li>
                  )}
                </>
              )}

              {!user && (
                <>
                  {location.pathname === '/login' ? (
                    <li className="header-login-button activeClass">
                      <Link to="/login">Sign In</Link>
                    </li>
                  ) : (
                    <li className="header-login-button">
                      <Link to="/login">Sign In</Link>
                    </li>
                  )}
                </>
              )}

              {!user && (
                <>
                  {location.pathname === '/signup' ? (
                    <li className="header-signup-button activeClass">
                      <Link to="/signup">Sign Up</Link>
                    </li>
                  ) : (
                    <li className="header-signup-button">
                      <Link to="/signup">Sign Up</Link>
                    </li>
                  )}
                </>
              )}

              {user && (
                <li className="header-logout-button">
                  <Link to="#" onClick={logout}>
                    Sign Out
                  </Link>
                </li>
              )}
              <div className="themeToggle">
                <ThemeSwitch setTheme={setTheme} />
              </div>
            </ul>
          </nav>
        </header>
      ) : (
        <div className="centerLoading">
          <CircularProgress />
        </div>
      )}
    </OutsideAlerter>
  );
};

export default Header;
