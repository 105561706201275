import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

import AdminRolesTable from './AdminRolesTable';

const AdminUsers = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllSubscriptions = async () => {
      setLoading(true);
      try {
        const response = await axios({
          method: 'GET',
          url: `/api/v1/admin/getAllSubscriptions`,
        });
        setSubscriptions(response.data.data);
      } catch (err) {
        console.log('Error->', err.response.data.message);
      }
      setLoading(false);
    };
    getAllSubscriptions();
  }, []);

  return (
    <div className="adminUsers">
      <h1>Roles</h1>
      {loading ? (
        <CircularProgress sx={{ m: 3 }} />
      ) : (
        subscriptions.length > 0 && <AdminRolesTable subscriptions={subscriptions} />
      )}
    </div>
  );
};

export default AdminUsers;
