import React from 'react';
import round from '../../../helpers/round';

const PilerockBcResult = ({ data, result }) => {
  const {
    socketDiameter,
    socketLength,
    c0,
    rqd,
    pileLength,
    gammaConcrete,
    sigmaConcrete,
    nms,
    qsrOption,
    FsSt,
    FsSs,
    FSt,
    FSs,
    FTension,
  } = data;
  const {
    ae,
    cm,
    sigmac,
    sigmacPsi,
    qsrPsiMin,
    qsrPsiMax,
    qsrPsiAve,
    qsrPsi,
    qsr,
    QSR,
    pileTipArea,
    QTR,
    pileWeight,
    qUltCompression,
    qAllCompressionStatic,
    qAllCompressionSeismic,
    qUltTension,
    qAllTension,
    qAllCompressionStaticIsEnough,
    qAllCompressionSeismicIsEnough,
    qAllTensionIsEnough,
  } = result;

  return (
    <section className="output pilerockbc">
      <h2>Output</h2>
      <div className="result-container">
        <div className="column">
          <h3>Side Resistance</h3>
          <p>
            &alpha;<sub>E</sub> = 0.0231(RQD) - 1.32 &le;0.15
          </p>
          <p>
            &alpha;<sub>E</sub> = 0.0231({round(rqd, 2)}) - 1.32
          </p>
          <p>
            &alpha;<sub>E</sub> = {round(ae, 2)}
          </p>
          <br />
          <p>
            C<sub>m</sub> = &alpha;<sub>E</sub>C<sub>0</sub>
          </p>
          <p>
            C<sub>m</sub> = ({round(ae, 2)})({round(c0, 2)})
          </p>
          <p>
            C<sub>m</sub> = {round(cm, 2)} MPa
          </p>
          <br />
          <p>
            &sigma;<sub>c</sub> = Min(C<sub>m</sub>,&sigma;<sub>b</sub>)
          </p>
          <p>
            &sigma;<sub>c</sub> = {round(sigmac, 2)} MPa ({round(sigmacPsi, 0)} psi)
          </p>
          <br />
          <p>
            q<sub>sr</sub> = {round(qsrPsi, 0)} psi = {round(qsr, 0)} kPa (Figure 4.6.5.3.1A)
          </p>
          <br />

          <p>
            Q<sub>SR</sub> = &Pi; B<sub>r</sub>D<sub>r</sub>q<sub>sr</sub>
          </p>
          <p>
            Q<sub>SR</sub> = ({round(Math.PI, 2)})({round(socketDiameter, 2)})(
            {round(socketLength, 2)})({round(qsr, 2)})
          </p>
          <p>
            Q<sub>SR</sub> = {round(QSR, 0)} kN
          </p>
          <h3>Tip Resistance</h3>
          <p>
            N<sub>ms</sub> = {round(nms, 2)} (Table 4.4.8.1.2A)
          </p>
          <p>
            Q<sub>TR</sub> = N<sub>ms</sub>C<sub>0</sub>A<sub>T</sub>
          </p>
          <p>
            Q<sub>TR</sub> = ({round(nms, 2)})({round(c0, 2)})({round(pileTipArea, 2)})
          </p>
          <p>
            Q<sub>TR</sub> = {round(QTR, 2)} kN
          </p>
        </div>

        <div className="column">
          <div className="final-results">
            <h3>Compression Capacity - Static</h3>
            <p>
              Q<sub>ult</sub> = Q<sub>SR</sub> + Q<sub>TR</sub> - W
            </p>
            <p>
              Q<sub>ult</sub> = ({round(QSR, 0)}) + ({round(QTR, 0)}) - ({round(pileWeight, 0)})
            </p>
            <p>
              Q<sub>ult</sub> = {round(qUltCompression, 0)} kN
            </p>
            <br />
            <p>
              Q<sub>all</sub> = Q<sub>ult</sub> / FS
            </p>
            <p>
              Q<sub>all</sub> = ({round(qUltCompression, 0)}) / ({round(FsSt, 2)})
            </p>
            <p>
              {qAllCompressionStaticIsEnough ? (
                <span>
                  <span>
                    Q<sub>all</sub> = {round(qAllCompressionStatic, 0)} kN &ge; {round(FSt, 0)} kN
                  </span>
                  <span className="enough"> (&#10004;)</span>
                </span>
              ) : (
                <span>
                  <span>
                    Q<sub>all</sub> = {round(qAllCompressionStatic, 0)} kN &lt; {round(FSt, 0)} kN
                  </span>
                  <span className="not-enough"> (&#10006;)</span>
                </span>
              )}
            </p>
          </div>
          <div className="final-results">
            <h3>Compression Capacity - Seismic</h3>
            <p>
              Q<sub>all</sub> = Q<sub>ult</sub> / FS
            </p>
            <p>
              Q<sub>all</sub> = ({round(qUltCompression, 0)}) /({round(FsSs, 2)})
            </p>
            <p>
              {qAllCompressionSeismicIsEnough ? (
                <span>
                  <span>
                    Q<sub>all</sub> = {round(qAllCompressionSeismic, 0)} kN &ge; {round(FSs, 0)} kN
                  </span>
                  <span className="enough"> (&#10004;)</span>
                </span>
              ) : (
                <span>
                  <span>
                    Q<sub>all</sub> = {round(qAllCompressionSeismic, 0)} kN &lt; {round(FSs, 0)} kN
                  </span>
                  <span className="not-enough"> (&#10006;)</span>
                </span>
              )}
            </p>
          </div>
          <div className="final-results">
            <h3>Tension Capacity - Seismic</h3>
            <p>
              Q<sub>ult</sub> = 0.7Q<sub>SR</sub> + W
            </p>
            <p>
              Q<sub>ult</sub> = 0.7({round(QSR, 0)}) + ({round(pileWeight, 0)})
            </p>
            <p>
              Q<sub>ult</sub> = {round(qUltTension, 0)} kN
            </p>
            <br />
            <p>
              Q<sub>all</sub> = Q<sub>ult</sub> / FS
            </p>
            <p>
              Q<sub>all</sub> = ({round(qUltTension, 0)}) / ({round(FsSs, 2)})
            </p>
            <p>
              {qAllTensionIsEnough ? (
                <span>
                  <span>
                    Q<sub>all</sub> = {round(qAllTension, 0)} kN &ge; {round(FTension, 0)} kN
                  </span>
                  <span className="enough"> (&#10004;)</span>
                </span>
              ) : (
                <span>
                  <span>
                    Q<sub>all</sub> = {round(qAllTension, 0)} kN &lt; {round(FTension, 0)} kN
                  </span>
                  <span className="not-enough"> (&#10006;)</span>
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PilerockBcResult;
