import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { apiNewsletterSubscription } from '../../helpers/apiRequests';
import validateEmail from '../../helpers/validateEmail';
import MuiSnackbar from '../common/MuiSnackbar';
import CircularProgress from '@mui/material/CircularProgress';

const Newsletter = () => {
  const [subscriberEmail, setSubscriberEmail] = useState('');
  const [error, setError] = useState();
  const [helperText, setHelperText] = useState('');
  const [subscribeButtonDisabled, setSubscribeButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleChangeEmail = (e) => {
    setSubscriberEmail(e.target.value);
    if (e.target.value.length > 0) {
      if (validateEmail(e.target.value)) {
        setError(false);
        setHelperText('');
        setSubscribeButtonDisabled(false);
      } else {
        setError(true);
        setHelperText('Please write a valid email address');
        setSubscribeButtonDisabled(true);
      }
    } else {
      setError(false);
      setHelperText('Please write your email address.');
      setSubscribeButtonDisabled(true);
    }
  };

  const handleSubscirbeClick = async () => {
    setLoading(true);
    setSubscribeButtonDisabled(true);
    const response = await apiNewsletterSubscription({ email: subscriberEmail });
    setLoading(false);
    setSubscribeButtonDisabled(false);

    if (response.status === 'success') {
      showMuiSnack('Thanks for subscribing.', 'success');
    } else {
      showMuiSnack(response.message, 'warning');
    }
  };

  const handleBlur = () => {
    setError(false);
    setHelperText('');
  };

  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const showMuiSnack = (message, type) => {
    setAlertMessage(message);
    setSeverity(type);
    setOpen(true);
  };

  return (
    <div className="emailSubscribe">
      <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
      <TextField
        size="small"
        label="Email"
        variant="outlined"
        placeholder="Your email here"
        style={{ width: 250, marginBottom: 5 }}
        error={error}
        helperText={helperText}
        onChange={handleChangeEmail}
        value={subscriberEmail}
        onBlur={handleBlur}
        onFocus={handleChangeEmail}
      />

      <Button
        variant="contained"
        size="small"
        style={{ width: 250 }}
        onClick={handleSubscirbeClick}
        disabled={subscribeButtonDisabled}
      >
        {loading ? <CircularProgress size={20} /> : 'Subscribe'}
      </Button>
    </div>
  );
};

export default Newsletter;
