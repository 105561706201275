import round from '../../../helpers/round';

const HansenResultShapeFactors = ({ result }) => {
  const {
    phips,
    effectiveFndWidth,
    effectiveFndLength,
    isInclinedLoading,
    isPhiZero,
    nc,
    nq,
    icb,
    icl,
    iqb,
    iql,
    iGammab,
    iGammal,
    sc,
    scb,
    scl,
    sq,
    sqb,
    sql,
    sGamma,
    sGammab,
    sGammal,
    sGammabInitial,
    sGammalInitial,
    issGammabOver06,
    issGammalOver06,
    sGammaInitial,
    issGammaOver06,
  } = result.output;

  const case1 = isInclinedLoading && isPhiZero;
  const case2 = isInclinedLoading && !isPhiZero;
  const case3 = !isInclinedLoading && isPhiZero;
  const case4 = !isInclinedLoading && !isPhiZero;

  const Case1Case3Sc = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          s'<sub>c</sub>
        </h4>
        <p>
          &phi; = 0 &rarr; Only s'<sub>c</sub> should be calculated.
        </p>
        <p>
          s'<sub>c</sub> = 0.2 (B' / L')
        </p>
        <p>
          s'<sub>c</sub> = 0.2 ({round(effectiveFndWidth, 2)}) / ({round(effectiveFndLength, 2)})
        </p>
        <p>
          s'<sub>c</sub> = {round(sc, 2)}
        </p>
      </div>
    );
  };

  const Case2Sq = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          s<sub>q</sub>
        </h4>
        <p>
          H<sub>B</sub> &ne; 0 and/or H<sub>L</sub> &ne; 0 (Inclined loading.)
        </p>
        <p>
          s<sub>q, B</sub> and s<sub>q, L</sub> should be calculated.
        </p>
        <br />
        <p>
          s<sub>q, B</sub> = 1 + (B' / L') (i<sub>q, B</sub>) sin&phi;
        </p>
        <p>
          s<sub>q, B</sub> = 1 + ({round(effectiveFndWidth, 2)} / {round(effectiveFndLength, 2)}) ({round(iqb, 2)}) (sin
          {round(phips, 2)});
        </p>
        <p>
          s<sub>q, B</sub>= {round(sqb, 2)}
        </p>
        <br />
        <p>
          s<sub>q, L</sub> = 1 + (L' / B') (i<sub>q, L</sub>) sin&phi;
        </p>
        <p>
          s<sub>q, L</sub> = 1 + ({round(effectiveFndLength, 2)} / {round(effectiveFndWidth, 2)}) ({round(iql, 2)}) (sin
          {round(phips, 2)});
        </p>
        <p>
          s<sub>q, L</sub>= {round(sql, 2)}
        </p>
      </div>
    );
  };
  const Case2Sc = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          s<sub>c</sub>
        </h4>
        <p>
          H<sub>B</sub> &ne; 0 and/or H<sub>L</sub> &ne; 0 (Inclined loading.)
        </p>
        <p>
          s<sub>c, B</sub> and s<sub>c, L</sub> should be calculated.
        </p>
        <br />
        <p>
          s<sub>c, B</sub> = 1 + (N<sub>q</sub> / N<sub>c</sub>) (B' / L') (i<sub>c, B</sub>)
        </p>
        <p>
          s<sub>c, B</sub> = 1 + ({round(nq, 2)} / {round(nc, 2)}) ({round(effectiveFndWidth, 2)} / {round(effectiveFndLength, 2)}
          ) ({round(icb, 2)})
        </p>
        <p>
          s<sub>c, B</sub> = {round(scb, 2)}
        </p>
        <br />
        <p>
          s<sub>c, L</sub> = 1 + (N<sub>q</sub> / N<sub>c</sub>) (L' / B') (i<sub>c, L</sub>)
        </p>
        <p>
          s<sub>c, L</sub> = 1 + ({round(nq, 2)} / {round(nc, 2)}) ({round(effectiveFndLength, 2)} / {round(effectiveFndWidth, 2)}
          ) ({round(icl, 2)})
        </p>
        <p>
          s<sub>c, L</sub> = {round(scl, 2)}
        </p>
      </div>
    );
  };
  const Case2Sgamma = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          s<sub>&gamma;</sub>
        </h4>
        <p>
          H<sub>B</sub> &ne; 0 and/or H<sub>L</sub> &ne; 0 (Inclined loading.)
        </p>
        <p>
          s<sub>&gamma;, B</sub> and s<sub>&gamma;, L</sub> should be calculated.
        </p>
        <br />
        <p>
          s<sub>&gamma;, B</sub> = 1 - 0.4 (B' / L') (i<sub>&gamma;, B</sub> / i<sub>&gamma;, L</sub>)
        </p>
        <p>
          s<sub>&gamma;, B</sub> = 1 - 0.4 ({round(effectiveFndWidth, 2)} / {round(effectiveFndLength, 2)}) ({round(iGammab, 2)} /{' '}
          {round(iGammal, 2)})
        </p>
        <p>
          s<sub>&gamma;, B</sub> = {round(sGammabInitial, 2)}{' '}
          {issGammabOver06 ? (
            <span> &ge; 0.6 (OK)</span>
          ) : (
            <span>
              &lt;0.6 (NOT OK) Use s<sub>&gamma;, B</sub> = {round(sGammab, 2)}
            </span>
          )}
        </p>
        <br />
        <p>
          s<sub>&gamma;, L</sub> = 1 - 0.4 (L' / B') (i<sub>&gamma;, L</sub> / i<sub>&gamma;, B</sub>)
        </p>
        <p>
          s<sub>&gamma;, L</sub> = 1 - 0.4 ({round(effectiveFndLength, 2)} / {round(effectiveFndWidth, 2)}) ({round(iGammal, 2)} /{' '}
          {round(iGammab, 2)})
        </p>
        <p>
          s<sub>&gamma;, L</sub> = {round(sGammalInitial, 2)}{' '}
          {issGammalOver06 ? (
            <span> &ge; 0.6 (OK)</span>
          ) : (
            <span>
              &lt;0.6 (NOT OK) <br />
              Use s<sub>&gamma;, L</sub> = {round(sGammal, 2)}
            </span>
          )}
        </p>
      </div>
    );
  };

  const Case4Sc = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          s<sub>c</sub>
        </h4>
        <p>
          H<sub>B</sub> = H<sub>L</sub> = 0 (No inclined loading.)
        </p>
        <p>
          s<sub>c</sub> = 1 + (N<sub>q</sub> / N<sub>c</sub>) (B' / L')
        </p>
        <p>
          s<sub>c</sub> = 1 + ({round(nq, 2)} / {round(nc, 2)}) ({round(effectiveFndWidth, 2)} / {round(effectiveFndLength, 2)})
        </p>
        <p>
          s<sub>c</sub> = {round(sc, 2)}
        </p>
      </div>
    );
  };
  const Case4Sq = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          s<sub>q</sub>
        </h4>
        <p>
          H<sub>B</sub> = H<sub>L</sub> = 0 (No inclined loading.)
        </p>
        <p>
          s<sub>q</sub> = 1 + (B' / L') sin&phi;
        </p>
        <p>
          s<sub>q</sub> = 1 + ({round(effectiveFndWidth, 2)} / {round(effectiveFndLength, 2)}) (sin
          {round(phips, 2)});
        </p>
        <p>
          s<sub>q</sub> = {round(sq, 2)}
        </p>
      </div>
    );
  };
  const Case4Sgamma = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          s<sub>&gamma;</sub>
        </h4>
        <p>
          H<sub>B</sub> = H<sub>L</sub> = 0 (No inclined loading.)
        </p>
        <p>
          s<sub>&gamma;</sub> = 1 - 0.4 (B' / L')
        </p>
        <p>
          s<sub>&gamma;</sub> = 1 - 0.4 ({round(effectiveFndWidth, 2)} / {round(effectiveFndLength, 2)})
        </p>
        <p>
          s<sub>&gamma;</sub> = {round(sGammaInitial, 2)}{' '}
          {issGammaOver06 ? (
            <span> &ge; 0.6 (OK)</span>
          ) : (
            <span>
              &lt;0.6 (NOT OK){' '}
              <p>
                Use s<sub>&gamma;, L</sub> = {round(sGamma, 2)}
              </p>
            </span>
          )}
        </p>
      </div>
    );
  };
  return (
    <div className="checks">
      <h3>
        Shape Factors (s<sub>c</sub>, s<sub>q</sub>, s<sub>&gamma;</sub>)
      </h3>
      <div className="factors-wrapper">
        {case1 && <Case1Case3Sc />}

        {case2 && <Case2Sq />}
        {case2 && <Case2Sc />}
        {case2 && <Case2Sgamma />}

        {case3 && <Case1Case3Sc />}

        {case4 && <Case4Sc />}
        {case4 && <Case4Sq />}
        {case4 && <Case4Sgamma />}
      </div>
    </div>
  );
};
export default HansenResultShapeFactors;
