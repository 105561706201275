import React, { forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const MuiSnackbar = ({ open, setOpen, severity, alertMessage }) => {
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} variant="standard" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {/* <AlertTitle>Alert title here</AlertTitle> */}
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};
export default MuiSnackbar;
