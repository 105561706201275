import round from '../../../helpers/round';

const HansenResultHelpfulValues = ({ result }) => {
  const { fndWidth, fndLength, eccentricityX, eccentricityY, phi, cohesion, caCoefficient, df } = result.input;

  const {
    effectiveFndWidth,
    effectiveFndLength,
    effectiveFndRatio,
    effectiveFndArea,
    isInclinedLoading,
    isPhiZero,
    isWidthLargerThanLength,
    fndRatio,
    IsfndRatioOver2,
    isPhiOver34,
    phips,
    ca,
    dfOverFndWidth,
    dfOverFndLength,
    isDfOverFndWidthGreaterThan1,
    isDfOverFndLengthGreaterThan1,
    k,
    kb,
    kl,
  } = result.output;

  const showBLstuff = isInclinedLoading && !isPhiZero;
  return (
    <div className="checks">
      <h3>Calculation of Some Middle Values</h3>
      <div className="factors-wrapper">
        <div className="meyerhof-factors">
          <h4>Effective Foundation Width (B')</h4>
          <p>
            B'= B-2e<sub>y</sub>
          </p>
          <p>
            B'= {round(fndWidth, 2)}-2({round(eccentricityY, 2)})
          </p>
          {isWidthLargerThanLength ? <p>B'= {round(effectiveFndLength, 2)}m</p> : <p>B'= {round(effectiveFndWidth, 2)}m</p>}
          {isWidthLargerThanLength && <p>Since B'&gt; L'</p>}
          {isWidthLargerThanLength && <p>B'= {round(effectiveFndWidth, 2)}m</p>}
        </div>
        <div className="meyerhof-factors">
          <h4>Effective Foundation Length (L')</h4>
          <p>
            L'= L-2e<sub>x</sub>
          </p>
          <p>
            L'= {round(fndLength, 2)}-2({round(eccentricityX, 2)})
          </p>
          {isWidthLargerThanLength ? <p>L'= {round(effectiveFndWidth, 2)}m</p> : <p>L'= {round(effectiveFndLength, 2)}m</p>}
          {isWidthLargerThanLength && <p>Since L'&lt; B'</p>}
          {isWidthLargerThanLength && <p>L'= {round(effectiveFndLength, 2)}m</p>}
        </div>
        <div className="meyerhof-factors">
          <h4>
            Effective Foundation Area (A<sub>f</sub>)
          </h4>
          <p>
            A<sub>f</sub>= B'L'
          </p>
          <p>
            A<sub>f</sub>= ({round(effectiveFndWidth, 2)})({round(effectiveFndLength, 2)})
          </p>
          <p>
            A<sub>f</sub>= {round(effectiveFndArea, 2)}m<sup>2</sup>
          </p>
        </div>
        <div className="meyerhof-factors">
          <h4>Effective Foundation Ratio (B'/L')</h4>
          <p>
            B'/L'= ({round(effectiveFndWidth, 2)})/({round(effectiveFndLength, 2)})
          </p>

          <p>B'/L'= {round(effectiveFndRatio, 2)}</p>
        </div>
        <div className="meyerhof-factors">
          <h4>
            Base adhesion (C<sub>a</sub>)
          </h4>
          <p>
            C<sub>a</sub>= (c)(cohesion reduction factor)
          </p>
          <p>
            C<sub>a</sub>= ({round(cohesion, 2)})({round(caCoefficient, 2)})
          </p>
          <p>
            C<sub>a</sub>= {round(ca, 2)} kPa
          </p>
        </div>
        <div className="meyerhof-factors">
          <h4>
            Plane Strain Friction Angle (&phi;<sub>ps</sub>)
          </h4>
          <p>
            If L/B &gt;2 and &phi; &gt;34 &rarr; Use &phi;<sub>ps</sub>
          </p>
          <p>
            L/B = {round(fndLength, 2)}/{round(fndWidth, 2)} = {round(fndRatio, 2)}
            {IsfndRatioOver2 ? <span>&gt;2 (OK)</span> : <span>&le;2 (NOT OK)</span>}
          </p>
          <p>{phi > 34 ? <span>&phi;={round(phi, 2)} &gt; 34 (OK)</span> : <span>&phi;={round(phi, 2)}&le;34 (NOT OK)</span>}</p>
          {IsfndRatioOver2 && isPhiOver34 ? (
            <>
              <p>
                Both conditions OK. Use &phi;<sub>ps</sub> as follows:
              </p>
              <p>
                &phi;<sub>ps</sub> = 1.5&phi;-17
              </p>
              <p>
                &phi;<sub>ps</sub> = 1.5({round(phi, 2)})-17
              </p>
              <p>
                &phi;<sub>ps</sub> = {round(phips, 2)}
              </p>
            </>
          ) : (
            <>
              <p>Both conditions not OK. Use &phi; as follows:</p>
              <p>
                &phi;<sub>ps</sub> = &phi; = {round(phi, 2)}
              </p>
            </>
          )}
        </div>
        {!showBLstuff && (
          <div className="meyerhof-factors">
            <h4>Depth factor coefficient (k)</h4>

            <p>
              D<sub>f</sub> / B &le; 1 &rarr; k = D<sub>f</sub> / B{' '}
            </p>
            <p>
              D<sub>f</sub> / B &gt; 1 &rarr; k = arctan (D<sub>f</sub> / B)
            </p>
            <p>k in radians.</p>
            <p>
              Since D<sub>f</sub> / B = {round(df, 2)} / {round(fndWidth, 2)} = {round(dfOverFndWidth, 2)}
              {isDfOverFndWidthGreaterThan1 ? <span> &gt; 1</span> : <span> &le; 1</span>}
            </p>
            {isDfOverFndWidthGreaterThan1 ? (
              <>
                <p>
                  k = arctan (D<sub>f</sub> / B)
                </p>
                <p>
                  k = arctan ({round(df, 2)} / {round(fndWidth, 2)})
                </p>
                <p>k = {round(k, 2)}</p>
              </>
            ) : (
              <>
                <p>
                  k = D<sub>f</sub> / B = {round(df, 2)} / {round(fndWidth, 2)}
                </p>
                <p>k = {round(k, 2)}</p>
              </>
            )}
          </div>
        )}
        {showBLstuff && (
          <div className="meyerhof-factors">
            <h4>
              Depth factor coefficient (k<sub>B</sub>)
            </h4>
            <p>
              D<sub>f</sub> / B &le; 1 &rarr; k<sub>B</sub> = D<sub>f</sub> / B{' '}
            </p>
            <p>
              D<sub>f</sub> / B &gt; 1 &rarr; k<sub>B</sub> = arctan (D<sub>f</sub> / B)
            </p>
            <p>
              k<sub>B</sub> in radians.
            </p>
            <p>
              Since D<sub>f</sub> / B = {round(df, 2)} / {round(fndWidth, 2)} = {round(dfOverFndWidth, 2)}{' '}
              {isDfOverFndWidthGreaterThan1 ? <span> &gt; 1</span> : <span> &le; 1</span>}
            </p>

            {isDfOverFndWidthGreaterThan1 ? (
              <>
                <p>
                  k<sub>B</sub> = arctan (D<sub>f</sub> / B)
                </p>
                <p>
                  k<sub>B</sub> = arctan ({round(df, 2)} / {round(fndWidth, 2)})
                </p>
                <p>
                  k<sub>B</sub> = {round(kb, 2)}
                </p>
              </>
            ) : (
              <>
                <p>
                  k<sub>B</sub> = D<sub>f</sub> / B = {round(df, 2)} / {round(fndWidth, 2)}
                </p>
                <p>
                  k<sub>B</sub> = {round(kb, 2)}
                </p>
              </>
            )}
          </div>
        )}
        {showBLstuff && (
          <div className="meyerhof-factors">
            <h4>
              Depth factor coefficient (k<sub>L</sub>)
            </h4>

            <p>
              D<sub>f</sub> / L &le; 1 &rarr; k<sub>L</sub> = D<sub>f</sub> / L{' '}
            </p>
            <p>
              D<sub>f</sub> / L &gt; 1 &rarr; k<sub>L</sub> = arctan (D<sub>f</sub> / L)
            </p>
            <p>
              k<sub>L</sub> in radians.
            </p>
            <p>
              Since D<sub>f</sub> / L = {round(df, 2)} / {round(fndLength, 2)} = {round(dfOverFndLength, 2)}{' '}
              {isDfOverFndWidthGreaterThan1 ? <span> &gt; 1</span> : <span> &le; 1</span>}
            </p>
            {isDfOverFndLengthGreaterThan1 ? (
              <>
                <p>
                  k<sub>L</sub> = arctan (D<sub>f</sub> / L)
                </p>
                <p>
                  k<sub>L</sub> = arctan ({round(df, 2)} / {round(fndLength, 2)})
                </p>
                <p>
                  k<sub>L</sub> = {round(kl, 2)}
                </p>
              </>
            ) : (
              <>
                <p>
                  k<sub>L</sub> = D<sub>f</sub> / L = {round(df, 2)} / {round(fndLength, 2)}
                </p>
                <p>
                  k<sub>L</sub> = {round(kl, 2)}
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default HansenResultHelpfulValues;
