import React, { useEffect, useContext } from 'react';
import MyCalculations from './MyCalculations';
import { Context } from '../../App';

const Sidebar = ({
  loadAllCalculations,
  calculations,
  loadOneCalculation,
  deleteCalculations,
  createNewCalculation,
  isLoading,
  displaySidebar,
  handleSidebarDisplay,
  URL,
  setInputs,
  calculate,
}) => {
  const { user } = useContext(Context);

  useEffect(() => {
    loadAllCalculations(URL.LOADALL);
  }, []);

  //SET STATES FOR SIDEBAR RENDER

  return (
    displaySidebar && (
      <div className="left-sidebar">
        <MyCalculations
          calculations={calculations}
          isLoading={isLoading}
          loadOneCalculation={loadOneCalculation}
          calculate={calculate}
          deleteCalculations={deleteCalculations}
          createNewCalculation={createNewCalculation}
          handleSidebarDisplay={handleSidebarDisplay}
          URL={URL}
          setInputs={setInputs}
          userRole={user.role}
        />
      </div>
    )
  );
};

export default Sidebar;
