import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';

import Header from '../common/Header';
import Footer from '../common/Footer';
import MuiSnackbar from '../common/MuiSnackbar';

const ResetPassword = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Reset Password';
  }, []);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setpasswordConfirm] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { token } = useParams();
  const nav = useNavigate();
  useEffect(() => {
    if (password.length > 7 && passwordConfirm.length > 7 && password === passwordConfirm) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [password, passwordConfirm]);

  const changePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios({
        method: 'PATCH',
        url: `/api/v1/users/resetPassword/${token}`,
        data: {
          password,
          passwordConfirm,
        },
      });

      if (response.data.status === 'success') {
        showMuiSnack('Your password updated succesfully!', 'success');
        setPassword('');
        setpasswordConfirm('');
        setTimeout(() => {
          nav('/login');
        }, 1500);
      }
    } catch (err) {
      showMuiSnack(err.response.data.message, 'error');
    }
    setLoading(false);
  };

  const handleClickShowPassword = (variable, setter) => {
    setter(!variable);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const showMuiSnack = (message, type) => {
    setAlertMessage(message);
    setSeverity(type);
    setOpen(true);
  };

  return (
    <>
      <Header />
      <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
      <div className="login">
        <LockIcon color="primary" fontSize="large" />

        <h2>Reset Your Password</h2>
        <form className="forgotPassword">
          <TextField
            size="small"
            style={{ width: 300 }}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      handleClickShowPassword(showPassword, setShowPassword);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="New Password"
          />
          <TextField
            size="small"
            style={{ width: 300 }}
            type={showPasswordConfirm ? 'text' : 'password'}
            value={passwordConfirm}
            onChange={(e) => setpasswordConfirm(e.target.value)}
            autoComplete="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      handleClickShowPassword(showPasswordConfirm, setShowPasswordConfirm);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Confirm New Password"
          />
          <Button variant="contained" style={{ width: 300 }} onClick={changePassword} disabled={buttonDisabled}>
            {loading ? <CircularProgress size={25} color="grey" /> : 'Update Password'}
          </Button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;
