import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';
import { CircularProgress } from '@mui/material';
import axios from 'axios';

const CalculationCounts = () => {
  const [loading, setLoading] = useState(false);
  const [calculationCounts, setCalculationCounts] = useState({});
  useEffect(() => {
    const getAllCalculations = async () => {
      setLoading(true);
      setCalculationCounts({});
      try {
        const response = await axios({
          method: 'GET',
          url: '/api/v1/admin/getAllSaves',
        });

        setCalculationCounts(response.data);
      } catch (err) {
        console.log('Error->', err);
      }
      setLoading(false);
    };
    getAllCalculations();
  }, []);

  const Calculation = ({ calculation, totalCount }) => {
    return (
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle totalCalculationCountsTitles">
            <div>{calculation.name}:</div>
            <div className="number">{loading ? <CircularProgress size={10} /> : calculation.count}</div>
          </div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (calculation.count / totalCount) * 100}
          title={loading ? 0 : `${Math.floor((calculation.count / totalCount) * 100)}%`}
        />
      </div>
    );
  };
  return (
    <div className="UserSummary totalCalculationCounts">
      <div className="summaryContainer">
        <div className="summary">
          <div className="number">{loading ? <CircularProgress size={30} /> : calculationCounts.results?.totalSaveCount}</div>
          <div className="text">Total Save Count</div>
        </div>
        <div className="icon">
          <SaveIcon color="primary" />
        </div>
      </div>
      <Divider />
      {calculationCounts?.individualSaveCounts?.map((calculation) => {
        return (
          <Calculation calculation={calculation} totalCount={calculationCounts.results?.totalSaveCount} key={calculation.name} />
        );
      })}
    </div>
  );
};

export default CalculationCounts;
