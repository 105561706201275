import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import round from '../../../helpers/round';
import MuiSnackbar from '../../common/MuiSnackbar';

const OutputLayer = ({ layer, index, layers, setLayers, resultsObj, isLoadingCalculate, isLoadingLoad, isLoadingSave }) => {
  const regexForPositiveDecimals = /^[+]?\d*(?:[.]\d*)?$/;

  const onChangeWarningForPositiveDecimals = (e, variable, variableSetter) => {
    if (regexForPositiveDecimals.test(e.target.value)) {
      variableSetter(e);
    } else {
      showMuiSnack(`${variable}: Can only be a postive number. `, 'warning');
    }
  };

  const changeZ = (e) => {
    const layer = {
      ...layers[index],
      z: e.target.value,
    };

    const newLayers = layers.slice();
    newLayers.splice(index, 1, layer);
    setLayers(newLayers);
  };

  const changeSPT = (e) => {
    const layer = {
      ...layers[index],
      SPT: e.target.value,
    };

    const newLayers = layers.slice();
    newLayers.splice(index, 1, layer);
    setLayers(newLayers);
  };

  const changeUscs = (e) => {
    const layer = {
      ...layers[index],
      uscs: e.target.value,
    };

    const newLayers = layers.slice();
    newLayers.splice(index, 1, layer);
    setLayers(newLayers);
  };

  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const showMuiSnack = (message, type) => {
    setAlertMessage(message);
    setSeverity(type);
    setOpen(true);
  };

  return (
    <div className="layer">
      <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        value={layer.id}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        value={layer.z}
        onChange={(e) => onChangeWarningForPositiveDecimals(e, 'z', changeZ)}
        disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        value={round(resultsObj?.normalStres, 2)}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        value={round(resultsObj?.effectiveStress, 2)}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        value={layer.SPT}
        onChange={(e) => onChangeWarningForPositiveDecimals(e, 'SPT', changeSPT)}
        disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          minWidth: 120,
          maxWidth: 150,
        }}
        size="small"
        select
        value={layer.uscs}
        onChange={changeUscs}
        disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
      >
        <MenuItem value="0" disabled>
          Select USCS
        </MenuItem>
        <MenuItem value="1">GW</MenuItem>
        <MenuItem value="2">GP</MenuItem>
        <MenuItem value="3">GM</MenuItem>
        <MenuItem value="4">GC</MenuItem>
        <MenuItem value="5">SW</MenuItem>
        <MenuItem value="6">SP</MenuItem>
        <MenuItem value="7">SM</MenuItem>
        <MenuItem value="8">SC</MenuItem>
        <MenuItem value="9">ML</MenuItem>
        <MenuItem value="10">MH</MenuItem>
        <MenuItem value="11">CL</MenuItem>
        <MenuItem value="12">CH</MenuItem>
      </TextField>
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        value={round(resultsObj?.siltySandSPT, 2)}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        value={round(resultsObj?.ce, 2)}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        value={round(resultsObj?.cb, 2)}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        value={round(resultsObj?.cs, 2)}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        value={round(resultsObj?.cr, 2)}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        value={round(resultsObj?.cn, 2)}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        value={round(resultsObj?.cbf, 2)}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        value={round(resultsObj?.n60, 0)}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        value={round(resultsObj?.n160, 0)}
      />
    </div>
  );
};

export default OutputLayer;
