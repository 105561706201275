import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const CalculationErrorMessage = ({ message }) => {
  return (
    <div className="calculationErrorMessage">
      <div className="icon">
        <ErrorOutlineIcon fontSize="small" />
      </div>
      <div className="message">{message}</div>
    </div>
  );
};

export default CalculationErrorMessage;
