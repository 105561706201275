import React from 'react';
import Divider from '@mui/material/Divider';
import HansenResultChecks from './HansenResultChecks';
import HansenResultMiddleValues from './HansenResultMiddleValues';
import HansenResultBcFactors from './HansenResultBcFactors';
import HansenResultInclinationFactors from './HansenResultInclinationFactors';
import HansenResultDepthFactors from './HansenResultDepthFactors';
import HansenResultShapeFactors from './HansenResultShapeFactors';
import HansenResultGroundFactors from './HansenResultGroundFactors';
import HansenResultBaseFactors from './HansenResultBaseFactors';
import HansenResultBearingCapacity from './HansenResultBearingCapacity';
import HansenResultEffectOfGroundWater from './HansenResultEffectOfGroundWater';

const MeyerhofResult = ({ result }) => {
  const { isEccentricityX_OK, isEccentricityY_OK, isMinFndWidth_OK, isMinFndlength_OK, isSliding_OK } = result.output;
  const isAllChecksOK = isEccentricityX_OK && isEccentricityY_OK && isMinFndWidth_OK && isMinFndlength_OK && isSliding_OK;
  return (
    <section className="output">
      <h2>Output </h2>
      <HansenResultChecks result={result} isAllChecksOK={isAllChecksOK} />
      {isAllChecksOK && (
        <>
          <Divider sx={{ margin: '10px 0' }} />
          <HansenResultMiddleValues result={result} />
          <Divider sx={{ margin: '10px 0' }} />
          <HansenResultBcFactors result={result} />
          <Divider sx={{ margin: '10px 0' }} />
          <HansenResultInclinationFactors result={result} />
          <Divider sx={{ margin: '10px 0' }} />
          <HansenResultShapeFactors result={result} />
          <Divider sx={{ margin: '10px 0' }} />
          <HansenResultDepthFactors result={result} />
          <Divider sx={{ margin: '10px 0' }} />
          <HansenResultGroundFactors result={result} />
          <Divider sx={{ margin: '10px 0' }} />
          <HansenResultBaseFactors result={result} />
          <Divider sx={{ margin: '10px 0' }} />
          <HansenResultEffectOfGroundWater result={result} />
          <Divider sx={{ margin: '10px 0' }} />
          <HansenResultBearingCapacity result={result} />
        </>
      )}
    </section>
  );
};

export default MeyerhofResult;
