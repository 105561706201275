import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import Header from '../../common/Header';
import Footer from '../../common/Footer';

const LiquefactionTheory = () => {
  // Google Ads & Set page title
  useEffect(() => {
    document.title = 'GeoCalc | Liquefaction | Theory';
  }, []);

  return (
    <>
      <Header />
      <div className="theory">
        <div className="theoryContainer">
          <h1>Liquefaction | Theory & Formulations</h1>
          <h2>What is liquefaction ?</h2>
          <p>
            Soil liquefaction is a phenomenon that occurs when soil, typically saturated with water, loses its strength and
            stiffness in response to an applied stress, such as shaking during an earthquake or other types of strong ground
            motion. The process occurs when the pressure of the water in the soil pores exceeds the weight of the soil grains,
            causing the soil particles to lose their contact and start moving independently, leading to a reduction in soil
            strength and stiffness.
          </p>
          <p>
            During an earthquake, the shaking can cause the water pressure in the soil to increase and exceed the soil's ability
            to support the weight of the structures built on it. This can result in the soil losing its strength and stiffness,
            which can cause buildings, bridges, and other structures to sink or tilt, and even collapse in severe cases.
          </p>
          <p>
            Soil liquefaction is a significant geotechnical hazard that can have devastating consequences for infrastructure,
            buildings, and people's safety. It is essential to evaluate the potential for liquefaction in a site and implement
            measures to mitigate its effects, such as soil stabilization or retrofitting of structures.
          </p>
          <h2>How to determine factor of safety against liquefaction? </h2>
          <p>
            The Standard Penetration Test (SPT) is a widely used in-situ test to evaluate the strength and liquefaction potential
            of soil. The test involves driving a standard sampler into the soil using a hammer and recording the number of blows
            required to drive the sampler a specific distance. The SPT values obtained from the test can be used to estimate the
            factor of safety against soil liquefaction.
          </p>
          <p>
            The factor of safety against soil liquefaction is defined as the ratio of the available shear strength of the soil to
            the estimated shear stress generated during an earthquake. A factor of safety greater than one indicates that the soil
            is stable and will not liquefy during an earthquake.
          </p>
          <h2>The Procedure and formulations for liquefaction calculations </h2>
          <p>
            The calculation tool on this website for calculating factor of safety against soil liquefaction follows the procedure
            given in the Turkish Earthquake Code (2019) - Chapter 16B. The details of this procedure is given below.
          </p>
          <h3>SPT corrections</h3>
          <p>
            Measured SPT values in the field (N) at each depth (z) will be corrected to (N<sub>1</sub>)<sub>60</sub> values using
            the equation below.
          </p>
          <p className="theoryEquation">
            (N<sub>1</sub>)<sub>60</sub> = N x C<sub>N</sub> x C<sub>R</sub> x C<sub>S</sub> x C<sub>B</sub> x C<sub>E</sub>
          </p>
          <p className="theoryEquation">Where;</p>
          <h4>
            C<sub>N</sub>: Overburden Pressure Correction Factor
          </h4>
          <p>
            Overburden correction factor will be calculated using the equation below. The vertical effective pressure,
            &sigma;'(kN/m2) should be calculated for the ground conditions when the Standard Penetration Test (SPT) is performed.
            Any excavation, fill or foundation loads after the test will be ignored.
          </p>
          <p className="theoryEquation">
            C<sub>N</sub> = 9.78 x (1 / &sigma;') <sup>0.5</sup> &le; 1.70
          </p>
          <h4>
            C<sub>R</sub>: Rod Length Correction Factor
          </h4>
          <p>Rod length correction factor will be selected from the table below.</p>
          <div className="correctionFactorTable">
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Rod Length(m)</div>
              <div className="correctionFactorTableColumn">
                <p>
                  C<sub>R</sub>
                </p>
              </div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">0 - 4</div>
              <div className="correctionFactorTableColumn">0.75</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">4 - 6</div>
              <div className="correctionFactorTableColumn">0.85</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">6 - 10</div>
              <div className="correctionFactorTableColumn">0.95</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn no-border-bottom">&gt; 10</div>
              <div className="correctionFactorTableColumn no-border-bottom">1.00</div>
            </div>
          </div>
          <h4>
            C<sub>S</sub>: Sampler Type Correction Factor
          </h4>
          <p>Sampler type correction factor will be selected from the table below.</p>
          <div className="correctionFactorTable">
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Sampler Type</div>
              <div className="correctionFactorTableColumn">
                <p>
                  C<sub>S</sub>
                </p>
              </div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Standard Liner (With liner)</div>
              <div className="correctionFactorTableColumn">1.00</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn no-border-bottom">Without Liner</div>
              <div className="correctionFactorTableColumn no-border-bottom">1.10 - 1.30</div>
            </div>
          </div>
          <h4>
            C<sub>B</sub>: Borehole Diameter Correction Factor
          </h4>
          <p>Borehole diameter correction factor will be selected from the table below.</p>
          <div className="correctionFactorTable">
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Borehole Dia.(mm)</div>
              <div className="correctionFactorTableColumn">
                <p>
                  C<sub>B</sub>
                </p>
              </div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">65 - 115</div>
              <div className="correctionFactorTableColumn">1.00</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">150</div>
              <div className="correctionFactorTableColumn">1.05</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn no-border-bottom">200</div>
              <div className="correctionFactorTableColumn no-border-bottom">1.15</div>
            </div>
          </div>
          <h4>
            C<sub>E</sub>: Energy correction factor
          </h4>
          <p>Energy correction factor will be selected from the table below.</p>
          <div className="correctionFactorTable">
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Hammer Type</div>
              <div className="correctionFactorTableColumn">
                <p>
                  C<sub>E</sub>
                </p>
              </div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Safety</div>
              <div className="correctionFactorTableColumn">0.60 - 1.17</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Donut</div>
              <div className="correctionFactorTableColumn">0.45 - 1.00</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn no-border-bottom">Auto</div>
              <div className="correctionFactorTableColumn no-border-bottom">0.90 - 1.60</div>
            </div>
          </div>
          <h4>SPT corrections for fine content</h4>
          <p>
            (N<sub>1</sub>)<sub>60</sub> values will be corrected to (N<sub>1</sub>)<sub>60,f</sub> values considering the fine
            content (FC%) of the samples using the equation below.
          </p>
          <p className="theoryEquation">
            (N<sub>1</sub>)<sub>60,f</sub> = &alpha; + &beta;(N<sub>1</sub>)<sub>60</sub>
          </p>
          <p className="theoryEquation">Where, &alpha; and &beta; are calculated as follows;</p>

          <div className="correctionFactorTable">
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">FC (%)</div>
              <div className="correctionFactorTableColumn">&alpha;</div>
              <div className="correctionFactorTableColumn">&beta;</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">FC &le; 5</div>
              <div className="correctionFactorTableColumn">0</div>
              <div className="correctionFactorTableColumn">1</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn ">5 &lt; FC &le; 35</div>
              <div className="correctionFactorTableColumn">
                <p>
                  e
                  <sup>
                    1.76 - (190 / FC<sup>2</sup>)
                  </sup>
                </p>
              </div>
              <div className="correctionFactorTableColumn">
                <p>
                  0.99 + FC<sup>1.5</sup> / 1000
                </p>
              </div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn no-border-bottom">FC &gt; 35</div>
              <div className="correctionFactorTableColumn no-border-bottom">5</div>
              <div className="correctionFactorTableColumn no-border-bottom">1.2</div>
            </div>
          </div>
          <h3>Liquefaction Resistance of Soil</h3>
          <p>
            &tau;<sub>R</sub> (Liquefaction resistance of the soil) will be calculated by multiplying CRR<sub>M7.5</sub> (cyclic
            resistance ratio for a magnetide 7.5 earthquake), C<sub>M</sub> (Earthquake magnitude correction factor) and effective
            stress (&sigma;') as follows:
          </p>
          <p className="theoryEquation">
            &tau;<sub>R</sub> = CRR<sub>M7.5</sub> x C<sub>M</sub> x &sigma;'
          </p>
          <p className="theoryEquation">Where;</p>
          <p className="theoryEquation">
            {/* CRR M7.5*/}
            <math display="block">
              <msub>
                <mi>CRR</mi>
                <mn>M7.5</mn>
              </msub>
              <mo>=</mo>
              {/* FIRST TERM STARTS*/}
              <mfrac>
                <mrow>
                  <mn>1</mn>
                </mrow>
                <mrow>
                  <mn>34</mn>
                  <mo>−</mo>
                  <msub>
                    <mi>N</mi>
                    <mn>1,60f</mn>
                  </msub>
                </mrow>
              </mfrac>
              {/* FIRST TERM ENDS*/}
              <mo>+</mo>
              {/* SECOND TERM STARTS*/}
              <mfrac>
                <mrow>
                  <msub>
                    <mi>N</mi>
                    <mn>1,60f</mn>
                  </msub>
                </mrow>
                <mrow>
                  <mn>135</mn>
                </mrow>
              </mfrac>
              {/* SECOND TERM ENDS*/}
              <mo>+</mo>
              {/* THIRD TERM STARTS*/}
              <mfrac>
                <mrow>
                  <mn>50</mn>
                </mrow>
                <msup>
                  <mrow>
                    <mo>(</mo>
                    <mn>10</mn>
                    <msub>
                      <mi>N</mi>
                      <mn>1,60f</mn>
                    </msub>
                    <mo>+</mo>
                    <mn>45</mn>
                    <mo>)</mo>
                  </mrow>
                  <mn>2</mn>
                </msup>
              </mfrac>
              {/* THIRD TERM ENDS*/}
              <mo>-</mo>
              {/* FORTH TERM STARTS*/}
              <mfrac>
                <mrow>
                  <mn>1</mn>
                </mrow>
                <mrow>
                  <mn>200</mn>
                </mrow>
              </mfrac>
              {/* FORTH TERM ENDS*/}
            </math>
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>C</mi>
                <mn>M</mn>
              </msub>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msup>
                    <mn>10</mn>
                    <mn>2.24</mn>
                  </msup>
                </mrow>
                <mrow>
                  <msup>
                    <msub>
                      <mi>M</mi>
                      <mi>w</mi>
                    </msub>
                    <mn>2.56</mn>
                  </msup>
                </mrow>
              </mfrac>
            </math>
          </p>
          <h3>Average Cyclic Shear Stress</h3>
          <p>
            &tau;<sub>eq</sub> (Average cyclic shear stress) will be calculated by multiplying &sigma; (total stress at
            calculation depth) SDS (Design spectral response acceleration parameter at short periods), r<sub>d</sub> (Stress
            reduction factor) as follows:
          </p>
          <p className="theoryEquation">
            &tau;<sub>eq</sub> = 0.65 x &sigma; x (0.4S<sub>DS</sub>) x r<sub>d</sub>
          </p>
          <p className="theoryEquation">
            Where, r<sub>d</sub> will be calculated as follows;
          </p>
          <div className="correctionFactorTable">
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">z(m)</div>
              <div className="correctionFactorTableColumn">
                <p>
                  r<sub>d</sub>
                </p>
              </div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">z &le; 9.15</div>
              <div className="correctionFactorTableColumn">1 - 0.00765z</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn "> 9.15 &lt; z &le; 23</div>
              <div className="correctionFactorTableColumn ">1.174 - 0.0267z</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn "> 23 &lt; z &le; 30</div>
              <div className="correctionFactorTableColumn ">0.744 - 0.008z</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn no-border-bottom">z &gt; 30</div>
              <div className="correctionFactorTableColumn no-border-bottom">0.5</div>
            </div>
          </div>
          <h3>Factor of Safety Against Liquefaction</h3>
          <p className="theoryEquation">
            <math display="block">
              <mi>FS</mi>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>&tau;</mi>
                    <mi>R</mi>
                  </msub>
                </mrow>
                <mrow>
                  <msub>
                    <mi>&tau;</mi>
                    <mi>eq</mi>
                  </msub>
                </mrow>
              </mfrac>
            </math>
          </p>
          <div className="theoryButtonContainer">
            <Link to={'/calculations/liquefaction-tbdy'}>
              <Button variant="outlined" size="small">
                GO TO LIQUEFACTION CALCULATOR
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LiquefactionTheory;
