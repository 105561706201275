import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

import Header from '../../common/Header';
import Footer from '../../common/Footer';
import SubscriptionBox from './SubscriptionBox';

const Pricing = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Pricing';
  }, []);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllSubscriptions = async () => {
      setLoading(true);
      try {
        const response = await axios({
          method: 'GET',
          url: '/api/v1/users/getAllSubscriptions',
        });
        setSubscriptions(response.data.subscriptions);
      } catch (err) {
        /* console.log(err); */
      }
      setLoading(false);
    };
    getAllSubscriptions();
  }, []);

  return (
    <>
      <Header />
      <div className={`${loading ? 'verticallyAlignParent' : 'pricing'}`}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div className="pricingContainer">
              {subscriptions.map((subscription) => {
                return <SubscriptionBox subscription={subscription} key={subscription.name} />;
              })}
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
