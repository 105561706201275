import React from 'react';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import LockIcon from '@mui/icons-material/Lock';

const CalculateButton = ({
  calculationCountReached,
  calculate,
  calculationInput,
  URL,
  calculateButtonDisabled,
  isLoadingCalculate,
  isLoadingLoad,
  isLoadingSave,
  layersFrontLength,
}) => {
  return (
    <>
      {calculationCountReached ? (
        <Tooltip
          title="You reached your calculation limit. Consider upgrading to a higher plan."
          arrow
          placement="bottom"
          TransitionComponent={Zoom}
        >
          <span>
            <Button
              variant="contained"
              disabled={true}
              onClick={(e) => {
                e.preventDefault();
                calculate(calculationInput, URL.CALCULATE);
              }}
            >
              Calculate
              <LockIcon fontSize={'small'} />
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          disabled={
            calculateButtonDisabled ||
            isLoadingCalculate ||
            isLoadingLoad ||
            isLoadingSave ||
            layersFrontLength < 1
          }
          onClick={(e) => {
            e.preventDefault();
            calculate(calculationInput, URL.CALCULATE);
          }}
        >
          Calculate
        </Button>
      )}
    </>
  );
};

export default CalculateButton;
