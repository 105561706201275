import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import Zoom from '@mui/material/Zoom';

export const CalculationName = ({
  name,
  MAX_NAME_LENGTH,
  handleChangeName,
  isLoadingCalculate,
  isLoadingLoad,
  isLoadingSave,
}) => {
  return (
    <TextField
      style={{ width: 140 }}
      size="small"
      label="Name"
      variant="outlined"
      inputProps={{
        maxLength: `${MAX_NAME_LENGTH}`,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip
              title={`Your calculation name (Max. ${MAX_NAME_LENGTH} characters)`}
              arrow
              placement="bottom"
              TransitionComponent={Zoom}
            >
              <InfoTwoToneIcon fontSize={'string'} />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      value={name}
      onChange={handleChangeName}
      disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
    />
  );
};

export const W = ({ checked, w, setw, isLoadingCalculate, isLoadingLoad, isLoadingSave, onChangeWarningForPositiveDecimals }) => {
  return (
    <TextField
      style={{ width: 100 }}
      size="small"
      label="w"
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={'Moisture content'} arrow placement="bottom" TransitionComponent={Zoom}>
              <InfoTwoToneIcon fontSize={'string'} />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      value={checked ? w : ''}
      onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Moisture content', setw)}
      disabled={!checked || isLoadingCalculate || isLoadingLoad || isLoadingSave}
    />
  );
};

export const Gs = ({
  checked,
  gs,
  setgs,
  isLoadingCalculate,
  isLoadingLoad,
  isLoadingSave,
  onChangeWarningForPositiveDecimals,
}) => {
  return (
    <TextField
      style={{ width: 100 }}
      size="small"
      label={
        <span>
          G<sub>s</sub>
        </span>
      }
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={'Specific gravity of soil solids'} arrow placement="bottom" TransitionComponent={Zoom}>
              <InfoTwoToneIcon fontSize={'string'} />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      value={checked ? gs : ''}
      onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Specific gravity', setgs)}
      disabled={!checked || isLoadingCalculate || isLoadingLoad || isLoadingSave}
    />
  );
};

export const N = ({ checked, n, setn, isLoadingCalculate, isLoadingLoad, isLoadingSave, onChangeWarningForPositiveDecimals }) => {
  return (
    <TextField
      style={{ width: 100 }}
      size="small"
      label={'n'}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={'Porosity'} arrow placement="bottom" TransitionComponent={Zoom}>
              <InfoTwoToneIcon fontSize={'string'} />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      value={checked ? n : ''}
      onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Porosity', setn)}
      disabled={!checked || isLoadingCalculate || isLoadingLoad || isLoadingSave}
    />
  );
};

export const E = ({ checked, e, sete, isLoadingCalculate, isLoadingLoad, isLoadingSave, onChangeWarningForPositiveDecimals }) => {
  return (
    <TextField
      style={{ width: 100 }}
      size="small"
      label={'e'}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={'Void ratio'} arrow placement="bottom" TransitionComponent={Zoom}>
              <InfoTwoToneIcon fontSize={'string'} />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      value={checked ? e : ''}
      onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Void ratio', sete)}
      disabled={!checked || isLoadingCalculate || isLoadingLoad || isLoadingSave}
    />
  );
};

export const S = ({ checked, s, sets, isLoadingCalculate, isLoadingLoad, isLoadingSave, onChangeWarningForPositiveDecimals }) => {
  return (
    <TextField
      style={{ width: 100 }}
      size="small"
      label={'S'}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={'Degree of saturation'} arrow placement="bottom" TransitionComponent={Zoom}>
              <InfoTwoToneIcon fontSize={'string'} />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      value={checked ? s : ''}
      onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Degree of saturation(s)', sets)}
      disabled={!checked || isLoadingCalculate || isLoadingLoad || isLoadingSave}
    />
  );
};

export const GammaMoist = ({
  checked,
  gammaMoist,
  setgammaMoist,
  isLoadingCalculate,
  isLoadingLoad,
  isLoadingSave,
  onChangeWarningForPositiveDecimals,
}) => {
  return (
    <TextField
      style={{ width: 100 }}
      size="small"
      label={<span>&gamma;</span>}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={'Moist unit weight'} arrow placement="bottom" TransitionComponent={Zoom}>
              <InfoTwoToneIcon fontSize={'string'} />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      value={checked ? gammaMoist : ''}
      onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Moist unit weight', setgammaMoist)}
      disabled={!checked || isLoadingCalculate || isLoadingLoad || isLoadingSave}
    />
  );
};

export const GammaSat = ({
  checked,
  gammaSat,
  setgammaSat,
  isLoadingCalculate,
  isLoadingLoad,
  isLoadingSave,
  onChangeWarningForPositiveDecimals,
}) => {
  return (
    <TextField
      style={{ width: 100 }}
      size="small"
      label={
        <span>
          &gamma;<sub>sat</sub>
        </span>
      }
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={'Saturated unit weight'} arrow placement="bottom" TransitionComponent={Zoom}>
              <InfoTwoToneIcon fontSize={'string'} />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      value={checked ? gammaSat : ''}
      onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Saturated unit weight', setgammaSat)}
      disabled={!checked || isLoadingCalculate || isLoadingLoad || isLoadingSave}
    />
  );
};

export const GammaDry = ({
  checked,
  gammaDry,
  setgammaDry,
  isLoadingCalculate,
  isLoadingLoad,
  isLoadingSave,
  onChangeWarningForPositiveDecimals,
}) => {
  return (
    <TextField
      style={{ width: 100 }}
      size="small"
      label={
        <span>
          &gamma;<sub>dry</sub>
        </span>
      }
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={'Dry unit weight'} arrow placement="bottom" TransitionComponent={Zoom}>
              <InfoTwoToneIcon fontSize={'string'} />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      value={checked ? gammaDry : ''}
      onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Dry unit weight', setgammaDry)}
      disabled={!checked || isLoadingCalculate || isLoadingLoad || isLoadingSave}
    />
  );
};
