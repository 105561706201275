import React, { useContext, useState } from 'react';
import { Tooltip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { Context } from '../../../App';
import { Link } from 'react-router-dom';
import Zoom from '@mui/material/Zoom';
import LockIcon from '@mui/icons-material/Lock';

import PricingInfoModal from '../../modals/PricingInfoModal';

const SubscriptionBox = ({ subscription }) => {
  const { user, isResolved } = useContext(Context);
  const [open, setOpen] = useState(false);
  const buttonTextHandler = (subscriptionBox) => {
    let buttonText;
    if (subscriptionBox === 'basic' && user.role === 'basic') buttonText = 'Current Plan';
    if (subscriptionBox === 'basic' && user.role === 'standard') buttonText = 'Downgrade';
    if (subscriptionBox === 'basic' && user.role === 'premium') buttonText = 'Downgrade';
    if (subscriptionBox === 'basic' && user.role === 'professional') buttonText = 'Downgrade';

    if (subscriptionBox === 'standard' && user.role === 'basic') buttonText = 'Upgrade';
    if (subscriptionBox === 'standard' && user.role === 'standard') buttonText = 'Current Plan';
    if (subscriptionBox === 'standard' && user.role === 'premium') buttonText = 'Downgrade';
    if (subscriptionBox === 'standard' && user.role === 'professional') buttonText = 'Downgrade';

    if (subscriptionBox === 'premium' && user.role === 'basic') buttonText = 'Upgrade';
    if (subscriptionBox === 'premium' && user.role === 'standard') buttonText = 'Upgrade';
    if (subscriptionBox === 'premium' && user.role === 'premium') buttonText = 'Current Plan';
    if (subscriptionBox === 'premium' && user.role === 'professional') buttonText = 'Downgrade';

    if (subscriptionBox === 'professional' && user.role === 'basic') buttonText = 'Upgrade';
    if (subscriptionBox === 'professional' && user.role === 'standard') buttonText = 'Upgrade';
    if (subscriptionBox === 'professional' && user.role === 'premium') buttonText = 'Upgrade';
    if (subscriptionBox === 'professional' && user.role === 'professional') buttonText = 'Current Plan';
    return buttonText;
  };
  const classNames = `title ${subscription.name}`;

  const handlePricingInfoModal = () => {
    setOpen(true);
  };
  return (
    <div className="box">
      <PricingInfoModal open={open} setOpen={setOpen} email={user?.email} />
      {subscription.name === 'premium' && (
        <Tooltip title={'Best Seller Plan'}>
          <StarIcon style={{ position: 'absolute', top: 10, right: 10, fontSize: 40, color: 'white' }} />
        </Tooltip>
      )}
      <div className={classNames}>{subscription.name.toUpperCase()}</div>
      <div className="price">
        {subscription.priceMonthly !== undefined && <span className="currencySymbol">$</span>}
        {subscription.priceMonthly / 100 || 'FREE'}
        {subscription.priceMonthly !== undefined && <span> / month</span>}
      </div>
      <div className="properties">
        <p>&#10003; Saving calculations: {subscription.saveCount > 0 ? 'Enabled' : 'Disabled'}</p>
        <p>
          &#10003; Calculation count limit:{' '}
          {subscription.calculationCount === 10000 ? 'Unlimited' : subscription.calculationCount}
        </p>
        <p>&#10003; Save count limit: {subscription.saveCount === 10000 ? 'Unlimited' : subscription.saveCount}</p>
      </div>
      <div className="price-button">
        {/* 1) If there is no user, show signup buttons */}
        {isResolved && !user && (
          <Link to="/signup">
            <button className={subscription.name}>Sign Up</button>
          </Link>
        )}
        {/* 2) If there is a user and user role is basic(free) and user does NOT have a stripe ID (first time buyer), 
        show buy(stripe session) buttons */}
        {isResolved && user && (
          <div>
            {user.emailConfirmed ? (
              <button id="checkout-and-portal-button" className={subscription.name} onClick={handlePricingInfoModal}>
                {buttonTextHandler(subscription.name)}
              </button>
            ) : (
              <Tooltip
                title="You must verify your email before changing your account plan."
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <button className={`${subscription.name} pricingButtonNoLink`}>
                  {buttonTextHandler(subscription.name)}
                  <LockIcon fontSize={'small'} />
                </button>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default SubscriptionBox;
