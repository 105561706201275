import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../App';
import axios from 'axios';

import {
  apiCalculate,
  apiSave,
  apiCheckCalculationNameforSaving,
  apiUpdate,
  apiLoadAllCalculations,
  apiDeleteCalculations,
  apiLoadOneCalculation,
} from '../../helpers/apiRequests';
const CalculationContext = React.createContext();

const CalculationContextProvider = ({ children }) => {
  const { user, setUser } = React.useContext(Context);

  //Set states for user limits
  const [userCalculationLimit, setUserCalculationLimit] = useState();
  const [userSaveLimit, setUserSaveLimit] = useState();

  const [userCalculationCount, setUserCalculationCount] = useState(user.totalCount.calculationCount);
  const [userSaveCount, setUserSaveCount] = useState(user.totalCount.saveCount);

  const [calculationCountReached, setCalculationCountReached] = useState();
  const [saveCountReached, setSaveCountReached] = useState();

  //Get subscription limits for current user
  useEffect(() => {
    const getAllSubscriptions = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: '/api/v1/users/getAllSubscriptions',
        });

        const result = response.data.subscriptions.filter((obj) => {
          return obj.name === user.role;
        });

        setUserCalculationLimit(result[0].calculationCount);
        setUserSaveLimit(result[0].saveCount);
      } catch (err) {
        /* console.log(err); */
      }
    };

    getAllSubscriptions();
  }, [user.role]);

  /* useEffect(() => {
    console.log('userCalculationLimit', userCalculationLimit);
    console.log('userSaveLimit', userSaveLimit);
    //console.log('user', user);
    console.log('------Calc Context------');
    console.log('userCalculationCount', userCalculationCount);
    console.log('userSaveCount', userSaveCount);
    console.log('------------------------');
  }, [userCalculationCount, userSaveCount, userCalculationLimit, userSaveLimit]); */

  //Check if the user reached userCalculationLimit or userSaveLimit
  useEffect(() => {
    if (userCalculationCount >= userCalculationLimit) {
      setCalculationCountReached(true);
    } else {
      setCalculationCountReached(false);
    }
    if (userSaveCount >= userSaveLimit) {
      setSaveCountReached(true);
    } else {
      setSaveCountReached(false);
    }
  }, [userCalculationLimit, userSaveLimit, userCalculationCount, userSaveCount]);

  //CONSTANTS
  const MAX_NAME_LENGTH = 20;
  const regexForPositiveDecimals = /^[+]?\d*(?:[.]\d*)?$/;

  //SET STATES FOR LOADIMG WARNINGS DURING API CALLS
  const [isLoadingCalculate, setIsLoadingCalculate] = useState(false);
  const [isLoadingLoadAll, setIsLoadingLoadAll] = useState(false);
  const [isLoadingLoad, setIsLoadingLoad] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  //SET STATE FOR OUTPUT VARIABLES
  const [result, setResult] = useState({});
  const [calculations, setCalculations] = useState([]);

  const [lastData, setLastData] = useState({});
  const [showOutput, setShowOutput] = useState(false);
  const [calculateButtonDisabled, setCalculateButtonDisabled] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  //SET STATES FOR SIDEBAR RENDER
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [sidebarIconTitle, setSidebarIconTitle] = useState('Hide My Calculations');

  // DECISION MODAL OPEN/CLOSE STATES
  const [decisionModalIsOpen, setDecisionModalIsOpen] = useState(false);
  const [decision, setDecision] = useState('update');

  //STATE FOR SAVNG POTENTIAL UPDATE CALCULATION ID
  const [updateCandidateID, setUpdateCanditadeID] = useState('');

  //HANDLE SIDEBAR - HIDE/SHOW
  const handleSidebarDisplay = () => {
    setDisplaySidebar(!displaySidebar);
    if (sidebarIconTitle === 'Hide My Calculations') {
      setSidebarIconTitle('Show My Calculations');
    } else {
      setSidebarIconTitle('Hide My Calculations');
    }
  };

  //HANDLE DECISION MODEL - OPEN/CLOSE
  const setDecisionModalIsOpenToTrue = () => {
    setDecisionModalIsOpen(true);
  };
  const setDecisionModalIsOpenToFalse = () => {
    setDecisionModalIsOpen(false);
    setDecision('update');
    setErrorMessage();
  };

  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const showMuiSnack = (message, type) => {
    setAlertMessage(message);
    setSeverity(type);
    setOpen(true);
  };

  // HANDLE INPUT CHANGES and WARNINGS
  // -- INPUT TYPE WARNINGS  -- ONCHANGE
  const onChangeWarningForPositiveDecimals = (e, variable, variableSetter) => {
    if (regexForPositiveDecimals.test(e.target.value)) {
      variableSetter(e.target.value);
    } else {
      showMuiSnack(`${variable}: Can only be a postive number. `, 'warning');
    }
  };

  //SET STATE FOR RESULTS ARRAY
  const [resultsArray, setResultsArray] = useState([]);
  const resultProps = resultsArray ? resultsArray : [];

  //----------API REQUESTS----------//
  //API REQUEST TO SERVER FOR CALCULATION RESULTS
  const calculate = async (parameters, URL_CALCULATE) => {
    setIsLoadingCalculate(true);
    //Get calculation results
    const response = await apiCalculate(URL_CALCULATE, parameters);

    if (response.status === 'fail') {
      showMuiSnack(response.message, 'error');
      setShowOutput(false);
      setCalculateButtonDisabled(false);
      setIsLoadingCalculate(false);
    } else {
      setUserCalculationCount((prevCount) => prevCount + 1);
      setUser((user) => ({
        ...user,
        ...(user.totalCount.calculationCount = user.totalCount.calculationCount + 1),
      }));
      //Set calculation results
      setResult(response);
      //IF the calculation is a layered calculation, set results array
      if (URL_CALCULATE.indexOf('liquefaction') !== -1 || URL_CALCULATE.indexOf('spt-correction') !== -1) {
        setResultsArray(response.layers);
      }
      // Create a deep copy of the data. So, after calculation, output do not change as input changes.
      setLastData(Object.assign({}, parameters));
      // Set state for output render to true
      setShowOutput(true);
      // Manage calculate button enabled/disabled condition
      setCalculateButtonDisabled(true);
      setSaveButtonDisabled(false);
      setIsLoadingCalculate(false);
    }
  };

  //API REQUEST TO SERVER FOR SETTING LOADED CALCULATION PARAMETERS
  const loadOneCalculation = async (id, URL_LOADONE) => {
    setIsLoadingLoad(true);
    setShowOutput(false);
    const loadedCalculationParameters = await apiLoadOneCalculation(URL_LOADONE, id);
    setCalculateButtonDisabled(false);
    setSaveButtonDisabled(false);
    setIsLoadingLoad(false);
    return loadedCalculationParameters.data.data;
  };

  //API REQUEST TO SERVER FOR GETTING ALL CALCULATIONS NAME AND IDS
  const loadAllCalculations = async (URL_LOADALL) => {
    setIsLoadingLoadAll(true);
    const calculations = await apiLoadAllCalculations(URL_LOADALL);
    setCalculations(calculations.data.data);
    setIsLoadingLoadAll(false);
  };

  //API REQUEST TO SERVER FOR UPDATING CALCULATIONS
  const update = async (data, URL_UPDATE) => {
    const updatedCalculation = await apiUpdate(URL_UPDATE, data, updateCandidateID);
    setDecisionModalIsOpenToFalse();
    showMuiSnack(`${updatedCalculation.data.data.name} updated successfully.`, 'success');
  };

  //API REQUEST TO SERVER FOR SAVING CALCULATIONS
  const save = async (data, URL_SAVE, URL_LOADALL) => {
    setIsLoadingSave(true);

    const response = await apiSave(URL_SAVE, data);
    if (response.status === 'fail') {
      showMuiSnack(response.message, 'error');
    } else {
      await loadAllCalculations(URL_LOADALL);
      setUserSaveCount(userSaveCount + 1);
      setUser((user) => ({
        ...user,
        ...(user.totalCount.saveCount = user.totalCount.saveCount + 1),
      }));
      showMuiSnack(`${response} is saved successfully`, 'success');
    }
    setIsLoadingSave(false);
  };

  // API REQUEST TO SERVER FOR CALCULATION NAME UNIQUENESS FOR "SAVE"
  const checkCalculationNameforSaving = async (data, URL_CHECKUNIQUENAME, URL_SAVE, URL_LOADALL) => {
    const isCalculationNameUnique = await apiCheckCalculationNameforSaving(URL_CHECKUNIQUENAME, data);
    if (isCalculationNameUnique.body) {
      save(data, URL_SAVE, URL_LOADALL);
    } else {
      //save calculation ID in case user updates.
      setUpdateCanditadeID(isCalculationNameUnique.id);
      setDecisionModalIsOpenToTrue();
    }
  };

  //API REQUEST TO SERVER FOR CALCULATION NAME UNIQUENESS FOR "SAVE AS"
  const checkCalculationNameforSavingAs = async (data, URL_CHECKUNIQUENAME, URL_SAVE, URL_LOADALL) => {
    const isCalculationNameUnique = await apiCheckCalculationNameforSaving(URL_CHECKUNIQUENAME, data);
    if (isCalculationNameUnique.body) {
      save(data, URL_SAVE, URL_LOADALL);
      setDecisionModalIsOpenToFalse();
    } else {
      setErrorMessage('Calculation name already exists!');
    }
  };

  //API REQUEST TO SERVER FOR DELETING SINGLE OR MULTIPLE DELETION
  const deleteCalculations = async (id, URL_DELETE, URL_LOADALL) => {
    await apiDeleteCalculations(URL_DELETE, id);
    showMuiSnack('Your calculation is deleted successfully.', 'success');
    setUserSaveCount(userSaveCount - id.length);
    setUser((user) => ({
      ...user,
      ...(user.totalCount.saveCount = user.totalCount.saveCount - id.length),
    }));
    await loadAllCalculations(URL_LOADALL);
  };

  return (
    <CalculationContext.Provider
      value={{
        //constants start
        MAX_NAME_LENGTH,
        regexForPositiveDecimals,
        //constants end

        //loading states start
        isLoadingCalculate,
        setIsLoadingCalculate,
        isLoadingLoadAll,
        setIsLoadingLoadAll,
        isLoadingLoad,
        setIsLoadingLoad,
        isLoadingSave,
        setIsLoadingSave,
        //loading states end

        result,
        setResult,
        resultsArray,
        setResultsArray,
        resultProps,
        calculations,
        setCalculations,
        lastData,
        setLastData,
        showOutput,
        setShowOutput,
        calculateButtonDisabled,
        setCalculateButtonDisabled,
        saveButtonDisabled,
        setSaveButtonDisabled,
        errorMessage,
        setErrorMessage,
        displaySidebar,
        setDisplaySidebar,
        sidebarIconTitle,
        setSidebarIconTitle,
        decisionModalIsOpen,
        setDecisionModalIsOpen,
        decision,
        setDecision,
        updateCandidateID,
        setUpdateCanditadeID,
        handleSidebarDisplay,
        setDecisionModalIsOpenToTrue,
        setDecisionModalIsOpenToFalse,
        onChangeWarningForPositiveDecimals,

        //Mui Snackbar starts
        showMuiSnack,
        open,
        setOpen,
        alertMessage,
        setAlertMessage,
        severity,
        setSeverity,
        //Mui Snackbar ends

        //api functions start
        calculate,
        loadOneCalculation,
        loadAllCalculations,
        update,
        save,
        checkCalculationNameforSaving,
        checkCalculationNameforSavingAs,
        deleteCalculations,
        //api functions end
        //count check
        calculationCountReached,
        saveCountReached,
      }}
    >
      {children}
    </CalculationContext.Provider>
  );
};

const useCalculationContext = () => {
  const context = useContext(CalculationContext);
  return context;
};
export { useCalculationContext, CalculationContextProvider };
