import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import InputAdornment from '@mui/material/InputAdornment';

const SearchBoxHomePage = ({ calculationsData, setFilteredCalculations, setMatchingResult }) => {
  //STATE FOR SEARCHBOX QUERY
  const [query, setQuery] = useState('');

  //STATE FOR CONTROLLING SEARCHBOX 'NO MATCHING RESULT' CONDITION

  useEffect(() => {
    const filteredCalculations = calculationsData.filter((calculation) => {
      // if query is empty, add all calculations to filteredCalculations
      if (query === '') {
        return true;
        // add matching calculations to filteredCalculations
      } else if (
        (calculation.description + ' ' + calculation.title)
          .toLowerCase()
          .includes(query.toLowerCase())
      ) {
        return true;
      }
      return false;
    });

    //Control state for 'no matching result'
    if (filteredCalculations.length === 0 && query) {
      setMatchingResult(true);
    } else {
      setMatchingResult(false);
    }
    setFilteredCalculations(
      //Sort filtered calculations alphabetically according to their titles
      filteredCalculations.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    );
  }, [query, calculationsData, setFilteredCalculations, setMatchingResult]);

  return (
    <div className="hompage-searchbox-container">
      <div className="homepage-searchbox">
        <TextField
          fullWidth
          sx={{ minWidth: '250px', maxWidth: '600px' }}
          size="small"
          label="Search Calculations"
          type="search"
          placeholder="Settlement, Terzaghi, Bearing Capacity..."
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchRoundedIcon fontSize={'large'} color={'info'} />
              </InputAdornment>
            ),
          }}
        ></TextField>
      </div>
    </div>
  );
};

export default SearchBoxHomePage;
