import round from '../../../helpers/round';

const HansenResultDepthFactors = ({ result }) => {
  const { phips, isInclinedLoading, isPhiZero, k, kb, kl, dc, dcb, dcl, dq, dqb, dql, dGamma, dGammab } = result.output;

  const case1 = isInclinedLoading && isPhiZero;
  const case2 = isInclinedLoading && !isPhiZero;
  const case3 = !isInclinedLoading && isPhiZero;
  const case4 = !isInclinedLoading && !isPhiZero;

  const Case1Case3dc = () => (
    <div className="meyerhof-factors">
      <h4>
        d'<sub>c</sub>
      </h4>
      <p>
        &phi; = 0 &rarr; Only d'<sub>c</sub> should be calculated.
      </p>
      <p>
        d'<sub>c</sub> = 0.4 k
      </p>
      <p>
        d'<sub>c</sub> = 0.4 ({round(k, 2)})
      </p>
      <p>
        s'<sub>c</sub> = {round(dc, 2)}
      </p>
    </div>
  );
  const Case2dq = () => (
    <div className="meyerhof-factors">
      <h4>
        d<sub>q</sub>
      </h4>
      <p>
        H<sub>B</sub> &ne; 0 and/or H<sub>L</sub> &ne; 0 (Inclined loading.)
      </p>
      <p>
        d<sub>q, B</sub> and d<sub>q, L</sub> should be calculated.
      </p>
      <br />
      <p>
        d<sub>q, B</sub> = 1 + 2tan&phi; (1 - sin&phi;)<sup>2</sup>(k<sub>B</sub>)
      </p>
      <p>
        d<sub>q, B</sub> = 1 + 2tan{round(phips, 2)} (1 - sin{round(phips, 2)})<sup>2</sup>({round(kb, 2)})
      </p>
      <p>
        d<sub>q, B</sub> = {round(dqb, 2)}
      </p>
      <br />
      <p>
        d<sub>q, L</sub> = 1 + 2tan&phi; (1 - sin&phi;)<sup>2</sup>(k<sub>L</sub>)
      </p>
      <p>
        d<sub>q, L</sub> = 1 + 2tan{round(phips, 2)} (1 - sin{round(phips, 2)})<sup>2</sup>({round(kl, 2)})
      </p>
      <p>
        d<sub>q, L</sub> = {round(dql, 2)}
      </p>
    </div>
  );
  const Case2dc = () => (
    <div className="meyerhof-factors">
      <h4>
        d<sub>c</sub>
      </h4>
      <p>
        H<sub>B</sub> &ne; 0 and/or H<sub>L</sub> &ne; 0 (Inclined loading.)
      </p>
      <p>
        d<sub>c, B</sub> and d<sub>c, L</sub> should be calculated.
      </p>
      <br />
      <p>
        d<sub>c, B</sub> = 1 + 0.4(k<sub>B</sub>)
      </p>
      <p>
        d<sub>c, B</sub> = 1 + 0.4({round(kb, 2)})
      </p>
      <p>
        d<sub>c, B</sub> = {round(dcb, 2)}
      </p>
      <br />
      <p>
        d<sub>c, L</sub> = 1 + 0.4(k<sub>L</sub>)
      </p>
      <p>
        d<sub>c, L</sub> = 1 + 0.4({round(kl, 2)})
      </p>
      <p>
        d<sub>c, L</sub> = {round(dcl, 2)}
      </p>
    </div>
  );
  const Case2dgamma = () => (
    <div className="meyerhof-factors">
      <h4>
        d<sub>&gamma;</sub>
      </h4>
      <p>
        H<sub>B</sub> &ne; 0 and/or H<sub>L</sub> &ne; 0 (Inclined loading.)
      </p>
      <p>
        d<sub>&gamma;, B</sub> and d<sub>&gamma;, L</sub> should be calculated.
      </p>
      <br />
      <p>
        d<sub>&gamma;, B</sub> = d<sub>&gamma;, L</sub> = {round(dGammab, 2)} for all &phi;
      </p>
    </div>
  );
  const Case4dc = () => (
    <div className="meyerhof-factors">
      <h4>
        d<sub>c</sub>
      </h4>
      <p>
        H<sub>B</sub> = H<sub>L</sub> = 0 (No inclined loading.)
      </p>
      <p>
        d<sub>c</sub> = 1 + 0.4(k)
      </p>
      <p>
        d<sub>c</sub> = 1 + 0.4({round(k, 2)})
      </p>
      <p>
        d<sub>c</sub> = {round(dc, 2)}
      </p>
    </div>
  );

  const Case4dq = () => (
    <div className="meyerhof-factors">
      <h4>
        d<sub>q</sub>
      </h4>
      <p>
        H<sub>B</sub> = H<sub>L</sub> = 0 (No inclined loading.)
      </p>
      <p>
        d<sub>q</sub> = 1 + 2tan&phi; (1 - sin&phi;)<sup>2</sup>(k)
      </p>
      <p>
        d<sub>q</sub> = 1 + 2tan{round(phips, 2)} (1 - sin{round(phips, 2)})<sup>2</sup>({round(k, 2)})
      </p>
      <p>
        d<sub>q</sub> = {round(dq, 2)}
      </p>
    </div>
  );
  const Case4dgamma = () => (
    <div className="meyerhof-factors">
      <h4>
        d<sub>&gamma;</sub>
      </h4>
      <p>
        H<sub>B</sub> = H<sub>L</sub> = 0 (No inclined loading.)
      </p>
      <p>
        d<sub>&gamma;</sub> = {round(dGamma, 2)} for all &phi;
      </p>
    </div>
  );

  return (
    <div className="checks">
      <h3>
        Depth Factors (d<sub>c</sub>, d<sub>q</sub>, d<sub>&gamma;</sub>)
      </h3>
      <div className="factors-wrapper">
        {case1 && <Case1Case3dc />}

        {case2 && <Case2dq />}
        {case2 && <Case2dc />}
        {case2 && <Case2dgamma />}

        {case3 && <Case1Case3dc />}

        {case4 && <Case4dq />}
        {case4 && <Case4dc />}
        {case4 && <Case4dgamma />}
      </div>
    </div>
  );
};
export default HansenResultDepthFactors;
