import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InputAdornment from '@mui/material/InputAdornment';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
const LabelContainer = () => {
  return (
    <div className="labelContainer layered-soil-layer">
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Soil layer ID" arrow placement="bottom" TransitionComponent={Zoom}>
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="Layer"
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 140 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Formation. " arrow placement="bottom" TransitionComponent={Zoom}>
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="Formation"
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 75 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Starting point of soil layer measured from ground surface (m). Equal to foundation depth at the first soil layer. "
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value={'z1'}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 75 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Ending point of soil layer measured from ground surface (m). "
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="z2"
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 75 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Soil layer thickness (m). Equals to z2-z1 of corresponding soil layer. "
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="Hi"
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 75 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Cohesion of the soil layer (kPa). "
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="c"
      />

      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 75 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Friction angle of the soil layer (degree). "
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="&phi;"
      />
    </div>
  );
};
export default LabelContainer;
