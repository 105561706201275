import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InputAdornment from '@mui/material/InputAdornment';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MuiSnackbar from '../../common/MuiSnackbar';
import CircularProgress from '@mui/material/CircularProgress';

import CalculateButton from '../../common/calculateButton';
import SaveButton from '../../common/saveButton';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Sidebar from '../../sidebar/Sidebar';
import DecisionModal from '../../modals/DecisionModal';
import ConsolidationResult from './ConsolidationResult';

import { useCalculationContext, CalculationContextProvider } from '../../context/calculationContext';

const Consolidation = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Consolidation';
  }, []);

  const {
    MAX_NAME_LENGTH,
    isLoadingCalculate,
    isLoadingLoadAll,
    isLoadingLoad,
    isLoadingSave,
    result,
    calculations,
    lastData,
    showOutput,
    setShowOutput,
    calculateButtonDisabled,
    setCalculateButtonDisabled,
    saveButtonDisabled,
    setSaveButtonDisabled,
    errorMessage,
    displaySidebar,
    setDisplaySidebar,
    decisionModalIsOpen,
    decision,
    setDecision,
    handleSidebarDisplay,
    setDecisionModalIsOpenToFalse,
    showMuiSnack,
    open,
    setOpen,
    alertMessage,
    severity,
    onChangeWarningForPositiveDecimals,
    calculate,
    loadOneCalculation,
    loadAllCalculations,
    update,
    checkCalculationNameforSaving,
    checkCalculationNameforSavingAs,
    deleteCalculations,
    calculationCountReached,
    saveCountReached,
  } = useCalculationContext();

  //SET STATES FOR INPUT VARIABLES
  const [name, setName] = useState('');
  const [z1, setz1] = useState('');
  const [z2, setz2] = useState('');
  const [df, setdf] = useState('');
  const [b, setb] = useState('');
  const [l, setl] = useState('');
  const [q, setq] = useState('');
  const [clayType, setclayType] = useState(0);
  const [drainageType, setdrainageType] = useState(0);
  const [mv, setmv] = useState('');
  const [cv, setcv] = useState('');
  const [t, sett] = useState('');

  // SET THE LOADED INPUTS
  const setInputs = (inputs) => {
    setName(inputs.name);
    setz1(inputs.z1);
    setz2(inputs.z2);
    setdf(inputs.df);
    setb(inputs.b);
    setl(inputs.l);
    setq(inputs.q);
    setclayType(inputs.clayType);
    setdrainageType(inputs.drainageType);
    setmv(inputs.mv);
    setcv(inputs.cv);
    sett(inputs.t);
  };

  //INPUTS FOR CALCULATION
  const calculationInput = {
    name,
    z1,
    z2,
    df,
    b,
    l,
    q,
    clayType,
    drainageType,
    mv,
    cv,
    t,
  };

  // MANAGE SAVE & CALCULATE BUTTONS DISABLED/ENABLED STATES
  useEffect(() => {
    setCalculateButtonDisabled(false);
    setSaveButtonDisabled(false);

    if (
      (calculationInput.name || calculationInput.name === 0) &&
      (calculationInput.z1 || calculationInput.z1 === 0) &&
      (calculationInput.z2 || calculationInput.z2 === 0) &&
      (calculationInput.df || calculationInput.df === 0) &&
      (calculationInput.b || calculationInput.b === 0) &&
      (calculationInput.l || calculationInput.l === 0) &&
      (calculationInput.q || calculationInput.q === 0) &&
      calculationInput.clayType &&
      calculationInput.drainageType &&
      (calculationInput.mv || calculationInput.mv === 0) &&
      (calculationInput.cv || calculationInput.cv === 0) &&
      (calculationInput.t || calculationInput.t === 0)
    ) {
      setCalculateButtonDisabled(false);
      setSaveButtonDisabled(false);
    } else {
      setCalculateButtonDisabled(true);
      setSaveButtonDisabled(true);
    }
  }, [
    setSaveButtonDisabled,
    setCalculateButtonDisabled,
    calculationInput.name,
    calculationInput.z1,
    calculationInput.z2,
    calculationInput.df,
    calculationInput.b,
    calculationInput.l,
    calculationInput.q,
    calculationInput.clayType,
    calculationInput.drainageType,
    calculationInput.mv,
    calculationInput.cv,
    calculationInput.t,
  ]);

  // Do not render output if any input parameter except name changes
  useEffect(() => {
    setShowOutput(false);
  }, [
    setShowOutput,
    calculationInput.z1,
    calculationInput.z2,
    calculationInput.df,
    calculationInput.b,
    calculationInput.l,
    calculationInput.q,
    calculationInput.clayType,
    calculationInput.drainageType,
    calculationInput.mv,
    calculationInput.cv,
    calculationInput.t,
  ]);

  //CLEAR INPUTS AND CLEAR OUTPUT
  const createNewCalculation = () => {
    setName('');
    setz1('');
    setz2('');
    setdf('');
    setb('');
    setl('');
    setq('');
    setclayType(0);
    setdrainageType(0);
    setmv('');
    setcv('');
    sett('');
    setShowOutput(false);
  };

  const URL = {
    CALCULATE: '/api/v1/calculations/consolidation',
    LOADONE: `/api/v1/calculations/consolidation/loadOne/`,
    LOADALL: '/api/v1/calculations/consolidation/loadAll',
    UPDATE: `/api/v1/calculations/consolidation/update/`,
    SAVE: '/api/v1/calculations/consolidation/save',
    CHECKUNIQUENAME: `/api/v1/calculations/consolidation/checkUniqueName/`,
    DELETE: '/api/v1/calculations/consolidation/delete',
  };

  // HANDLE INPUT CHANGES and WARNINGS
  // -- INPUT TYPE WARNINGS  -- ONCHANGE

  const handleChangeName = (e) => {
    if (e.target.value.length >= MAX_NAME_LENGTH) {
      showMuiSnack(`Calculation name can be max ${MAX_NAME_LENGTH} characters.`, 'warning');
    }
    setName(e.target.value);
  };

  //  -- LOGIC WARNINGS -- ONBLUR
  const handleLogicZ1 = (e) => {
    if (e.target.value && z2) {
      if (parseFloat(e.target.value) > parseFloat(z2)) {
        showMuiSnack('z2 must be greater than z1', 'warning');
      }
    }
  };
  const handleLogicZ2 = (e) => {
    if (z1 && e.target.value) {
      if (parseFloat(z1) > parseFloat(e.target.value)) {
        showMuiSnack('z2 must be greater than z1', 'warning');
      }
    }
  };
  const handleLogicB = (e) => {
    if (e.target.value && l) {
      if (parseFloat(e.target.value) > parseFloat(l)) {
        showMuiSnack('L must be greater than B', 'warning');
      }
    }
  };
  const handleLogicL = (e) => {
    if (b && e.target.value) {
      if (parseFloat(e.target.value) < parseFloat(b)) {
        showMuiSnack('L must be greater than B', 'warning');
      }
    }
  };

  // RETURNING JSX
  return (
    <>
      <Header />
      <div className="container">
        <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
        {displaySidebar ? (
          <Sidebar
            URL={URL}
            loadAllCalculations={loadAllCalculations}
            calculations={calculations}
            loadOneCalculation={loadOneCalculation}
            calculate={calculate}
            setInputs={setInputs}
            deleteCalculations={deleteCalculations}
            createNewCalculation={createNewCalculation}
            isLoading={isLoadingLoadAll}
            handleSidebarDisplay={handleSidebarDisplay}
            displaySidebar={displaySidebar}
            setDisplaySidebar={setDisplaySidebar}
          />
        ) : (
          <MenuOpenIcon
            color="primary"
            fontSize="large"
            className="sidebar-open-icon"
            alt="side bar menu icon"
            label="Show my calculations"
            onClick={handleSidebarDisplay}
          />
        )}

        <DecisionModal
          URL={URL}
          data={calculationInput}
          decisionModalIsOpen={decisionModalIsOpen}
          setDecisionModalIsOpenToFalse={setDecisionModalIsOpenToFalse}
          decision={decision}
          setDecision={setDecision}
          checkCalculationNameforSavingAs={checkCalculationNameforSavingAs}
          name={name}
          setName={setName}
          errorMessage={errorMessage}
          update={update}
          saveButtonDisabled={saveButtonDisabled}
        />
        <div className="right-calculation">
          <section className="input">
            <h1>Time Dependent Settlements of Shallow Foundations</h1>
            <form>
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label="Name"
                variant="outlined"
                inputProps={{
                  maxLength: `${MAX_NAME_LENGTH}`,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={`Your calculation name (Max. ${MAX_NAME_LENGTH} characters)`}
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={name}
                onChange={handleChangeName}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'z1'}>
                    z<sub>1</sub> (m)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Starting point of compressible layer measured from ground surface"
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={z1}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'z1', setz1)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                onBlur={handleLogicZ1}
              />

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'z2'}>
                    z<sub>2</sub> (m)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Ending point of compressible layer measured from ground surface"
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={z2}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'z2', setz2)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                onBlur={handleLogicZ2}
              />

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'df'}>
                    d<sub>f</sub> (m)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Foundation depth" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={df}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Df', setdf)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label="B (m)"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Foundation width" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={b}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'B', setb)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                onBlur={handleLogicB}
              />

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label="L (m)"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Foundation length" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={l}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'L', setl)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
                onBlur={handleLogicL}
              />

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label="q (kPa)"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Stress due to foundation loads" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={q}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'q', setq)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                select
                label="Clay type"
                value={clayType}
                onChange={(e) => setclayType(e.target.value)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              >
                <MenuItem disabled value="0">
                  Clay Type
                </MenuItem>
                <MenuItem value="1">Sensitive</MenuItem>
                <MenuItem value="2">Normally Consolidated</MenuItem>
                <MenuItem value="3">Overconsolidated</MenuItem>
                <MenuItem value="4">Heavily Overconsolidated</MenuItem>
              </TextField>

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                select
                label="Drainage Type"
                value={drainageType}
                onChange={(e) => setdrainageType(e.target.value)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              >
                <MenuItem disabled value="0">
                  Drainage Type
                </MenuItem>
                <MenuItem value="1">One Way</MenuItem>
                <MenuItem value="2">Two Way</MenuItem>
              </TextField>

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'mv'}>
                    m<sub>v</sub> (m<sup>2</sup>/kNx10 <sup>-5</sup>)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Coefficient of compressibility" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={mv}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'mv', setmv)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'cv'}>
                    c<sub>v</sub> (m<sup>2</sup>/sx10 <sup>-8</sup>)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Coefficient of consolidation" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={cv}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'cv', setcv)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label="t (month)"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Consolidation time" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={t}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 't', sett)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />

              <div className="buttonContainer">
                <CalculateButton
                  calculationCountReached={calculationCountReached}
                  calculate={calculate}
                  calculationInput={calculationInput}
                  URL={URL}
                  calculateButtonDisabled={calculateButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />

                {isLoadingLoad && <CircularProgress size={30} />}

                <SaveButton
                  saveCountReached={saveCountReached}
                  checkCalculationNameforSaving={checkCalculationNameforSaving}
                  calculationInput={calculationInput}
                  URL={URL}
                  saveButtonDisabled={saveButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
              </div>
            </form>
          </section>

          {isLoadingCalculate && (
            <div className="centerLoading">
              <CircularProgress />
            </div>
          )}
          {showOutput && <ConsolidationResult data={lastData} result={result} />}
        </div>
      </div>
      <Footer />
    </>
  );
};

const ConsolidationWithProvider = () => {
  return (
    <CalculationContextProvider>
      <Consolidation />
    </CalculationContextProvider>
  );
};

export default ConsolidationWithProvider;
