import round from '../../../helpers/round';

const HansenResultBaseFactors = ({ result }) => {
  const { cohesion, bearingCapacityFactorOfSafety } = result.input;

  const {
    effectiveFndWidth,
    effectiveFndLength,
    isInclinedLoading,
    isPhiZero,
    //bearing capacity factors
    nc,
    nq,
    nGamma,
    //depth factors
    dc,
    dcb,
    dcl,
    dq,
    dqb,
    dql,
    dGamma,
    dGammab,
    dGammal,
    //inclination factors
    ic,
    icb,
    icl,
    iq,
    iqb,
    iql,
    iGamma,
    iGammab,
    iGammal,
    //shape factors
    sc,
    scb,
    scl,
    sq,
    sqb,
    sql,
    sGammab,
    sGammal,
    sGamma,
    //ground factors
    gc,
    gq,
    gGamma,
    //base factors
    bc,
    bq,
    bGamma,
    // water table related
    effectiveStress,
    gammaEffective,
    //q ult
    term1,
    term2,
    term3,
    qult,
    qall,
    EQ1_inclinedTerm1,
    EQ1_inclinedTerm2,
    EQ1_inclinedTerm3,
    EQ1_inclinedQult,
    //Equation 2 terms
    EQ2_inclinedTerm1,
    EQ2_inclinedTerm2,
    EQ2_inclinedTerm3,
    EQ2_inclinedQult,
    qultInclined,
    qallInclined,
  } = result.output;

  const case1 = isInclinedLoading && isPhiZero;
  const case2 = isInclinedLoading && !isPhiZero;
  const case3 = !isInclinedLoading && isPhiZero;
  const case4 = !isInclinedLoading && !isPhiZero;

  return (
    <div className="checks">
      {/* //? CASE 1 - INCLINED LOADING && PHI = 0 */}
      {case1 && (
        <>
          <h3>
            Ultimate Bearing Capacity (q<sub>ult</sub>) & Allowable Bearing Capacity (q<sub>all</sub>)
          </h3>
          <div className="factors-wrapper">
            <div className="meyerhof-factors">
              <p>
                If H<sub>B</sub> &ne; 0 and/or H<sub>L</sub> &ne; 0 (Inclined loading) and &phi; = 0
              </p>
              <p>&darr;</p>
              <p>
                calculate q<sub>ult</sub> using the equation below.
              </p>

              <p>
                q<sub>ult</sub> = 5.14 s<sub>u</sub> (1 + s'<sub>c</sub> + d'<sub>c</sub> - i'<sub>c</sub> - b'<sub>c</sub> - g'
                <sub>c</sub>) + q&#772;
              </p>
              <br />
              <p>Using the previously calculated values we have:</p>

              <p>
                q<sub>ult</sub> = (5.14) ({round(cohesion, 2)}) [1 + ({round(sc, 2)}) + ({round(dc, 2)}) - ({round(ic, 2)}) - (
                {round(bc, 2)}) - ({round(gc, 2)})] + {round(effectiveStress, 2)}
              </p>

              <p>
                q<sub>ult</sub> = {round(qult, 0)} kPa
              </p>
              <br />

              <p>
                q<sub>all</sub> = q<sub>ult</sub> / FS<sub>Bearing</sub>
              </p>
              <p>
                q<sub>all</sub> = {round(qult, 0)} / {round(bearingCapacityFactorOfSafety, 2)}
              </p>
              <p>
                q<sub>all</sub> = {round(qall, 0)} kPa
              </p>
            </div>
          </div>
        </>
      )}
      {/* //? CASE 2 - INCLINED LOADING && PHI > 0 */}
      {case2 && (
        <>
          <h3>
            Ultimate Bearing Capacity (q<sub>ult</sub>) & Allowable Bearing Capacity (q<sub>all</sub>)
          </h3>
          <div className="factors-wrapper">
            <div className="meyerhof-factors">
              <p>
                If H<sub>B</sub> &ne; 0 and/or H<sub>L</sub> &ne; 0 (Inclined loading) and &phi; &gt; 0
              </p>
              <p>&darr;</p>
              <p>
                Calculate q<sub>ult</sub> using both of the equations below and use the smaller value.
              </p>
              <p>Using the previously calculated values we have:</p>
              <br />
              <p>Equation - 1</p>
              <p>
                q<sub>ult-1</sub> = (c N<sub>c</sub> s<sub>c, B</sub> d<sub>c, B</sub> i<sub>c, B</sub> b<sub>c</sub> g
                <sub>c</sub>) + (q&#772; N<sub>q</sub> s<sub>q, B</sub> d<sub>q, B</sub> i<sub>q, B</sub> b<sub>q</sub> g
                <sub>q</sub>) + (0.5 &gamma;<sub>e</sub> B' N<sub>&gamma;</sub> s<sub>&gamma;, B</sub> d<sub>&gamma;, B</sub> i
                <sub>&gamma;, B</sub> b<sub>&gamma;</sub> g<sub>&gamma;</sub>)
              </p>
              <p>
                q<sub>ult-1</sub> = ({round(cohesion, 2)}) ({round(nc, 2)}) ({round(scb, 2)}) ({round(dcb, 2)}) ({round(icb, 2)})
                ({round(bc, 2)}) ({round(gc, 2)}) + ({round(effectiveStress, 2)}) ({round(nq, 2)}) ({round(sqb, 2)}) (
                {round(dqb, 2)}) ({round(iqb, 2)}) ({round(bq, 2)}) ({round(gq, 2)}) + (0.5) ({round(gammaEffective, 2)}) (
                {round(effectiveFndWidth, 2)}) ({round(nGamma, 2)}) ({round(sGammab, 2)}) ({round(dGammab, 2)}) (
                {round(iGammab, 2)}) ({round(bGamma, 2)}) ({round(gGamma, 2)})
              </p>
              <p>
                q<sub>ult-1</sub> = ({round(EQ1_inclinedTerm1, 0)}) + ({round(EQ1_inclinedTerm2, 0)}) + (
                {round(EQ1_inclinedTerm3, 0)})
              </p>
              <p>
                q<sub>ult-1</sub> = {round(EQ1_inclinedQult, 0)} kPa
              </p>
              <br />
              <p>Equation - 2</p>
              <p>
                q<sub>ult-2</sub> = (c N<sub>c</sub> s<sub>c, L</sub> d<sub>c, L</sub> i<sub>c, L</sub> b<sub>c</sub> g
                <sub>c</sub>) + (q&#772; N<sub>q</sub> s<sub>q, L</sub> d<sub>q, L</sub> i<sub>q, L</sub> b<sub>q</sub> g
                <sub>q</sub>) + (0.5 &gamma;<sub>e</sub> L' N<sub>&gamma;</sub> s<sub>&gamma;, L</sub> d<sub>&gamma;, L</sub> i
                <sub>&gamma;, L</sub> b<sub>&gamma;</sub> g<sub>&gamma;</sub>)
              </p>
              <p>
                q<sub>ult-2</sub> = ({round(cohesion, 2)}) ({round(nc, 2)}) ({round(scl, 2)}) ({round(dcl, 2)}) ({round(icl, 2)})
                ({round(bc, 2)}) ({round(gc, 2)}) + ({round(effectiveStress, 2)}) ({round(nq, 2)}) ({round(sql, 2)}) (
                {round(dql, 2)}) ({round(iql, 2)}) ({round(bq, 2)}) ({round(gq, 2)}) + (0.5) ({round(gammaEffective, 2)}) (
                {round(effectiveFndLength, 2)}) ({round(nGamma, 2)}) ({round(sGammal, 2)}) ({round(dGammal, 2)}) (
                {round(iGammal, 2)}) ({round(bGamma, 2)}) ({round(gGamma, 2)})
              </p>
              <p>
                q<sub>ult-2</sub> = ({round(EQ2_inclinedTerm1, 0)}) + ({round(EQ2_inclinedTerm2, 0)}) + (
                {round(EQ2_inclinedTerm3, 0)})
              </p>
              <p>
                q<sub>ult-2</sub> = {round(EQ2_inclinedQult, 0)} kPa
              </p>
              <br />
              <p>
                q<sub>ult</sub> = Min (q<sub>ult-1</sub>, q<sub>ult-2</sub>)
              </p>
              <p>
                q<sub>ult</sub> = Min ({round(EQ1_inclinedQult, 0)}, {round(EQ2_inclinedQult, 0)})
              </p>
              <p>
                q<sub>ult</sub> = {round(qultInclined, 0)} kPa
              </p>
              <br />
              <p>
                q<sub>all</sub> = q<sub>ult</sub> / FS<sub>Bearing</sub>
              </p>
              <p>
                q<sub>all</sub> = {round(qultInclined, 0)} / {round(bearingCapacityFactorOfSafety, 2)}
              </p>
              <p>
                q<sub>all</sub> = {round(qallInclined, 0)} kPa
              </p>
            </div>
          </div>
        </>
      )}
      {/* //? CASE 3 - NOT INCLINED LOADING = 0 && PHI = 0 */}
      {case3 && (
        <>
          <h3>
            Ultimate Bearing Capacity (q<sub>ult</sub>) & Allowable Bearing Capacity (q<sub>all</sub>)
          </h3>
          <div className="factors-wrapper">
            <div className="meyerhof-factors">
              <p>
                If H<sub>B</sub> = 0 & H<sub>L</sub> = 0 (No Inclined loading) and &phi; = 0
              </p>
              <p>&darr;</p>
              <p>
                calculate q<sub>ult</sub> using the equation below.
              </p>

              <p>
                q<sub>ult</sub> = 5.14 s<sub>u</sub> (1 + s'<sub>c</sub> + d'<sub>c</sub> - i'<sub>c</sub> - b'<sub>c</sub> - g'
                <sub>c</sub>) + q&#772;
              </p>
              <br />
              <p>Using the previously calculated values we have:</p>

              <p>
                q<sub>ult</sub> = (5.14) ({round(cohesion, 2)}) [1 + ({round(sc, 2)}) + ({round(dc, 2)}) - ({round(ic, 2)}) - (
                {round(bc, 2)}) - ({round(gc, 2)})] + {round(effectiveStress, 2)}
              </p>

              <p>
                q<sub>ult</sub> = {round(qult, 0)} kPa
              </p>
              <br />

              <p>
                q<sub>all</sub> = q<sub>ult</sub> / FS<sub>Bearing</sub>
              </p>
              <p>
                q<sub>all</sub> = {round(qult, 0)} / {round(bearingCapacityFactorOfSafety, 2)}
              </p>
              <p>
                q<sub>all</sub> = {round(qall, 0)} kPa
              </p>
            </div>
          </div>
        </>
      )}
      {/* //? CASE 4 - NOT INCLINED LOADING = 0 && PHI > 0 */}
      {case4 && (
        <>
          <h3>
            Ultimate Bearing Capacity (q<sub>ult</sub>) & Allowable Bearing Capacity (q<sub>all</sub>)
          </h3>
          <div className="factors-wrapper">
            <div className="meyerhof-factors">
              <p>
                If H<sub>B</sub> = 0 & H<sub>L</sub> = 0 (No Inclined loading) and &phi; &gt; 0
              </p>
              <p>&darr;</p>
              <p>
                calculate q<sub>ult</sub> using the equation below.
              </p>
              <p>
                q<sub>ult</sub> = (c N<sub>c</sub> s<sub>c</sub> d<sub>c</sub> i<sub>c</sub> b<sub>c</sub> g<sub>c</sub>) +
                (q&#772; N<sub>q</sub> s<sub>q</sub> d<sub>q</sub> i<sub>q</sub> b<sub>q</sub> g<sub>q</sub>) + (0.5 &gamma;
                <sub>e</sub> B' N<sub>&gamma;</sub> s<sub>&gamma;</sub> d<sub>&gamma;</sub> i<sub>&gamma;</sub> b
                <sub>&gamma;</sub> g<sub>&gamma;</sub>)
              </p>
              <br />
              <p>Using the previously calculated values we have:</p>

              <p>
                q<sub>ult</sub> = ({round(cohesion, 2)}) ({round(nc, 2)}) ({round(sc, 2)}) ({round(dc, 2)}) ({round(ic, 2)}) (
                {round(bc, 2)}) ({round(gc, 2)}) + ({round(effectiveStress, 2)}) ({round(nq, 2)}) ({round(sq, 2)}) ({round(dq, 2)}
                ) ({round(iq, 2)}) ({round(bq, 2)}) ({round(gq, 2)}) + (0.5) ({round(gammaEffective, 2)}) (
                {round(effectiveFndWidth, 2)}) ({round(nGamma, 2)}) ({round(sGamma, 2)}) ({round(dGamma, 2)}) ({round(iGamma, 2)})
                ({round(bGamma, 2)}) ({round(gGamma, 2)})
              </p>
              <p>
                q<sub>ult</sub> = ({round(term1, 0)}) + ({round(term2, 0)}) + ({round(term3, 0)})
              </p>
              <p>
                q<sub>ult</sub> = {round(qult, 0)} kPa
              </p>
              <br />

              <p>
                q<sub>all</sub> = q<sub>ult</sub> / FS<sub>Bearing</sub>
              </p>
              <p>
                q<sub>all</sub> = {round(qult, 0)} / {round(bearingCapacityFactorOfSafety, 2)}
              </p>
              <p>
                q<sub>all</sub> = {round(qall, 0)} kPa
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default HansenResultBaseFactors;
