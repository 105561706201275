import React from 'react';
import {
  Gamma1Result,
  Gamma2Result,
  Gamma3Result,
  Gamma4Result,
  Gamma5Result,
  GammaDry1Result,
  GammaDry2Result,
  GammaDry3Result,
  GammaDry4Result,
  GammaDry5Result,
  GammaDry6Result,
  GammaDry7Result,
  GammaDry8Result,
  GammaSat1Result,
  GammaSat2Result,
  GammaSat3Result,
  GammaSat4Result,
  GammaSat5Result,
  GammaSat6Result,
  GammaSat7Result,
  GammaSat8Result,
  GammaSat9Result,
} from './resultComponents';
import Divider from '@mui/material/Divider';

const MeyerhofResult = ({ result }) => {
  const gammaMoistChecked = result.input.gammaMoistChecked;
  const gammaDryChecked = result.input.gammaDryChecked;
  const gammaSatChecked = result.input.gammaSatChecked;

  const gammaMoistInputCombination = result.input.gammaMoistInputCombination;
  const gammaDryInputCombination = result.input.gammaDryInputCombination;
  const gammaSatInputCombination = result.input.gammaSatInputCombination;
  return (
    <section className="output">
      <h2>Output </h2>
      <div className="weightVolumeResultContainer">
        {gammaMoistChecked && (
          <div className="weightVolumeResultBox">
            <h3>&gamma; </h3>
            {gammaMoistInputCombination === 1 && <Gamma1Result result={result} />}
            {gammaMoistInputCombination === 2 && <Gamma2Result result={result} />}
            {gammaMoistInputCombination === 3 && <Gamma3Result result={result} />}
            {gammaMoistInputCombination === 4 && <Gamma4Result result={result} />}
            {gammaMoistInputCombination === 5 && <Gamma5Result result={result} />}
          </div>
        )}
        {gammaDryChecked && (
          <div className="weightVolumeResultBox">
            <h3>
              &gamma;<sub>dry</sub>
            </h3>
            {gammaDryInputCombination === 1 && <GammaDry1Result result={result} />}
            {gammaDryInputCombination === 2 && <GammaDry2Result result={result} />}
            {gammaDryInputCombination === 3 && <GammaDry3Result result={result} />}
            {gammaDryInputCombination === 4 && <GammaDry4Result result={result} />}
            {gammaDryInputCombination === 5 && <GammaDry5Result result={result} />}
            {gammaDryInputCombination === 6 && <GammaDry6Result result={result} />}
            {gammaDryInputCombination === 7 && <GammaDry7Result result={result} />}
            {gammaDryInputCombination === 8 && <GammaDry8Result result={result} />}
          </div>
        )}
        {gammaSatChecked && (
          <div className="weightVolumeResultBox">
            <h3>
              &gamma;<sub>sat</sub>
            </h3>
            {gammaSatInputCombination === 1 && <GammaSat1Result result={result} />}
            {gammaSatInputCombination === 2 && <GammaSat2Result result={result} />}
            {gammaSatInputCombination === 3 && <GammaSat3Result result={result} />}
            {gammaSatInputCombination === 4 && <GammaSat4Result result={result} />}
            {gammaSatInputCombination === 5 && <GammaSat5Result result={result} />}
            {gammaSatInputCombination === 6 && <GammaSat6Result result={result} />}
            {gammaSatInputCombination === 7 && <GammaSat7Result result={result} />}
            {gammaSatInputCombination === 8 && <GammaSat8Result result={result} />}
            {gammaSatInputCombination === 9 && <GammaSat9Result result={result} />}
          </div>
        )}
      </div>
    </section>
  );
};

export default MeyerhofResult;
