import React, { useEffect, useState } from 'react';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import AdminRoleModal from './AdminRoleModal';
import basicImage from '../../../assets/adminRoles/basic.JPG';
import standardImage from '../../../assets/adminRoles/standard.JPG';
import premiumImage from '../../../assets/adminRoles/premium.JPG';
import professionalImage from '../../../assets/adminRoles/professional.JPG';
import supportImage from '../../../assets/adminRoles/support.JPG';
import adminImage from '../../../assets/adminRoles/admin.JPG';

const UserTable = ({ subscriptions }) => {
  const [open, setOpen] = useState(false);
  const [modalSubscription, setModalSubscription] = useState();
  const [sortedSubscriptions, setSortedSubscriptions] = useState([]);

  const handleUserInfoModel = (subscription) => {
    setModalSubscription(subscription);
    setOpen(true);
  };

  const UserRoleImage = ({ type }) => {
    let image;
    if (type === 'basic') image = basicImage;
    if (type === 'standard') image = standardImage;
    if (type === 'premium') image = premiumImage;
    if (type === 'professional') image = professionalImage;
    if (type === 'support') image = supportImage;
    if (type === 'admin') image = adminImage;
    return <img src={image} alt="role" className="adminImage" />;
  };

  useEffect(() => {
    setSortedSubscriptions(
      subscriptions.sort((a, b) => {
        if (a.saveCount > b.saveCount) {
          return 1;
        }
        if (a.saveCount < b.saveCount) {
          return -1;
        }
        return 0;
      })
    );
  }, [subscriptions]);

  return (
    <div className="userTable">
      <div className="userTableHeader adminRolesEntry">
        <div className="userTableEntry">#</div>
        <div className="userTableEntry">Role</div>
        <div className="userTableEntry">Monthly Price (Cents)</div>
        <div className="userTableEntry">Save Limit</div>
        <div className="userTableEntry">Calculation Limit</div>
        <div className="userTableEntry">PDF Export</div>
        <div className="userTableEntry">Calculation Steps</div>
      </div>
      {sortedSubscriptions.map((subscription, index) => {
        return (
          <div
            className="userTableRow adminRolesEntry"
            key={subscription._id}
            onClick={() => {
              handleUserInfoModel(subscription);
            }}
          >
            <div className="userTableEntry ">{index + 1}</div>
            <div className="userTableEntry">
              <UserRoleImage type={subscription.name} />
            </div>
            <div className="userTableEntry">{subscription.priceMonthly || '-'}</div>
            <div className="userTableEntry">{subscription.saveCount}</div>
            <div className="userTableEntry">{subscription.calculationCount}</div>
            <div className="userTableEntry">
              {subscription.pdfExport ? <CheckCircleOutlineIcon color="success" /> : <HighlightOffIcon color="error" />}
            </div>
            <div className="userTableEntry">
              {subscription.calculationSteps ? <CheckCircleOutlineIcon color="success" /> : <HighlightOffIcon color="error" />}
            </div>
          </div>
        );
      })}
      <AdminRoleModal
        open={open}
        setOpen={setOpen}
        modalSubscription={modalSubscription}
        setModalSubscription={setModalSubscription}
      />
    </div>
  );
};

export default UserTable;
