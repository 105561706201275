import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { CircularProgress, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import LockIcon from '@mui/icons-material/Lock';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import validateEmail from '../../helpers/validateEmail';
import { Context } from '../../App';
import Header from '../common/Header';
import Footer from '../common/Footer';
import MuiSnackbar from '../common/MuiSnackbar';

const Signup = () => {
  useEffect(() => {
    document.title = 'GeoCalc | SignUp';
  }, []);
  const nav = useNavigate();

  //!GOOGLE ReCAPTCHA
  const [SuccessMsg, setSuccessMsg] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');
  const [valid_token, setValidToken] = useState([]);
  const [captchaChecked, setCaptchaChecked] = useState(false);

  const SITE_KEY = process.env.REACT_APP_reCAPTCHA_SITE_KEY;
  const SECRET_KEY = process.env.REACT_APP_reCAPTCHA_SECRET_KEY;

  const captchaRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let token = captchaRef.current.getValue();
    captchaRef.current.reset();

    if (token) {
      let valid_token = await verifyToken(token);
      setValidToken(valid_token);

      if (valid_token[0].success === true) {
        //console.log('verified');
        //setSuccessMsg('Hurray!! you have submitted the form');
        signup(e);
        setCaptchaChecked(false);
      } else {
        //console.log('not verified');
        //setErrorMsg(' Sorry!! Verify you are not a bot');
        setCaptchaChecked(false);
      }
    }
  };

  const verifyToken = async (token) => {
    let APIResponse = [];

    try {
      let response = await axios.post(`/api/v1/users/verify-token`, {
        reCAPTCHA_TOKEN: token,
        Secret_Key: SECRET_KEY,
      });

      APIResponse.push(response['data']);
      return APIResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const checkCapthca = () => {
    setCaptchaChecked(!captchaChecked);
  };
  //!GOOGLE ReCAPTCHA

  //Snackbar states
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { setLastpage, setUser, theme } = useContext(Context);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [checked, setChecked] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  //error states
  const [errorName, errorNameSet] = useState(false);
  const [errorEmail, errorEmailSet] = useState(false);
  const [errorPassword, errorPasswordSet] = useState(false);
  const [errorPasswordConfirm, errorPasswordConfirmSet] = useState(false);

  //error messages
  const [errorNameMessage, errorNameMessageSet] = useState('');
  const [errorEmailMessage, errorEmailMessageSet] = useState('');
  const [errorPasswordMessage, errorPasswordMessageSet] = useState('');
  const [errorPasswordConfirmMessage, errorPasswordConfirmMessageSet] = useState('');

  const handleClickShowPassword = (variable, setter) => {
    setter(!variable);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //Validation for name, email, password and passwordConfirm.
  //Add errors by onBlur, remove errors by useEffect.

  //Name Validation
  const validateName = (name) => {
    if (name.length < 2) {
      errorNameSet(true);
      errorNameMessageSet('Name must be min. 2 characters.');
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (name.length > 1) {
      errorNameMessageSet('');
      errorNameSet(false);
    }
  }, [name]);

  //Email Validation
  const validateEmailAndHandleError = (email) => {
    if (!validateEmail(email)) {
      errorEmailSet(true);
      errorEmailMessageSet('Please provide a valid email.');
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (validateEmail(email)) {
      errorEmailMessageSet('');
      errorEmailSet(false);
    }
  }, [email]);

  //Password Validation
  const validatePassword = (password) => {
    if (password.length < 8) {
      errorPasswordSet(true);
      errorPasswordMessageSet('Password must be min. 8 characters.');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (password.length > 7) {
      errorPasswordMessageSet('');
      errorPasswordSet(false);
    }
    if (passwordConfirm === password) {
      errorPasswordMessageSet('');
      errorPasswordSet(false);
    }
  }, [password, passwordConfirm]);

  //PasswordConfirm Validation
  const validatePasswordConfirm = (password, passwordConfirm) => {
    if (passwordConfirm.length < 8) {
      errorPasswordConfirmSet(true);
      errorPasswordConfirmMessageSet('"Password Confirm" must be min. 8 characters.');
      return false;
    }
    if (password !== passwordConfirm) {
      errorPasswordConfirmSet(true);
      errorPasswordConfirmMessageSet('"Password" and "Password Confirm" must match.');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (passwordConfirm.length > 7) {
      errorPasswordConfirmMessageSet('');
      errorPasswordConfirmSet(false);
    }
    if (passwordConfirm === password) {
      errorPasswordConfirmMessageSet('');
      errorPasswordConfirmSet(false);
    }
  }, [password, passwordConfirm]);

  //Handle disabled/enabled state of Sign Up button
  useEffect(() => {
    if (
      checked &&
      validateName(name) &&
      validateEmail(email) &&
      validatePassword(password, passwordConfirm) &&
      validatePasswordConfirm(password, passwordConfirm) &&
      captchaChecked === true
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [checked, name, email, password, passwordConfirm, captchaChecked]);

  const signup = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axios({
        method: 'POST',
        url: '/api/v1/users/signup',
        data: {
          name,
          email,
          password,
          passwordConfirm,
        },
      });

      if (response.data.status === 'success') {
        setUser(response.data.data.user);
        setLastpage('./signup');
        nav('/');
      }
    } catch (err) {
      setAlertMessage(err.response.data.message);
      setOpen(true);
    }
    setLoading(false);
  };

  return (
    <>
      <Header />
      <div className="signup">
        <MuiSnackbar open={open} setOpen={setOpen} severity="error" alertMessage={alertMessage} />
        <LockIcon color="primary" fontSize="large" />
        <h2>Sign Up</h2>
        <form>
          <TextField
            onBlur={(e) => validateName(e.target.value)}
            className="test-signup-name"
            style={{ width: 300 }}
            size="small"
            label="Name"
            variant="outlined"
            autoComplete="name"
            onChange={(e) => setName(e.target.value)}
            disabled={loading}
            error={errorName}
            helperText={errorNameMessage}
          />
          <TextField
            onBlur={(e) => validateEmailAndHandleError(e.target.value)}
            className="test-signup-email"
            style={{ width: 300 }}
            size="small"
            label="Email"
            variant="outlined"
            autoComplete="username"
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            error={errorEmail}
            helperText={errorEmailMessage}
          />
          <TextField
            onBlur={(e) => validatePassword(e.target.value)}
            className="test-signup-password"
            size="small"
            style={{ width: 300 }}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      handleClickShowPassword(showPassword, setShowPassword);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Password"
            disabled={loading}
            error={errorPassword}
            helperText={errorPasswordMessage}
          />
          <TextField
            onBlur={(e) => validatePasswordConfirm(password, e.target.value)}
            className="test-signup-password-confirm"
            size="small"
            style={{ width: 300 }}
            type={showPasswordConfirm ? 'text' : 'password'}
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            autoComplete="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      handleClickShowPassword(showPasswordConfirm, setShowPasswordConfirm);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Confirm Password"
            disabled={loading}
            error={errorPasswordConfirm}
            helperText={errorPasswordConfirmMessage}
          />
          <label>
            <Checkbox
              className="test-check-terms"
              size="small"
              onChange={() => setChecked(!checked)}
              checked={checked}
              disabled={loading}
            ></Checkbox>
            I've read
            <Link component={RouterLink} target="_blank" rel="noreferrer" to="/terms-of-use">
              {' '}
              terms and conditions
            </Link>
          </label>
          {!loading && (
            <ReCAPTCHA
              className="recaptcha"
              sitekey={SITE_KEY}
              ref={captchaRef}
              theme={theme === 'light' ? 'light' : 'dark'}
              onChange={checkCapthca}
            />
          )}
          <Button
            className="test-signup-button"
            variant="contained"
            style={{ width: 300 }}
            fullWidth
            disabled={buttonDisabled || loading}
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress color="grey" size={20} /> : 'SIGN UP'}
          </Button>
        </form>
        <Link component={RouterLink} to="/login">
          Already have an account? Sign in here!
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default Signup;
