import round from '../../../helpers/round';

const HansenResultGroundFactors = ({ result }) => {
  const { beta } = result.input;

  const { isInclinedLoading, isPhiZero, gc, gq, gGamma } = result.output;
  const case1 = isInclinedLoading && isPhiZero;
  const case2 = isInclinedLoading && !isPhiZero;
  const case3 = !isInclinedLoading && isPhiZero;
  const case4 = !isInclinedLoading && !isPhiZero;

  const Case1Case3gc = () => (
    <div className="meyerhof-factors">
      <h4>
        g<sub>c</sub>
      </h4>
      <p>
        &phi; = 0 &rarr; Only g'<sub>c</sub> should be calculated.
      </p>
      <p>
        g'<sub>c</sub> = &beta; / 147
      </p>
      <p>
        g'<sub>c</sub> = {round(beta, 2)} / 147
      </p>
      <p>
        g'<sub>c</sub>={round(gc, 2)}
      </p>
    </div>
  );

  const Case2Case4gq = () => (
    <div className="meyerhof-factors">
      <h4>
        g<sub>q</sub>
      </h4>
      <p>
        g<sub>q</sub> = (1 - 0.5 tan&beta;)<sup>5</sup>
      </p>
      <p>
        g<sub>q</sub> = (1 - 0.5 tan{round(beta, 2)})<sup>5</sup>
      </p>
      <p>
        g<sub>q</sub>={round(gq, 2)}
      </p>
    </div>
  );

  const Case2Case4gc = () => (
    <div className="meyerhof-factors">
      <h4>
        g<sub>c</sub>
      </h4>
      <p>
        g<sub>c</sub> = 1 - &beta; / 147
      </p>
      <p>
        g<sub>c</sub> = 1 - {round(beta, 2)} / 147
      </p>
      <p>
        g<sub>c</sub>={round(gc, 2)}
      </p>
    </div>
  );

  const Case2Case4ggamma = () => (
    <div className="meyerhof-factors">
      <h4>
        g<sub>&gamma;</sub>
      </h4>
      <p>
        g<sub>&gamma;</sub> = (1 - 0.5 tan&beta;)<sup>5</sup>
      </p>
      <p>
        g<sub>&gamma;</sub> = (1 - 0.5 tan{round(beta, 2)})<sup>5</sup>
      </p>
      <p>
        g<sub>&gamma;</sub>={round(gGamma, 2)}
      </p>
    </div>
  );

  return (
    <div className="checks">
      <h3>
        Ground Factors (g<sub>c</sub>, g<sub>q</sub>, g<sub>&gamma;</sub>)
      </h3>
      <div className="factors-wrapper">
        {case1 && <Case1Case3gc />}

        {case2 && <Case2Case4gq />}
        {case2 && <Case2Case4gc />}
        {case2 && <Case2Case4ggamma />}

        {case3 && <Case1Case3gc />}

        {case4 && <Case2Case4gq />}
        {case4 && <Case2Case4gc />}
        {case4 && <Case2Case4ggamma />}
      </div>
    </div>
  );
};
export default HansenResultGroundFactors;
