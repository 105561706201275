import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const style = {
  position: 'absolute',
  minWidth: '300px',
  maxWidth: '500px',
  lineHeight: '28px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'left',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 5,
};

const styleCloseIcon = {
  position: 'absolute',
  top: '5px',
  right: '5px',
  cursor: 'pointer',
};

export default function TransitionsModal({ open, setOpen, email }) {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <CloseRoundedIcon sx={styleCloseIcon} onClick={handleClose} color={'primary'} />
          <div className="userModalContainer">
            <h2>About Your Payment</h2>
            <p>
              We are using patreon.com as our payment system. If you are willing to subscribe to one of our plans, you need to
              create an account at patreon.com with the same email you used here which is "{email}"
            </p>
            <p>Otherwise we will not be able to match your account with your payment.</p>
            <Button
              className="pricingInfoModalButton"
              variant="contained"
              href="https://www.patreon.com/geocalc/membership"
              target={'blank'}
              onClick={handleClose}
              rel="noopener noreferrer"
            >
              GO TO PATREON
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
