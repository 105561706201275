import React, { useState, forwardRef, useEffect } from 'react';
import axios from 'axios';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const ChangePassword = () => {
  const [passwordCurrent, setPasswordCurrent] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setpasswordConfirm] = useState('');

  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [passwordCurrentWarningText, setPasswordCurrentWarningText] = useState('');
  const [passwordWarningText, setPasswordWarningText] = useState('');
  const [passwordConfirmWarningText, setPasswordConfirmWarningText] = useState('');

  const [errorPasswordCurrent, setErrorPasswordCurrent] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorPasswordConfirm, setErrorPasswordConfirm] = useState(false);

  useEffect(() => {
    if (passwordCurrent.length > 7) {
      setPasswordCurrentWarningText('');
      setErrorPasswordCurrent(false);
    }

    if (password.length > 7) {
      setPasswordWarningText('');
      setErrorPassword(false);
    }

    if (passwordConfirm.length > 7) {
      setPasswordConfirmWarningText('');
      setErrorPasswordConfirm(false);
      if (passwordConfirm !== password) {
        setErrorPasswordConfirm(true);
        setErrorPassword(true);
        setPasswordConfirmWarningText('"New Password" must match "Confirm New Password"');
      }
    }
    if (passwordCurrent.length > 7 && password.length > 7 && passwordConfirm.length > 7 && password === passwordConfirm) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [passwordCurrent, password, passwordConfirm]);

  const handleValidationCurrentPassword = () => {
    if (passwordCurrent.length < 8) {
      setErrorPasswordCurrent(true);
      setPasswordCurrentWarningText('Password must be min. 8 characters');
    } else {
      setPasswordCurrentWarningText('');
    }
  };

  const handleValidationPassword = () => {
    if (password.length < 8) {
      setErrorPassword(true);
      setPasswordWarningText('New password must be min. 8 characters');
    } else {
      setPasswordWarningText('');
    }
  };

  const handleValidationPasswordConfirm = () => {
    if (passwordConfirm.length < 8) {
      setErrorPasswordConfirm(true);
      setPasswordConfirmWarningText('Password confirm must be min. 8 characters');
    } else {
      setPasswordConfirmWarningText('');
      if (passwordConfirm !== password) {
        setErrorPasswordConfirm(true);
        setErrorPassword(true);
        setPasswordConfirmWarningText('"New Password" must match "Confirm New Password"');
      }
    }
  };

  const handleClickShowPassword = (variable, setter) => {
    setter(!variable);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios({
        method: 'PATCH',
        url: '/api/v1/users/updateMyPassword',
        data: {
          passwordCurrent,
          password,
          passwordConfirm,
        },
      });

      if (response.data.status === 'success') {
        setAlertMessage('Your password is updated successfully.');
        setSeverity('success');
        setOpen(true);
        setPasswordCurrent('');
        setPassword('');
        setpasswordConfirm('');
        setPasswordCurrentWarningText('');
        setPasswordWarningText('');
        setPasswordConfirmWarningText('');
        setErrorPasswordCurrent(false);
        setErrorPassword(false);
        setErrorPasswordConfirm(false);
      }
    } catch (err) {
      setAlertMessage(err.response.data.message);
      setSeverity('error');
      setOpen(true);
    }
    setLoading(false);
  };

  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="account-section">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <h3>Change Password</h3>

      <TextField
        onBlur={handleValidationCurrentPassword}
        size="small"
        helperText={passwordCurrentWarningText}
        style={{ width: 400 }}
        type={showPasswordCurrent ? 'text' : 'password'}
        value={passwordCurrent}
        error={errorPasswordCurrent}
        onChange={(e) => setPasswordCurrent(e.target.value)}
        autoComplete="password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  handleClickShowPassword(showPasswordCurrent, setShowPasswordCurrent);
                }}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPasswordCurrent ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Current Password"
      />
      <TextField
        onBlur={handleValidationPassword}
        size="small"
        helperText={passwordWarningText}
        style={{ width: 400 }}
        type={showPassword ? 'text' : 'password'}
        value={password}
        error={errorPassword}
        onChange={(e) => setPassword(e.target.value)}
        autoComplete="password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  handleClickShowPassword(showPassword, setShowPassword);
                }}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="New Password"
      />
      <TextField
        onBlur={handleValidationPasswordConfirm}
        size="small"
        helperText={passwordConfirmWarningText}
        style={{ width: 400 }}
        type={showPasswordConfirm ? 'text' : 'password'}
        value={passwordConfirm}
        error={errorPasswordConfirm}
        onChange={(e) => setpasswordConfirm(e.target.value)}
        autoComplete="password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  handleClickShowPassword(showPasswordConfirm, setShowPasswordConfirm);
                }}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Confirm New Password"
      />
      <Button variant="contained" style={{ width: 400 }} onClick={changePassword} disabled={buttonDisabled}>
        {loading ? <CircularProgress size={20} /> : 'Save'}
      </Button>
    </div>
  );
};

export default ChangePassword;
