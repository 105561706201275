import React from 'react';
import round from '../../../helpers/round';

const OutputLayer = ({ iteration, phi, c, hEffective }) => {
  return (
    <div className="layer layered-soil-layer">
      {iteration && <span> {round(iteration, 3)} </span>}
      {(phi || phi === 0) && <span> {round(phi, 3)} </span>}
      {(c || c === 0) && <span> {round(c, 3)} </span>}
      {hEffective && <span> {round(hEffective, 3)} </span>}
    </div>
  );
};

export default OutputLayer;
