import React, { useEffect, useState, forwardRef } from 'react';
import MuiAlert from '@mui/material/Alert';
import round from '../../../helpers/round';

import usNavy1982 from '../../../assets/calculations/usNavy1982/usNavy1982_modified.JPG';

const StroudResult = ({ result }) => {
  const { liquidLimit } = result.input;
  const {
    cv1Limits,
    cv2Limits,
    cv3Limits,
    isCv1LimitOK,
    isCv2LimitOK,
    isCv3LimitOK,
    cv1_m2perYear,
    cv2_m2perYear,
    cv3_m2perYear,
    cv1_cm2PerSecond,
    cv2_cm2PerSecond,
    cv3_cm2PerSecond,
  } = result.output;

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} {...props} />;
  });

  const GraphLimitAlert = ({ type }) => {
    return (
      <Alert severity="warning" style={{ minWidth: 400, maxWidth: 600, textAlign: 'left', marginTop: '10px' }}>
        <span>
          Liquid Limit (LL) provided ({round(liquidLimit, 2)}%) is out of the limits of the graph.
          {type === 'cv1' && 'For Cv-1, valid LL(%) values range between 33-120.'}
          {type === 'cv2' && 'For Cv-2  valid LL(%) values range between 27-160.'}
          {type === 'cv3' && 'For Cv-3  valid LL(%) values range between 27-120.'}
        </span>
      </Alert>
    );
  };

  return (
    <section className="output">
      <h2>Output </h2>
      <div className="clayEffectiveContainer">
        <div className="clayEffectiveBox">
          <div className="clayEffectiveGraphContainer">
            <h4>US Navy (1982)</h4>
            <img src={usNavy1982} alt="usNavy 1982"></img>
          </div>
          <p>For LL = {round(liquidLimit, 2)}% </p>
          <p>&darr;</p>

          {isCv1LimitOK ? (
            <p>
              c<sub>v-1</sub> = {round(cv1_m2perYear, 2)}m<sup>2</sup>/year = {round(cv1_cm2PerSecond, 6)}cm<sup>2</sup>/s
            </p>
          ) : (
            <GraphLimitAlert type="cv1" />
          )}

          {isCv2LimitOK ? (
            <p>
              c<sub>v-2</sub> = {round(cv2_m2perYear, 2)}m<sup>2</sup>/year = {round(cv2_cm2PerSecond, 6)}cm<sup>2</sup>/s
            </p>
          ) : (
            <GraphLimitAlert type="cv2" />
          )}

          {isCv3LimitOK ? (
            <p>
              c<sub>v-3</sub> = {round(cv3_m2perYear, 2)}m<sup>2</sup>/year = {round(cv3_cm2PerSecond, 6)}cm<sup>2</sup>/s
            </p>
          ) : (
            <GraphLimitAlert type="cv3" />
          )}
        </div>
      </div>
    </section>
  );
};

export default StroudResult;
