export const calculationsData = [
  // CALCULATION TOOLS AVAILABLE
  {
    title: 'SPT Corrections',
    description: 'Calculate N60 and N160 values for a given borehole log',
    link: '/calculations/spt-corr',
    theoryLink: '/spt-corr-theory',
    id: 1,
    className: 'box',
  },
  {
    title: "Bearing Capacity of Shallow Foundations | Terzaghi's Method",
    description: 'Calculate bearing capacity of shallow foundations in soil. Terzaghi (1943)',
    link: '/calculations/terzaghi',
    theoryLink: '/terzaghi-theory',
    id: 2,
    className: 'box',
  },
  {
    title: "Bearing Capacity of Shallow Foundations | Meyerhof's Method",
    description: 'Calculate bearing capacity of shallow foundations in soil. Meyerhof (1963)',
    link: '/calculations/meyerhof',
    theoryLink: '/meyerhof-theory',
    id: 3,
    className: 'box',
  },

  {
    title: 'Bearing Capacity of Pile Foundations',
    description: 'Calculate bearing capacity of pile foundations in rock. AASHTO (2002)',
    link: '/calculations/pile-rock-bc',
    theoryLink: '/pile-rock-bc-theory',
    id: 4,
    className: 'box',
  },
  {
    title: 'Soil Liquefaction',
    description: 'Calculate soil liquefaction safety factors based on SPT values for a given borehole log.',
    link: '/calculations/liquefaction-tbdy',
    theoryLink: '/liquefaction-tbdy-theory',
    id: 5,
    className: 'box',
  },
  {
    title: 'Settlement',
    description: 'Calculate immediate settlement of pile foundations in rock. AASHTO (2002)',
    link: '/calculations/pile-rock-settle-aashto2002',
    theoryLink: '/pile-rock-settle-aashto2002-theory',
    id: 6,
    className: 'box',
  },
  {
    title: 'Settlement',
    description: 'Calculate time dependent settlements of shallow foundations in soil',
    link: '/calculations/consolidation',
    theoryLink: '/consolidation-theory',
    id: 7,
    className: 'box',
  },
  {
    title: 'Average c-phi Calculation for Layered Soils',
    description: 'Calculate cav - phiav for layered soils to use in bearing capacity calculations for shallow foundations.',
    link: '/calculations/layered-soil',
    theoryLink: '/layered-soil-theory',
    id: 8,
    className: 'box',
  },
  {
    title: "Bearing Capacity of Shallow Foundations | Hansen's Method",
    description: 'Calculate bearing capacity of shallow foundations in soil. Hansen (1970)',
    link: '/calculations/hansen',
    theoryLink: '/hansen-theory',
    id: 9,
    className: 'box ',
  },
  {
    title: 'Weight - Volume Relationships',
    description:
      'Calculate soil properties like void ratio, porosity, degree of saturation, specific gravitiy, saturated unit weight and more.',
    link: '/calculations/weightVolumeRelations',
    theoryLink: '/weightVolumeRelations-theory',
    id: 10,
    className: 'box ',
  },
  {
    title: 'cu-mv & SPT-PI Relationships',
    description: 'Calculate undrained shear strength (cu) and coefficient of volume compressibility (mv) of clays. Stroud (1974)',
    link: '/calculations/stroud',
    theoryLink: '/stroud-theory',
    id: 11,
    className: 'box ',
  },
  {
    title: 'Plasticity Index Vs Effective Friction Angle Relationships',
    description:
      'Calculate effective friction angle of clays with 3 approaches. Terzaghi, Peck, Mesri (1996) - Das (1985) - Gibson (1953)',
    link: '/calculations/clayEffectiveFrictionAngle',
    theoryLink: '/clayEffectiveFrictionAngle-theory',
    id: 12,
    className: 'box ',
  },
  {
    title: 'Liquid Limit Vs Coefficient of Consolidation of Clays',
    description: 'Calculate coefficient of consolidation (cv) of clays using liquid limit (LL). US Navy (1982)',
    link: '/calculations/cvLLUsNavy',
    theoryLink: '/cvLLUsNavy-theory',
    id: 13,
    className: 'box ',
  },
  {
    title: 'Vertical Stress Increase Due to Surface Loads',
    description: 'Calculate vertical stress increase at a depth due to surface loads by 60 degree method and 1H:2V method.',
    link: '/calculations/stressIncrease',
    theoryLink: '/stressIncrease-theory',
    id: 14,
    className: 'box ',
  },
  // CALCULATION TOOLS BEING DEVELOPED
  {
    title: 'Bearing Capacity of Pile Foundations in Soil',
    link: '#',
    theoryLink: '#',
    id: -1,
    className: 'box coming-soon',
    releaseDate: 'To be announced',
  },

  {
    title: 'Parameter Estimation of Cohesive Soils Using Pressuremeter Results',
    link: '#',
    theoryLink: '#',
    id: -1,
    className: 'box coming-soon',
    releaseDate: 'To be announced',
  },

  {
    title: "Bearing Capacity of Shallow Foundations | Vesic's Method",
    link: '#',
    theoryLink: '#',
    id: -1,
    className: 'box coming-soon',
    releaseDate: 'To be announced',
  },
];
