import { React } from 'react';
import Button from '@mui/material/Button';
import CalculateButton from './calculateButton';
import SaveButton from './saveButton';

const ButtonContainer = ({
  URL,
  removeLayer,
  calculate,
  checkCalculationNameforSaving,
  calculationInput,
  addLayer,
  layersFrontLength,
  isLoadingCalculate,
  isLoadingLoad,
  isLoadingSave,
  saveButtonDisabled,
  calculateButtonDisabled,
  calculationCountReached,
  saveCountReached,
}) => {
  return (
    <div className="buttonContainer SPTbuttonContainer">
      <CalculateButton
        calculationCountReached={calculationCountReached}
        calculate={calculate}
        calculationInput={calculationInput}
        URL={URL}
        calculateButtonDisabled={calculateButtonDisabled}
        isLoadingCalculate={isLoadingCalculate}
        isLoadingLoad={isLoadingLoad}
        isLoadingSave={isLoadingSave}
        layersFrontLength={layersFrontLength}
      />
      <Button
        variant="outlined"
        onClick={removeLayer}
        disabled={isLoadingCalculate || isLoadingSave || isLoadingLoad || layersFrontLength < 1}
      >
        Remove Layer
      </Button>
      <Button
        variant="outlined"
        onClick={addLayer}
        disabled={isLoadingCalculate || isLoadingSave || isLoadingLoad || layersFrontLength > 39}
      >
        Add Layer
      </Button>
      <SaveButton
        saveCountReached={saveCountReached}
        checkCalculationNameforSaving={checkCalculationNameforSaving}
        calculationInput={calculationInput}
        URL={URL}
        saveButtonDisabled={saveButtonDisabled}
        isLoadingCalculate={isLoadingCalculate}
        isLoadingLoad={isLoadingLoad}
        isLoadingSave={isLoadingSave}
        layersFrontLength={layersFrontLength}
      />
    </div>
  );
};
export default ButtonContainer;
