import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { CircularProgress } from '@mui/material';
import { LineChart } from '../Charts/LineChart';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const PaidVsTime = () => {
  const [paidPeriod, setPaidPeriod] = useState(7);
  const [paidLoadingGraph, setPaidLoadingGraph] = useState(false);
  const [lastXdayPaidLabels, setLastXdayPaidLabels] = useState([]);

  const [lastXdayStandardData, setLastXdayStandardData] = useState([]);
  const [lastXdayPremiumData, setLastXdayPremiumData] = useState([]);
  const [lastXdayProfessionalData, setLastXdayProfessionalData] = useState([]);

  const [lastXdayStandardDataCumulative, setLastXdayStandardDataCumulative] = useState([]);
  const [lastXdayPremiumDataCumulative, setLastXdayPremiumDataCumulative] = useState([]);
  const [lastXdayProfessionalDataCumulative, setLastXdayProfessionalDataCumulative] = useState([]);

  useEffect(() => {
    const getLastXPeriodPaid = async () => {
      setPaidLoadingGraph(true);

      try {
        const response = await axios({
          method: 'GET',
          url: `/api/v1/admin/getLastXPeriodPaidUsers/${paidPeriod}`,
        });
        setLastXdayPaidLabels(response.data.data.labels);

        setLastXdayStandardData(response.data.data.dataSetStandard);
        setLastXdayPremiumData(response.data.data.dataSetPremium);
        setLastXdayProfessionalData(response.data.data.dataSetProfessional);

        setLastXdayStandardDataCumulative(response.data.data.standard);
        setLastXdayPremiumDataCumulative(response.data.data.premium);
        setLastXdayProfessionalDataCumulative(response.data.data.professional);
      } catch (err) {
        console.log('Error->', err.response.data.message);
      }
      setPaidLoadingGraph(false);
    };
    getLastXPeriodPaid();
  }, [paidPeriod]);

  const dataPaid = {
    label: 'Paid customers (Cumulative)',
    period: paidPeriod,
    labels: lastXdayPaidLabels,
    datasets: [
      {
        label: 'Standard',
        data: lastXdayStandardData,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Premium',
        data: lastXdayPremiumData,
        borderColor: 'rgba(88, 124, 193, 1)',
        backgroundColor: 'rgba(88, 124, 193, 0.5)',
      },
      {
        label: 'Professional',
        data: lastXdayProfessionalData,
        borderColor: 'rgba(89, 194, 136, 1)',
        backgroundColor: 'rgba(89, 194, 136, 0.5)',
      },
    ],
  };
  const dataCumulative = {
    label: 'Paid customers',
    period: paidPeriod,
    labels: lastXdayPaidLabels,
    datasets: [
      {
        label: 'Standard',
        data: lastXdayStandardDataCumulative,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Premium',
        data: lastXdayPremiumDataCumulative,
        borderColor: 'rgba(88, 124, 193, 1)',
        backgroundColor: 'rgba(88, 124, 193, 0.5)',
      },
      {
        label: 'Professional',
        data: lastXdayProfessionalDataCumulative,
        borderColor: 'rgba(89, 194, 136, 1)',
        backgroundColor: 'rgba(89, 194, 136, 0.5)',
      },
    ],
  };
  return (
    <div className="SignupVsTime ">
      <div className="icon">
        <QueryStatsIcon color="primary" fontSize="large" />
      </div>
      <div className="selectPeriod">
        <FormControl>
          <InputLabel id="paid">Period</InputLabel>
          <Select
            size="small"
            labelId="paid"
            value={paidPeriod}
            label="Period"
            sx={{ width: '150px' }}
            onChange={(e) => {
              setPaidPeriod(e.target.value);
            }}
          >
            <MenuItem value={7}>Last 7 days</MenuItem>
            <MenuItem value={15}>Last 15 days</MenuItem>
            <MenuItem value={30}>Last 30 days</MenuItem>
            <MenuItem value={90}>Last 3 months</MenuItem>
            <MenuItem value={180}>Last 6 months</MenuItem>
            <MenuItem value={365}>Last 12 months</MenuItem>
            <MenuItem value={730}>Last 2 years</MenuItem>
          </Select>
        </FormControl>
      </div>

      {paidLoadingGraph ? (
        <CircularProgress />
      ) : (
        <>
          <LineChart data={dataCumulative} />
          <LineChart data={dataPaid} />
        </>
      )}
    </div>
  );
};

export default PaidVsTime;
