import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import VerifiedIcon from '@mui/icons-material/Verified';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CircularProgress from '@mui/material/CircularProgress';

const ConfirmEmail = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Confirm Email';
  }, []);
  const [loading, setLoading] = useState(false);
  const [infoSuccess, setInfoSuccess] = useState('');
  const [infoFail, setInfoFail] = useState('');

  const { token } = useParams();

  useEffect(() => {
    const confirmEmail = async () => {
      setLoading(true);
      try {
        const response = await axios({
          method: 'PATCH',
          url: `/api/v1/users/confirmEmail/${token}`,
        });

        if (response.data.status === 'success') {
          setInfoSuccess('Your email is verified succesfully!');
        }
      } catch (err) {
        setInfoFail(err.response.data.message);
      }
      setLoading(false);
    };
    confirmEmail();
  }, [token]);

  return (
    <div className="emailConfirm">
      <h2 className="confirmHeading">geocalc.app | Email Verification</h2>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {infoSuccess && (
            <div className="emailConfirmSuccessContainer">
              <VerifiedIcon sx={{ fontSize: 60, color: '#31c80fd5' }} />
              <div className="emailConfirmInfoSuccess">{infoSuccess}</div>
            </div>
          )}
          {infoFail && (
            <div className="emailConfirmFailContainer">
              <GppMaybeIcon sx={{ fontSize: 60, color: '#e70707d5' }} />
              <div className="emailConfirmInfoFail">{infoFail}</div>
            </div>
          )}

          <div>
            <Link component={RouterLink} to="/login">
              Sign in geocalc.app!
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default ConfirmEmail;
