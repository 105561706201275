import round from '../../../helpers/round';
import WaterCase1 from '../../../assets/calculations/hansen/WaterCase1.jpg';
import WaterCase2 from '../../../assets/calculations/hansen/WaterCase2.jpg';
import WaterCase3 from '../../../assets/calculations/hansen/WaterCase3.jpg';
import TransitionsModal from '../../modals/TransitionsModal';
const HansenResultEffectOfGroundWater = ({ result }) => {
  const { df, fndWidth, gwt, gamma } = result.input;
  const { phips, h, dw, waterLevelCase, gammaEffectiveInitial, gammaEffective, GRAVITY } = result.output;

  const HCalculation = () => (
    <>
      <p>
        H = D<sub>f</sub> + 0.5 B tan(45 + &phi;/2)
      </p>
      <p>
        H = {round(df, 2)} + (0.5) ({round(fndWidth, 2)}) [tan(45 + {round(phips, 2)} / 2)]
      </p>
      <p>H = {round(h, 2)}m</p>
    </>
  );
  const WaterLevelCase1 = () => (
    <>
      <HCalculation />
      <p>&darr;</p>
      <p>
        GWT = {round(gwt, 2)} &gt; D<sub>f</sub> = {round(df, 2)} and GWT = {round(gwt, 2)} &le; H = {round(h, 2)}
      </p>
      <p>&darr;</p>
      <p>Ground water level is below foundation base and above wedge length of foundation.</p>
      <p>
        In this case use &gamma;<sub>e</sub> as follows:
      </p>
      <p>&darr;</p>
      <p>
        &gamma;<sub>e</sub> = (2H - d<sub>w</sub>) (d<sub>w</sub> / H<sup>2</sup>) (&gamma;<sub>sat</sub>) + (&gamma;' / H
        <sup>2</sup>) (H - d<sub>w</sub>)<sup>2</sup>
      </p>

      <p>Where;</p>
      <p>H = Wedge zone measured from ground level.</p>
      <p>
        d<sub>w</sub> = Depth to water table below base of footing.
      </p>
      <p>
        &gamma;<sub>sat</sub> = Saturated unit weight of soil in depth d<sub>w</sub>
      </p>
      <p>&gamma;' = Submerged unit weight of soil below water table</p>
      <p>&darr;</p>

      <p>
        d<sub>w</sub> = GWT - D<sub>f</sub>
      </p>
      <p>
        d<sub>w</sub> = {round(gwt, 2)} - {round(df, 2)}
      </p>
      <p>
        d<sub>w</sub> = {round(dw, 2)}m
      </p>
      <p>&darr;</p>
      <p>
        &gamma;' = &gamma;<sub>sat</sub> - &gamma;<sub>w</sub>
      </p>
      <p>
        &gamma;' = {round(gamma, 2)} - {round(GRAVITY, 2)}
      </p>
      <p>&gamma;' = {round(gammaEffectiveInitial, 2)}</p>
      <p>&darr;</p>
      <p>
        &gamma;<sub>e</sub> = [2 ({round(h, 2)}) - {round(dw, 2)}] ({round(dw, 2)} / {round(h, 2)}
        <sup>2</sup>) ({round(gamma, 2)}) + ({round(gammaEffectiveInitial, 2)} / {round(h, 2)}
        <sup>2</sup>) ({round(h, 2)} - {round(dw, 2)})<sup>2</sup>
      </p>
      <p>
        &gamma;<sub>e</sub> = {round(gammaEffective, 2)}m
      </p>
      <br />

      <TransitionsModal
        image={WaterCase1}
        title="Ground Water Level"
        description={'Ground water level is below foundation base and above wedge length of foundation. '}
      ></TransitionsModal>
    </>
  );

  const WaterLevelCase2 = () => (
    <>
      <p>
        GWT = {round(gwt, 2)} &nbsp; &le; &nbsp; D<sub>f</sub> = {round(df, 2)}
      </p>
      <p>&darr;</p>
      <p>Ground water level is above foundation base.</p>
      <p>So, all the wedge length of the foundation is submerged into water. </p>
      <p>&darr;</p>
      <p>
        &gamma;<sub>e</sub> = &gamma;' = &gamma; - &gamma;<sub>w</sub>
      </p>
      <p>
        &gamma;<sub>e</sub> = {round(gamma, 2)} - {round(GRAVITY, 2)}
      </p>
      <p>
        &gamma;<sub>e</sub> = {round(gammaEffective, 2)}
      </p>
      <br />
      <TransitionsModal
        image={WaterCase2}
        title="Ground Water Level"
        description={
          'Ground water level is above foundation base. So, all the wedge length of the foundation is submerged into water.'
        }
      ></TransitionsModal>
    </>
  );

  const WaterLevelCase3 = () => (
    <>
      <HCalculation />
      <p>&darr;</p>
      <p>
        GWT = {round(gwt, 2)} &nbsp; &ge; &nbsp; H = {round(h, 2)}
      </p>
      <p>&darr;</p>
      <p>Ground water level is below wedge length of the foundation.</p>
      <p>So, ground water has no effect on bearing capacity of the soil for this foundation.</p>
      <p>&darr;</p>
      <p>
        &gamma;<sub>e</sub> = &gamma;
      </p>
      <p>
        &gamma;<sub>e</sub> = {round(gamma, 2)}
      </p>
      <br />
      <TransitionsModal
        image={WaterCase3}
        title="Ground Water Level"
        description={
          'Ground water level (GWT) is below wedge length of the foundation (Df + Hw). Therefore, ground water has no effect on bearing capacity of the soil for this foundation.'
        }
      ></TransitionsModal>
    </>
  );

  return (
    <div className="checks">
      <h3>Effect Of Ground Water</h3>
      <div className="factors-wrapper">
        <div className="meyerhof-factors">
          <h4>
            &gamma;<sub>e</sub> (Average effective unit weight of soil)
          </h4>
          {waterLevelCase === 1 && <WaterLevelCase1 />}
          {waterLevelCase === 2 && <WaterLevelCase2 />}
          {waterLevelCase === 3 && <WaterLevelCase3 />}
        </div>
      </div>
    </div>
  );
};
export default HansenResultEffectOfGroundWater;
