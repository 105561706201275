import * as React from 'react';
import { useState, useEffect } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { convertFirstCharacterAllWordsToUppercase } from '../../../helpers/convertStringToUpperLowerCase';

export default function BasicTable({ user }) {
  const [stats, setStats] = useState([]);

  //Set states for user limits
  const [userCalculationLimit, setUserCalculationLimit] = useState();
  const [userSaveLimit, setUserSaveLimit] = useState();

  //Set states for chart customisation and warnings
  const [colorCalculationCount, setColorCalculationCount] = useState('');
  const [colorSaveCount, setColorSaveCount] = useState('');
  const [showCiriticalStuff, setShowcriticalStuff] = useState(false);

  //Get subscription limits for current user
  useEffect(() => {
    const getUserSubscriptionDetails = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: '/api/v1/users/getUserSubscriptionDetails',
        });

        setUserCalculationLimit(response.data.userSubscriptionDetails[0].calculationCount);
        setUserSaveLimit(response.data.userSubscriptionDetails[0].saveCount);
      } catch (err) {
        /* console.log(err); */
      }
    };

    getUserSubscriptionDetails();
  }, []);

  useEffect(() => {
    //Convert user counts to array
    setStats(Object.entries(user.count));

    //Calculate remaining percentage of calculation count
    const remainingPercentageCalculationCount = 100 - (user.totalCount.calculationCount / userCalculationLimit) * 100;

    //Calculate remaining percentage of save count (basic users can not save calculations)
    let remainingPercentageSaveCount;
    if (user.role !== 'basic') {
      remainingPercentageSaveCount = 100 - (user.totalCount.saveCount / userSaveLimit) * 100;
    }

    if (remainingPercentageCalculationCount < 10) {
      setColorCalculationCount('#B33A3A');
      setShowcriticalStuff(true);
    } else {
      setColorCalculationCount('#2d7dcd');
      setShowcriticalStuff(false);
    }

    if (user.role !== 'basic') {
      if (remainingPercentageSaveCount < 10) {
        setColorSaveCount('#B33A3A');
        setShowcriticalStuff(true);
      } else {
        setColorSaveCount('#2d7dcd');
        setShowcriticalStuff(false);
      }
    }
    if (user.role !== 'basic') {
      if (remainingPercentageSaveCount < 10 || remainingPercentageCalculationCount < 10) {
        setShowcriticalStuff(true);
      }
    }
  }, [user, userCalculationLimit, userSaveLimit]);

  return (
    <div className="statsWrapper">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300, maxWidth: 800 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="usage-colored" align="center" sx={{ padding: '5px' }}>
                Calculation Name
              </TableCell>
              <TableCell className="usage-colored" align="center" sx={{ padding: '5px' }}>
                Calculation Count
              </TableCell>
              <TableCell
                className="usage-colored"
                align="center"
                sx={{
                  display: 'flex',
                  padding: '5px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Save Count{' '}
                {user.role === 'basic' && (
                  <Tooltip title="Upgrade your account to save your calculations.">
                    <IconButton>
                      <InfoTwoToneIcon fontSize={'small'} color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stats.map((row) => (
              <TableRow key={row[1].name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row[1].name}
                </TableCell>
                <TableCell align="center">{row[1].calculationCount}</TableCell>

                {user.role === 'basic' ? (
                  <TableCell align="center">-</TableCell>
                ) : (
                  <TableCell align="center">{row[1].saveCount}</TableCell>
                )}
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="center">Total</TableCell>
              <TableCell align="center">{user.totalCount.calculationCount}</TableCell>
              {user.role === 'basic' ? (
                <TableCell align="center">-</TableCell>
              ) : (
                <TableCell align="center">{user.totalCount.saveCount}</TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className="chartContainer">
        <div className="chartHelper">
          <p>
            <span className="usedDot"></span>
            <span className="chartHelperText">Used</span>
          </p>

          <p>
            <span className="remainingDot"></span>
            <span className="chartHelperText">Remaining</span>
          </p>

          {showCiriticalStuff && (
            <p>
              <span className="remainingCriticalDot"></span>
              <span className="chartHelperText">Remaining (below 10%)</span>
            </p>
          )}
        </div>
        {userCalculationLimit > 0 && (
          <div className="chartWrapper">
            <div className="chart">
              <div className="chartTitle">Calculation Count</div>
              <PieChart
                data={[
                  {
                    title: 'Used',
                    value: `${user.totalCount.calculationCount}`,
                    color: '#729eca',
                  },
                  {
                    title: 'Remaining',
                    value: `${userCalculationLimit - user.totalCount.calculationCount}`,
                    color: `${colorCalculationCount}`,
                  },
                ]}
                totalValue={userCalculationLimit}
                lineWidth={35}
                label={({ dataEntry }) => `${Math.round(dataEntry.percentage)} %`}
                animate={true}
                viewBoxSize={[300, 300]}
                center={[150, 150]}
                radius={150}
                labelPosition={83}
                labelStyle={{
                  fill: 'white',
                  fontSize: '20px',
                }}
                animationDuration={1000}
              />
            </div>
          </div>
        )}
        {user.role !== 'basic' && userSaveLimit > 0 && (
          <div className="chartWrapper">
            <div className="chart">
              <div className="chartTitle">Save Count</div>
              <PieChart
                data={[
                  { title: 'Used', value: `${user.totalCount.saveCount}`, color: '#729eca' },
                  {
                    title: 'Remaining',
                    value: `${userSaveLimit - user.totalCount.saveCount}`,
                    color: `${colorSaveCount}`,
                  },
                ]}
                totalValue={userSaveLimit}
                lineWidth={35}
                label={({ dataEntry }) => `${Math.round(dataEntry.percentage)} %`}
                animate={true}
                viewBoxSize={[300, 300]}
                center={[150, 150]}
                radius={150}
                labelPosition={83}
                labelStyle={{
                  fill: 'white',
                  fontSize: '20px',
                }}
                animationDuration={1000}
              />
            </div>
          </div>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300, maxWidth: 800 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="usage-colored" align="center" sx={{ padding: '5px' }}>
                Feature
              </TableCell>
              <TableCell className="usage-colored" align="center" sx={{ padding: '5px' }}>
                {convertFirstCharacterAllWordsToUppercase(user.role)} Plan Limit
              </TableCell>
              <TableCell className="usage-colored" align="center" sx={{ padding: '5px' }}>
                Used
              </TableCell>
              <TableCell className="usage-colored" align="center" sx={{ padding: '5px' }}>
                Remaining
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">Calculation Count</TableCell>
              <TableCell align="center">{userCalculationLimit}</TableCell>
              <TableCell align="center">{user.totalCount.calculationCount}</TableCell>
              <TableCell align="center">
                {userCalculationLimit && user.totalCount.calculationCount
                  ? userCalculationLimit - user.totalCount.calculationCount
                  : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Save Count</TableCell>
              <TableCell align="center">{userSaveLimit}</TableCell>
              <TableCell align="center">{user.role === 'basic' ? '-' : user.totalCount.saveCount}</TableCell>
              <TableCell align="center">
                {user.role === 'basic'
                  ? '-'
                  : userSaveLimit && user.totalCount.saveCount
                  ? userSaveLimit - user.totalCount.saveCount
                  : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {showCiriticalStuff && (
        <div className="usageUpgrade">
          <h3>Hey {convertFirstCharacterAllWordsToUppercase(user.name)}!</h3>
          <p>
            You {userSaveLimit - user.totalCount.saveCount === 0 ? 'reached ' : 'are about to reach '}
            your subscription plan limit.{' '}
          </p>
          {user.role === 'professional' ? (
            <>
              <p>Please contact us! </p>
              <Link to="/contact">
                <Button variant="contained" type="submit">
                  Contact
                </Button>
              </Link>
            </>
          ) : (
            <>
              <p>Consider upgrading to a higher plan. </p>
              <Link to="/pricing">
                <Button variant="contained" type="submit">
                  Pricing
                </Button>
              </Link>
            </>
          )}
        </div>
      )}
    </div>
  );
}
