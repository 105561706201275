import round from '../../../helpers/round';

const HansenResultInclinationFactors = ({ result }) => {
  const { nu, hb, hl, normal_v, alpha1, alpha2 } = result.input;
  const {
    phips,
    HMax,
    isInclinedLoading,
    isPhiZero,
    effectiveFndArea,
    ca,
    nq,
    ic,
    icb,
    icl,
    iq,
    iqb,
    iql,
    iGamma,
    iGammab,
    iGammal,
  } = result.output;

  const case1 = isInclinedLoading && isPhiZero;
  const case2 = isInclinedLoading && !isPhiZero;
  const case3 = !isInclinedLoading && isPhiZero;
  const case4 = !isInclinedLoading && !isPhiZero;

  const Case1Ic = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          i'<sub>c</sub>
        </h4>
        <p>
          &phi; = 0 &rarr; Only i'<sub>c</sub> should be calculated
        </p>
        <p>
          i'<sub>c</sub> = 0.5 - (1 - H<sub>i</sub> / (A<sub>f</sub>C<sub>a</sub>))<sup>1/2</sup>
        </p>
        <p>
          i'<sub>c</sub> = 0.5 - (1 - {round(HMax, 2)} / [({round(effectiveFndArea, 2)})({round(ca, 2)})])<sup>1/2</sup>
        </p>
        <p>
          i'<sub>c</sub> = {round(ic, 2)}
        </p>
      </div>
    );
  };

  const Case2Iq = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          i<sub>q</sub>
        </h4>
        <p>
          H<sub>B</sub> &ne; 0 and/or H<sub>L</sub> &ne; 0 (Inclined loading.)
        </p>
        <p>
          i<sub>q, B</sub> and i<sub>q, L</sub> should be calculated.
        </p>
        <br />
        <p>
          i<sub>q, B</sub>= [1 - 0.5H<sub>B</sub> / (V + A<sub>f</sub>C<sub>a</sub>cot&phi;)]
          <sup>
            &alpha;<sub>1</sub>
          </sup>
        </p>
        <p>
          i<sub>q, B</sub>= [1 - (0.5){round(hb, 2)} / ({round(normal_v, 2)} + ({round(effectiveFndArea, 2)})(
          {round(ca, 2)})(cot{round(phips, 2)}))]
          <sup>{round(alpha1, 2)}</sup>
        </p>
        <p>
          i<sub>q, B</sub>={round(iqb, 2)}
        </p>
        <br />
        <p>
          i<sub>q, L</sub>= [1 - 0.5H<sub>L</sub> / (V + A<sub>f</sub>C<sub>a</sub>cot&phi;)]
          <sup>
            &alpha;<sub>1</sub>
          </sup>
        </p>
        <p>
          i<sub>q, L</sub>= [1 - (0.5){round(hl, 2)} / ({round(normal_v, 2)} + ({round(effectiveFndArea, 2)})(
          {round(ca, 2)})(cot{round(phips, 2)}))]
          <sup>{round(alpha1, 2)}</sup>
        </p>
        <p>
          i<sub>q, L</sub>={round(iql, 2)}
        </p>
      </div>
    );
  };
  const Case2Ic = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          i<sub>c</sub>
        </h4>
        <p>
          H<sub>B</sub> &ne; 0 and/or H<sub>L</sub> &ne; 0 (Inclined loading.)
        </p>
        <p>
          i<sub>c, B</sub> and i<sub>c, L</sub> should be calculated.
        </p>
        <br />
        <p>
          i<sub>c, B</sub> = i<sub>q, B</sub> - (1 - i<sub>q, B</sub>) / (N<sub>q</sub> - 1)
        </p>
        <p>
          i<sub>c, B</sub> = {round(iqb, 2)} - (1 - {round(iqb, 2)}) / ({round(nq, 2)} - 1)
        </p>
        <p>
          i<sub>c, B</sub>={round(icb, 2)}
        </p>
        <br />
        <p>
          i<sub>c, L</sub> = i<sub>q, L</sub> - (1 - i<sub>q, L</sub>) / (n<sub>q</sub> - 1)
        </p>
        <p>
          i<sub>c, L</sub> = {round(iql, 2)} - (1 - {round(iql, 2)}) / ({round(nq, 2)} - 1)
        </p>
        <p>
          i<sub>c, L</sub>={round(icl, 2)}
        </p>
      </div>
    );
  };
  const Case2Igamma = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          i<sub>&gamma;</sub>
        </h4>
        <p>
          H<sub>B</sub> &ne; 0 and/or H<sub>L</sub> &ne; 0 (Inclined loading.)
        </p>
        <p>
          i<sub>&gamma;, B</sub> and i<sub>&gamma;, L</sub> should be calculated.
        </p>
        <br />
        <p>
          i<sub>&gamma;, B</sub> = &#123; 1 - [(0.7 - &eta;/450)H<sub>B</sub>] / (V + A<sub>f</sub>C<sub>a</sub>
          cot&phi;)&#125;
          <sup>
            &alpha;<sub>2</sub>
          </sup>
        </p>
        <p>
          i<sub>&gamma;, B</sub> = &#123; 1 - [(0.7 - {round(nu, 2)}/450){round(hb, 2)}] / ({round(normal_v, 2)} + (
          {round(effectiveFndArea, 2)}) ({round(ca, 2)}) cot{round(phips, 2)})&#125;<sup>{round(alpha2, 2)}</sup>
        </p>
        <p>
          i<sub>&gamma;, B</sub> = {round(iGammab, 2)}
        </p>
        <br />
        <p>
          i<sub>&gamma;, L</sub> = &#123; 1 - [(0.7 - &eta;/450)H<sub>L</sub>] / (V + A<sub>f</sub>C<sub>a</sub>
          cot&phi;)&#125;
          <sup>
            &alpha;<sub>2</sub>
          </sup>
        </p>
        <p>
          i<sub>&gamma;, L</sub> = &#123; 1 - [(0.7 - {round(nu, 2)}/450){round(hl, 2)}] / ({round(normal_v, 2)} + (
          {round(effectiveFndArea, 2)}) ({round(ca, 2)}) cot{round(phips, 2)})&#125;<sup>{round(alpha2, 2)}</sup>
        </p>
        <p>
          i<sub>&gamma;, L</sub> = {round(iGammal, 2)}
        </p>
      </div>
    );
  };

  const Case3Ic = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          i'<sub>c</sub>
        </h4>
        <p>
          H<sub>B</sub> = H<sub>L</sub> = 0 (No inclined loading.) and &phi; = 0
        </p>
        <p>&darr;</p>
        <p>
          i'<sub>c</sub> = 0
        </p>
      </div>
    );
  };

  const Case4Iq = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          i<sub>q</sub>
        </h4>
        <p>
          H<sub>B</sub> = H<sub>L</sub> = 0 (No inclined loading.)
        </p>
        <p>
          i<sub>q</sub>= [1 - 0.5H<sub>i</sub> / (V + A<sub>f</sub>C<sub>a</sub>cot&phi;)]
          <sup>
            &alpha;<sub>1</sub>
          </sup>
        </p>
        <p>
          i<sub>q</sub>= [1 - (0.5){round(Math.max(hb, hl), 2)} / ({round(normal_v, 2)} + ({round(effectiveFndArea, 2)})(
          {round(ca, 2)})(cot{round(phips, 2)}))]
          <sup>{round(alpha1, 2)}</sup>
        </p>
        <p>
          i<sub>q</sub>={round(iq, 2)}
        </p>
      </div>
    );
  };
  const Case4Ic = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          i<sub>c</sub>
        </h4>
        <p>
          H<sub>B</sub> = H<sub>L</sub> = 0 (No inclined loading.)
        </p>
        <p>
          i<sub>c</sub> = i<sub>q</sub> - (1 - i<sub>q</sub>) / (N<sub>q</sub> - 1)
        </p>
        <p>
          i<sub>c</sub> = {round(iq, 2)} - (1 - {round(iq, 2)}) / ({round(nq, 2)} - 1)
        </p>

        <p>
          i<sub>c</sub> = {round(ic, 2)}
        </p>
      </div>
    );
  };
  const Case4Igamma = () => {
    return (
      <div className="meyerhof-factors">
        <h4>
          i<sub>&gamma;</sub>
        </h4>
        <p>
          H<sub>B</sub> = H<sub>L</sub> = 0 (No inclined loading.)
        </p>
        <p>
          i<sub>&gamma;</sub> = &#123; 1 - [(0.7 - &eta;/450)H<sub>i</sub>] / (V + A<sub>f</sub>C<sub>a</sub>
          cot&phi;)&#125;<sup>{round(alpha2, 2)}</sup>
        </p>
        <p>
          i<sub>&gamma;</sub> = &#123; 1 - [(0.7 - {round(nu, 2)}/450){round(Math.max(hl, hb), 2)}] / ({round(normal_v, 2)} + (
          {round(effectiveFndArea, 2)}) ({round(ca, 2)}) cot{round(phips, 2)})&#125;<sup>{round(alpha2, 2)}</sup>
        </p>
        <p>
          i<sub>&gamma;</sub> = {round(iGamma, 2)}
        </p>
      </div>
    );
  };

  return (
    <div className="checks">
      <h3>
        Inclination Factors (i<sub>c</sub>, i<sub>q</sub>, i<sub>&gamma;</sub>)
      </h3>
      <div className="factors-wrapper">
        {case1 && <Case1Ic />}

        {case2 && <Case2Iq />}
        {case2 && <Case2Ic />}
        {case2 && <Case2Igamma />}

        {case3 && <Case3Ic />}

        {case4 && <Case4Iq />}
        {case4 && <Case4Ic />}
        {case4 && <Case4Igamma />}
      </div>
    </div>
  );
};
export default HansenResultInclinationFactors;
