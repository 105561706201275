import React from 'react';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import LockIcon from '@mui/icons-material/Lock';

const SaveButton = ({
  saveCountReached,
  checkCalculationNameforSaving,
  calculationInput,
  URL,
  saveButtonDisabled,
  isLoadingCalculate,
  isLoadingLoad,
  isLoadingSave,
  layersFrontLength,
}) => {
  return (
    <>
      {saveCountReached ? (
        <Tooltip
          title="You reached your saving limit. Consider upgrading to a higher plan."
          arrow
          placement="bottom"
          TransitionComponent={Zoom}
        >
          <span>
            <Button
              variant="contained"
              disabled={true}
              onClick={(e) => {
                e.preventDefault();
                checkCalculationNameforSaving(
                  calculationInput,
                  URL.CHECKUNIQUENAME,
                  URL.SAVE,
                  URL.LOADALL
                );
              }}
            >
              Save
              <LockIcon fontSize={'small'} />
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          disabled={
            saveButtonDisabled ||
            isLoadingCalculate ||
            isLoadingSave ||
            layersFrontLength < 1 ||
            isLoadingLoad
          }
          onClick={(e) => {
            e.preventDefault();
            checkCalculationNameforSaving(
              calculationInput,
              URL.CHECKUNIQUENAME,
              URL.SAVE,
              URL.LOADALL
            );
          }}
        >
          {isLoadingSave ? 'Saving...' : 'Save'}
        </Button>
      )}
    </>
  );
};

export default SaveButton;
