import round from '../../../helpers/round';

const HansenResultBcFactors = ({ result }) => {
  const { isPhiZero, phips, nc, nq, nGamma } = result.output;
  return (
    <div className="checks">
      <h3>
        Bearing Capacity Factors (N<sub>c</sub>, N<sub>q</sub>, N<sub>&gamma;</sub>)
      </h3>
      <div className="factors-wrapper">
        {!isPhiZero && (
          <div className="meyerhof-factors">
            <h4>
              N<sub>q</sub>
            </h4>
            <p>
              N<sub>q</sub>=e<sup>&pi;tan&phi;</sup>tan<sup>2</sup>(45+&phi;/2)
            </p>
            <p>
              N<sub>q</sub>=e<sup>&pi;tan{round(phips, 2)}</sup>tan<sup>2</sup>(45+{round(phips, 2)}/2)
            </p>
            <p>
              N<sub>q</sub>={round(nq, 2)}
            </p>
          </div>
        )}
        <div className="meyerhof-factors">
          <h4>
            N<sub>c</sub>
          </h4>
          {isPhiZero && (
            <p>
              &phi; = 0 &rarr; Only N<sub>c</sub> should be calculated.
            </p>
          )}
          {!isPhiZero ? (
            <>
              <p>
                N<sub>c</sub>=(N<sub>q</sub>-1) cot&phi;
              </p>
              <p>
                N<sub>c</sub>=({round(nq, 2)}-1)cot
                {round(phips, 2)}
              </p>
              <p>
                N<sub>c</sub>={round(nc, 2)}
              </p>
              <br />
            </>
          ) : (
            <>
              <p>
                N<sub>c</sub>=&pi;+2
              </p>
              <p>
                N<sub>c</sub>={round(nc, 2)}{' '}
              </p>
              <br />
            </>
          )}
        </div>
        {!isPhiZero && (
          <div className="meyerhof-factors">
            <h4>
              N<sub>&gamma;</sub>
            </h4>
            <span>
              <p>
                N<sub>&gamma;</sub>=1.5(N<sub>q</sub>-1)tan(&phi;)
              </p>
              <p>
                N<sub>&gamma;</sub>=1.5({round(nq, 2)}-1)tan({round(phips, 2)})
              </p>
              <p>
                N<sub>&gamma;</sub>={round(nGamma, 2)}
              </p>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default HansenResultBcFactors;
