import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import CalculateIcon from '@mui/icons-material/Calculate';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';

const AdminMenu = ({ setShowApp, handleMenu }) => {
  const handleAppRender = (appName) => {
    switch (appName) {
      case 'dashboard':
        return setShowApp({ dashboard: true, users: false, calculations: false, roles: false, sendEmail: false });
      case 'users':
        return setShowApp({ dashboard: false, users: true, calculations: false, roles: false, sendEmail: false });
      case 'calculations':
        return setShowApp({ dashboard: false, users: false, calculations: true, roles: false, sendEmail: false });
      case 'roles':
        return setShowApp({ dashboard: false, users: false, calculations: false, roles: true, sendEmail: false });
      case 'sendEmail':
        return setShowApp({ dashboard: false, users: false, calculations: false, roles: false, sendEmail: true });
      default:
        return setShowApp({ dashboard: true, users: false, calculations: false, roles: false });
    }
  };

  return (
    <div className="adminMenu">
      <div className="adminMenuCloseButton" onClick={handleMenu}>
        <CloseIcon color="primary" />
      </div>
      <div className="listItem" onClick={() => handleAppRender('dashboard')}>
        <div className="icon">
          <DashboardIcon color="primary" />
        </div>
        <div className="text">User Dashboard</div>
      </div>
      <div className="listItem" onClick={() => handleAppRender('users')}>
        <div className="icon">
          <GroupIcon color="primary" />
        </div>
        <div className="text">Users</div>
      </div>
      <div className="listItem" onClick={() => handleAppRender('calculations')}>
        <div className="icon">
          <CalculateIcon color="primary" />
        </div>
        <div className="text">Calculations</div>
      </div>
      <div className="listItem" onClick={() => handleAppRender('roles')}>
        <div className="icon">
          <ManageAccountsIcon color="primary" />
        </div>
        <div className="text">Roles</div>
      </div>
      <div className="listItem" onClick={() => handleAppRender('sendEmail')}>
        <div className="icon">
          <EmailIcon color="primary" />
        </div>
        <div className="text">Send Email</div>
      </div>
    </div>
  );
};

export default AdminMenu;
