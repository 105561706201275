import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Context } from '../../App';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import LockIcon from '@mui/icons-material/Lock';
import { TextField } from '@mui/material';
import Link from '@mui/material/Link';
import axios from 'axios';

import validateEmail from '../../helpers/validateEmail';
import MuiSnackbar from '../common/MuiSnackbar';
import Header from '../common/Header';
import Footer from '../common/Footer';

const Login = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Login';
  }, []);
  const nav = useNavigate();

  //Snackbar states
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { setUser, setLastpage } = useContext(Context);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [errorEmail, errorEmailSet] = useState(false);
  const [errorEmailMessage, errorEmailMessageSet] = useState('');
  const [errorPassword, errorPasswordSet] = useState(false);
  const [errorPasswordMessage, errorPasswordMessageSet] = useState('');

  //Email Validation
  const validateEmailAndHandleError = (email) => {
    if (!validateEmail(email)) {
      errorEmailSet(true);
      errorEmailMessageSet('Please provide a valid email.');
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (validateEmail(email)) {
      errorEmailMessageSet('');
      errorEmailSet(false);
    }
  }, [email]);

  //Password Validation
  const validatePassword = (password) => {
    if (password.length < 8) {
      errorPasswordSet(true);
      errorPasswordMessageSet('Password must be min. 8 characters.');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (password.length > 7) {
      errorPasswordMessageSet('');
      errorPasswordSet(false);
    }
  }, [password]);

  //Handle disabled/enabled state of Sign In button
  useEffect(() => {
    if (validateEmail(email) && validatePassword(password)) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [email, password]);

  const login = async (e) => {
    e.preventDefault();
    try {
      const response = await axios({
        method: 'POST',
        url: '/api/v1/users/login',
        data: {
          email,
          password,
        },
      });

      if (response.data.status === 'success') {
        setUser(response.data.data.user);
        setLastpage('./login');
        nav('/');
      }
    } catch (err) {
      setAlertMessage(err.response.data.message);
      setOpen(true);
    }
  };

  const handleClickShowPassword = (variable, setter) => {
    setter(!variable);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Header />
      <div className="login">
        <MuiSnackbar open={open} setOpen={setOpen} severity="error" alertMessage={alertMessage} />
        <LockIcon color="primary" fontSize="large" />
        <h2>Sign In</h2>
        <form>
          <TextField
            onBlur={(e) => validateEmailAndHandleError(e.target.value)}
            margin="dense"
            style={{ width: 300 }}
            size="small"
            label="E-Mail"
            variant="outlined"
            autoComplete="username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errorEmail}
            helperText={errorEmailMessage}
          />
          <TextField
            onBlur={(e) => validatePassword(e.target.value)}
            margin="dense"
            size="small"
            style={{ width: 300 }}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      handleClickShowPassword(showPassword, setShowPassword);
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Password"
            error={errorPassword}
            helperText={errorPasswordMessage}
          />

          <Button variant="contained" style={{ width: 300 }} fullWidth onClick={(e) => login(e)} disabled={buttonDisabled}>
            Sign In
          </Button>
        </form>
        <Link component={RouterLink} to="/forgotpassword">
          {' '}
          Forgot your password?
        </Link>
        <br />

        <Link component={RouterLink} to="/signup">
          {' '}
          Don't have an account? Sign Up here!
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default Login;
