import React, { useState, useEffect, forwardRef } from 'react';
import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import CalculationSearch from './CalculationSearch';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';
import Checkbox from '@mui/material/Checkbox';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import LockIcon from '@mui/icons-material/Lock';

const MyCalculations = ({
  calculations,
  loadOneCalculation,
  deleteCalculations,
  createNewCalculation,
  isLoading,
  handleSidebarDisplay,
  URL,
  setInputs,
  calculate,
  userRole,
}) => {
  //STATE FOR INDIVIDUAL CALCULATION CHECKBOXES
  const [selectedCalculations, setSelectedCalculations] = useState([]);

  //STATE FOR SEARCHBOX FILTERED CALCULATIONS
  const [filteredCalculations, setFilteredCalculations] = useState([]);

  //STATE FOR SELECTALL CHECKBOX
  const [selectAll, setSelectAll] = useState(false);

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} {...props} />;
  });

  //HANDLE SELECTALL CHECKBOX (CHECK IF ALL CHECKBOXES SELECTED, UNCHECK IF ONE OF THEM UNSELECTED)
  useEffect(() => {
    if (selectedCalculations.length === filteredCalculations.length && selectedCalculations.length !== 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedCalculations.length, filteredCalculations.length]);

  //HANDLE SELECTED CALCULATIONS FOR SELECTALL CHECKBOX
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      const newSelected = filteredCalculations.map((item) => {
        return item._id;
      });
      setSelectedCalculations(newSelected);
      setSelectAll(true);
    } else {
      setSelectedCalculations([]);
      setSelectAll(false);
    }
  };

  //HANDLE SELECTED CALCULATIONS FOR INDIVIDUAL CHECKBOXES
  const handleChange = (e) => {
    let newSelected = [...selectedCalculations, e.target.value];
    if (!e.target.checked) {
      newSelected = newSelected.filter((el) => {
        return el !== e.target.value;
      });
    }
    setSelectedCalculations(newSelected);
  };

  const onLoadClickHandler = async () => {
    const loadedParams = await loadOneCalculation(selectedCalculations, URL.LOADONE);
    setInputs(loadedParams);
    await calculate(loadedParams, URL.CALCULATE);
    setSelectedCalculations([]);
  };

  const onDeleteClickHandler = () => {
    deleteCalculations(selectedCalculations, URL.DELETE, URL.LOADALL);
    setSelectedCalculations([]);
    setDeleteConfirmationModalIsOpenToFalse();
  };

  // const onCreateNewClickHandler = () => {
  //   createNewCalculation();
  // };

  const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] = useState(false);
  const setDeleteConfirmationModalIsOpenToTrue = () => {
    setDeleteConfirmationModalIsOpen(true);
  };
  const setDeleteConfirmationModalIsOpenToFalse = () => {
    setDeleteConfirmationModalIsOpen(false);
  };

  return (
    <div className="sidebar-container">
      <CloseTwoToneIcon
        color="primary"
        className="sidebar-close-icon"
        alt="side bar menu icon"
        title="Hide my calculations"
        onClick={handleSidebarDisplay}
      />

      <h3>My Calculations</h3>
      <CalculationSearch
        calculations={calculations}
        setFilteredCalculations={setFilteredCalculations}
        selectedCalculations={selectedCalculations}
        isLoading={isLoading}
        userRole={userRole}
      />

      <div className="calculationContainer">
        <div className="no underline-bold">No</div>
        <div className="name underline-bold">Name</div>
        <div className="checkbox select-all-checkbox underline-bold">
          <Checkbox onChange={handleSelectAllChange} checked={selectAll} disabled={isLoading || userRole === 'basic'}></Checkbox>
        </div>
      </div>
      {isLoading ? (
        <CircularProgress size={30} />
      ) : (
        <div className="calculation-box">
          {filteredCalculations.map((calculation, index) => (
            <div className="calculationContainer" key={calculation._id} id={calculation._id}>
              <div className="no">{index + 1}</div>
              <div className="name">{calculation.name}</div>
              <div className="checkbox">
                <Checkbox
                  size="small"
                  onChange={handleChange}
                  value={calculation._id}
                  checked={selectedCalculations.indexOf(calculation._id) > -1}
                  disabled={userRole === 'basic'}
                ></Checkbox>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="sidebar-buttons-container">
        <DeleteConfirmationModal
          deleteConfirmationModalIsOpen={deleteConfirmationModalIsOpen}
          setDeleteConfirmationModalIsOpenToFalse={setDeleteConfirmationModalIsOpenToFalse}
          deleteCalculations={onDeleteClickHandler}
          selectedCalculations={selectedCalculations}
          createNewCalculation={createNewCalculation}
        />
        {userRole !== 'basic' ? (
          <Button
            variant="contained"
            className="sidebar-buttons"
            disabled={selectedCalculations.length !== 1 || isLoading}
            onClick={onLoadClickHandler}
          >
            Load
          </Button>
        ) : (
          <Tooltip
            title="Upgrade your account to save, load, update or delete your calculations."
            arrow
            placement="bottom"
            TransitionComponent={Zoom}
          >
            <div style={{ width: '100%' }}>
              <Button variant="contained" disabled={true} className="sidebar-buttons">
                Load
                <LockIcon fontSize={'small'} />
              </Button>
            </div>
          </Tooltip>
        )}

        {userRole !== 'basic' ? (
          <Button
            variant="contained"
            className="sidebar-buttons"
            disabled={selectedCalculations.length === 0 || isLoading}
            onClick={setDeleteConfirmationModalIsOpenToTrue}
          >
            Delete
          </Button>
        ) : (
          <Tooltip
            title="Upgrade your account to save, load, update or delete your calculations."
            arrow
            placement="bottom"
            TransitionComponent={Zoom}
          >
            <div style={{ width: '100%' }}>
              <Button variant="contained" disabled={true} className="sidebar-buttons">
                Delete
                <LockIcon fontSize={'small'} />
              </Button>
            </div>
          </Tooltip>
        )}
        {/* <Button
          variant="outlined"
          className="sidebar-buttons"
          onClick={onCreateNewClickHandler}
          disabled={isLoading}
        >
          Clear Parameters
        </Button> */}
      </div>
      <div className="basic-sidebar-warning">
        {userRole === 'basic' && (
          <Alert severity="warning" style={{ width: '80%', textAlign: 'left' }}>
            <div>
              Actions such as delete, save, update and load are not available for basic plan. <br />
              <br />
              You need to upgrade your account to a paid plan.
            </div>
            <Link to="/pricing">
              <Button variant="text" size="small" sx={{ textTransform: 'none', marginLeft: '-5px' }}>
                See our plans
              </Button>
            </Link>
          </Alert>
        )}
      </div>
    </div>
  );
};

export default MyCalculations;
