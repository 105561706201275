import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuItem from '@mui/material/MenuItem';

import MuiSnackbar from '../../common/MuiSnackbar';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CalculateButton from '../../common/calculateButton';
import SaveButton from '../../common/saveButton';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Sidebar from '../../sidebar/Sidebar';
import DecisionModal from '../../modals/DecisionModal';
import WeightVolumeRelationsResult from './WeightVolumeRelationsResult';
import hansenImage from '../../../assets/calculations/hansen/hansen.jpg';
import TransitionsModal from '../../modals/TransitionsModal';
import { CalculationName, W, Gs, E, N, S, GammaMoist, GammaSat, GammaDry } from './inputComponents';

import {
  Gamma1,
  Gamma2,
  Gamma3,
  Gamma4,
  Gamma5,
  GammaDry1,
  GammaDry2,
  GammaDry3,
  GammaDry4,
  GammaDry5,
  GammaDry6,
  GammaDry7,
  GammaDry8,
  GammaSat1,
  GammaSat2,
  GammaSat3,
  GammaSat4,
  GammaSat5,
  GammaSat6,
  GammaSat7,
  GammaSat8,
  GammaSat9,
} from './gammaFormulaComponents';
import { useCalculationContext, CalculationContextProvider } from '../../context/calculationContext';

const WeightVolumeRelationShips = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Weight - Volume Relationships';
  }, []);

  const {
    MAX_NAME_LENGTH,
    isLoadingCalculate,
    isLoadingLoadAll,
    isLoadingLoad,
    isLoadingSave,
    result,
    calculations,
    showOutput,
    setShowOutput,
    calculateButtonDisabled,
    setCalculateButtonDisabled,
    saveButtonDisabled,
    setSaveButtonDisabled,
    errorMessage,
    displaySidebar,
    setDisplaySidebar,
    decisionModalIsOpen,
    decision,
    setDecision,
    handleSidebarDisplay,
    setDecisionModalIsOpenToFalse,
    showMuiSnack,
    open,
    setOpen,
    alertMessage,
    severity,
    onChangeWarningForPositiveDecimals,
    calculate,
    loadOneCalculation,
    loadAllCalculations,
    update,
    checkCalculationNameforSaving,
    checkCalculationNameforSavingAs,
    deleteCalculations,
    calculationCountReached,
    saveCountReached,
  } = useCalculationContext();

  //SET STATES FOR INPUT VARIABLES
  const [name, setName] = useState('');
  const [w, setw] = useState('');
  const [gs, setgs] = useState('');
  const [e, sete] = useState('');
  const [s, sets] = useState('');
  const [n, setn] = useState('');
  const [gammaMoist, setgammaMoist] = useState('');
  const [gammaDry, setgammaDry] = useState('');
  const [gammaSat, setgammaSat] = useState('');

  const [gammaMoistInputCombination, setGammaMoistInputCombination] = useState(1);
  const [gammaDryInputCombination, setGammaDryInputCombination] = useState(1);
  const [gammaSatInputCombination, setGammaSatInputCombination] = useState(1);

  const [gammaMoistChecked, setGammaMoistChecked] = useState(false);
  const [gammaDryChecked, setGammaDryChecked] = useState(false);
  const [gammaSatChecked, setGammaSatChecked] = useState(false);

  const [isGammaInputsOK, setIsGammaInputsOK] = useState(false);
  const [isGammaDryInputsOK, setIsGammaDryInputsOK] = useState(false);
  const [isGammaSatInputsOK, setIsGammaSatInputsOK] = useState(false);

  // SET THE LOADED INPUTS
  const setInputs = (inputs) => {
    setName(inputs.name || '');
    setw(inputs.w || '');
    setgs(inputs.gs || '');
    sete(inputs.e || '');
    sets(inputs.s || '');
    setn(inputs.n || '');
    setgammaMoist(inputs.gammaMoist || '');
    setgammaDry(inputs.gammaDry || '');
    setgammaSat(inputs.gammaSat || '');
    setGammaMoistInputCombination(inputs.gammaMoistInputCombination || 1);
    setGammaDryInputCombination(inputs.gammaDryInputCombination || 1);
    setGammaSatInputCombination(inputs.gammaSatInputCombination || 1);
    setGammaMoistChecked(inputs.gammaMoistChecked || false);
    setGammaDryChecked(inputs.gammaDryChecked || false);
    setGammaSatChecked(inputs.gammaSatChecked || false);
  };

  //INPUTS FOR CALCULATION
  const calculationInput = {
    name,
    w,
    gs,
    e,
    s,
    n,
    gammaMoist,
    gammaDry,
    gammaSat,
    gammaMoistChecked,
    gammaMoistInputCombination,
    gammaDryChecked,
    gammaDryInputCombination,
    gammaSatChecked,
    gammaSatInputCombination,
  };
  const calculationInputJSON = JSON.stringify(calculationInput);

  // MANAGE SAVE & CALCULATE BUTTONS DISABLED/ENABLED STATES
  useEffect(() => {
    const checkMissingGammaInput = () => {
      if (gammaMoistChecked) {
        //Gamma - Combination 1
        if (gammaMoistInputCombination === 1) {
          if ((w || w === 0) && (gs || gs === 0) && (e || e === 0)) {
            setIsGammaInputsOK(true);
          } else {
            setIsGammaInputsOK(false);
          }
        }

        //Gamma - Combination 2
        if (gammaMoistInputCombination === 2) {
          if ((s || s === 0) && (gs || gs === 0) && (e || e === 0)) {
            setIsGammaInputsOK(true);
          } else {
            setIsGammaInputsOK(false);
          }
        }

        //Gamma - Combination 3
        if (gammaMoistInputCombination === 3) {
          if ((w || w === 0) && (gs || gs === 0) && (s || s === 0)) {
            setIsGammaInputsOK(true);
          } else {
            setIsGammaInputsOK(false);
          }
        }

        //Gamma - Combination 4
        if (gammaMoistInputCombination === 4) {
          if ((w || w === 0) && (gs || gs === 0) && (n || n === 0)) {
            setIsGammaInputsOK(true);
          } else {
            setIsGammaInputsOK(false);
          }
        }
        //Gamma - Combination 5
        if (gammaMoistInputCombination === 5) {
          if ((s || s === 0) && (gs || gs === 0) && (n || n === 0)) {
            setIsGammaInputsOK(true);
          } else {
            setIsGammaInputsOK(false);
          }
        }
      } else {
        setIsGammaInputsOK(false);
      }
    };

    checkMissingGammaInput();
  }, [
    w,
    gs,
    e,
    s,
    n,
    gammaMoistChecked,
    gammaMoistInputCombination,
    setCalculateButtonDisabled,
    setSaveButtonDisabled,
    isGammaInputsOK,
  ]);

  useEffect(() => {
    const checkMissingGammaDryInput = () => {
      if (gammaDryChecked) {
        //GammaDry - Combination 1
        if (gammaDryInputCombination === 1) {
          if ((w || w === 0) && (gammaMoist || gammaMoist === 0)) {
            setIsGammaDryInputsOK(true);
          } else {
            setIsGammaDryInputsOK(false);
          }
        }

        //GammaDry - Combination 2
        if (gammaDryInputCombination === 2) {
          if ((gs || gs === 0) && (e || e === 0)) {
            setIsGammaDryInputsOK(true);
          } else {
            setIsGammaDryInputsOK(false);
          }
        }

        //GammaDry - Combination 3
        if (gammaDryInputCombination === 3) {
          if ((gs || gs === 0) && (n || n === 0)) {
            setIsGammaDryInputsOK(true);
          } else {
            setIsGammaDryInputsOK(false);
          }
        }

        //GammaDry - Combination 4
        if (gammaDryInputCombination === 4) {
          if ((gs || gs === 0) && (w || w === 0) && (s || s === 0)) {
            setIsGammaDryInputsOK(true);
          } else {
            setIsGammaDryInputsOK(false);
          }
        }

        //GammaDry - Combination 5
        if (gammaDryInputCombination === 5) {
          if ((e || e === 0) && (w || w === 0) && (s || s === 0)) {
            setIsGammaDryInputsOK(true);
          } else {
            setIsGammaDryInputsOK(false);
          }
        }

        //GammaDry - Combination 6
        if (gammaDryInputCombination === 6) {
          if ((gammaSat || gammaSat === 0) && (e || e === 0)) {
            setIsGammaDryInputsOK(true);
          } else {
            setIsGammaDryInputsOK(false);
          }
        }

        //GammaDry - Combination 7
        if (gammaDryInputCombination === 7) {
          if ((gammaSat || gammaSat === 0) && (n || n === 0)) {
            setIsGammaDryInputsOK(true);
          } else {
            setIsGammaDryInputsOK(false);
          }
        }

        //GammaDry - Combination 8
        if (gammaDryInputCombination === 8) {
          if ((gammaSat || gammaSat === 0) && (gs || gs === 0)) {
            setIsGammaDryInputsOK(true);
          } else {
            setIsGammaDryInputsOK(false);
          }
        }
      } else {
        setIsGammaDryInputsOK(false);
      }
    };
    checkMissingGammaDryInput();
  }, [w, gs, e, s, n, gammaMoist, gammaDryChecked, gammaSat, gammaDryInputCombination]);

  useEffect(() => {
    const checkMissingGammaSatInput = () => {
      //GammaSat - Combination 1
      if (gammaSatChecked) {
        if (gammaSatInputCombination === 1) {
          if ((gs || gs === 0) && (e || e === 0)) {
            setIsGammaSatInputsOK(true);
          } else {
            setIsGammaSatInputsOK(false);
          }
        }

        //GammaSat - Combination 2
        if (gammaSatInputCombination === 2) {
          if ((gs || gs === 0) && (n || n === 0)) {
            setIsGammaSatInputsOK(true);
          } else {
            setIsGammaSatInputsOK(false);
          }
        }

        //GammaSat - Combination 3
        if (gammaSatInputCombination === 3) {
          if ((gs || gs === 0) && (w || w === 0)) {
            setIsGammaSatInputsOK(true);
          } else {
            setIsGammaSatInputsOK(false);
          }
        }

        //GammaSat - Combination 4
        if (gammaSatInputCombination === 4) {
          if ((e || e === 0) && (w || w === 0)) {
            setIsGammaSatInputsOK(true);
          } else {
            setIsGammaSatInputsOK(false);
          }
        }

        //GammaSat - Combination 5
        if (gammaSatInputCombination === 5) {
          if ((n || n === 0) && (w || w === 0)) {
            setIsGammaSatInputsOK(true);
          } else {
            setIsGammaSatInputsOK(false);
          }
        }

        //GammaSat - Combination 6
        if (gammaSatInputCombination === 6) {
          if ((gammaDry || gammaDry === 0) && (e || e === 0)) {
            setIsGammaSatInputsOK(true);
          } else {
            setIsGammaSatInputsOK(false);
          }
        }

        //GammaSat - Combination 7
        if (gammaSatInputCombination === 7) {
          if ((gammaDry || gammaDry === 0) && (n || n === 0)) {
            setIsGammaSatInputsOK(true);
          } else {
            setIsGammaSatInputsOK(false);
          }
        }

        //GammaSat - Combination 8
        if (gammaSatInputCombination === 8) {
          if ((gammaDry || gammaDry === 0) && (s || s === 0)) {
            setIsGammaSatInputsOK(true);
          } else {
            setIsGammaSatInputsOK(false);
          }
        }

        //GammaSat - Combination 9
        if (gammaSatInputCombination === 9) {
          if ((gammaDry || gammaDry === 0) && (w || w === 0)) {
            setIsGammaSatInputsOK(true);
          } else {
            setIsGammaSatInputsOK(false);
          }
        }
      } else {
        setIsGammaSatInputsOK(false);
      }
    };
    checkMissingGammaSatInput();
  }, [w, gs, e, s, n, gammaMoist, gammaSatChecked, gammaSat, gammaDry, gammaSatInputCombination]);

  useEffect(() => {
    ///Singles
    if (gammaMoistChecked && !gammaDryChecked && !gammaSatChecked) {
      if (isGammaInputsOK) {
        setCalculateButtonDisabled(false);
        setSaveButtonDisabled(false);
      } else {
        setCalculateButtonDisabled(true);
        setSaveButtonDisabled(true);
      }
    }

    if (!gammaMoistChecked && gammaDryChecked && !gammaSatChecked) {
      if (isGammaDryInputsOK) {
        setCalculateButtonDisabled(false);
        setSaveButtonDisabled(false);
      } else {
        setCalculateButtonDisabled(true);
        setSaveButtonDisabled(true);
      }
    }

    if (!gammaMoistChecked && !gammaDryChecked && gammaSatChecked) {
      if (isGammaSatInputsOK) {
        setCalculateButtonDisabled(false);
        setSaveButtonDisabled(false);
      } else {
        setCalculateButtonDisabled(true);
        setSaveButtonDisabled(true);
      }
    }
    ///Doubles
    if (gammaMoistChecked && gammaDryChecked && !gammaSatChecked) {
      if (isGammaInputsOK && isGammaDryInputsOK) {
        setCalculateButtonDisabled(false);
        setSaveButtonDisabled(false);
      } else {
        setCalculateButtonDisabled(true);
        setSaveButtonDisabled(true);
      }
    }

    if (gammaMoistChecked && !gammaDryChecked && gammaSatChecked) {
      if (isGammaInputsOK && isGammaSatInputsOK) {
        setCalculateButtonDisabled(false);
        setSaveButtonDisabled(false);
      } else {
        setCalculateButtonDisabled(true);
        setSaveButtonDisabled(true);
      }
    }
    if (!gammaMoistChecked && gammaDryChecked && gammaSatChecked) {
      if (isGammaDryInputsOK && isGammaSatInputsOK) {
        setCalculateButtonDisabled(false);
        setSaveButtonDisabled(false);
      } else {
        setCalculateButtonDisabled(true);
        setSaveButtonDisabled(true);
      }
    }

    //Triples
    if (gammaMoistChecked && gammaDryChecked && gammaSatChecked) {
      if (isGammaInputsOK && isGammaDryInputsOK && isGammaSatInputsOK) {
        setCalculateButtonDisabled(false);
        setSaveButtonDisabled(false);
      } else {
        setCalculateButtonDisabled(true);
        setSaveButtonDisabled(true);
      }
    }

    if (!gammaMoistChecked && !gammaDryChecked && !gammaSatChecked) {
      setCalculateButtonDisabled(true);
      setSaveButtonDisabled(true);
    }

    //Name
    if (!name) {
      setCalculateButtonDisabled(true);
      setSaveButtonDisabled(true);
    }
  }, [
    name,
    w,
    gs,
    e,
    s,
    n,
    gammaMoist,
    gammaDry,
    gammaSat,
    gammaMoistInputCombination,
    gammaDryInputCombination,
    gammaSatInputCombination,
    gammaMoistChecked,
    gammaDryChecked,
    gammaSatChecked,
    isGammaInputsOK,
    isGammaDryInputsOK,
    isGammaSatInputsOK,
    setCalculateButtonDisabled,
    setSaveButtonDisabled,
  ]);

  // DO NOT RENDER OUTPUT IF ANY INPUT PARAMETER EXCEPT NAME CHANGES
  useEffect(() => {
    setShowOutput(false);
  }, [setShowOutput, calculationInputJSON]);

  //CLEAR INPUTS AND CLEAR OUTPUT
  const createNewCalculation = () => {
    setName('');
    setw('');
    setgs('');
    sete('');
    sets('');
    setn('');
    setgammaMoist('');
    setgammaDry('');
    setgammaSat('');
    setShowOutput(false);
  };

  //API URLS
  const URL = {
    CALCULATE: '/api/v1/calculations/weightVolumeRelationships',
    LOADONE: `/api/v1/calculations/weightVolumeRelationships/loadOne/`,
    LOADALL: '/api/v1/calculations/weightVolumeRelationships/loadAll',
    UPDATE: `/api/v1/calculations/weightVolumeRelationships/update/`,
    SAVE: '/api/v1/calculations/weightVolumeRelationships/save',
    CHECKUNIQUENAME: `/api/v1/calculations/weightVolumeRelationships/checkUniqueName/`,
    DELETE: '/api/v1/calculations/weightVolumeRelationships/delete',
  };

  const handleChangeName = (e) => {
    if (e.target.value.length >= MAX_NAME_LENGTH) {
      showMuiSnack(`Calculation name can be max ${MAX_NAME_LENGTH} characters.`, 'warning');
    }
    setName(e.target.value);
  };

  const handleChangeGamma = () => {
    setGammaMoistChecked(!gammaMoistChecked);
  };

  const handleChangeGammaDry = () => {
    setGammaDryChecked(!gammaDryChecked);
  };

  const handleChangeGammaSat = () => {
    setGammaSatChecked(!gammaSatChecked);
  };

  // RETURN INPUT AND OUTPUT JSX
  return (
    <>
      <Header />
      <div className="container">
        <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
        {displaySidebar ? (
          <Sidebar
            URL={URL}
            loadAllCalculations={loadAllCalculations}
            calculations={calculations}
            loadOneCalculation={loadOneCalculation}
            calculate={calculate}
            setInputs={setInputs}
            deleteCalculations={deleteCalculations}
            createNewCalculation={createNewCalculation}
            isLoading={isLoadingLoadAll}
            handleSidebarDisplay={handleSidebarDisplay}
            displaySidebar={displaySidebar}
            setDisplaySidebar={setDisplaySidebar}
          />
        ) : (
          <MenuOpenIcon
            color="primary"
            fontSize="large"
            className="sidebar-open-icon"
            alt="side bar menu icon"
            label="Show my calculations"
            onClick={handleSidebarDisplay}
          />
        )}
        <DecisionModal
          URL={URL}
          data={calculationInput}
          decisionModalIsOpen={decisionModalIsOpen}
          setDecisionModalIsOpenToFalse={setDecisionModalIsOpenToFalse}
          decision={decision}
          setDecision={setDecision}
          checkCalculationNameforSavingAs={checkCalculationNameforSavingAs}
          name={name}
          setName={setName}
          errorMessage={errorMessage}
          update={update}
          saveButtonDisabled={saveButtonDisabled}
        />
        <div className="right-calculation">
          <section className="input weightVolumeRelations horizontalScroll">
            <h1>Weight - Volume Relationships</h1>
            <div className="inputWrapper ">
              <div className="inputRow titles">
                <div className="calculationName">
                  Calculation Name{' '}
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={
                      <div key={'Name'} className="tooltipContainer">
                        <div>Please provide a name for your calculation.</div>
                      </div>
                    }
                  >
                    <InfoTwoToneIcon fontSize={'small'} color="primary" />
                  </Tooltip>
                </div>
                <div className="checkboxes">
                  Parameter{' '}
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={
                      <div key={'Parameter'} className="tooltipContainer">
                        <div>Check the unit weight parameter you want to calculate.</div>
                        <div>Results of the checked parameters will be shown on output section.</div>
                      </div>
                    }
                  >
                    <InfoTwoToneIcon fontSize={'small'} color="primary" />
                  </Tooltip>
                </div>
                <div className="selects">
                  Combination{' '}
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={
                      <div key={'Combination'} className="tooltipContainer">
                        <div>Select the combination of input parameters to calculate the selected unit weight.</div>
                      </div>
                    }
                  >
                    <InfoTwoToneIcon fontSize={'small'} color="primary" />
                  </Tooltip>
                </div>
                <div className="inputs ">
                  Input{' '}
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={
                      <div key={'Input'} className="tooltipContainer">
                        <div>Provide input values for the selected combination. </div>
                      </div>
                    }
                  >
                    <InfoTwoToneIcon fontSize={'small'} color="primary" />
                  </Tooltip>
                </div>
                <div className="formula">
                  Formula{' '}
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={
                      <div key={'Formula'} className="tooltipContainer">
                        <div>The formula to be used based on selected input combination. </div>
                      </div>
                    }
                  >
                    <InfoTwoToneIcon fontSize={'small'} color="primary" />
                  </Tooltip>
                </div>
              </div>
              <div className="inputRow">
                <div className="calculationName">
                  <CalculationName
                    name={name}
                    MAX_NAME_LENGTH={MAX_NAME_LENGTH}
                    handleChangeName={handleChangeName}
                    isLoadingCalculate={isLoadingCalculate}
                    isLoadingLoad={isLoadingLoad}
                    isLoadingSave={isLoadingSave}
                  />
                </div>
                <div className="checkboxes">
                  <FormControlLabel
                    control={<Checkbox checked={gammaMoistChecked} onChange={handleChangeGamma} />}
                    label={<span>&gamma;</span>}
                  />
                </div>
                <div className="selects">
                  <TextField
                    size="small"
                    style={{ minWidth: 175 }}
                    select
                    defaultValue={gammaMoistInputCombination}
                    label="Input combination"
                    value={gammaMoistInputCombination}
                    onChange={(e) => setGammaMoistInputCombination(e.target.value)}
                    disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave || !gammaMoistChecked}
                  >
                    <MenuItem value={1}>
                      <span>
                        w - G<sub>s</sub> - e
                      </span>
                    </MenuItem>
                    <MenuItem value={2}>
                      <span>
                        S - G<sub>s</sub> - e
                      </span>
                    </MenuItem>
                    <MenuItem value={3}>
                      <span>
                        w - G<sub>s</sub> - S
                      </span>
                    </MenuItem>
                    <MenuItem value={4}>
                      <span>
                        w - G<sub>s</sub> - n
                      </span>
                    </MenuItem>
                    <MenuItem value={5}>
                      <span>
                        S - G<sub>s</sub> - n
                      </span>
                    </MenuItem>
                  </TextField>
                </div>
                <div className="inputs">
                  {gammaMoistInputCombination === 1 && (
                    <>
                      <W
                        checked={gammaMoistChecked}
                        w={w}
                        setw={setw}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <Gs
                        checked={gammaMoistChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <E
                        checked={gammaMoistChecked}
                        e={e}
                        sete={sete}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaMoistInputCombination === 2 && (
                    <>
                      <S
                        checked={gammaMoistChecked}
                        s={s}
                        sets={sets}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <Gs
                        checked={gammaMoistChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <E
                        checked={gammaMoistChecked}
                        e={e}
                        sete={sete}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaMoistInputCombination === 3 && (
                    <>
                      <W
                        checked={gammaMoistChecked}
                        w={w}
                        setw={setw}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <Gs
                        checked={gammaMoistChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <S
                        checked={gammaMoistChecked}
                        s={s}
                        sets={sets}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaMoistInputCombination === 4 && (
                    <>
                      <W
                        checked={gammaMoistChecked}
                        w={w}
                        setw={setw}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <Gs
                        checked={gammaMoistChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <N
                        checked={gammaMoistChecked}
                        n={n}
                        setn={setn}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaMoistInputCombination === 5 && (
                    <>
                      <S
                        checked={gammaMoistChecked}
                        s={s}
                        sets={sets}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <Gs
                        checked={gammaMoistChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <N
                        checked={gammaMoistChecked}
                        n={n}
                        setn={setn}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                </div>
                <div className="formula">
                  {gammaMoistInputCombination === 1 && <Gamma1 />}
                  {gammaMoistInputCombination === 2 && <Gamma2 />}
                  {gammaMoistInputCombination === 3 && <Gamma3 />}
                  {gammaMoistInputCombination === 4 && <Gamma4 />}
                  {gammaMoistInputCombination === 5 && <Gamma5 />}
                </div>
              </div>
              <div className="inputRow ">
                <div className="calculationName"></div>
                <div className="checkboxes">
                  <FormControlLabel
                    control={<Checkbox checked={gammaDryChecked} onChange={handleChangeGammaDry} />}
                    label={
                      <span>
                        &gamma;<sub>dry</sub>
                      </span>
                    }
                  />
                </div>
                <div className="selects">
                  <TextField
                    size="small"
                    style={{ minWidth: 175 }}
                    select
                    defaultValue={gammaDryInputCombination}
                    label="Input combination"
                    value={gammaDryInputCombination}
                    onChange={(e) => setGammaDryInputCombination(e.target.value)}
                    disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave || !gammaDryChecked}
                  >
                    <MenuItem value={1}>
                      <span>&gamma; - w</span>
                    </MenuItem>
                    <MenuItem value={2}>
                      <span>
                        G<sub>s</sub> - e
                      </span>
                    </MenuItem>
                    <MenuItem value={3}>
                      <span>
                        G<sub>s</sub> - n
                      </span>
                    </MenuItem>
                    <MenuItem value={4}>
                      <span>
                        G<sub>s</sub> - w - S
                      </span>
                    </MenuItem>
                    <MenuItem value={5}>
                      <span>e - w - S</span>
                    </MenuItem>
                    <MenuItem value={6}>
                      <span>
                        &gamma;<sub>sat</sub> - e
                      </span>
                    </MenuItem>
                    <MenuItem value={7}>
                      <span>
                        &gamma;<sub>sat</sub> - n
                      </span>
                    </MenuItem>
                    <MenuItem value={8}>
                      <span>
                        &gamma;<sub>sat</sub> - G<sub>s</sub>
                      </span>
                    </MenuItem>
                  </TextField>
                </div>
                <div className="inputs">
                  {gammaDryInputCombination === 1 && (
                    <>
                      <GammaMoist
                        checked={gammaDryChecked}
                        gammaMoist={gammaMoist}
                        setgammaMoist={setgammaMoist}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <W
                        checked={gammaDryChecked}
                        w={w}
                        setw={setw}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaDryInputCombination === 2 && (
                    <>
                      <Gs
                        checked={gammaDryChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <E
                        checked={gammaDryChecked}
                        e={e}
                        sete={sete}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaDryInputCombination === 3 && (
                    <>
                      <Gs
                        checked={gammaDryChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <N
                        checked={gammaDryChecked}
                        n={n}
                        setn={setn}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaDryInputCombination === 4 && (
                    <>
                      <Gs
                        checked={gammaDryChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <W
                        checked={gammaDryChecked}
                        w={w}
                        setw={setw}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <S
                        checked={gammaDryChecked}
                        s={s}
                        sets={sets}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaDryInputCombination === 5 && (
                    <>
                      <E
                        checked={gammaDryChecked}
                        e={e}
                        sete={sete}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <W
                        checked={gammaDryChecked}
                        w={w}
                        setw={setw}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <S
                        checked={gammaDryChecked}
                        s={s}
                        sets={sets}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaDryInputCombination === 6 && (
                    <>
                      <GammaSat
                        checked={gammaDryChecked}
                        gammaSat={gammaSat}
                        setgammaSat={setgammaSat}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <E
                        checked={gammaDryChecked}
                        e={e}
                        sete={sete}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaDryInputCombination === 7 && (
                    <>
                      <GammaSat
                        checked={gammaDryChecked}
                        gammaSat={gammaSat}
                        setgammaSat={setgammaSat}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <N
                        checked={gammaDryChecked}
                        n={n}
                        setn={setn}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaDryInputCombination === 8 && (
                    <>
                      <GammaSat
                        checked={gammaDryChecked}
                        gammaSat={gammaSat}
                        setgammaSat={setgammaSat}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <Gs
                        checked={gammaDryChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                </div>
                <div className="formula">
                  {gammaDryInputCombination === 1 && <GammaDry1 />}
                  {gammaDryInputCombination === 2 && <GammaDry2 />}
                  {gammaDryInputCombination === 3 && <GammaDry3 />}
                  {gammaDryInputCombination === 4 && <GammaDry4 />}
                  {gammaDryInputCombination === 5 && <GammaDry5 />}
                  {gammaDryInputCombination === 6 && <GammaDry6 />}
                  {gammaDryInputCombination === 7 && <GammaDry7 />}
                  {gammaDryInputCombination === 8 && <GammaDry8 />}
                </div>
              </div>
              <div className="inputRow ">
                <div className="calculationName"></div>
                <div className="checkboxes">
                  <FormControlLabel
                    control={<Checkbox checked={gammaSatChecked} onChange={handleChangeGammaSat} />}
                    label={
                      <span>
                        &gamma;<sub>sat</sub>
                      </span>
                    }
                  />
                </div>
                <div className="selects">
                  <TextField
                    size="small"
                    style={{ minWidth: 175 }}
                    select
                    defaultValue={gammaSatInputCombination}
                    label="Input combination"
                    value={gammaSatInputCombination}
                    onChange={(e) => setGammaSatInputCombination(e.target.value)}
                    disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave || !gammaSatChecked}
                  >
                    <MenuItem value={1}>
                      <span>
                        G<sub>s</sub> - e
                      </span>
                    </MenuItem>
                    <MenuItem value={2}>
                      <span>
                        G<sub>s</sub> - n
                      </span>
                    </MenuItem>
                    <MenuItem value={3}>
                      <span>
                        G<sub>s</sub> - w
                      </span>
                    </MenuItem>
                    <MenuItem value={4}>
                      <span>e - w</span>
                    </MenuItem>
                    <MenuItem value={5}>
                      <span>n - w</span>
                    </MenuItem>
                    <MenuItem value={6}>
                      <span>
                        &gamma;<sub>dry</sub> - e
                      </span>
                    </MenuItem>
                    <MenuItem value={7}>
                      <span>
                        &gamma;<sub>dry</sub> - n
                      </span>
                    </MenuItem>
                    <MenuItem value={8}>
                      <span>
                        &gamma;<sub>dry</sub> - G<sub>s</sub>
                      </span>
                    </MenuItem>
                    <MenuItem value={9}>
                      <span>
                        &gamma;<sub>dry</sub> - w
                      </span>
                    </MenuItem>
                  </TextField>
                </div>
                <div className="inputs">
                  {gammaSatInputCombination === 1 && (
                    <>
                      <Gs
                        checked={gammaSatChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <E
                        checked={gammaSatChecked}
                        e={e}
                        sete={sete}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaSatInputCombination === 2 && (
                    <>
                      <Gs
                        checked={gammaSatChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <N
                        checked={gammaSatChecked}
                        n={n}
                        setn={setn}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaSatInputCombination === 3 && (
                    <>
                      <Gs
                        checked={gammaSatChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <W
                        checked={gammaSatChecked}
                        w={w}
                        setw={setw}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaSatInputCombination === 4 && (
                    <>
                      <E
                        checked={gammaSatChecked}
                        e={e}
                        sete={sete}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <W
                        checked={gammaSatChecked}
                        w={w}
                        setw={setw}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaSatInputCombination === 5 && (
                    <>
                      <N
                        checked={gammaSatChecked}
                        n={n}
                        setn={setn}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <W
                        checked={gammaSatChecked}
                        w={w}
                        setw={setw}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaSatInputCombination === 6 && (
                    <>
                      <GammaDry
                        checked={gammaSatChecked}
                        gammaDry={gammaDry}
                        setgammaDry={setgammaDry}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <E
                        checked={gammaSatChecked}
                        e={e}
                        sete={sete}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaSatInputCombination === 7 && (
                    <>
                      <GammaDry
                        checked={gammaSatChecked}
                        gammaDry={gammaDry}
                        setgammaDry={setgammaDry}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <N
                        checked={gammaSatChecked}
                        n={n}
                        setn={setn}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaSatInputCombination === 8 && (
                    <>
                      <GammaDry
                        checked={gammaSatChecked}
                        gammaDry={gammaDry}
                        setgammaDry={setgammaDry}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <Gs
                        checked={gammaSatChecked}
                        gs={gs}
                        setgs={setgs}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                  {gammaSatInputCombination === 9 && (
                    <>
                      <GammaDry
                        checked={gammaSatChecked}
                        gammaDry={gammaDry}
                        setgammaDry={setgammaDry}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                      <W
                        checked={gammaSatChecked}
                        w={w}
                        setw={setw}
                        isLoadingCalculate={isLoadingCalculate}
                        isLoadingLoad={isLoadingLoad}
                        isLoadingSave={isLoadingSave}
                        onChangeWarningForPositiveDecimals={onChangeWarningForPositiveDecimals}
                      />
                    </>
                  )}
                </div>
                <div className="formula">
                  {gammaSatInputCombination === 1 && <GammaSat1 />}
                  {gammaSatInputCombination === 2 && <GammaSat2 />}
                  {gammaSatInputCombination === 3 && <GammaSat3 />}
                  {gammaSatInputCombination === 4 && <GammaSat4 />}
                  {gammaSatInputCombination === 5 && <GammaSat5 />}
                  {gammaSatInputCombination === 6 && <GammaSat6 />}
                  {gammaSatInputCombination === 7 && <GammaSat7 />}
                  {gammaSatInputCombination === 8 && <GammaSat8 />}
                  {gammaSatInputCombination === 9 && <GammaSat9 />}
                </div>
              </div>
              <div className="buttonContainer">
                <CalculateButton
                  calculationCountReached={calculationCountReached}
                  calculate={calculate}
                  calculationInput={calculationInput}
                  URL={URL}
                  calculateButtonDisabled={calculateButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
                {isLoadingLoad && <CircularProgress size={30} />}

                <SaveButton
                  saveCountReached={saveCountReached}
                  checkCalculationNameforSaving={checkCalculationNameforSaving}
                  calculationInput={calculationInput}
                  URL={URL}
                  saveButtonDisabled={saveButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
              </div>
            </div>
          </section>
          {showOutput && <WeightVolumeRelationsResult result={result} />}
          {isLoadingCalculate && (
            <div className="centerLoading">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

const WeightVolumeRelationShipsWithProvider = () => {
  return (
    <CalculationContextProvider>
      <WeightVolumeRelationShips />
    </CalculationContextProvider>
  );
};
export default WeightVolumeRelationShipsWithProvider;
