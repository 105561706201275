import React, { useState, useEffect } from 'react';
import Header from '../common/Header';
import AdminMenu from './Menu/AdminMenu';
import AdminDashboard from './Dashboard/AdminDashboard';
import AdminUsers from './Users/AdminUsers';
import AdminCalculations from './Calculations/AdminCalculations';
import AdminRoles from './Roles/AdminRoles';
import AdminSendEmail from './SendEmail/AdminSendEmail';
import MenuIcon from '@mui/icons-material/Menu';

const Admin = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Admin';
  }, []);
  const [showMenu, setShowMenu] = useState(false);
  const [showApp, setShowApp] = useState({
    dashboard: true,
    users: false,
    roles: false,
    calculations: false,
    sendEmail: false,
  });
  const handleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div className="adminContainer">
      <Header />
      <div className="adminContentContainer">
        {showMenu && <AdminMenu setShowApp={setShowApp} setShowMenu={setShowMenu} handleMenu={handleMenu} />}
        <div className="adminContent">
          {!showMenu && (
            <div className="adminMenuIcon" onClick={handleMenu}>
              <MenuIcon color="primary" />
            </div>
          )}

          {showApp.dashboard && <AdminDashboard />}
          {showApp.users && <AdminUsers />}
          {showApp.calculations && <AdminCalculations />}
          {showApp.roles && <AdminRoles />}
          {showApp.sendEmail && <AdminSendEmail />}
        </div>
      </div>
    </div>
  );
};

export default Admin;
