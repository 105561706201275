import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import Header from '../../common/Header';
import Footer from '../../common/Footer';

const SptcorrTheory = () => {
  // Google Ads & Set page title
  useEffect(() => {
    document.title = 'GeoCalc | SPT Correction Theory';
  }, []);

  return (
    <>
      <Header />
      <div className="theory">
        <div className="theoryContainer">
          <h1>SPT Correction | Theory & Formulations</h1>
          <h2>What is SPT (Standard Penetration Test) ?</h2>
          <p>
            The Standard Penetration Test (SPT) is a widely used in-situ soil testing method to determine the strength and
            consistency of soil at a particular location. It is an empirical test that involves driving a standard sampler with a
            weight of 65 kg and falling freely from a height of 760 mm into the soil for a total of 150 mm increments. The number
            of blows required to drive the sampler the last 300 mm of penetration is called the "N-value" or "blow count."
          </p>
          <p>
            The SPT is used to determine the soil type and properties, such as the density, strength, and shear resistance. The
            test is commonly used in geotechnical and foundation engineering to assess the suitability of a site for construction,
            design of foundations, and slope stability analysis. It is also used to estimate the liquefaction potential of soils
            during earthquakes, as well as for soil profiling and characterization in environmental and geological studies.
          </p>
          <h2>Why do we need SPT corrections?</h2>
          <p>
            SPT (Standard Penetration Test) values often need to be corrected to account for various factors that can affect the
            test results. It's important to note that the correction factors can vary depending on the soil type, and other
            site-specific factors. Therefore, it's essential to consult with a geotechnical engineer to determine the appropriate
            correction factor for a specific site. Some of the reasons why SPT values need to be corrected are:
          </p>
          <h4>Overburden stress</h4>
          <p>
            The weight of the soil above the test location can cause stress in the soil and affect the penetration resistance of
            the sampler. The correction factor for overburden stress takes into account the effective overburden stress of the
            soil.
          </p>
          <h4>Sampler size and shape</h4>
          <p>
            The size and shape of the sampler can affect the SPT values. The correction factor for sampler size and shape takes
            into account the area of the sampler and its shape factor.
          </p>
          <h4>Energy transfer</h4>
          <p>
            The energy transferred from the hammer to the sampler can affect the SPT values. The correction factor for energy
            transfer takes into account the weight of the hammer, the height of fall, and the efficiency of the system.
          </p>
          <h4>Soil disturbance</h4>
          <p>
            The SPT test can cause soil disturbance, which can affect the test results. The correction factor for soil disturbance
            takes into account the length of the sampler and the disturbance caused by the test.
          </p>
          <h4>Borehole diameter: </h4>
          <p>
            The diameter of the borehole can affect the SPT values. The correction factor for borehole diameter takes into account
            the difference between the borehole diameter and the sampler diameter.
          </p>
          <h2>How do we perform SPT corrections ?</h2>
          <p>
            Measured SPT values in the field (N) at each depth (z) will be corrected to (N<sub>1</sub>)<sub>60</sub> values using
            the equation below.
          </p>
          <p className="theoryEquation">
            (N<sub>1</sub>)<sub>60</sub> = N' x C<sub>E</sub> x C<sub>N</sub> x C<sub>B</sub> x C<sub>S</sub> x C<sub>R</sub> x C
            <sub>BF</sub>
          </p>
          <p className="theoryEquation">Where;</p>
          <h4>N': SPT value after silty sand correction (dilatancy correction) (Terzaghi & Peck, 1948)</h4>
          <p>
            If the below 3 conditions are present, silty sand correction should be made. N is the value measured in the field.
          </p>
          <p>1. If the ground water level is above test depth.</p>
          <p>2. If the soil is sand or silty sand</p>
          <p>3. If N &gt;15 </p>

          <p>If all the conditions are met, N' is calculated using the equation below.</p>
          <p className="theoryEquation">N' = 15 + 0.5(N - 15)</p>
          <h4>
            C<sub>E</sub>: Energy correction factor (Skempton, 1986)
          </h4>
          <p>Energy correction factor will be selected from the table below.</p>
          <div className="correctionFactorTable">
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Hammer Type</div>
              <div className="correctionFactorTableColumn">
                <p>
                  C<sub>E</sub>
                </p>
              </div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Safety</div>
              <div className="correctionFactorTableColumn">0.60 - 1.17</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Donut</div>
              <div className="correctionFactorTableColumn">0.45 - 1.00</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn no-border-bottom">Auto</div>
              <div className="correctionFactorTableColumn no-border-bottom">0.90 - 1.60</div>
            </div>
          </div>
          <h4>
            C<sub>N</sub>: Overburden Pressure Correction Factor (Liao & Whitman, 1986)
          </h4>
          <p>
            Overburden correction factor will be calculated using the equation below. The vertical effective pressure,
            &sigma;'(kN/m2) should be calculated for the ground conditions when the Standard Penetration Test (SPT) is performed.
            Any excavation, fill or foundation loads after the test will be ignored.
          </p>
          <p className="theoryEquation">
            C<sub>N</sub> = 9.78 x (1 / &sigma;') <sup>0.5</sup> &le; 1.70
          </p>
          <h4>
            C<sub>B</sub>: Borehole Diameter Correction Factor (Skempton, 1986)
          </h4>
          <p>Borehole diameter correction factor will be selected from the table below.</p>
          <div className="correctionFactorTable">
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Borehole Dia.(mm)</div>
              <div className="correctionFactorTableColumn">
                <p>
                  C<sub>B</sub>
                </p>
              </div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">65 - 115</div>
              <div className="correctionFactorTableColumn">1.00</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">150</div>
              <div className="correctionFactorTableColumn">1.05</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn no-border-bottom">200</div>
              <div className="correctionFactorTableColumn no-border-bottom">1.15</div>
            </div>
          </div>
          <h4>
            C<sub>S</sub>: Sampler Type Correction Factor (Skempton, 1986)
          </h4>
          <p>Sampler type correction factor will be selected from the table below.</p>
          <div className="correctionFactorTable">
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Sampler Type</div>
              <div className="correctionFactorTableColumn">
                <p>
                  C<sub>S</sub>
                </p>
              </div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Standard Liner (With liner)</div>
              <div className="correctionFactorTableColumn">1.00</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn no-border-bottom">Without Liner</div>
              <div className="correctionFactorTableColumn no-border-bottom">1.10 - 1.30</div>
            </div>
          </div>
          <h4>
            C<sub>R</sub>: Rod Length Correction Factor (Skempton, 1986)
          </h4>
          <p>Rod length correction factor will be selected from the table below.</p>
          <div className="correctionFactorTable">
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Rod Length(m)</div>
              <div className="correctionFactorTableColumn">
                <p>
                  C<sub>R</sub>
                </p>
              </div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">0 - 4</div>
              <div className="correctionFactorTableColumn">0.75</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">4 - 6</div>
              <div className="correctionFactorTableColumn">0.85</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">6 - 10</div>
              <div className="correctionFactorTableColumn">0.95</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn no-border-bottom">&gt; 10</div>
              <div className="correctionFactorTableColumn no-border-bottom">1.00</div>
            </div>
          </div>
          <h4>
            C<sub>BF</sub>: Blow count frequency correction factor (McGregor ve Duncan, 1998)
          </h4>
          <p>Blow count frequency correction factor will be selected from the table below.</p>
          <div className="correctionFactorTable">
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">Blow Count</div>
              <div className="correctionFactorTableColumn">
                <p>
                  C<sub>BF</sub>
                </p>
              </div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn">&lt; 20</div>
              <div className="correctionFactorTableColumn">0.95</div>
            </div>
            <div className="correctionFactorTableRow">
              <div className="correctionFactorTableColumn no-border-bottom">&gt; 20</div>
              <div className="correctionFactorTableColumn no-border-bottom">1.05</div>
            </div>
          </div>
          <div className="theoryButtonContainer">
            <Link to={'/calculations/spt-corr'}>
              <Button variant="outlined" size="small">
                GO TO SPT CORRECTION CALCULATOR
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SptcorrTheory;
