import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InputAdornment from '@mui/material/InputAdornment';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CircularProgress from '@mui/material/CircularProgress';

import { useCalculationContext, CalculationContextProvider } from '../../context/calculationContext';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import MuiSnackbar from '../../common/MuiSnackbar';
import Sidebar from '../../sidebar/Sidebar';
import DecisionModal from '../../modals/DecisionModal';
import OutputLayer from './OutputLayer';
import ButtonContainer from '../../common/buttonContainerForLayeredCalculations';
import LabelContainer from './LabelContainer';
import { useCheckMissingParamsForLayeredCalculations } from '../../../hooks/useCheckMissingParamsForLayeredCalculations';

const SptCorrection = () => {
  useEffect(() => {
    document.title = 'GeoCalc | SPT Correction';
  }, []);

  const {
    MAX_NAME_LENGTH,
    isLoadingCalculate,
    isLoadingLoadAll,
    isLoadingLoad,
    isLoadingSave,
    result,
    setResult,
    resultsArray,
    setResultsArray,
    resultProps,
    calculations,
    setShowOutput,
    calculateButtonDisabled,
    setCalculateButtonDisabled,
    saveButtonDisabled,
    setSaveButtonDisabled,
    errorMessage,
    displaySidebar,
    setDisplaySidebar,
    decisionModalIsOpen,
    decision,
    setDecision,
    handleSidebarDisplay,
    setDecisionModalIsOpenToFalse,
    showMuiSnack,
    open,
    setOpen,
    alertMessage,
    severity,
    onChangeWarningForPositiveDecimals,
    calculate,
    loadOneCalculation,
    loadAllCalculations,
    update,
    checkCalculationNameforSaving,
    checkCalculationNameforSavingAs,
    deleteCalculations,
    calculationCountReached,
    saveCountReached,
  } = useCalculationContext();

  //SET STATES FOR INPUT VARIABLES
  const [name, setName] = useState('');
  const [gamma, setGamma] = useState('');
  const [gwt, setGwt] = useState('');
  const [hammerType, setHammerType] = useState(0);
  const [samplerType, setSamplerType] = useState(0);
  const [boreholeDiameter, setBoreholeDiameter] = useState(0);
  const [layersFront, setLayersFront] = useState([]);

  // SET THE LOADED INPUTS
  const setInputs = (inputs) => {
    setName(inputs.name);
    setGamma(inputs.gamma);
    setGwt(inputs.gwt);
    setHammerType(inputs.hammerType);
    setSamplerType(inputs.samplerType);
    setBoreholeDiameter(inputs.boreholeDiameter);
    setLayersFront(inputs.layersFront);
  };

  //INPUTS FOR CALCULATION
  const calculationInput = {
    name,
    gamma,
    gwt,
    hammerType,
    samplerType,
    boreholeDiameter,
    layersFront,
  };
  //ADD LAYER
  const addLayer = (e) => {
    e.preventDefault();
    const layer = {
      id: layersFront.length + 1,
      z: (layersFront.length + 1) * 1.5,
      SPT: '',
      uscs: 0,
    };

    const newLayersFront = layersFront.slice();
    newLayersFront.push(layer);
    setLayersFront(newLayersFront);

    const newResultsArray = resultsArray.slice();
    newResultsArray.push([]);
    setResultsArray(newResultsArray);
  };

  //REMOVE LAYER
  const removeLayer = (e) => {
    e.preventDefault();
    const newLayersFront = layersFront.slice();
    newLayersFront.pop();
    setLayersFront(newLayersFront);

    resultsArray.pop();
  };

  // MANAGE SAVE & CALCULATE BUTTONS DISABLED/ENABLED STATES
  useEffect(() => {
    setCalculateButtonDisabled(false);
    setSaveButtonDisabled(false);

    if (
      (calculationInput.name || calculationInput.name === 0) &&
      (calculationInput.gamma || calculationInput.gamma === 0) &&
      (calculationInput.gwt || calculationInput.gwt === 0) &&
      (calculationInput.hammerType || calculationInput.hammerType === 0) &&
      (calculationInput.samplerType || calculationInput.samplerType === 0) &&
      (calculationInput.boreholeDiameter || calculationInput.boreholeDiameter === 0)
    ) {
      setCalculateButtonDisabled(false);
      setSaveButtonDisabled(false);
    } else {
      setCalculateButtonDisabled(true);
      setSaveButtonDisabled(true);
    }
  }, [
    setCalculateButtonDisabled,
    setSaveButtonDisabled,
    calculationInput.name,
    calculationInput.gamma,
    calculationInput.gwt,
    calculationInput.hammerType,
    calculationInput.samplerType,
    calculationInput.boreholeDiameter,
  ]);

  //check layer mising params and handle calculate button disabled/enabled status
  const doLayersHaveMissingParams = useCheckMissingParamsForLayeredCalculations(layersFront);
  useEffect(() => {
    setCalculateButtonDisabled(doLayersHaveMissingParams);
    setSaveButtonDisabled(doLayersHaveMissingParams);
  }, [doLayersHaveMissingParams, layersFront, setCalculateButtonDisabled, setSaveButtonDisabled]);

  // Do not render output if any input parameter except name changes
  useEffect(() => {
    setResultsArray([]);
  }, [
    setResultsArray,
    setResult,
    calculationInput.gamma,
    calculationInput.gwt,
    calculationInput.hammerType,
    calculationInput.samplerType,
    calculationInput.boreholeDiameter,
    calculationInput.layersFront,
  ]);

  //CLEAR INPUTS AND CLEAR OUTPUT
  const createNewCalculation = () => {
    setName('');
    setGamma('');
    setGwt('');
    setHammerType(0);
    setSamplerType(0);
    setBoreholeDiameter(0);
    setLayersFront([]);
    setShowOutput(false);
  };

  //API URLS
  const URL = {
    CALCULATE: '/api/v1/calculations/spt-correction',
    LOADONE: `/api/v1/calculations/spt-correction/loadOne/`,
    LOADALL: '/api/v1/calculations/spt-correction/loadAll',
    UPDATE: `/api/v1/calculations/spt-correction/update/`,
    SAVE: '/api/v1/calculations/spt-correction/save',
    CHECKUNIQUENAME: `/api/v1/calculations/spt-correction/checkUniqueName/`,
    DELETE: '/api/v1/calculations/spt-correction/delete',
  };

  const handleChangeName = (e) => {
    if (e.target.value.length >= MAX_NAME_LENGTH) {
      showMuiSnack(`Calculation name can be max ${MAX_NAME_LENGTH} characters.`, 'warning');
    }
    setName(e.target.value);
  };

  // RETURN INPUT AND OUTPUT JSX
  return (
    <>
      <Header />
      <div className="container">
        <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
        {displaySidebar ? (
          <Sidebar
            URL={URL}
            loadAllCalculations={loadAllCalculations}
            calculations={calculations}
            loadOneCalculation={loadOneCalculation}
            calculate={calculate}
            setInputs={setInputs}
            deleteCalculations={deleteCalculations}
            createNewCalculation={createNewCalculation}
            isLoading={isLoadingLoadAll}
            handleSidebarDisplay={handleSidebarDisplay}
            displaySidebar={displaySidebar}
            setDisplaySidebar={setDisplaySidebar}
          />
        ) : (
          <MenuOpenIcon
            color="primary"
            fontSize="large"
            className="sidebar-open-icon"
            alt="side bar menu icon"
            label="Show my calculations"
            onClick={handleSidebarDisplay}
          />
        )}
        <DecisionModal
          URL={URL}
          data={calculationInput}
          decisionModalIsOpen={decisionModalIsOpen}
          setDecisionModalIsOpenToFalse={setDecisionModalIsOpenToFalse}
          decision={decision}
          setDecision={setDecision}
          checkCalculationNameforSavingAs={checkCalculationNameforSavingAs}
          name={name}
          setName={setName}
          errorMessage={errorMessage}
          update={update}
          saveButtonDisabled={saveButtonDisabled}
        />
        <div className="right-calculation">
          <section className="input loadingRelative">
            <h1>SPT Correction</h1>
            {isLoadingLoad && (
              <div className="loadingAbsolute">
                <CircularProgress size={30} />
              </div>
            )}
            <form>
              <TextField
                sx={{ width: 140 }}
                size="small"
                label="Name"
                variant="outlined"
                inputProps={{
                  maxLength: `${MAX_NAME_LENGTH}`,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Your calculation name" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={name}
                onChange={handleChangeName}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                sx={{ width: 140 }}
                size="small"
                label={
                  <span key={'gamma'} className="subscriptMargin">
                    &gamma; (kN/m<sup>3</sup>)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Natural unit weight of soil" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={gamma}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Gamma', setGamma)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                sx={{ width: 140 }}
                size="small"
                label={'GWT(m)'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Ground water level measured from ground surface"
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={gwt}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'GWT', setGwt)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                sx={{ width: 140 }}
                label={'Hammer Type'}
                size="small"
                select
                value={hammerType}
                onChange={(e) => setHammerType(e.target.value)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              >
                <MenuItem value="0" disabled>
                  Select Hammer Type
                </MenuItem>
                <MenuItem value="1">Safety</MenuItem>
                <MenuItem value="2">Donut</MenuItem>
                <MenuItem value="3">Automatic</MenuItem>
              </TextField>

              <TextField
                sx={{ width: 140 }}
                label={'Sampler Type'}
                size="small"
                select
                value={samplerType}
                onChange={(e) => setSamplerType(e.target.value)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              >
                <MenuItem value="0" disabled>
                  Select Sampler Type
                </MenuItem>
                <MenuItem value="1">Standard Sampler</MenuItem>
                <MenuItem value="2">Sampler Without Liner</MenuItem>
              </TextField>

              <TextField
                sx={{ minWidth: 140, maxWidth: 140 }}
                label={'Borehole Diameter'}
                size="small"
                select
                value={boreholeDiameter}
                onChange={(e) => setBoreholeDiameter(e.target.value)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              >
                <MenuItem value="0" disabled>
                  Select Borehole Diameter
                </MenuItem>
                <MenuItem value="1">65-115</MenuItem>
                <MenuItem value="2">150</MenuItem>
                <MenuItem value="3">200</MenuItem>
              </TextField>
            </form>
          </section>

          <section className="output loadingRelative horizontalScroll">
            {isLoadingCalculate && (
              <div className="loadingAbsolute">
                <CircularProgress />
              </div>
            )}
            <h2>Output </h2>
            <form className="sptForm">
              {layersFront.length > 0 && <LabelContainer />}
              {layersFront?.map((el, index) => (
                <OutputLayer
                  key={index}
                  layer={el}
                  index={index}
                  layers={layersFront}
                  setLayers={setLayersFront}
                  resultsObj={resultProps[index]}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
              ))}
            </form>
          </section>
          <ButtonContainer
            URL={URL}
            addLayer={addLayer}
            removeLayer={removeLayer}
            calculate={calculate}
            checkCalculationNameforSaving={checkCalculationNameforSaving}
            calculationInput={calculationInput}
            layersFrontLength={layersFront.length}
            isLoadingCalculate={isLoadingCalculate}
            isLoadingLoad={isLoadingLoad}
            isLoadingSave={isLoadingSave}
            saveButtonDisabled={saveButtonDisabled}
            calculateButtonDisabled={calculateButtonDisabled}
            calculationCountReached={calculationCountReached}
            saveCountReached={saveCountReached}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

const SptCorrectionWithProvider = () => {
  return (
    <CalculationContextProvider>
      <SptCorrection />
    </CalculationContextProvider>
  );
};
export default SptCorrectionWithProvider;
