import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import Header from '../../common/Header';
import Footer from '../../common/Footer';
import TransitionsModal from '../../modals/TransitionsModal';
import figure1 from '../../../assets/calculations/pilerockbc/1.png';
import figure2 from '../../../assets/calculations/pilerockbc/2.png';

const PileRockBcTheory = () => {
  // Google Ads & Set page title
  useEffect(() => {
    document.title = 'GeoCalc | Bearing Capacity of Pile Foundations in Rock, AASHTO(2002) Theory';
  }, []);

  return (
    <>
      <Header />
      <div className="theory">
        <div className="theoryContainer">
          <h1>Bearing Capacity of Pile Foundations in Rock, AASHTO(2002) | Theory & Formulations</h1>
          <h2>Axial Capacity In Rock (Article 4.6.5.3)</h2>
          <p>
            Drilled shafts are socketed into rock to limit axial displacements, increase load capacity and/or provide fixity for
            resistance to lateral loading. In determining the axial capacity of drilled shafts with rock sockets, the side
            resistance from overlying soil deposits may be ignored.
          </p>
          <p>
            Typically, axial compression load is carried solely by the side resistance on a shaft socketed into rock until a total
            shaft settlement (&rho;<sub>s</sub>) on the order of 0.4 inches (&#8776;1cm) occurs. At this dispacement, the ultimate
            side resistance, Q<sub>SR</sub>, is mobilized and slip occurs between the concrete and rock. As a result of this slip,
            any additional load is transferred to the tip.
          </p>
          <p>
            The design procedures assume the socket is constructed in reasonably sound rock that is little affected by
            construction (i.e, does not rapidly degrade upon excavation and/or exposure to air or water) and which is cleaned
            prior to concrete placement (i.e, free of soil and other debris). If the rock is degradable, consideration of special
            construction procedures, larger socket dimensions, or reduced socket capacities should be considered.
          </p>
          <h3>Side Resistance (Article 4.6.5.3.1)</h3>
          <p>
            The ultimate side resistance (Q<sub>SR</sub>) for shafts socketed into rock may be determined using the following:
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>Q</mi>
                <mi>SR</mi>
              </msub>
              <mo>=</mo>
              <mi>&pi;</mi>
              <msub>
                <mi>B</mi>
                <mi>r</mi>
              </msub>
              <msub>
                <mi>D</mi>
                <mi>r</mi>
              </msub>
              <msub>
                <mi>q</mi>
                <mi>SR</mi>
              </msub>
            </math>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>
            Q<sub>SR</sub>: Ultimate side resistance of rock socket.
          </p>
          <p>
            B<sub>r</sub>: Diameter of rock socket.
          </p>
          <p>
            D<sub>r</sub>: Length of rock socket.
          </p>
          <p>
            q<sub>SR</sub>: Ultimate unit shear resistance along shaft/rock interface.
          </p>
          <p>
            Refer to Figure 4.6.5.3.1A for values of q<sub>SR</sub>. For uplift loading Q<sub>ult</sub> of a rock socket shall be
            limited to 0.7 Q<sub>SR</sub>.
          </p>
          <TransitionsModal
            image={figure1}
            title="Bearing Capacity of Pile Foundations in Rock, AASHTO(2002)"
            description={'Determine qsr using the weaker of unconfined compressive strength of rock or concrete'}
            buttonText={'Open Figure 4.6.5.3.1A'}
          ></TransitionsModal>
          <p>
            The design of rock sockets shall be based on the unconfined compressive strength of the rock mass (C<sub>m</sub>) or
            concrete, whichever is weaker (&sigma;<sub>c</sub>). C<sub>m</sub> may be estimated using the following relationship:
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>C</mi>
                <mi>m</mi>
              </msub>
              <mo>=</mo>
              <msub>
                <mi>&alpha;</mi>
                <mi>E</mi>
              </msub>
              <msub>
                <mi>C</mi>
                <mi>0</mi>
              </msub>
            </math>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>
            C<sub>m</sub>: Uniaxial compressive strength of rock mass.
          </p>
          <p>
            C<sub>0</sub>: Uniaxial compressive strength of intact rock.
          </p>
          <p>
            &alpha;<sub>E</sub>: Reduction factor to estimate rock mass modulus and uniaxial strength from modulus and uniaxial
            strength of intact rock. &alpha;<sub>E</sub> may be calculated using the equation 4.4.8.2.2-4 as follows:
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&alpha;</mi>
                <mi>E</mi>
              </msub>
              <mo>=</mo>
              <mn>0.00231</mn>
              <mo>(</mo>
              <mi>RQD</mi>
              <mo>)</mo>
              <mo>-</mo>
              <mn>1.32</mn>
              <mo>&ge;</mo>
              <mn>1.15</mn>
            </math>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>RQD: Rock quality designation.</p>
          <h3>Tip Resistance (Article 4.6.5.3.2)</h3>
          <p>
            Evaluation of ultimate tip resistance (Q<sub>TR</sub>) for rock-socketed drilled shafts shall consider the influence
            of rock discontinuities. Q<sub>TR</sub> for rock-socketed drilled shafts may be determined using the following:
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>Q</mi>
                <mi>TR</mi>
              </msub>
              <mo>=</mo>
              <msub>
                <mi>N</mi>
                <mi>ms</mi>
              </msub>
              <msub>
                <mi>C</mi>
                <mi>0</mi>
              </msub>
              <msub>
                <mi>A</mi>
                <mi>t</mi>
              </msub>
            </math>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>
            Q<sub>tr</sub>: Ultimate tip resistance of rock socket.
          </p>
          <p>
            N<sub>ms</sub>: Coefficient factor selected from Table 4.4.8.1.2A
          </p>

          <TransitionsModal
            image={figure2}
            title="Bearing Capacity of Pile Foundations in Rock, AASHTO(2002)"
            description={'Choose Nms using rock properties'}
            buttonText={'Open Table 4.4.8.1.2A'}
          ></TransitionsModal>
          <p>
            C<sub>0</sub>: Uniaxial compressive strength of intact rock.
          </p>
          <p>
            A<sub>t</sub>: Area of shaft tip.
          </p>
          <p>
            Preferably, values of C<sub>0</sub> should be determined from the results of laboratory testing of rock cores obtained
            within 2B of the base of the footing. Where rock strata within this interval are variable in strength, the rock with
            the lowest capacity should be used to determine Q<sub>TR</sub>.
          </p>
          <h3>Axial Capacity (Article 4.6.5.1)</h3>
          <p>
            The ultimate axial capacity Q<sub>ult</sub> of drilled shafts shall be determined in accordance with the following for
            compression and uplift respectively:
          </p>
          <p className="theoryEquation">
            Q<sub>ult</sub> = Q<sub>SR</sub> + Q<sub>TR</sub> - W (Compression)
          </p>
          <p className="theoryEquation">
            Q<sub>ult</sub> = 0.7Q<sub>SR</sub> + W (Uplift)
          </p>
          <p>The allowable or working axial load shall be determined as:</p>
          <p className="theoryEquation">
            Q<sub>all</sub> = Q<sub>ult</sub> / FS
          </p>
          <p className="theoryEquation">Where</p>
          <p>
            Q<sub>ult</sub>: Ultimate axial load capacity
          </p>
          <p>
            Q<sub>all</sub>: Allowable axial load capacity
          </p>
          <p>
            Q<sub>SR</sub>: Ultimate side resistance of rock socket
          </p>
          <p>
            Q<sub>TR</sub>: Ultimate tip resistance of rock socket
          </p>
          <p>W: Weight of shaft</p>
          <p>FS: Factor of safety</p>
          <div className="theoryButtonContainer">
            <Link to={'/calculations/pile-rock-bc'}>
              <Button variant="outlined" size="small">
                GO TO CALCULATOR
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PileRockBcTheory;
