import { useEffect, useState } from 'react';

export function useCheckMissingParamsForLayeredCalculations(layersFront) {
  const [hasMissingParams, setHasMissingParams] = useState(false);

  useEffect(() => {
    const arrayOfHappyLayers = layersFront.filter((layer) => {
      const layerParams = Object.values(layer);
      const arrMap = layerParams.map((param) => {
        return !!param;
      });
      return arrMap.every((el) => el === true);
    });

    if (arrayOfHappyLayers.length === layersFront.length) {
      setHasMissingParams(false);
    } else {
      setHasMissingParams(true);
    }
  }, [layersFront]);
  return hasMissingParams;
}
