import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InputAdornment from '@mui/material/InputAdornment';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
const LabelContainer = () => {
  return (
    <div className="labelContainer">
      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Soil layer ID" arrow placement="bottom" TransitionComponent={Zoom}>
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="Layer"
      />
      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Depth of soil layer (m)"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="z"
      />
      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Total stress at soil layer (kPa)"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="&sigma;"
      />

      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Effective stress at soil layer (kPa)"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="&sigma;'"
      />
      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Measured SPT-N value in the field"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="SPT"
      />
      <TextField
        style={{ minWidth: 120, maxWidth: 150 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Soil type according to Unified Soil Classification System"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="USCS"
      />
      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="SPT N value after silty sand correction"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="N'"
      />
      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Energy correction factor"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="Ce"
      />

      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Borehole diameter correction factor"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="Cb"
      />
      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Hammer type correction factor"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="Cs"
      />
      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Rod length correction factor"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="Cr"
      />
      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Overburden pressure correction factor"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="Cn"
      />
      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Blow speed correction factor"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="Cbf"
      />
      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Corrected SPT value for 60% energy level"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="N60"
      />
      <TextField
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Corrected SPT value for 60% energy level and 1 atm"
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <InfoTwoToneIcon fontSize={'string'} />
              </Tooltip>
            </InputAdornment>
          ),
          style: { fontWeight: 600 },
        }}
        disabled
        value="N160"
      />
    </div>
  );
};
export default LabelContainer;
