/* import { MathJax } from 'better-react-mathjax'; */

//----Gamma Moist Formulas
export const Gamma1 = () => {
  /* return <MathJax>{'\\(\\gamma = \\frac{(1+w)G_s\\gamma_w}{1+e} \\)'}</MathJax>; */
  return (
    <span>
      &gamma; = [(1+w)G<sub>s</sub> &gamma;<sub>w</sub>] / (1 + e)
    </span>
  );
};
export const Gamma2 = () => {
  /* return <MathJax>{'\\(\\gamma = \\frac{(G_s+Se)\\gamma_w}{1+e} \\)'}</MathJax>; */
  return (
    <span>
      &gamma; = [(G<sub>s</sub> + Se)&gamma;<sub>w</sub> ] / (1 + e)
    </span>
  );
};
export const Gamma3 = () => {
  /* return <MathJax>{'\\(\\gamma = \\frac{(1+w)G_s\\gamma_w}{1+\\frac{wG_s}{S}} \\)'}</MathJax>; */
  return (
    <span>
      &gamma; = [(1 + w)G<sub>s</sub>&gamma;<sub>w</sub>] / [(1 + wG<sub>s</sub> / S)]
    </span>
  );
};
export const Gamma4 = () => {
  /* return <MathJax>{'\\(\\gamma = G_s\\gamma_w(1-n)(1+w) \\)'}</MathJax>; */
  return (
    <span>
      &gamma; = G<sub>s</sub>&gamma;<sub>w</sub>(1 - n)(1 + w)
    </span>
  );
};
export const Gamma5 = () => {
  /* return <MathJax>{'\\(\\gamma = G_s\\gamma_w(1-n)+nS\\gamma_w \\)'}</MathJax>; */
  return (
    <span>
      &gamma; = G<sub>s</sub>&gamma;<sub>w</sub>(1 - n) + nS&gamma;<sub>w</sub>
    </span>
  );
};

//----Gamma Dry Formulas
export const GammaDry1 = () => {
  /* return <MathJax>{'\\(\\gamma_{dry} = \\frac{\\gamma}{1+w} \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>dry</sub> = &gamma; / (1 + w)
    </span>
  );
};
export const GammaDry2 = () => {
  /* return <MathJax>{'\\(\\gamma_{dry} = \\frac{G_s\\gamma_w}{1+e} \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>dry</sub> = (G<sub>s</sub>&gamma;<sub>w</sub>) / (1 + e)
    </span>
  );
};
export const GammaDry3 = () => {
  /* return <MathJax>{'\\(\\gamma_{dry} = G_s\\gamma_w(1-n) \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>dry</sub> = G<sub>s</sub>&gamma;<sub>w</sub>(1 - n)
    </span>
  );
};
export const GammaDry4 = () => {
  /* return <MathJax>{'\\(\\gamma_{dry} = \\frac{G_s\\gamma_w}{1+\\frac{wG_s}{S}} \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>dry</sub> = (G<sub>s</sub>&gamma;<sub>w</sub>) / (1 + wG<sub>s</sub> / S)
    </span>
  );
};
export const GammaDry5 = () => {
  /* return <MathJax>{'\\(\\gamma_{dry} = \\frac{eS\\gamma_w}{(1+e)w} \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>dry</sub> = (eS&gamma;<sub>w</sub>) / [(1 + e)w]
    </span>
  );
};
export const GammaDry6 = () => {
  /* return <MathJax>{'\\(\\gamma_{dry} = \\gamma_{sat}-\\frac{e\\gamma_w}{(1+e)} \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>dry</sub> = &gamma;<sub>sat</sub> - (e&gamma;<sub>w</sub>) / (1 + e)
    </span>
  );
};
export const GammaDry7 = () => {
  /* return <MathJax>{'\\(\\gamma_{dry} = \\gamma_{sat} - n\\gamma_w \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>dry</sub> = &gamma;<sub>sat</sub> - n&gamma;<sub>w</sub>
    </span>
  );
};
export const GammaDry8 = () => {
  /* return <MathJax>{'\\(\\gamma_{dry} = \\frac{(\\gamma_{sat} - \\gamma_w)G_s}{G_s - 1} \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>dry</sub> = G<sub>s</sub>(&gamma;<sub>sat</sub> - &gamma;<sub>w</sub>) / (G<sub>s</sub> - 1)
    </span>
  );
};

//----Gamma Sat Formulas
export const GammaSat1 = () => {
  /* return <MathJax>{'\\(\\gamma_{sat} = \\frac{(G_s+e)\\gamma_w}{1+e} \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>sat</sub> = (G<sub>s</sub> + e)&gamma;<sub>w</sub> / (1 + e)
    </span>
  );
};
export const GammaSat2 = () => {
  /* return <MathJax>{'\\(\\gamma_{sat} = [(1-n)G_s + n]\\gamma_w \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>sat</sub> = [(1 - n)G<sub>s</sub> + n]&gamma;<sub>w</sub>
    </span>
  );
};
export const GammaSat3 = () => {
  /* return <MathJax>{'\\(\\gamma_{sat} = \\left( \\frac{1+w}{1+wG_s} \\right) G_s\\gamma_w \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>sat</sub> = [(1 + w) / (1 + wG<sub>s</sub>)]G<sub>s</sub>&gamma;<sub>w</sub>
    </span>
  );
};
export const GammaSat4 = () => {
  /* return <MathJax>{'\\(\\gamma_{sat} = \\left( \\frac{e}{w} \\right)\\left( \\frac{1+w}{1+e} \\right) \\gamma_w \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>sat</sub> = (e / w)[(1 + w) / (1 + e)]&gamma;<sub>w</sub>
    </span>
  );
};
export const GammaSat5 = () => {
  /* return <MathJax>{'\\(\\gamma_{sat} = n\\left( \\frac{1+w}{w} \\right)\\gamma_w \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>sat</sub> = [(1 + w) / w]n&gamma;<sub>w</sub>
    </span>
  );
};
export const GammaSat6 = () => {
  /* return <MathJax>{'\\(\\gamma_{sat} = \\gamma_{dry}+\\left( \\frac{e}{1+e} \\right)\\gamma_w \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>sat</sub> = &gamma;<sub>dry</sub> + [e / (1 + e)]&gamma;<sub>w</sub>
    </span>
  );
};
export const GammaSat7 = () => {
  /* return <MathJax>{'\\(\\gamma_{sat} = \\gamma_{dry}+n\\gamma_w \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>sat</sub> = &gamma;<sub>dry</sub> + n&gamma;<sub>w</sub>
    </span>
  );
};
export const GammaSat8 = () => {
  /* return <MathJax>{'\\(\\gamma_{sat} = \\left( 1-\\frac{1}{G_s} \\right)\\gamma_{dry}+\\gamma_w \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>sat</sub> = (1 - 1 / G<sub>s</sub>)&gamma;<sub>dry</sub> + &gamma;<sub>w</sub>
    </span>
  );
};
export const GammaSat9 = () => {
  /* return <MathJax>{'\\(\\gamma_{sat} = \\gamma_{dry}(1+w) \\)'}</MathJax>; */
  return (
    <span>
      &gamma;<sub>sat</sub> = &gamma;<sub>dry</sub>(1 + w)
    </span>
  );
};
