import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';

const style = {
  position: 'absolute',
  minWidth: '400px',

  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 5,
};

const styleCloseIcon = {
  position: 'absolute',
  top: '5px',
  right: '5px',
  cursor: 'pointer',
};

export default function TransitionsModal({ open, setOpen, modalSubscription, setModalSubscription }) {
  const [updateMessage, setUpdateMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUpdateMessage('');
  }, [modalSubscription]);

  const updateSubscription = async (ID, data) => {
    setLoading(true);
    try {
      const response = await axios({
        method: 'PATCH',
        url: `/api/v1/admin/updateSubscription/${ID}`,
        data,
      });
      if (response.status === 200) {
        setUpdateMessage(response.data.message);
      }
    } catch (err) {
      setUpdateMessage(err.response.data.message);
    }
    setLoading(false);
  };

  const handleClose = () => setOpen(false);

  return (
    <div className="transitionModalsSketch">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <CloseRoundedIcon sx={styleCloseIcon} onClick={handleClose} color={'primary'} />
            <div className="userModalContainer">
              <h3>Role</h3>
              <TextField size="small" label="ID" variant="outlined" value={modalSubscription?._id} disabled />
              <TextField
                size="small"
                label="Role"
                variant="outlined"
                value={modalSubscription?.name}
                disabled
                onChange={(e) => {
                  setModalSubscription({ ...modalSubscription, name: e.target.value });
                }}
              />
              <TextField
                size="small"
                label="Description"
                variant="outlined"
                multiline
                rows={3}
                value={modalSubscription?.description}
                onChange={(e) => {
                  setModalSubscription({ ...modalSubscription, description: e.target.value });
                }}
              />
              <h3>Limits</h3>
              <TextField
                size="small"
                label="Calculation Limit"
                variant="outlined"
                value={modalSubscription?.calculationCount}
                onChange={(e) => {
                  setModalSubscription({ ...modalSubscription, calculationCount: e.target.value });
                }}
              />
              <TextField
                size="small"
                label="Save Limit"
                variant="outlined"
                value={modalSubscription?.saveCount}
                onChange={(e) => {
                  setModalSubscription({ ...modalSubscription, saveCount: e.target.value });
                }}
              />
              <FormControl>
                <InputLabel id="active">Calculation Steps</InputLabel>
                <Select
                  size="small"
                  labelId="active"
                  value={modalSubscription?.calculationSteps}
                  label="Calculation Steps"
                  onChange={(e) => {
                    setModalSubscription({ ...modalSubscription, calculationSteps: e.target.value });
                  }}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel id="active">PDF Export</InputLabel>
                <Select
                  size="small"
                  labelId="active"
                  value={modalSubscription?.pdfExport}
                  label="PDF Export"
                  onChange={(e) => {
                    setModalSubscription({ ...modalSubscription, pdfExport: e.target.value });
                  }}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>

              {!(
                modalSubscription?.name === 'admin' ||
                modalSubscription?.name === 'support' ||
                modalSubscription?.name === 'basic'
              ) && (
                <>
                  <h3>Prices</h3>
                  <TextField
                    size="small"
                    label="Monthly Price (Cents)"
                    variant="outlined"
                    value={modalSubscription?.priceMonthly}
                    onChange={(e) => {
                      setModalSubscription({ ...modalSubscription, priceMonthly: e.target.value });
                    }}
                  />
                </>
              )}

              <Button
                disabled={loading}
                variant="contained"
                onClick={() => {
                  updateSubscription(modalSubscription._id, modalSubscription);
                }}
              >
                {loading ? 'Updating...' : 'Update Role'}
              </Button>
              {updateMessage && <div className="AdminUserInfo">{updateMessage}</div>}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
