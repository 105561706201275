import React from 'react';
import round from '../../../helpers/round';
import TextField from '@mui/material/TextField';

const ConsolidationResult = ({ data, result }) => {
  const { cohesion, phi, df, fndWidth, gamma, fndType, gwt, FsSt, FsSs, FSt, FSs } = data;

  const {
    nq,
    nc,
    ngamma,
    sc,
    sgamma,
    effectiveStress,
    h,
    gammaEffective,
    qUlt,
    qAllSt,
    qAllSs,
    isQAllStEnough,
    isQAllSsEnough,
  } = result;

  return (
    <section className="output">
      <h2>Output </h2>
      <div className="disabled-inputs">
        <TextField
          style={{ minWidth: 80, maxWidth: 120 }}
          size="small"
          label={
            <span key={'nc'}>
              N<sub>c</sub>
            </span>
          }
          variant="outlined"
          readOnly
          disabled={true}
          value={round(nc, 2)}
        />
        <TextField
          style={{ minWidth: 80, maxWidth: 120 }}
          size="small"
          label={
            <span key={'nq'}>
              N<sub>q</sub>
            </span>
          }
          variant="outlined"
          readOnly
          disabled={true}
          value={round(nq, 2)}
        />
        <TextField
          style={{ minWidth: 80, maxWidth: 120 }}
          size="small"
          label={
            <span key={'ngamma'}>
              N<sub>&gamma;</sub>
            </span>
          }
          variant="outlined"
          readOnly
          disabled={true}
          value={round(ngamma, 2)}
        />
        <TextField
          style={{ minWidth: 80, maxWidth: 120 }}
          size="small"
          label={
            <span key={'sc'}>
              s<sub>c</sub>
            </span>
          }
          variant="outlined"
          readOnly
          disabled={true}
          value={round(sc, 2)}
        />
        <TextField
          style={{ minWidth: 80, maxWidth: 120 }}
          size="small"
          label={
            <span key={'sgamma'}>
              s<sub>&gamma;</sub>
            </span>
          }
          variant="outlined"
          readOnly
          disabled={true}
          value={round(sgamma, 2)}
        />
      </div>
      <div className="formula-group">
        <div className="formulaBox">
          q<sub>ult</sub> = c N<sub>c</sub> s<sub>c</sub> + q N<sub>q</sub> + 0.5 &gamma;
          <sub>e</sub> B N<sub>&gamma;</sub> s<sub>&gamma;</sub>
        </div>
        <div className="formulaBox">
          q<sub>ult</sub> = ({round(cohesion, 2)})({round(nc, 2)})({round(sc, 2)}) + (
          {round(effectiveStress, 2)})({round(nq, 2)}) + (0.5)({round(gammaEffective, 2)})(
          {round(fndWidth, 2)})({round(ngamma, 2)})({round(sgamma, 2)})
        </div>
        <div className="formulaBox">
          q<sub>ult</sub> = {round(qUlt, 0)} kPa
        </div>
      </div>

      <div className="formula-group">
        <div className="formulaBox">
          q<sub>all - st</sub> = q<sub>ult</sub> / FS<sub>st</sub>
        </div>
        <div className="formulaBox">
          q<sub>all - st</sub> = {round(qUlt, 0)} / {round(FsSt, 2)}
        </div>
        <div className="formulaBox">
          q<sub>all - st</sub> = {round(qAllSt, 0)} kPa{' '}
          {isQAllStEnough ? (
            <span className="enough"> (&#10004;)</span>
          ) : (
            <span className="not-enough"> (&#10006;)</span>
          )}
        </div>
      </div>

      <div className="formula-group">
        <div className="formulaBox">
          q<sub>all - ss</sub> = q<sub>ult</sub> / FS<sub>ss</sub>
        </div>
        <div className="formulaBox">
          q<sub>all - ss</sub> = {round(qUlt, 0)} / {round(FsSs, 2)}
        </div>
        <div className="formulaBox">
          q<sub>all - ss</sub> = {round(qAllSs, 0)} kPa{' '}
          {isQAllSsEnough ? (
            <span className="enough"> (&#10004;)</span>
          ) : (
            <span className="not-enough">(&#10006;)</span>
          )}
        </div>
      </div>
    </section>
  );
};

export default ConsolidationResult;
