import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';

const style = {
  position: 'absolute',
  minWidth: '600px',

  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 5,
};

const styleCloseIcon = {
  position: 'absolute',
  top: '5px',
  right: '5px',
  cursor: 'pointer',
};

export default function TransitionsModal({ open, setOpen, modalUser, setModalUser }) {
  const [updateMessage, setUpdateMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [messageClass, setMessageClass] = useState('');

  useEffect(() => {
    setUpdateMessage('');
  }, [modalUser]);

  const updateUser = async (ID, data) => {
    setLoading(true);
    try {
      const response = await axios({
        method: 'PATCH',
        url: `/api/v1/admin/updateUser/${ID}`,
        data,
      });
      if (response.status === 200) {
        setMessageClass('success');
        setUpdateMessage(response.data.message);
      }
    } catch (err) {
      setMessageClass('fail');
      setUpdateMessage(err.response.data.message);
    }
    setLoading(false);
  };

  const handleClose = () => setOpen(false);

  return (
    <div className="transitionModalsSketch">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <CloseRoundedIcon sx={styleCloseIcon} onClick={handleClose} color={'primary'} />
            <div className="userModalContainer">
              <div className="userModalContainer-wrapper">
                <div className="userModalContainer-Left">
                  <h3>User Details</h3>
                  <TextField size="small" label="ID" variant="outlined" value={modalUser?._id} disabled />
                  <TextField
                    size="small"
                    label="Name"
                    variant="outlined"
                    value={modalUser?.name}
                    onChange={(e) => {
                      setModalUser({ ...modalUser, name: e.target.value });
                    }}
                  />
                  <TextField
                    size="small"
                    label="Email"
                    variant="outlined"
                    value={modalUser?.email}
                    onChange={(e) => {
                      setModalUser({ ...modalUser, email: e.target.value });
                    }}
                  />
                  <FormControl>
                    <InputLabel id="active">Email Confirmed?</InputLabel>
                    <Select
                      size="small"
                      labelId="active"
                      value={modalUser?.emailConfirmed}
                      label="Email Confirmed?"
                      onChange={(e) => {
                        setModalUser({ ...modalUser, emailConfirmed: e.target.value });
                      }}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel id="active">Role</InputLabel>
                    <Select
                      size="small"
                      labelId="active"
                      value={modalUser?.role}
                      label="Active ?"
                      onChange={(e) => {
                        setModalUser({ ...modalUser, role: e.target.value });
                      }}
                    >
                      <MenuItem value={'basic'}>Basic</MenuItem>
                      <MenuItem value={'standard'}>Standard</MenuItem>
                      <MenuItem value={'premium'}>Premium</MenuItem>
                      <MenuItem value={'professional'}>Professional</MenuItem>
                      <MenuItem value={'admin'}>Admin</MenuItem>
                      <MenuItem value={'support'}>Support</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel id="active">Is Sub Give Away?</InputLabel>
                    <Select
                      size="small"
                      labelId="active"
                      value={modalUser?.isSubGiveAway}
                      label="Is sub give away?"
                      onChange={(e) => {
                        setModalUser({ ...modalUser, isSubGiveAway: e.target.value });
                      }}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>
                  <h3>User Usage Details</h3>
                  <TextField
                    size="small"
                    label="Calculation Count"
                    variant="outlined"
                    value={modalUser?.totalCount.calculationCount}
                    onChange={(e) => {
                      setModalUser({
                        ...modalUser,
                        totalCount: { ...modalUser.totalCount, calculationCount: e.target.value },
                      });
                    }}
                  />
                  <TextField
                    size="small"
                    label="Save Count"
                    variant="outlined"
                    value={modalUser?.totalCount.saveCount}
                    onChange={(e) => {
                      setModalUser({
                        ...modalUser,
                        totalCount: { ...modalUser.totalCount, saveCount: e.target.value },
                      });
                    }}
                  />
                </div>
                <div className="userModalContainer-Right">
                  <h3>User Time Details</h3>
                  <TextField
                    size="small"
                    label="CreatedAt"
                    variant="outlined"
                    value={modalUser?.createdAt || ''}
                    onChange={(e) => {
                      setModalUser({ ...modalUser, createdAt: e.target.value });
                    }}
                  />
                  <TextField
                    size="small"
                    label="RoleChangedAt"
                    variant="outlined"
                    value={modalUser?.roleChangedAt || ''}
                    onChange={(e) => {
                      setModalUser({ ...modalUser, roleChangedAt: e.target.value });
                    }}
                  />
                  <FormControl>
                    <InputLabel id="active">Is Patreon Sub Cancelled?</InputLabel>
                    <Select
                      size="small"
                      labelId="active"
                      value={modalUser?.isPatreonSubCancelled}
                      label="Is Patreon Sub Cancelled?"
                      onChange={(e) => {
                        setModalUser({ ...modalUser, isPatreonSubCancelled: e.target.value });
                      }}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    size="small"
                    label="Patreon Sub Will Be Cancelled At"
                    variant="outlined"
                    value={modalUser?.patreonSubWillBeCancelledAt || ''}
                    onChange={(e) => {
                      setModalUser({ ...modalUser, patreonSubWillBeCancelledAt: e.target.value });
                    }}
                  />
                  <h3>User Actions</h3>
                  <FormControl>
                    <InputLabel id="active">Active</InputLabel>
                    <Select
                      size="small"
                      labelId="active"
                      value={modalUser?.active}
                      label="Active"
                      onChange={(e) => {
                        setModalUser({ ...modalUser, active: e.target.value });
                      }}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <Button
                disabled={loading}
                variant="contained"
                onClick={() => {
                  updateUser(modalUser._id, modalUser);
                }}
              >
                {loading ? 'Updating...' : 'Update User'}
              </Button>
              {updateMessage && (
                <div className="AdminUserInfo">
                  <span className={messageClass}>{updateMessage}</span>
                </div>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
