import React, { useEffect, useState, useContext, forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import Header from '../../common/Header';
import Footer from '../../common/Footer';
import CalculationLinkBox from './CalculationLinkBox';
import SearchBoxHomePage from './SearchBoxHomePage';
import { convertFirstCharacterAllWordsToUppercase } from '../../../helpers/convertStringToUpperLowerCase';
import { calculationsData } from './calculationBoxData';
import { Context } from '../../../App';

const HomePage = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Home';
  }, []);
  const { user, lastpage, setLastpage } = useContext(Context);
  const [filteredCalculations, setFilteredCalculations] = useState([]);
  const [matchingResult, setMatchingResult] = useState(false);
  const [loadingFavorites, setLoadingFavorites] = useState(false);
  //Sncakbar
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} variant="standard" {...props} />;
  });
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  //Handle welcome message for the user
  useEffect(() => {
    if (user && (lastpage === './login' || lastpage === './signup')) {
      setAlertMessage(`Welcome ${convertFirstCharacterAllWordsToUppercase(user.name)}. Happy calculating :)`);
      setOpen(true);
    }
    setLastpage('');
  }, [user, lastpage, setAlertMessage, setLastpage, setOpen]);

  //Handle favorites
  const [favorites, setFavorites] = useState([]);
  const [checked, setChecked] = useState(false);

  const handleFavorite = (e, id) => {
    if (e.target.tagName === 'svg' || e.target.tagName === 'path') e.preventDefault();

    if (!favorites.includes(id) && id !== -1) {
      setFavorites((favorites) => [...favorites, id]);
    } else if (id !== -1) {
      setFavorites(favorites.filter((el) => el !== id));
    }
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (!user) return;

    let source;
    if (source) source.cancel();
    source = axios.CancelToken.source();

    const getFavoriteCalculations = async () => {
      setLoadingFavorites(true);
      try {
        const response = await axios.get('/api/v1/calculations/getFavorites', {
          cancelToken: source.token,
        });

        setFavorites(response.data.data.favoriteCalculations.calculations);
        setChecked(response.data.data.favoriteCalculations.show);
      } catch (error) {
        if (axios.isCancel(error)) {
          /* console.log('error getFavoriteCalculations', error); */
        } else {
          throw error;
        }
      }
      setLoadingFavorites(false);
    };
    getFavoriteCalculations();
    return () => {
      source.cancel();
    };
  }, [user]);

  useEffect(() => {
    if (!user) return;
    let source;
    if (source) source.cancel();
    source = axios.CancelToken.source();

    const setFavoriteCalculations = async () => {
      try {
        await axios({
          method: 'POST',
          url: '/api/v1/calculations/setFavorites',
          cancelToken: source.token,
          data: { calculations: favorites, show: checked },
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          /* console.log('error setFavoriteCalculations', error); */
        } else {
          throw error;
        }
      }
    };
    if (favorites.length !== 0) {
      setFavoriteCalculations();
    } else {
      setTimeout(() => {
        setFavoriteCalculations();
      }, 1000);
    }
    return () => {
      source.cancel();
    };
  }, [favorites, checked, user]);

  const [favoritesMatchingResult, setFavoritesMatchingResult] = useState();
  useEffect(() => {
    const test = filteredCalculations.filter((el) => {
      return favorites.includes(el.id);
    });

    if (test.length > 0) {
      setFavoritesMatchingResult(false);
    } else {
      setFavoritesMatchingResult(true);
    }
    if (favorites.length === 0) setChecked(false);
  }, [filteredCalculations, favorites]);

  return (
    <>
      <Header />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {user && user?.emailConfirmed === false && (
        <Alert severity="warning">
          <AlertTitle>
            <strong>Confirm your email</strong>
          </AlertTitle>
          <span>
            You have to confirm your email to use the calculation tools below. We have already sent you an email about this. If
            the email did not reach you or 24 hours passed after you got the email, you can resend it from your{' '}
            <Link to={'/account'}>
              <span>'Account'</span>
            </Link>{' '}
            page.
          </span>
        </Alert>
      )}
      <div className={`${loadingFavorites ? 'verticallyAlignParent' : 'homepage'}`}>
        {!loadingFavorites && (
          <SearchBoxHomePage
            calculationsData={calculationsData}
            setFilteredCalculations={setFilteredCalculations}
            setMatchingResult={setMatchingResult}
          />
        )}

        {user && !loadingFavorites && (
          <FormControlLabel control={<Switch checked={checked} onChange={handleChange} />} label="Show Favorites" />
        )}

        {loadingFavorites && <CircularProgress />}
        {!loadingFavorites && (
          <div className="hompage-links-container">
            {checked
              ? filteredCalculations.map((el) => {
                  return (
                    //Render favorite calculations if checked
                    favorites.includes(el.id) && (
                      <CalculationLinkBox
                        key={`${el.title} - ${el.description}`}
                        el={el}
                        handleFavorite={handleFavorite}
                        favorites={favorites}
                        user={user}
                      />
                    )
                  );
                })
              : filteredCalculations.map((el) => {
                  return (
                    //Render all available calculations if  not checked
                    el.id !== -1 && (
                      <CalculationLinkBox
                        key={`${el.title} - ${el.description}`}
                        el={el}
                        handleFavorite={handleFavorite}
                        favorites={favorites}
                        user={user}
                      />
                    )
                  );
                })}
            {!checked &&
              filteredCalculations.map((el) => {
                return (
                  //Render also 'Coming Soon' calculations if not checked
                  el.id === -1 && (
                    <CalculationLinkBox
                      key={`${el.title} - ${el.description}`}
                      el={el}
                      handleFavorite={handleFavorite}
                      favorites={favorites}
                      user={user}
                    />
                  )
                );
              })}
            {!checked && matchingResult && <div className="no-match-warning">No matching result for your query.</div>}

            {checked && favoritesMatchingResult && (
              <div className="no-match-warning">You have no favorite calculations for your query.</div>
            )}
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default HomePage;
