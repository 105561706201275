import React, { useState, useEffect } from 'react';
import axios from 'axios';

import SignupVsTime from './SignUpVsTime';
import PaidVsTime from './PaidVsTime';
import UserSummary from './UserSummary';
import RoleSummary from './RoleSummary';
import OperationSummary from './OperationSummary';
import GiveawaySummary from './GiveawaySummary';
import PaidUsersSummary from './PaidUsersSummary';

const AdminDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [userCounts, setUserCounts] = useState({});

  useEffect(() => {
    const getAllUsers = async () => {
      setLoading(true);
      setUserCounts({});
      try {
        const response = await axios({
          method: 'GET',
          url: '/api/v1/admin/getAllUsers',
        });

        setUserCounts(response.data.results);
      } catch (err) {
        console.log('Error->', err.response.data.message);
      }
      setLoading(false);
    };
    getAllUsers();
  }, []);

  return (
    <div className="adminDashboard">
      <h1>User Dashboard</h1>
      <div className="adminDashboardContainer">
        <div className="flex">
          <UserSummary loading={loading} userCounts={userCounts} />
          <RoleSummary loading={loading} userCounts={userCounts} />
          <PaidUsersSummary loading={loading} userCounts={userCounts} />

          <GiveawaySummary loading={loading} userCounts={userCounts} />
          <OperationSummary loading={loading} userCounts={userCounts} />
        </div>
        <div className="flex">
          <SignupVsTime />
          <PaidVsTime />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
