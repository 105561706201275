import React, { useEffect, useState, forwardRef } from 'react';
import MuiAlert from '@mui/material/Alert';
import round from '../../../helpers/round';
import f1Grapgh from '../../../assets/calculations/stroud/f1.JPG';
import f2Grapgh from '../../../assets/calculations/stroud/f2.JPG';

const StroudResult = ({ result }) => {
  const { pi, spt } = result.input;
  const { f1, f2, cu, mv } = result.output;

  //f1 Graph Coordinates
  const [f1VerticalLeft, setf1VerticalLeft] = useState('');
  const [f1VerticalHeight, setf1VerticalHeight] = useState('');
  const [f1HorizontalWidth, setf1HorizontalWidth] = useState('');
  const [f1HorizontalBottom, setf1HorizontalBottom] = useState('');

  //f2 Graph Coordinates
  const [f2VerticalLeft, setf2VerticalLeft] = useState('');
  const [f2VerticalHeight, setf2VerticalHeight] = useState('');
  const [f2HorizontalWidth, setf2HorizontalWidth] = useState('');
  const [f2HorizontalBottom, setf2HorizontalBottom] = useState('');

  const [isPIForf1_OK, setIsPIForf1_OK] = useState(Boolean);
  const [isPIForf2_OK, setIsPIForf2_OK] = useState(Boolean);

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} {...props} />;
  });

  //Graph line coordinates
  useEffect(() => {
    pi >= 15 && pi <= 64 ? setIsPIForf1_OK(true) : setIsPIForf1_OK(false);
    pi >= 12 && pi <= 65 ? setIsPIForf2_OK(true) : setIsPIForf2_OK(false);
    //f1 Graph Coordinates
    setf1VerticalLeft(47.5 + (pi * (283.5 - 47.5)) / 70);
    setf1VerticalHeight((f1 * (216.8 - 40.5)) / 10);
    setf1HorizontalWidth(2 + (pi * (283.5 - 47.5)) / 70);
    setf1HorizontalBottom(40.5 + (f1 * (216.8 - 40.5)) / 10);

    //f2 Graph Coordinates
    setf2VerticalLeft(50 + (pi * (282 - 50)) / 70);
    setf2VerticalHeight((f2 * (209 - 38.5)) / 1000);
    setf2HorizontalWidth(2 + (pi * (282 - 50)) / 70);
    setf2HorizontalBottom(39 + (f2 * (209 - 39)) / 1000);
  }, [pi, f1, f2]);

  return (
    <section className="output">
      <h2>Output </h2>
      <div className="stroudContainer">
        <div className="stroudBox">
          <div className="stroudf1GraphContainer">
            {isPIForf1_OK && (
              <>
                <div
                  className="stroudf1VerticalLine"
                  style={{ height: `${f1VerticalHeight}px`, left: `${f1VerticalLeft}px` }}
                ></div>
                <div
                  className="stroudf1HorizontalLine"
                  style={{ width: `${f1HorizontalWidth}px`, bottom: `${f1HorizontalBottom}px` }}
                ></div>
                <div className="f1Value" style={{ bottom: `${f1HorizontalBottom + 5}px` }}>
                  f<sub>1</sub>={round(f1, 2)}
                </div>
              </>
            )}

            <img src={f1Grapgh} alt="stroud grapgh for f1"></img>

            {!isPIForf1_OK && (
              <Alert severity="warning" style={{ width: 300, textAlign: 'left', marginTop: '10px' }}>
                <span>
                  Plasticity Index (PI) provided ({round(pi, 2)}%) is out of the limits of the graph. Results may not be accurate.{' '}
                  <br />
                  <br />
                  For f<sub>1</sub> → valid PI values range between 15% - 64%.
                </span>
              </Alert>
            )}
          </div>
          <p>
            For PI = {round(pi, 2)}% &rarr; f<sub>1</sub>={round(f1, 2)} kN/m<sup>2</sup>
          </p>
          <p>&darr;</p>
          <p>
            c<sub>u</sub> = f<sub>1</sub> x N<sub>(60)</sub>
          </p>
          <p>&darr;</p>
          <p>
            c<sub>u</sub> = {round(f1, 2)} x {round(spt, 2)}
          </p>
          <p>&darr;</p>
          <p>
            c<sub>u</sub> = {round(cu, 2)} kN/m<sup>2</sup>
          </p>
        </div>
        <div className="stroudBox">
          <div className="stroudf2GraphContainer">
            {isPIForf2_OK && (
              <>
                <div
                  className="stroudf2VerticalLine"
                  style={{ height: `${f2VerticalHeight}px`, left: `${f2VerticalLeft}px` }}
                ></div>
                <div
                  className="stroudf2HorizontalLine"
                  style={{ width: `${f2HorizontalWidth}px`, bottom: `${f2HorizontalBottom}px` }}
                ></div>
                <div className="f2Value" style={{ bottom: `${f2HorizontalBottom + 5}px` }}>
                  f<sub>2</sub>={round(f2, 2)}
                </div>
              </>
            )}

            <img src={f2Grapgh} alt="stroud grapgh for f2"></img>
            {!isPIForf2_OK && (
              <Alert severity="warning" style={{ width: 300, textAlign: 'left', marginTop: '10px' }}>
                <span>
                  Plasticity Index (PI) provided ({round(pi, 2)}%) is out of the limits of the graph. Results may not be accurate.{' '}
                  <br />
                  <br />
                  For f<sub>2</sub> → valid PI values range between 12% - 65%.
                </span>
              </Alert>
            )}
          </div>
          <p>
            For PI = {round(pi, 2)}% &rarr; f<sub>2</sub>={round(f2, 2)} kN/m<sup>2</sup>
          </p>
          <p>&darr;</p>
          <p>
            m<sub>v</sub> = 1 / (f<sub>1</sub> x N<sub>(60)</sub>)
          </p>
          <p>&darr;</p>
          <p>
            m<sub>v</sub> = 1 / ({round(f2, 2)} x {round(spt, 2)})
          </p>
          <p>&darr;</p>
          {spt === 0 ? (
            <Alert severity="warning" style={{ width: 300, textAlign: 'left', marginTop: '10px' }}>
              <span>Please provide a non-zero spt value!</span>
            </Alert>
          ) : (
            <p>
              m<sub>v</sub> = {round(mv, 8)} m<sup>2</sup>/kN
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default StroudResult;
