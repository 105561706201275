//----Gamma Moist Formulas
import round from '../../../helpers/round';

export const Gamma1Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma; = [(1 + w)G<sub>s</sub> &gamma;<sub>w</sub>] / (1 + e)
      </p>
      <p>
        &gamma; = [(1+{round(result.input.w, 3)})({round(result.input.gs, 3)})({round(result.output.gammaWater, 3)})] / (1 + (
        {round(result.input.e, 3)}))
      </p>
      <p>
        &gamma; = {round(result.output.outputGammaMoist, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};

export const Gamma2Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma; = [(G<sub>s</sub> + Se)&gamma;<sub>w</sub> ] / (1 + e)
      </p>
      <p>
        &gamma; = [(({round(result.input.gs, 3)}) + ({round(result.input.s, 3)})({round(result.input.e, 3)})) (
        {round(result.output.gammaWater, 3)}) ] / (1 + ({round(result.input.e, 3)}))
      </p>
      <p>
        &gamma; = {round(result.output.outputGammaMoist, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};
export const Gamma3Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma; = [(1 + w)G<sub>s</sub>&gamma;<sub>w</sub>] / [(1 + wG<sub>s</sub> / S)]
      </p>
      <p>
        &gamma; = [(1 + {round(result.input.w, 3)})({round(result.input.gs, 3)})({round(result.output.gammaWater, 3)})] / [(1 + (
        {round(result.input.w, 3)})({round(result.input.gs, 3)}) / {round(result.input.s, 3)})]
      </p>
      <p>
        &gamma; = {round(result.output.outputGammaMoist, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};
export const Gamma4Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma; = G<sub>s</sub>&gamma;<sub>w</sub>(1 - n)(1 + w)
      </p>
      <p>
        &gamma; = ({round(result.input.gs, 3)})({round(result.output.gammaWater, 3)})(1 - {round(result.input.n, 3)})(1 +{' '}
        {round(result.input.w, 3)})
      </p>
      <p>
        &gamma; = {round(result.output.outputGammaMoist, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};
export const Gamma5Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma; = G<sub>s</sub>&gamma;<sub>w</sub>(1 - n) + nS&gamma;<sub>w</sub>
      </p>
      <p>
        &gamma; = ({round(result.input.gs, 3)})({round(result.output.gammaWater, 3)})(1 - {round(result.input.n, 3)}) + (
        {round(result.input.n, 3)})({round(result.input.s, 3)})({round(result.output.gammaWater, 3)})
      </p>
      <p>
        &gamma; = {round(result.output.outputGammaMoist, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};

//----Gamma Dry Formulas
export const GammaDry1Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>dry</sub> = &gamma; / (1 + w)
      </p>
      <p>
        &gamma;<sub>dry</sub> = {round(result.input.gammaMoist, 3)} / (1 + {round(result.input.w, 3)})
      </p>
      <p>
        &gamma;<sub>dry</sub> = {round(result.output.outputGammaDry, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};
export const GammaDry2Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>dry</sub> = (G<sub>s</sub>&gamma;<sub>w</sub>) / (1 + e)
      </p>
      <p>
        &gamma;<sub>dry</sub> = ({round(result.input.gs, 3)} x {round(result.output.gammaWater, 3)}) / (1 +{' '}
        {round(result.input.e, 3)})
      </p>
      <p>
        &gamma;<sub>dry</sub> = {round(result.output.outputGammaDry, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};
export const GammaDry3Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>dry</sub> = G<sub>s</sub>&gamma;<sub>w</sub>(1 - n)
      </p>
      <p>
        &gamma;<sub>dry</sub> = ({round(result.input.gs, 3)}) ({round(result.output.gammaWater, 3)}) (1 -{' '}
        {round(result.input.n, 3)})
      </p>
      <p>
        &gamma;<sub>dry</sub> = {round(result.output.outputGammaDry, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};
export const GammaDry4Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>dry</sub> = (G<sub>s</sub>&gamma;<sub>w</sub>) / (1 + wG<sub>s</sub> / S)
      </p>
      <p>
        &gamma;<sub>dry</sub> = ({round(result.input.gs, 3)} x {round(result.output.gammaWater, 3)}) / (1 +{' '}
        {round(result.input.w, 3)} x {round(result.input.gs, 3)} / {round(result.input.s, 3)})
      </p>
      <p>
        &gamma;<sub>dry</sub> = {round(result.output.outputGammaDry, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};
export const GammaDry5Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>dry</sub> = (eS&gamma;<sub>w</sub>) / [(1 + e)w]
      </p>
      <p>
        &gamma;<sub>dry</sub> = ({round(result.input.e, 3)} x {round(result.input.s, 3)} x {round(result.output.gammaWater, 3)}) /
        [(1 + {round(result.input.e, 3)}) x {round(result.input.w, 3)}]
      </p>
      <p>
        &gamma;<sub>dry</sub> = {round(result.output.outputGammaDry, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};

export const GammaDry6Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>dry</sub> = &gamma;<sub>sat</sub> - (e&gamma;<sub>w</sub>) / (1 + e)
      </p>
      <p>
        &gamma;<sub>dry</sub> = {round(result.input.gammaSat, 3)} - ({round(result.input.e, 3)} x{' '}
        {round(result.output.gammaWater, 3)}) / (1 + {round(result.input.e, 3)})
      </p>
      <p>
        &gamma;<sub>dry</sub> = {round(result.output.outputGammaDry, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};
export const GammaDry7Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>dry</sub> = &gamma;<sub>sat</sub> - n&gamma;<sub>w</sub>
      </p>
      <p>
        &gamma;<sub>dry</sub> = {round(result.input.gammaSat, 3)} - {round(result.input.e, 3)} x{' '}
        {round(result.output.gammaWater, 3)}
      </p>
      <p>
        &gamma;<sub>dry</sub> = {round(result.output.outputGammaDry, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};
export const GammaDry8Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>dry</sub> = G<sub>s</sub>(&gamma;<sub>sat</sub> - &gamma;<sub>w</sub>) / (G<sub>s</sub> - 1)
      </p>
      <p>
        &gamma;<sub>dry</sub> = {round(result.input.gs, 3)} x ({round(result.input.gammaSat, 3)} -{' '}
        {round(result.output.gammaWater, 3)}) / ({round(result.input.gs, 3)} - 1)
      </p>
      <p>
        &gamma;<sub>dry</sub> = {round(result.output.outputGammaDry, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};

//----Gamma Sat Formulas
export const GammaSat1Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>sat</sub> = (G<sub>s</sub> + e)&gamma;<sub>w</sub> / (1 + e)
      </p>
      <p>
        &gamma;<sub>sat</sub> = ({round(result.input.gs, 3)} + {round(result.input.e, 3)}) x {round(result.output.gammaWater, 3)}{' '}
        / (1 + {round(result.input.e, 3)})
      </p>
      <p>
        &gamma;<sub>sat</sub> = {round(result.output.outputGammaSat, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};

export const GammaSat2Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>sat</sub> = [(1 - n)G<sub>s</sub> + n]&gamma;<sub>w</sub>
      </p>
      <p>
        &gamma;<sub>sat</sub> = [(1 - {round(result.input.n, 3)}) x {round(result.input.gs, 3)} + {round(result.input.n, 3)}] x{' '}
        {round(result.output.gammaWater, 3)}
      </p>
      <p>
        &gamma;<sub>sat</sub> = {round(result.output.outputGammaSat, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};

export const GammaSat3Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>sat</sub> = [(1 + w) / (1 + wG<sub>s</sub>)]G<sub>s</sub>&gamma;<sub>w</sub>
      </p>
      <p>
        &gamma;<sub>sat</sub> = [(1 + {round(result.input.w, 3)}) / (1 + {round(result.input.w, 3)} x {round(result.input.gs, 3)}
        )] x {round(result.input.gs, 3)} x {round(result.output.gammaWater, 3)}
      </p>
      <p>
        &gamma;<sub>sat</sub> = {round(result.output.outputGammaSat, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};

export const GammaSat4Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>sat</sub> = (e / w)[(1 + w) / (1 + e)]&gamma;<sub>w</sub>
      </p>
      <p>
        &gamma;<sub>sat</sub> = ({round(result.input.e, 3)} / {round(result.input.w, 3)}) x [(1 + {round(result.input.w, 3)}) / (1
        + {round(result.input.e, 3)})] x {round(result.output.gammaWater, 3)}
      </p>
      <p>
        &gamma;<sub>sat</sub> = {round(result.output.outputGammaSat, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};

export const GammaSat5Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>sat</sub> = [(1 + w) / w]n&gamma;<sub>w</sub>
      </p>
      <p>
        &gamma;<sub>sat</sub> = [(1 + {round(result.input.w, 3)}) / {round(result.input.w, 3)}] x {round(result.input.n, 3)} x{' '}
        {round(result.output.gammaWater, 3)}
      </p>
      <p>
        &gamma;<sub>sat</sub> = {round(result.output.outputGammaSat, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};

export const GammaSat6Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>sat</sub> = &gamma;<sub>dry</sub> + [e / (1 + e)]&gamma;<sub>w</sub>
      </p>
      <p>
        &gamma;<sub>sat</sub> = {round(result.input.gammaDry, 3)} + [{round(result.input.e, 3)} / (1 + {round(result.input.e, 3)}
        )] x {round(result.output.gammaWater, 3)}
      </p>
      <p>
        &gamma;<sub>sat</sub> = {round(result.output.outputGammaSat, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};

export const GammaSat7Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>sat</sub> = &gamma;<sub>dry</sub> + n&gamma;<sub>w</sub>
      </p>
      <p>
        &gamma;<sub>sat</sub> = {round(result.input.gammaDry, 3)} + {round(result.input.e, 3)} x{' '}
        {round(result.output.gammaWater, 3)}
      </p>
      <p>
        &gamma;<sub>sat</sub> = {round(result.output.outputGammaSat, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};

export const GammaSat8Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>sat</sub> = (1 - 1 / G<sub>s</sub>)&gamma;<sub>dry</sub> + &gamma;<sub>w</sub>
      </p>
      <p>
        &gamma;<sub>sat</sub> = (1 - 1 / {round(result.input.gs, 3)}) x {round(result.input.gammaDry, 3)} +{' '}
        {round(result.output.gammaWater, 3)}
      </p>
      <p>
        &gamma;<sub>sat</sub> = {round(result.output.outputGammaSat, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};

export const GammaSat9Result = ({ result }) => {
  return (
    <>
      <p>
        &gamma;<sub>sat</sub> = &gamma;<sub>dry</sub>(1 + w)
      </p>
      <p>
        &gamma;<sub>sat</sub> = {round(result.input.gammaDry, 3)} x (1 + {round(result.input.w, 3)})
      </p>
      <p>
        &gamma;<sub>sat</sub> = {round(result.output.outputGammaSat, 2)} kN/m<sup>3</sup>
      </p>
    </>
  );
};
