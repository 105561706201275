import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InputAdornment from '@mui/material/InputAdornment';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MuiSnackbar from '../../common/MuiSnackbar';
import CircularProgress from '@mui/material/CircularProgress';

import CalculateButton from '../../common/calculateButton';
import SaveButton from '../../common/saveButton';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Sidebar from '../../sidebar/Sidebar';
import DecisionModal from '../../modals/DecisionModal';
import MeyerhofResult from './/MeyerhofResult';
import meyerhofImage from '../../../assets/calculations/meyerhof/meyerhof.png';
import TransitionsModal from '../../modals/TransitionsModal';

import { useCalculationContext, CalculationContextProvider } from '../../context/calculationContext';

const Meyerhof = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Bearing Capacity of Shallow Foundations - Meyerhof (1963)';
  }, []);

  const {
    MAX_NAME_LENGTH,
    isLoadingCalculate,
    isLoadingLoadAll,
    isLoadingLoad,
    isLoadingSave,
    result,
    calculations,
    lastData,
    showOutput,
    setShowOutput,
    calculateButtonDisabled,
    setCalculateButtonDisabled,
    saveButtonDisabled,
    setSaveButtonDisabled,
    errorMessage,
    displaySidebar,
    setDisplaySidebar,
    decisionModalIsOpen,
    decision,
    setDecision,
    handleSidebarDisplay,
    setDecisionModalIsOpenToFalse,
    showMuiSnack,
    open,
    setOpen,
    alertMessage,
    severity,
    onChangeWarningForPositiveDecimals,
    calculate,
    loadOneCalculation,
    loadAllCalculations,
    update,
    checkCalculationNameforSaving,
    checkCalculationNameforSavingAs,
    deleteCalculations,
    calculationCountReached,
    saveCountReached,
  } = useCalculationContext();

  //SET STATES FOR INPUT VARIABLES
  const [name, setName] = useState('');
  const [cohesion, setCohesion] = useState('');
  const [phi, setPhi] = useState('');
  const [df, setDf] = useState('');
  const [fndWidth, setFndWidth] = useState('');
  const [fndLength, setFndLength] = useState('');
  const [theta, setTheta] = useState('');
  const [gamma, setGamma] = useState('');
  const [gwt, setGwt] = useState('');
  const [FsSt, setFsSt] = useState('');
  const [FsSs, setFsSs] = useState('');
  const [FSt, setFSt] = useState('');
  const [FSs, setFSs] = useState('');

  // SET THE LOADED INPUTS
  const setInputs = (inputs) => {
    setName(inputs.name);
    setCohesion(inputs.cohesion);
    setPhi(inputs.phi);
    setDf(inputs.df);
    setFndWidth(inputs.fndWidth);
    setFndLength(inputs.fndLength);
    setTheta(inputs.theta);
    setGamma(inputs.gamma);
    setGwt(inputs.gwt);
    setFsSt(inputs.FsSt);
    setFsSs(inputs.FsSs);
    setFSt(inputs.FSt);
    setFSs(inputs.FSs);
  };

  //INPUTS FOR CALCULATION
  const calculationInput = {
    name,
    cohesion,
    phi,
    df,
    fndWidth,
    fndLength,
    theta,
    gamma,
    gwt,
    FsSt,
    FsSs,
    FSt,
    FSs,
  };

  // MANAGE SAVE & CALCULATE BUTTONS DISABLED/ENABLED STATES
  useEffect(() => {
    setCalculateButtonDisabled(false);
    setSaveButtonDisabled(false);

    if (
      (calculationInput.name || calculationInput.name === 0) &&
      (calculationInput.cohesion || calculationInput.cohesion === 0) &&
      (calculationInput.phi || calculationInput.phi === 0) &&
      (calculationInput.df || calculationInput.df === 0) &&
      (calculationInput.fndWidth || calculationInput.fndWidth === 0) &&
      (calculationInput.fndLength || calculationInput.fndLength === 0) &&
      (calculationInput.theta || calculationInput.theta === 0) &&
      (calculationInput.gamma || calculationInput.gamma === 0) &&
      (calculationInput.gwt || calculationInput.gwt === 0) &&
      (calculationInput.FsSt || calculationInput.FsSt === 0) &&
      (calculationInput.FsSs || calculationInput.FsSs === 0) &&
      (calculationInput.FSt || calculationInput.FSt === 0) &&
      (calculationInput.FSs || calculationInput.FSs === 0)
    ) {
      setCalculateButtonDisabled(false);
      setSaveButtonDisabled(false);
    } else {
      setCalculateButtonDisabled(true);
      setSaveButtonDisabled(true);
    }
  }, [
    setCalculateButtonDisabled,
    setSaveButtonDisabled,
    calculationInput.name,
    calculationInput.cohesion,
    calculationInput.phi,
    calculationInput.df,
    calculationInput.fndWidth,
    calculationInput.fndLength,
    calculationInput.theta,
    calculationInput.gamma,
    calculationInput.gwt,
    calculationInput.FsSt,
    calculationInput.FsSs,
    calculationInput.FSt,
    calculationInput.FSs,
  ]);

  // DO NOT RENDER OUTPUT IF ANY INPUT PARAMETER EXCEPT NAME CHANGES
  useEffect(() => {
    setShowOutput(false);
  }, [
    setShowOutput,
    calculationInput.cohesion,
    calculationInput.phi,
    calculationInput.df,
    calculationInput.fndWidth,
    calculationInput.fndLength,
    calculationInput.theta,
    calculationInput.gamma,
    calculationInput.gwt,
    calculationInput.FsSt,
    calculationInput.FsSs,
    calculationInput.FSt,
    calculationInput.FSs,
  ]);

  //CLEAR INPUTS AND CLEAR OUTPUT
  const createNewCalculation = () => {
    setName('');
    setCohesion('');
    setPhi('');
    setDf('');
    setFndWidth('');
    setFndLength('');
    setTheta('');
    setGamma('');
    setGwt('');
    setFsSt('');
    setFsSs('');
    setFSt('');
    setFSs('');
    setShowOutput(false);
  };

  //API URLS
  const URL = {
    CALCULATE: '/api/v1/calculations/meyerhof',
    LOADONE: `/api/v1/calculations/meyerhof/loadOne/`,
    LOADALL: '/api/v1/calculations/meyerhof/loadAll',
    UPDATE: `/api/v1/calculations/meyerhof/update/`,
    SAVE: '/api/v1/calculations/meyerhof/save',
    CHECKUNIQUENAME: `/api/v1/calculations/meyerhof/checkUniqueName/`,
    DELETE: '/api/v1/calculations/meyerhof/delete',
  };

  const handleChangeName = (e) => {
    if (e.target.value.length >= MAX_NAME_LENGTH) {
      showMuiSnack(`Calculation name can be max ${MAX_NAME_LENGTH} characters.`, 'warning');
    }
    setName(e.target.value);
  };

  // RETURN INPUT AND OUTPUT JSX
  return (
    <>
      <Header />
      <div className="container">
        <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
        {displaySidebar ? (
          <Sidebar
            URL={URL}
            loadAllCalculations={loadAllCalculations}
            calculations={calculations}
            loadOneCalculation={loadOneCalculation}
            calculate={calculate}
            setInputs={setInputs}
            deleteCalculations={deleteCalculations}
            createNewCalculation={createNewCalculation}
            isLoading={isLoadingLoadAll}
            handleSidebarDisplay={handleSidebarDisplay}
            displaySidebar={displaySidebar}
            setDisplaySidebar={setDisplaySidebar}
          />
        ) : (
          <MenuOpenIcon
            color="primary"
            fontSize="large"
            className="sidebar-open-icon"
            alt="side bar menu icon"
            label="Show my calculations"
            onClick={handleSidebarDisplay}
          />
        )}
        <DecisionModal
          URL={URL}
          data={calculationInput}
          decisionModalIsOpen={decisionModalIsOpen}
          setDecisionModalIsOpenToFalse={setDecisionModalIsOpenToFalse}
          decision={decision}
          setDecision={setDecision}
          checkCalculationNameforSavingAs={checkCalculationNameforSavingAs}
          name={name}
          setName={setName}
          errorMessage={errorMessage}
          update={update}
          saveButtonDisabled={saveButtonDisabled}
        />
        <div className="right-calculation">
          <section className="input">
            <h1>Bearing Capacity of Shallow Foundations - Meyerhof (1963)</h1>

            <form>
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label="Name"
                variant="outlined"
                inputProps={{
                  maxLength: `${MAX_NAME_LENGTH}`,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={`Your calculation name (Max. ${MAX_NAME_LENGTH} characters)`}
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={name}
                onChange={handleChangeName}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label="c (kPa)"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Cohesion" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={cohesion}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Cohesion', setCohesion)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'phi'}>
                    &phi; (<sup>o</sup>)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Internal friction angle" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={phi}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Phi', setPhi)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'df'}>
                    D<sub>f</sub> (m)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Depth of foundation" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={df}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Df', setDf)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={'B (m)'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Foundation width" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={fndWidth}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Foundation Width', setFndWidth)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={'L (m)'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Foundation length" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={fndLength}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Foundation Length', setFndLength)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'theta'}>
                    &theta; (<sup>o</sup>)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Angle of force" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={theta}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Theta', setTheta)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label={
                  <span key={'gamma'}>
                    &gamma; (kN/m<sup>3</sup>)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Natural unit weight of soil" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={gamma}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Gamma', setGamma)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={'GWT (m)'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Ground water level measured from ground surface"
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={gwt}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'GWT', setGwt)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label={'FsSt'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Factor of safety (Static)" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={FsSt}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'FsSt', setFsSt)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label={'FsSs'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Factor of safety (Seismic)" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={FsSs}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'FsSs', setFsSs)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label={'FSt(kPa)'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Static load" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={FSt}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'FSt', setFSt)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label={'FSs(kPa)'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Seismic load" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={FSs}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'FSs', setFSs)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TransitionsModal
                image={meyerhofImage}
                title="Meyerhof (1963)"
                description={'Soil parameters and dimensions are shown on the sketch'}
              ></TransitionsModal>

              <div className="buttonContainer">
                <CalculateButton
                  calculationCountReached={calculationCountReached}
                  calculate={calculate}
                  calculationInput={calculationInput}
                  URL={URL}
                  calculateButtonDisabled={calculateButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
                {isLoadingLoad && <CircularProgress size={30} />}

                <SaveButton
                  saveCountReached={saveCountReached}
                  checkCalculationNameforSaving={checkCalculationNameforSaving}
                  calculationInput={calculationInput}
                  URL={URL}
                  saveButtonDisabled={saveButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
              </div>
            </form>
          </section>
          {showOutput && <MeyerhofResult data={lastData} result={result} />}
          {isLoadingCalculate && (
            <div className="centerLoading">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

const MeyerhofWithProvider = () => {
  return (
    <CalculationContextProvider>
      <Meyerhof />
    </CalculationContextProvider>
  );
};
export default MeyerhofWithProvider;
