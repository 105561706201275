import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import round from '../../../helpers/round';
import MuiSnackbar from '../../common/MuiSnackbar';

const InputLayer = ({ layer, updateLayer, isLoadingSave, isLoadingCalculate, isLoadingLoad }) => {
  const regexForPositiveDecimals = /^[+]?\d*(?:[.]\d*)?$/;

  const onChangeWarningForPositiveDecimals = (e, variable, variableSetter) => {
    if (regexForPositiveDecimals.test(e.target.value)) {
      variableSetter(e);
    } else {
      showMuiSnack(`${variable}: Can only be a postive number. `, 'warning');
    }
  };

  const changeZ2 = (e) => {
    updateLayer('z2', e.target.value);
  };

  const changeFormation = (e) => {
    updateLayer('formation', e.target.value);
  };

  const changeC = (e) => {
    updateLayer('c', e.target.value);
  };

  const changePhi = (e) => {
    updateLayer('phi', e.target.value);
  };
  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const showMuiSnack = (message, type) => {
    setAlertMessage(message);
    setSeverity(type);
    setOpen(true);
  };

  return (
    <div className="layer layered-soil-layer">
      <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
      {/* INPUTS IN LAYERS */}
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 100 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        inputProps={{ readOnly: true }}
        value={layer.id}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 140 }}
        size="small"
        variant="outlined"
        value={layer.formation}
        onChange={changeFormation}
        disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 75 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        inputProps={{ readOnly: true }}
        value={layer.z1}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 75 }}
        size="small"
        variant="outlined"
        value={layer.z2}
        onChange={(e) => onChangeWarningForPositiveDecimals(e, 'z2', changeZ2)}
        disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 75 }}
        size="small"
        variant="outlined"
        className="layeredDisabledInput"
        disabled
        inputProps={{ readOnly: true }}
        value={layer.hi > 0 ? layer.hi : ''}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 75 }}
        size="small"
        variant="outlined"
        value={layer.c}
        onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Cohesion', changeC)}
        disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
      />
      <TextField
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        style={{ minWidth: 75 }}
        size="small"
        variant="outlined"
        value={layer.phi}
        onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Friction Angle', changePhi)}
        disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
      />
    </div>
  );
};

export default InputLayer;
