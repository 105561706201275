import React from 'react';
import round from '../../../helpers/round';

const PilerocksettleResult = ({ data, result }) => {
  const { Q, socketLength, socketDiameter, ec, em, ips } = data;
  const { pileTipArea, settlementM, settlementMm } = result;

  return (
    <section className="output pilerocksettle">
      <h2>Output</h2>
      <div>
        <p>
          <math display="block">
            <msub>
              <mi>&rho;</mi>
              <mi>s</mi>
            </msub>
            <mo>=</mo>
            <mi>Q</mi>
            <mo>[</mo>
            <mfrac>
              <mrow>
                <msub>
                  <mi>I</mi>
                  <mi>ps</mi>
                </msub>
              </mrow>
              <mrow>
                <msub>
                  <mi>B</mi>
                  <mi>r</mi>
                </msub>
                <msub>
                  <mi>E</mi>
                  <mi>m</mi>
                </msub>
              </mrow>
            </mfrac>
            <mo>+</mo>
            <mfrac>
              <mrow>
                <msub>
                  <mi>D</mi>
                  <mi>r</mi>
                </msub>
              </mrow>
              <mrow>
                <mi>
                  <mi>A</mi>
                </mi>
                <msub>
                  <mi>E</mi>
                  <mi>c</mi>
                </msub>
              </mrow>
            </mfrac>
            <mo>]</mo>
          </math>
        </p>

        <p>
          <math display="block">
            <msub>
              <mi>&rho;</mi>
              <mi>s</mi>
            </msub>
            <mo>=</mo>
            <mn>{round(Q, 2)}</mn>
            <mo>[</mo>
            <mfrac>
              <mrow>
                <mn>{round(ips, 2)}</mn>
              </mrow>
              <mrow>
                <mn>{round(socketDiameter, 2)}</mn>
                <mo>x</mo>
                <mn>{round(em, 2)}</mn>
              </mrow>
            </mfrac>
            <mo>+</mo>
            <mfrac>
              <mrow>
                <mn>{round(socketLength, 2)}</mn>
              </mrow>
              <mrow>
                <mn>{round(pileTipArea, 2)}</mn>
                <mo>x</mo>
                <mn>{round(ec, 2)}</mn>
              </mrow>
            </mfrac>
            <mo>]</mo>
          </math>
        </p>

        <p>
          <math display="block">
            <msub>
              <mi>&rho;</mi>
              <mi>s</mi>
            </msub>
            <mo>=</mo>
            <mn>{round(settlementM, 5)}</mn>
            <mi>m</mi>
          </math>
        </p>
        <p>
          <math display="block">
            <msub>
              <mi>&rho;</mi>
              <mi>s</mi>
            </msub>
            <mo>=</mo>
            <mn>{round(settlementMm, 2)}</mn>
            <mi> mm</mi>
          </math>
        </p>
      </div>
    </section>
  );
};

export default PilerocksettleResult;
