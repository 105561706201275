const OutputLabelContainer = () => {
  return (
    <div className="labelContainer layered-soil-layer">
      <label>Iteration #</label>
      <label>
        &phi; (<sup>o</sup>)
      </label>
      <label>c (kPa)</label>
      <label>
        H<sub>effective </sub> (m)
      </label>
    </div>
  );
};
export default OutputLabelContainer;
