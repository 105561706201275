import { useEffect } from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';

import Header from '../common/Header';
import Footer from '../common/Footer';
import MuiSnackbar from '../common/MuiSnackbar';

import axios from 'axios';

const ForgotPassword = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Forgot Password?';
  }, []);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const forgotPassword = async () => {
    setLoading(true);
    setButtonDisabled(true);
    try {
      const response = await axios({
        method: 'POST',
        url: '/api/v1/users/forgotpassword',
        data: {
          email,
        },
      });

      if (response.data.status === 'success') {
        showMuiSnack(response.data.message, 'success');
      }
    } catch (err) {
      showMuiSnack(err.response.data.message, 'error');
    }
    setLoading(false);
    setButtonDisabled(false);
  };

  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const showMuiSnack = (message, type) => {
    setAlertMessage(message);
    setSeverity(type);
    setOpen(true);
  };

  return (
    <>
      <Header />
      <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
      <div className="login">
        <h2>Forgot Password?</h2>
        <form className="forgotPassword">
          <p>Enter the email adress associated with your account and we'll send you a link to reset your password.</p>
          <TextField
            margin="dense"
            style={{ width: 300 }}
            size="small"
            label="E-Mail"
            variant="outlined"
            autoComplete="username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button variant="contained" style={{ width: 300 }} fullWidth onClick={forgotPassword} disabled={buttonDisabled}>
            {loading ? <CircularProgress size={25} color="grey" /> : 'Send Password Reset Link'}
          </Button>
        </form>

        <br />
        <Link component={RouterLink} to="/signup">
          {' '}
          Don't have an account? Sign Up here!
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
