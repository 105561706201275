import Modal from 'react-modal';
import Button from '@mui/material/Button';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

Modal.setAppElement('#root');

const DeleteConfirmationModal = ({
  deleteConfirmationModalIsOpen,
  setDeleteConfirmationModalIsOpenToFalse,
  deleteCalculations,
  selectedCalculations,
  createNewCalculation,
}) => {
  return (
    <Modal
      isOpen={deleteConfirmationModalIsOpen}
      className="modal"
      overlayClassName="modal-overlay"
      closeTimeoutMS={0}
      onRequestClose={setDeleteConfirmationModalIsOpenToFalse}
    >
      <CloseTwoToneIcon
        className="modalCloseIcon"
        onClick={setDeleteConfirmationModalIsOpenToFalse}
        alt="Close modal"
      ></CloseTwoToneIcon>
      <div className="text">
        {selectedCalculations.length === 1
          ? 'Are you sure you want to delete the 1 selected calculation?'
          : `Are you sure you want to delete the ${selectedCalculations.length} selected calculations?`}
      </div>

      <div className="buttonContainer">
        <Button
          variant="contained"
          onClick={() => {
            deleteCalculations();
            createNewCalculation();
          }}
        >
          Yes, proceed
        </Button>
        <Button variant="outlined" onClick={setDeleteConfirmationModalIsOpenToFalse}>
          No, cancel
        </Button>
      </div>
    </Modal>
  );
};
export default DeleteConfirmationModal;
