import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CalculationCounts from './CalculationCounts';
import CalculationsVsTime from './CalculationsVsTime';
import SavesVsTime from './SavesVsTime';
import SaveCounts from './SaveCounts';

const AdminCalculations = () => {
  const [loading, setLoading] = useState(false);
  const [calculationCounts, setCalculationCounts] = useState({});

  useEffect(() => {
    const getAllCalculations = async () => {
      setLoading(true);
      setCalculationCounts({});
      try {
        const response = await axios({
          method: 'GET',
          url: '/api/v1/admin/getAllCalculations',
        });

        setCalculationCounts(response.data);
      } catch (err) {
        console.log('Error->', err);
      }
      setLoading(false);
    };
    getAllCalculations();
  }, []);

  return (
    <>
      <h1>Calculations</h1>
      <div className="adminCalculations">
        <CalculationCounts loading={loading} calculationCounts={calculationCounts} />
        <SaveCounts />
        <CalculationsVsTime />
        <SavesVsTime />
      </div>
    </>
  );
};

export default AdminCalculations;
