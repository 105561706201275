import { useEffect } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';

const About = () => {
  useEffect(() => {
    document.title = 'GeoCalc | About';
  }, []);
  return (
    <>
      <Header />
      <div className="aboutUs">
        <div className="aboutProgress">
          <div className="center" id="level-1">
            2013
          </div>
          <div className="right" id="level-1">
            When there were spreadsheets!
          </div>
          <div className="center" id="level-2">
            2016
          </div>
          <div className="left" id="level-2">
            An idea? Why don't we use an online tool?
          </div>
          <div className="center" id="level-3">
            2017
          </div>
          <div className="right" id="level-3">
            First web app with only 3 calculations!
          </div>
          <div className="center" id="level-4">
            2019
          </div>
          <div className="left" id="level-4">
            A growing request for new geotechnical calculations!
          </div>
          <div className="center" id="level-5">
            2021
          </div>
          <div className="right" id="level-5">
            Version 2! More calculations. But, there were no user actions such as login, save, load etc.
          </div>
          <div className="left " id="level-6">
            Version 3 launched! Even more calculations with user actions such as login, save, load etc.
          </div>
          <div className="center " id="level-6">
            2023 - Q1
          </div>
          <div className="center" id="level-7">
            2023 - Q2
          </div>
          <div className="right" id="level-7">
            PDF exports and new tools.
          </div>
          <div className="center" id="level-8">
            2023 - Q3
          </div>
          <div className="left" id="level-8">
            More integrations!
          </div>
          <div className="center" id="level-9">
            2023 - Q4
          </div>
          <div className="right" id="level-9">
            More integrations!
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
