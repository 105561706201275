import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import Header from '../../common/Header';
import Footer from '../../common/Footer';

const ConsolidationTheory = () => {
  // Google Ads & Set page title
  useEffect(() => {
    document.title = 'GeoCalc | Time Dependent Settlements of Shallow Foundations | Theory';
  }, []);

  return (
    <>
      <Header />
      <div className="theory">
        <div className="theoryContainer">
          <h1>Time Dependent Settlements of Shallow Foundations | Theory & Formulations</h1>
          <div className="theoryComingSoon">Coming Soon!</div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ConsolidationTheory;
