import axios from 'axios';

export const apiCalculate = async (url, data) => {
  try {
    const response = await axios({
      method: 'POST',
      url,
      data,
    });

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const apiSave = async (url, data) => {
  try {
    const response = await axios({
      method: 'POST',
      url,
      data,
    });
    return response.data.data.data.name;
  } catch (err) {
    return err.response.data;
  }
};

export const apiFavoriteCalculations = async (data) => {
  try {
    const response = await axios({
      method: 'POST',
      url: '/api/v1/calculations/setFavorites',
      data,
    });

    return response.data.data.favoriteCalculations;
  } catch (err) {
    /* console.log(err.response.data); */
    return err.response.data;
  }
};

export const apiGetFavoriteCalculations = async () => {
  try {
    const response = await axios({
      method: 'GET',
      url: '/api/v1/calculations/getFavorites',
    });

    return response.data.data.favoriteCalculations;
  } catch (err) {
    /* console.log(err.response.data); */
    return err.response.data;
  }
};

export const apiCheckCalculationNameforSaving = async (url, data) => {
  const response = await fetch(url + `${data.name}`, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const responseJSON = await response.json();
  return responseJSON;
};

export const apiUpdate = async (url, data, id) => {
  const response = await fetch(url + `${id}`, {
    method: 'PATCH',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const apiLoadAllCalculations = async (url) => {
  const response = await fetch(url, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
};

export const apiDeleteCalculations = async (url, id) => {
  await fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(id),
  });
};

export const apiLoadOneCalculation = async (url, id) => {
  const response = await fetch(url + `${id}`, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
};

export const apiSendContactMail = async (data) => {
  const response = await fetch('/api/v1/users/contact', {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const apiCaptchaVerification = async (data) => {
  const response = await fetch('/api/v1/users/captcha', {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const apiLogout = async () => {
  const response = await fetch('/api/v1/users/logout', {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
};

export const apiNewsletterSubscription = async (data) => {
  const response = await fetch('/api/v1/users/newsletter', {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
};
