import React, { useEffect, useState, forwardRef } from 'react';
import MuiAlert from '@mui/material/Alert';
import round from '../../../helpers/round';

import TerzaghiPeckMesri from '../../../assets/calculations/piPhi/Terzaghi_Peck_Mesri_1996.JPG';
import Kenny from '../../../assets/calculations/piPhi/kenny1959.JPG';
import Gibson from '../../../assets/calculations/piPhi/gibson 1953.JPG';

const StroudResult = ({ result }) => {
  const { pi } = result.input;
  const { phiRes_Gibson, phi_Gibson, phi_Kenny, phi_TPM, sinPhi_Kenny } = result.output;

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} {...props} />;
  });

  const [isTPM_PI_OK, setIsTPM_PI_OK] = useState();
  const [isgibson_PI_OK, setIsgibson_PI_OK] = useState();
  const [isKenny_PI_OK, setIsKenny_PI_OK] = useState();

  useEffect(() => {
    if (pi >= 5 && pi <= 1000) {
      setIsTPM_PI_OK(true);
    } else {
      setIsTPM_PI_OK(false);
    }

    if (pi >= 13 && pi <= 117) {
      setIsgibson_PI_OK(true);
    } else {
      setIsgibson_PI_OK(false);
    }

    if (pi >= 7 && pi <= 115) {
      setIsKenny_PI_OK(true);
    } else {
      setIsKenny_PI_OK(false);
    }
  }, [pi]);

  const GraphLimitAlert = ({ type }) => {
    return (
      <Alert severity="warning" style={{ minWidth: 400, maxWidth: 600, textAlign: 'left', marginTop: '10px' }}>
        <span>
          Plasticity Index (PI) provided ({round(pi, 2)}%) is out of the limits of the graph. Results may not be accurate. <br />
          <br />
          {type === 'terzaghi' && 'For Terzaghi, Peck, Mesri (1996) valid PI values range between 5 - 1000.'}
          {type === 'gibson' && 'For Gibson 1953, valid PI values range between 13 - 117.'}
          {type === 'kenny' && 'For Kenny 1959, valid PI values range between 7 - 115.'}
        </span>
      </Alert>
    );
  };

  return (
    <section className="output">
      <h2>Output </h2>
      <div className="clayEffectiveContainer">
        <div className="clayEffectiveBox">
          <div className="clayEffectiveGraphContainer">
            <h4>Terzaghi, Peck, Mesri (1996)</h4>
            <img src={TerzaghiPeckMesri} alt="Graph for Terzaghi Peck Mesri 1996"></img>
            {!isTPM_PI_OK && <GraphLimitAlert type="terzaghi" />}
          </div>
          <p>
            For PI = {round(pi, 2)}% &rarr; &phi;' = {round(phi_TPM, 2)}
            <sup>o</sup>
          </p>
        </div>
        <div className="clayEffectiveBox">
          <div className="clayEffectiveGraphContainer">
            <h4>Kenny (1959)</h4>
            <img src={Kenny} alt="Kenny 1959"></img>
            {!isKenny_PI_OK && <GraphLimitAlert type="kenny" />}
          </div>
          <p>
            For PI = {round(pi, 2)}% &rarr; sin(&phi;') = {round(sinPhi_Kenny, 3)} &rarr; &phi;' = {round(phi_Kenny, 2)}
            <sup>o</sup>
          </p>
        </div>
        <div className="clayEffectiveBox">
          <div className="clayEffectiveGraphContainer">
            <h4>Gibson (1953)</h4>
            <img src={Gibson} alt="Gibson 1953"></img>
            {!isgibson_PI_OK && <GraphLimitAlert type="gibson" />}
          </div>
          <p>
            For PI = {round(pi, 2)}% &rarr; &phi;' = {round(phi_Gibson, 2)}
            <sup>o</sup>
          </p>
          <p>
            Also &phi;'<sub>r</sub> = {round(phiRes_Gibson, 2)}
            <sup>o</sup>
          </p>
        </div>
      </div>
    </section>
  );
};

export default StroudResult;
