import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { CircularProgress } from '@mui/material';

const OperationSummary = ({ loading, userCounts }) => {
  return (
    <div className="UserSummary">
      <div className="summaryContainer">
        <div className="summary">
          <div className="number">{loading ? <CircularProgress size={30} /> : userCounts.operationalUserCount}</div>
          <div className="text">Operational Personnel</div>
        </div>
        <div className="icon">
          <AdminPanelSettingsIcon color="primary" fontSize="large" />
        </div>
      </div>
      <Divider />
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Support:</div> <div>{loading ? <CircularProgress size={10} /> : userCounts.supportCount}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.supportCount / userCounts.operationalUserCount) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.supportCount / userCounts.operationalUserCount) * 100)}%`}
        />
      </div>
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Admin:</div> <div> {loading ? <CircularProgress size={10} /> : userCounts.adminCount}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.adminCount / userCounts.operationalUserCount) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.adminCount / userCounts.operationalUserCount) * 100)}%`}
        />
      </div>
    </div>
  );
};

export default OperationSummary;
