const convertFirstCharacterToUppercase = (stringToConvert) => {
  var firstCharacter = stringToConvert.substring(0, 1);
  var restString = stringToConvert.substring(1).toLowerCase();

  return firstCharacter.toUpperCase() + restString;
};
const convertFirstCharacterAllWordsToUppercase = (stringToConvert) => {
  const wordsArray = stringToConvert.split(' ');
  const convertedWordsArray = wordsArray.map((word) => {
    return convertFirstCharacterToUppercase(word);
  });

  return convertedWordsArray.join(' ');
};

export { convertFirstCharacterToUppercase, convertFirstCharacterAllWordsToUppercase };
