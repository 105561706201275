import React, { useState } from 'react';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import AdminUserModel from '../../modals/AdminUserModal';
const UserTable = ({ allUsers }) => {
  const [open, setOpen] = useState(false);
  const [modalUser, setModalUser] = useState();

  const handleUserInfoModel = (user) => {
    setModalUser(user);
    setOpen(true);
  };

  return (
    <div className="userTable">
      <div className="userTableHeader">
        <div className="userTableEntry">#</div>
        <div className="userTableEntry">Name</div>
        <div className="userTableEntry">Email</div>
        <div className="userTableEntry">Email Confirmed</div>
        <div className="userTableEntry">Role</div>
        <div className="userTableEntry">Active</div>
      </div>
      {allUsers.map((user, index) => {
        return (
          <div
            className="userTableRow"
            key={user._id}
            onClick={() => {
              handleUserInfoModel(user);
            }}
          >
            <div className="userTableEntry">{index + 1}</div>
            <div className="userTableEntry">{user.name}</div>
            <div className="userTableEntry">{user.email}</div>
            <div className="userTableEntry">
              {user.emailConfirmed ? <CheckCircleOutlineIcon color="success" /> : <ErrorOutlineIcon color="error" />}
            </div>
            <div className="userTableEntry">{user.role}</div>
            <div className="userTableEntry">
              {user.active ? <CheckCircleOutlineIcon color="success" /> : <ErrorOutlineIcon color="error" />}
            </div>
          </div>
        );
      })}
      <AdminUserModel open={open} setOpen={setOpen} modalUser={modalUser} setModalUser={setModalUser} />
    </div>
  );
};

export default UserTable;
