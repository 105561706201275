import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InputAdornment from '@mui/material/InputAdornment';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MuiSnackbar from '../../common/MuiSnackbar';
import CircularProgress from '@mui/material/CircularProgress';

import CalculateButton from '../../common/calculateButton';
import SaveButton from '../../common/saveButton';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Sidebar from '../../sidebar/Sidebar';
import DecisionModal from '../../modals/DecisionModal';
import HansenResult from './HansenResult';
import hansenImage from '../../../assets/calculations/hansen/hansen.jpg';
import TransitionsModal from '../../modals/TransitionsModal';

import { useCalculationContext, CalculationContextProvider } from '../../context/calculationContext';

const Hansen = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Bearing Capacity of Shallow Foundations - Hansen (1970 )';
  }, []);

  const {
    MAX_NAME_LENGTH,
    isLoadingCalculate,
    isLoadingLoadAll,
    isLoadingLoad,
    isLoadingSave,
    result,
    calculations,
    showOutput,
    setShowOutput,
    calculateButtonDisabled,
    setCalculateButtonDisabled,
    saveButtonDisabled,
    setSaveButtonDisabled,
    errorMessage,
    displaySidebar,
    setDisplaySidebar,
    decisionModalIsOpen,
    decision,
    setDecision,
    handleSidebarDisplay,
    setDecisionModalIsOpenToFalse,
    showMuiSnack,
    open,
    setOpen,
    alertMessage,
    severity,
    onChangeWarningForPositiveDecimals,
    calculate,
    loadOneCalculation,
    loadAllCalculations,
    update,
    checkCalculationNameforSaving,
    checkCalculationNameforSavingAs,
    deleteCalculations,
    calculationCountReached,
    saveCountReached,
  } = useCalculationContext();

  //SET STATES FOR INPUT VARIABLES
  const [name, setName] = useState('');
  const [fndWidth, setFndWidth] = useState('');
  const [fndLength, setFndLength] = useState('');
  const [eccentricityX, setEccentricityX] = useState('');
  const [eccentricityY, setEccentricityY] = useState('');
  const [columnDimensionX, setColumnDimensionX] = useState('');
  const [columnDimensionY, setColumnDimensionY] = useState('');
  const [cohesion, setCohesion] = useState('');
  const [phi, setPhi] = useState('');
  const [df, setDf] = useState('');
  const [beta, setBeta] = useState('');
  const [nu, setNu] = useState('');
  const [hb, setHb] = useState('');
  const [hl, setHl] = useState('');
  const [normal_v, setNormal_v] = useState('');
  const [caCoefficient, setCaCoefficient] = useState('');
  const [alpha1, setAlpha1] = useState('');
  const [alpha2, setAlpha2] = useState('');
  const [gamma, setGamma] = useState('');
  const [gwt, setGwt] = useState('');
  const [bearingCapacityFactorOfSafety, setBearingCapacityFactorOfSafety] = useState('');
  const [slidingFactorOfSafety, setSlidingFactorOfSafety] = useState('');

  // SET THE LOADED INPUTS
  const setInputs = (inputs) => {
    setName(inputs.name);
    setFndWidth(inputs.fndWidth);
    setFndLength(inputs.fndLength);
    setEccentricityX(inputs.eccentricityX);
    setEccentricityY(inputs.eccentricityY);
    setColumnDimensionX(inputs.columnDimensionX);
    setColumnDimensionY(inputs.columnDimensionY);
    setCohesion(inputs.cohesion);
    setPhi(inputs.phi);
    setDf(inputs.df);
    setBeta(inputs.beta);
    setNu(inputs.nu);
    setHb(inputs.hb);
    setHl(inputs.hl);
    setNormal_v(inputs.normal_v);
    setCaCoefficient(inputs.caCoefficient);
    setAlpha1(inputs.alpha1);
    setAlpha2(inputs.alpha2);
    setGamma(inputs.gamma);
    setGwt(inputs.gwt);
    setBearingCapacityFactorOfSafety(inputs.bearingCapacityFactorOfSafety);
    setSlidingFactorOfSafety(inputs.slidingFactorOfSafety);
  };

  //INPUTS FOR CALCULATION
  const calculationInput = {
    name,
    fndWidth,
    fndLength,
    eccentricityX,
    eccentricityY,
    columnDimensionX,
    columnDimensionY,
    cohesion,
    phi,
    df,
    beta,
    nu,
    hb,
    hl,
    normal_v,
    caCoefficient,
    alpha1,
    alpha2,
    gamma,
    gwt,
    bearingCapacityFactorOfSafety,
    slidingFactorOfSafety,
  };

  // MANAGE SAVE & CALCULATE BUTTONS DISABLED/ENABLED STATES
  useEffect(() => {
    setCalculateButtonDisabled(false);
    setSaveButtonDisabled(false);

    if (
      (calculationInput.name || calculationInput.name === 0) &&
      (calculationInput.fndWidth || calculationInput.fndWidth === 0) &&
      (calculationInput.fndLength || calculationInput.fndLength === 0) &&
      (calculationInput.eccentricityX || calculationInput.eccentricityX === 0) &&
      (calculationInput.eccentricityY || calculationInput.eccentricityY === 0) &&
      (calculationInput.columnDimensionX || calculationInput.columnDimensionX === 0) &&
      (calculationInput.columnDimensionY || calculationInput.columnDimensionY === 0) &&
      (calculationInput.cohesion || calculationInput.cohesion === 0) &&
      (calculationInput.phi || calculationInput.phi === 0) &&
      (calculationInput.df || calculationInput.df === 0) &&
      (calculationInput.beta || calculationInput.beta === 0) &&
      (calculationInput.nu || calculationInput.nu === 0) &&
      (calculationInput.hb || calculationInput.hb === 0) &&
      (calculationInput.hl || calculationInput.hl === 0) &&
      (calculationInput.normal_v || calculationInput.normal_v === 0) &&
      (calculationInput.caCoefficient || calculationInput.caCoefficient === 0) &&
      (calculationInput.alpha1 || calculationInput.alpha1 === 0) &&
      (calculationInput.alpha2 || calculationInput.alpha2 === 0) &&
      (calculationInput.gamma || calculationInput.gamma === 0) &&
      (calculationInput.gwt || calculationInput.gwt === 0) &&
      (calculationInput.bearingCapacityFactorOfSafety || calculationInput.bearingCapacityFactorOfSafety === 0) &&
      (calculationInput.slidingFactorOfSafety || calculationInput.slidingFactorOfSafety === 0)
    ) {
      setCalculateButtonDisabled(false);
      setSaveButtonDisabled(false);
    } else {
      setCalculateButtonDisabled(true);
      setSaveButtonDisabled(true);
    }
  }, [
    setCalculateButtonDisabled,
    setSaveButtonDisabled,
    calculationInput.name,
    calculationInput.fndWidth,
    calculationInput.fndLength,
    calculationInput.eccentricityX,
    calculationInput.eccentricityY,
    calculationInput.columnDimensionX,
    calculationInput.columnDimensionY,
    calculationInput.cohesion,
    calculationInput.phi,
    calculationInput.df,
    calculationInput.beta,
    calculationInput.nu,
    calculationInput.hb,
    calculationInput.hl,
    calculationInput.normal_v,
    calculationInput.caCoefficient,
    calculationInput.alpha1,
    calculationInput.alpha2,
    calculationInput.gamma,
    calculationInput.gwt,
    calculationInput.bearingCapacityFactorOfSafety,
    calculationInput.slidingFactorOfSafety,
  ]);

  // DO NOT RENDER OUTPUT IF ANY INPUT PARAMETER EXCEPT NAME CHANGES
  useEffect(() => {
    setShowOutput(false);
  }, [
    setShowOutput,
    calculationInput.fndWidth,
    calculationInput.fndLength,
    calculationInput.eccentricityX,
    calculationInput.eccentricityY,
    calculationInput.columnDimensionX,
    calculationInput.columnDimensionY,
    calculationInput.cohesion,
    calculationInput.phi,
    calculationInput.df,
    calculationInput.beta,
    calculationInput.nu,
    calculationInput.hb,
    calculationInput.hl,
    calculationInput.normal_v,
    calculationInput.caCoefficient,
    calculationInput.alpha1,
    calculationInput.alpha2,
    calculationInput.gamma,
    calculationInput.gwt,
    calculationInput.bearingCapacityFactorOfSafety,
    calculationInput.slidingFactorOfSafety,
  ]);

  //CLEAR INPUTS AND CLEAR OUTPUT
  const createNewCalculation = () => {
    setName('');
    setFndWidth('');
    setFndLength('');
    setEccentricityX('');
    setEccentricityY('');
    setColumnDimensionX('');
    setColumnDimensionY('');
    setCohesion('');
    setPhi('');
    setDf('');
    setBeta('');
    setNu('');
    setHb('');
    setHl('');
    setNormal_v('');
    setCaCoefficient('');
    setAlpha1('');
    setAlpha2('');
    setGamma('');
    setGwt('');
    setBearingCapacityFactorOfSafety('');
    setSlidingFactorOfSafety('');
    setShowOutput(false);
  };

  //API URLS
  const URL = {
    CALCULATE: '/api/v1/calculations/hansen',
    LOADONE: `/api/v1/calculations/hansen/loadOne/`,
    LOADALL: '/api/v1/calculations/hansen/loadAll',
    UPDATE: `/api/v1/calculations/hansen/update/`,
    SAVE: '/api/v1/calculations/hansen/save',
    CHECKUNIQUENAME: `/api/v1/calculations/hansen/checkUniqueName/`,
    DELETE: '/api/v1/calculations/hansen/delete',
  };

  const handleChangeName = (e) => {
    if (e.target.value.length >= MAX_NAME_LENGTH) {
      showMuiSnack(`Calculation name can be max ${MAX_NAME_LENGTH} characters.`, 'warning');
    }
    setName(e.target.value);
  };

  // RETURN INPUT AND OUTPUT JSX
  return (
    <>
      <Header />
      <div className="container">
        <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
        {displaySidebar ? (
          <Sidebar
            URL={URL}
            loadAllCalculations={loadAllCalculations}
            calculations={calculations}
            loadOneCalculation={loadOneCalculation}
            calculate={calculate}
            setInputs={setInputs}
            deleteCalculations={deleteCalculations}
            createNewCalculation={createNewCalculation}
            isLoading={isLoadingLoadAll}
            handleSidebarDisplay={handleSidebarDisplay}
            displaySidebar={displaySidebar}
            setDisplaySidebar={setDisplaySidebar}
          />
        ) : (
          <MenuOpenIcon
            color="primary"
            fontSize="large"
            className="sidebar-open-icon"
            alt="side bar menu icon"
            label="Show my calculations"
            onClick={handleSidebarDisplay}
          />
        )}
        <DecisionModal
          URL={URL}
          data={calculationInput}
          decisionModalIsOpen={decisionModalIsOpen}
          setDecisionModalIsOpenToFalse={setDecisionModalIsOpenToFalse}
          decision={decision}
          setDecision={setDecision}
          checkCalculationNameforSavingAs={checkCalculationNameforSavingAs}
          name={name}
          setName={setName}
          errorMessage={errorMessage}
          update={update}
          saveButtonDisabled={saveButtonDisabled}
        />
        <div className="right-calculation">
          <section className="input">
            <h1>Bearing Capacity of Shallow Foundations - Hansen (1970 )</h1>
            <form>
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label="Name"
                variant="outlined"
                inputProps={{
                  maxLength: `${MAX_NAME_LENGTH}`,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={`Your calculation name (Max. ${MAX_NAME_LENGTH} characters)`}
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={name}
                onChange={handleChangeName}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={'B (m)'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Foundation width" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={fndWidth}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Foundation Width', setFndWidth)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={'L (m)'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Foundation length" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={fndLength}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Foundation Length', setFndLength)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label={
                  <span key={'eccentricityX'}>
                    e<sub>x</sub> (m)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Eccentricity in X direction" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={eccentricityX}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Eccentricity X', setEccentricityX)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label={
                  <span key={'eccentricityY'}>
                    e<sub>y</sub> (m)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Eccentricity in Y direction" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={eccentricityY}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Eccentricity Y', setEccentricityY)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label={
                  <span key={'columnDimensionX'}>
                    w<sub>x</sub> (m)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Column dimension in X direction" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={columnDimensionX}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'wx', setColumnDimensionX)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label={
                  <span key={'columnDimensionY'}>
                    w<sub>y</sub> (m)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Column dimension in Y direction" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={columnDimensionY}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'wy', setColumnDimensionY)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label="c (kPa)"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Cohesion" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={cohesion}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Cohesion', setCohesion)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'phi'} className="subscriptMargin">
                    &phi; (<sup>o</sup>)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Internal friction angle" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={phi}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Phi', setPhi)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'df'}>
                    D<sub>f</sub> (m)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Depth of foundation" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={df}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Df', setDf)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key="beta" className="subscriptMargin">
                    &beta; (<sup>o</sup>)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Slope angle" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={beta}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Beta', setBeta)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key="nu" className="subscriptMargin">
                    &eta; (<sup>o</sup>)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Base tilt angle" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={nu}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'eta', setNu)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label={
                  <span key={'gamma'} className="subscriptMargin">
                    &gamma; (kN/m<sup>3</sup>)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Natural unit weight of soil" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={gamma}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Gamma', setGamma)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'hb'}>
                    H<sub>B</sub> (kN)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Force parallel to foundation width" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={hb}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Hb', setHb)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'hl'}>
                    H<sub>L</sub> (kN)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Force parallel to foundation length" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={hl}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Hl', setHl)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={<span key={'v'}>V (kN)</span>}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Force normal to foundation base" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={normal_v}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'V', setNormal_v)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'ca'}>
                    C<sub>a</sub> Red. Factor
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Cohesion reduction factor. 0.6 to 1.0" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={caCoefficient}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Ca', setCaCoefficient)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'alpha1'}>
                    &alpha;<sub>1</sub>
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={
                          <span key={'alpha1Title'}>
                            Exponent for calculating I<sub>q</sub> values. Suggested values range from 2 to 3.
                          </span>
                        }
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={alpha1}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Alpha-1', setAlpha1)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'alpha2'}>
                    &alpha;<sub>2</sub>
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        /* title="Exponent for calculating Igamma values. Suggested values range from 3 to 4." */
                        title={
                          <span key={'alpha2Title'}>
                            Exponent for calculating I<sub>&gamma;</sub> values. Suggested values range from 3 to 4.
                          </span>
                        }
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={alpha2}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Alpha-2', setAlpha2)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={'GWT (m)'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Ground water level measured from ground surface"
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={gwt}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'GWT', setGwt)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'bearingCapacityFactorOfSafety'}>
                    FS<sub>Bearing</sub>
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Factor of safety for bearing capacity." arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={bearingCapacityFactorOfSafety}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'FS Bearing', setBearingCapacityFactorOfSafety)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'slidingFactorOfSafety'}>
                    FS<sub>Sliding</sub>
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Factor of safety for bearing capacity." arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={slidingFactorOfSafety}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'FS Sliding', setSlidingFactorOfSafety)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />

              <TransitionsModal
                image={hansenImage}
                title="Hansen (1970)"
                description={'Dimensions and angles are shown on the sketch.'}
              ></TransitionsModal>

              <div className="buttonContainer">
                <CalculateButton
                  calculationCountReached={calculationCountReached}
                  calculate={calculate}
                  calculationInput={calculationInput}
                  URL={URL}
                  calculateButtonDisabled={calculateButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
                {isLoadingLoad && <CircularProgress size={30} />}

                <SaveButton
                  saveCountReached={saveCountReached}
                  checkCalculationNameforSaving={checkCalculationNameforSaving}
                  calculationInput={calculationInput}
                  URL={URL}
                  saveButtonDisabled={saveButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
              </div>
            </form>
          </section>
          {showOutput && <HansenResult result={result} />}
          {isLoadingCalculate && (
            <div className="centerLoading">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

const HansenWithProvider = () => {
  return (
    <CalculationContextProvider>
      <Hansen />
    </CalculationContextProvider>
  );
};
export default HansenWithProvider;
