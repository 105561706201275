import logo from '../../assets/logo/logo.png';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to="/">
      <div className="logo">
        <div className="logoLeft">
          <img src={logo} alt="logo"></img>
        </div>
        <div className="logoRight">
          <span>Geotechnical</span>
          <span>Calculations</span>
        </div>
      </div>
    </Link>
  );
};

export default Logo;
