import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { CircularProgress } from '@mui/material';
import { LineChart } from '../Charts/LineChart';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const SignupVsTime = () => {
  const [loadingGraph, setLoadingGraph] = useState(false);
  const [period, setPeriod] = useState(7);
  const [type, setType] = useState('all');

  const [lastXdaySignupsData, setLastXdaySignupsData] = useState([]);
  const [lastXdaySignupsLabels, setLastXdaySignupsLabels] = useState([]);
  const [lastXdaySignupsDataCumulative, setLastXdaySignupsDataCumulative] = useState([]);

  useEffect(() => {
    const getLastXPeriodSignups = async () => {
      setLoadingGraph(true);

      try {
        const response = await axios({
          method: 'GET',
          url: `/api/v1/admin/getLastXPeriodSaves/${period}/${type}`,
        });
        setLastXdaySignupsData(response.data.data.dataSet);
        setLastXdaySignupsLabels(response.data.data.labels);
        setLastXdaySignupsDataCumulative(response.data.data.dataSetCumulative);
      } catch (err) {
        console.log('Error->', err.response.data.message);
      }
      setLoadingGraph(false);
    };
    getLastXPeriodSignups();
  }, [period, type]);

  const data1 = {
    label: 'Saves',
    period,
    labels: lastXdaySignupsLabels,
    datasets: [
      {
        label: 'Saves',
        data: lastXdaySignupsData,
        borderColor: '#1976D2',
        backgroundColor: '#1976D2',
      },
    ],
  };

  const data2 = {
    label: 'Saves (Cumulative)',
    period,
    labels: lastXdaySignupsLabels,
    datasets: [
      {
        label: 'Saves (Cumulative)',
        data: lastXdaySignupsDataCumulative,
        borderColor: '#1976D2',
        backgroundColor: '#1976D2',
      },
    ],
  };

  return (
    <div className="SignupVsTime ">
      <div className="selectPeriod">
        <FormControl>
          <InputLabel id="active">Period</InputLabel>
          <Select
            size="small"
            labelId="active"
            value={period}
            label="Period"
            sx={{ width: '150px' }}
            onChange={(e) => {
              setPeriod(e.target.value);
            }}
          >
            <MenuItem value={7}>Last 7 days</MenuItem>
            <MenuItem value={15}>Last 15 days</MenuItem>
            <MenuItem value={30}>Last 30 days</MenuItem>
            <MenuItem value={90}>Last 3 months</MenuItem>
            <MenuItem value={180}>Last 6 months</MenuItem>
            <MenuItem value={365}>Last 12 months</MenuItem>
            <MenuItem value={730}>Last 2 years</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="selectCalculation">
        <FormControl>
          <InputLabel id="active">Calculation</InputLabel>
          <Select
            size="small"
            labelId="active"
            value={type}
            label="Calculation"
            sx={{ width: '150px' }}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <MenuItem value={'all'}>All</MenuItem>
            <MenuItem value={'consolidation'}>Consolidation</MenuItem>
            <MenuItem value={'pileRockSettle'}>Pile Rock Settle</MenuItem>
            <MenuItem value={'terzaghi'}>Terzaghi</MenuItem>
            <MenuItem value={'meyerhof'}>Meyerhof</MenuItem>
            <MenuItem value={'hansen'}>Hansen</MenuItem>
            <MenuItem value={'pileRockBc'}>Pilerockbc</MenuItem>
            <MenuItem value={'SptCorrection'}>Spt Correction</MenuItem>
            <MenuItem value={'liquefaction'}>Liquefaction</MenuItem>
            <MenuItem value={'layeredSoil'}>Layered Soil</MenuItem>
            <MenuItem value={'weightVolumeRelationships'}>Weight Volume</MenuItem>
            <MenuItem value={'stroud'}>Stroud</MenuItem>
            <MenuItem value={'clayEffectiveFrictionAngle'}>Clay Friction Angle</MenuItem>
            <MenuItem value={'cvLLUsNavy'}>Cv Vs LL</MenuItem>
            <MenuItem value={'stressIncrease'}>Stress Increase</MenuItem>
          </Select>
        </FormControl>
      </div>
      {loadingGraph ? (
        <CircularProgress />
      ) : (
        <>
          <LineChart data={data1} />
          <LineChart data={data2} />
        </>
      )}
    </div>
  );
};

export default SignupVsTime;
