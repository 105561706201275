import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import TransitionsModal from '../../modals/TransitionsModal';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import f1Grapgh from '../../../assets/calculations/stroud/f1.JPG';
import f2Grapgh from '../../../assets/calculations/stroud/f2.JPG';

const StroudTheory = () => {
  useEffect(() => {
    document.title = 'GeoCalc | cu - mv Vs SPT - PI Relationships | Stroud 1974, 1975 | Theory';
  }, []);

  return (
    <>
      <Header />
      <div className="theory">
        <div className="theoryContainer">
          <h1>
            c<sub>u</sub> - m<sub>v</sub> & SPT - PI Relationships | Stroud (1974) | Theory & Formulations
          </h1>
          <p>
            Database comprised of 1200 SPT-N values from 42 different sites in UK by Stroud (1974). In this study, unconsolidated
            undrained (UU) triaxial tests were performed on high quality undisturbed samples in order to obtain actual undrained
            shear strength values of clayey soils.
          </p>
          <p>
            As a result, Stroud (1974) reported that for clayey soils with plasticity index (PI) in range of 25 - 60 and SPT-N
            values in range of 10 - 60 blow/30 cm, undrained shear strength values obtained from UU tests are in range of 25 - 500
            kN/m<sup>2</sup>. Stroud (1974) proposed a correlation between f<sub>1</sub> and PI as shown in Figure A where f
            <sub>1</sub> is a factor depending on plasticity index.
          </p>
          <TransitionsModal
            image={f1Grapgh}
            title="Stroud (1974)"
            description={'Relation between SPT-N60 - cu - PI'}
            buttonText={'Figure A'}
          ></TransitionsModal>
          <p className="theoryEquation">
            c<sub>u</sub>(kPa) = f<sub>1</sub> x N<sub>60</sub>
          </p>
          <p>
            Based on the previously stated study, Stroud (1974) proposed a correlation between f<sub>2</sub> and PI for the
            relation between coefficient of volumetric compression (m<sub>v</sub>) and SPT-N value as shown in Figure B. Here, f
            <sub>2</sub> is a factor depending on plasticity index.
          </p>
          <TransitionsModal
            image={f2Grapgh}
            title="Stroud (1974)"
            description={'Relation between SPT-N60 - mv - PI'}
            buttonText={'Figure B'}
          ></TransitionsModal>
          <p className="theoryEquation">
            m<sub>v</sub>(m<sup>2</sup>/kN) = 1 / (f<sub>2</sub> x N<sub>60</sub>)
          </p>
          <p className="citation">Geoteknik Mühendisliğinde Saha Deneyleri (Erol and Çekinmez, 2014). </p>
          <div className="theoryButtonContainer">
            <Link to={'/calculations/stroud'}>
              <Button variant="outlined" size="small">
                GO TO cu & mv CALCULATOR
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StroudTheory;
