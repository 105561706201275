import React from 'react';
import GroupIcon from '@mui/icons-material/Group';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';

import { CircularProgress } from '@mui/material';

const UserSummary = ({ loading, userCounts }) => {
  return (
    <div className="UserSummary">
      <div className="summaryContainer">
        <div className="summary">
          <div className="number">{loading ? <CircularProgress size={30} /> : userCounts.userCount}</div>
          <div className="text">Total Users</div>
        </div>
        <div className="icon">
          <GroupIcon color="primary" fontSize="large" />
        </div>
      </div>
      <Divider />
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Active:</div>{' '}
          <div>{loading ? <CircularProgress size={10} /> : userCounts.activeUserCount}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.activeUserCount / userCounts.userCount) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.activeUserCount / userCounts.userCount) * 100)}%`}
        />
      </div>
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Passive:</div>{' '}
          <div>{loading ? <CircularProgress size={10} /> : userCounts.passiveUserCount}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.passiveUserCount / userCounts.userCount) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.passiveUserCount / userCounts.userCount) * 100)}%`}
        />
      </div>
      <Divider sx={{ m: 1 }} />
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Email Confirmed:</div>{' '}
          <div>{loading ? <CircularProgress size={10} /> : userCounts.confirmedUsers}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.confirmedUsers / userCounts.userCount) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.confirmedUsers / userCounts.userCount) * 100)}%`}
        />
      </div>

      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Email Not Confirmed:</div>{' '}
          <div>{loading ? <CircularProgress size={10} /> : userCounts.nonConfirmedUsers}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.nonConfirmedUsers / userCounts.userCount) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.nonConfirmedUsers / userCounts.userCount) * 100)}%`}
        />
      </div>
      <Divider sx={{ m: 1 }} />

      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Email Confirmation Ratio:</div>{' '}
          <div>
            {loading ? (
              <CircularProgress size={10} />
            ) : (
              <p>{Math.floor((userCounts.confirmedUsers / userCounts.userCount) * 100)}%</p>
            )}
          </div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.confirmedUsers / userCounts.userCount) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.confirmedUsers / userCounts.userCount) * 100)}%`}
        />
      </div>
    </div>
  );
};

export default UserSummary;
