import Modal from 'react-modal';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import MuiSnackbar from '../common/MuiSnackbar';

Modal.setAppElement('#root');

const DecisionModal = ({
  data,
  decisionModalIsOpen,
  setDecisionModalIsOpenToFalse,
  decision,
  setDecision,
  checkCalculationNameforSavingAs,
  name,
  setName,
  errorMessage,
  update,
  saveButtonDisabled,
  URL,
}) => {
  const handleRadioChange = (e) => {
    setDecision(e.target.value);
  };

  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const showMuiSnack = (message, type) => {
    setAlertMessage(message);
    setSeverity(type);
    setOpen(true);
  };

  const handleChangeName = (e) => {
    if (e.target.value.length >= 20) {
      showMuiSnack(`Calculation name can be max ${20} characters.`, 'warning');
    }
    setName(e.target.value);
  };

  return (
    <Modal
      isOpen={decisionModalIsOpen}
      className="modal"
      overlayClassName="modal-overlay"
      closeTimeoutMS={0}
      onRequestClose={setDecisionModalIsOpenToFalse}
    >
      <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
      <CloseTwoToneIcon onClick={setDecisionModalIsOpenToFalse} className="modalCloseIcon" />
      <div className="text">There is already a calculation with the same name. What would you like to do?</div>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="update"
        >
          <FormControlLabel value="update" control={<Radio />} label="Update calculation" onChange={handleRadioChange} />
          <FormControlLabel value="saveas" control={<Radio />} label="Save as new calculation" onChange={handleRadioChange} />
        </RadioGroup>
      </FormControl>

      {decision === 'saveas' && (
        <TextField
          style={{ minWidth: 150, maxWidth: 200 }}
          label="Name"
          variant="outlined"
          value={name}
          onChange={handleChangeName}
          inputProps={{
            maxLength: 20,
          }}
        ></TextField>
      )}
      <div className="buttonContainer">
        {decision === 'update' ? (
          <Button
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              update(data, URL.UPDATE);
            }}
          >
            Update
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled={saveButtonDisabled}
            onClick={(e) => {
              e.preventDefault();
              checkCalculationNameforSavingAs(data, URL.CHECKUNIQUENAME, URL.SAVE, URL.LOADALL);
            }}
          >
            Save As
          </Button>
        )}
      </div>
      {errorMessage && <div className="error">{errorMessage}</div>}
    </Modal>
  );
};
export default DecisionModal;
