import { useState, forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import axios from 'axios';

const ManageSubscription = ({ user, setUser }) => {
  const [checked, setChecked] = useState({
    newToolRelease: user?.emailPreferences.newToolRelease,
    newsletter: user?.emailPreferences.newsletter,
    lastCalculation: user?.emailPreferences.lastCalculation,
    calculationCountReset: user?.emailPreferences.calculationCountReset,
  });

  const [loading, setLoading] = useState(false);

  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const handleChange = (type) => {
    switch (type) {
      case 'newToolRelease':
        setChecked({
          ...checked,
          newToolRelease: !checked.newToolRelease,
        });
        break;
      case 'newsletter':
        setChecked({
          ...checked,
          newsletter: !checked.newsletter,
        });
        break;
      case 'lastCalculation':
        setChecked({
          ...checked,
          lastCalculation: !checked.lastCalculation,
        });
        break;
      case 'calculationCountReset':
        setChecked({
          ...checked,
          calculationCountReset: !checked.calculationCountReset,
        });
        break;
      default:
        console.log(`-`);
    }
  };

  const updateUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios({
        method: 'PATCH',
        url: '/api/v1/users/updateEmailPreferences',
        data: {
          emailPreferences: checked,
        },
      });

      if (response.data.status === 'success') {
        setUser(response.data.data.user);
        setAlertMessage('Your email preferences updated successfully');
        setSeverity('success');
        setOpen(true);
      }
    } catch (err) {
      setAlertMessage(err.response.data.message);
      setSeverity('error');
      setOpen(true);
    }
    setLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} {...props} />;
  });

  return (
    <div className="account-section">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <h3>Email Preferences</h3>
      <div className="emailPreferences">
        <div className="emailPreferencesRow">
          <Checkbox
            checked={checked.newToolRelease}
            onChange={() => {
              handleChange('newToolRelease');
            }}
          />
          <span className="emailPreferencesLabel">Send me an email when a new calculation tool is released.</span>
        </div>

        <div className="emailPreferencesRow">
          <Checkbox
            checked={checked.newsletter}
            onChange={() => {
              handleChange('newsletter');
            }}
          />
          <span className="emailPreferencesLabel">Send me monthly newsletters.</span>
        </div>
        <div className="emailPreferencesRow">
          <Checkbox
            checked={checked.lastCalculation}
            onChange={() => {
              handleChange('lastCalculation');
            }}
          />{' '}
          <span className="emailPreferencesLabel">Send me an email after 30 days of inactive period.</span>
        </div>
        <div className="emailPreferencesRow">
          <Checkbox
            checked={checked.calculationCountReset}
            onChange={() => {
              handleChange('calculationCountReset');
            }}
          />{' '}
          <span className="emailPreferencesLabel">Send me an email after my calculation count resets to 0.</span>
        </div>
        <Button variant="contained" style={{ width: 300 }} onClick={updateUser} disabled={loading}>
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

export default ManageSubscription;
