import React from 'react';
import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { Context } from '../../../App';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Profile from './Profile';
import ChangePassword from './ChangePassword';
import Usage from './Usage';
import DeleteAccount from './DeleteAccount';
import ManageBilling from './Subscription';
import EmailPreferences from './EmailPreferences';

const Account = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Account';
  }, []);
  const { user, setUser, isResolved } = useContext(Context);
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Header />
      <div className="account">
        <div className="boxWrapper">
          <Box sx={{ maxWidth: { xs: 400, sm: 900 }, bgcolor: 'background.paper', textTransform: 'lowercase' }}>
            <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile>
              <Tab label="Name & Email" sx={{ textTransform: 'none' }} />
              <Tab label="Password" sx={{ textTransform: 'none' }} />
              <Tab label="Subscription" sx={{ textTransform: 'none' }} />
              <Tab label="Usage" sx={{ textTransform: 'none' }} />
              <Tab label="Email Preferences" sx={{ textTransform: 'none' }} />
              <Tab label="Delete account" sx={{ textTransform: 'none' }} />
            </Tabs>
          </Box>
        </div>
        {value === 0 && <Profile user={user} setUser={setUser} name={name} setName={setName} email={email} setEmail={setEmail} />}
        {value === 1 && <ChangePassword />}
        {value === 2 && <ManageBilling user={user} isResolved={isResolved} />}
        {value === 3 && <Usage user={user} />}
        {value === 4 && <EmailPreferences user={user} setUser={setUser} />}
        {value === 5 && <DeleteAccount />}
      </div>
      <Footer />
    </>
  );
};

export default Account;
