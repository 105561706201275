import round from '../../../helpers/round';
import stressIncrease_A from '../../../assets/calculations/stressIncrease/stressIncrease_A_001.jpg';
import stressIncrease_B from '../../../assets/calculations/stressIncrease/stressIncrease_B_001.jpg';

const StroudResult = ({ result }) => {
  const { q, B, L, z } = result.input;
  const { sigmaZ_60degree, sigmaZ_1H2V } = result.output;

  return (
    <section className="output">
      <h2>Output </h2>
      <div className="stressIncreaseContainer">
        <div className="stressIncreaseBox">
          <div className="stressIncreaseGraphContainer">
            <h4>1H:2V Approximation</h4>
            <img src={stressIncrease_A} alt="1H:2V approximation"></img>
          </div>
          <p>
            &sigma;<sub>z</sub> = qBL / [(L+z)(B+z)]
          </p>
          <p>&darr;</p>
          <p>
            &sigma;<sub>z</sub> = {round(q, 2)} x {round(B, 2)} x {round(L, 2)} / [({round(L, 2)} + {round(z, 2)})({round(B, 2)} +{' '}
            {round(z, 2)})]
          </p>
          <p>&darr;</p>
          <p>
            &sigma;<sub>z</sub> = {round(sigmaZ_1H2V, 2)} kPa
          </p>
        </div>
        <div className="stressIncreaseBox">
          <div className="stressIncreaseGraphContainer">
            <h4>
              30<sup>o</sup> Approximation
            </h4>

            <img src={stressIncrease_B} alt="30 degree approximation"></img>
          </div>

          <p>
            &sigma;<sub>z</sub> = qBL / [(L+2ztan30)(B+2ztan30)]
          </p>
          <p>&darr;</p>
          <p>
            &sigma;<sub>z</sub> = {round(q, 2)} x {round(B, 2)} x {round(L, 2)} / [({round(L, 2)} + 2({round(z, 2)})tan30)(
            {round(B, 2)} + 2({round(z, 2)})tan30)]
          </p>
          <p>&darr;</p>
          <p>
            &sigma;<sub>z</sub> = {round(sigmaZ_60degree, 2)} kPa
          </p>
        </div>
      </div>
    </section>
  );
};

export default StroudResult;
