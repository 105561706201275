import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import Header from '../../common/Header';
import Footer from '../../common/Footer';
import TransitionsModal from '../../modals/TransitionsModal';
import figureA from '../../../assets/calculations/weightVolume/FigureA.JPG';
import figureB from '../../../assets/calculations/weightVolume/FigureB.JPG';
import figureC from '../../../assets/calculations/weightVolume/FigureC.JPG';
import figureD from '../../../assets/calculations/weightVolume/FigureD.JPG';
import figureE from '../../../assets/calculations/weightVolume/FigureE.JPG';
import figureF from '../../../assets/calculations/weightVolume/FigureF.JPG';

const WeightVolumeRelationsTheory = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Weight - Volume Relationships | Theory';
  }, []);

  return (
    <>
      <Header />
      <div className="theory">
        <div className="theoryContainer">
          <h1>Weight - Volume Relationships | Theory & Formulations</h1>
          <h2>Weight - Volume Relationships</h2>
          <p>
            Figure A.a shows an element of soil of volume V and weight W as it would exist in a natural state. To develop the
            weight - volume relationships, we must separate the three phases (that is, solid, water, and air) as shown in Figure
            A.b. Thus, the total volume of a given soil sample can be expressed as;
          </p>
          <TransitionsModal
            image={figureA}
            title="Soil Element"
            description={'(a)Soil element in natural state, (b)Three phases of the soil element'}
            buttonText={'Figure A'}
          ></TransitionsModal>
          <p className="theoryEquation">
            <math display="block">
              <mi>V</mi>
              <mo>=</mo>
              <msub>
                <mi>V</mi>
                <mi>s</mi>
              </msub>
              <mo>+</mo>
              <msub>
                <mi>V</mi>
                <mi>v</mi>
              </msub>
              <mo>=</mo>
              <msub>
                <mi>V</mi>
                <mi>s</mi>
              </msub>
              <mo>+</mo>
              <msub>
                <mi>V</mi>
                <mi>w</mi>
              </msub>
              <mo>+</mo>
              <msub>
                <mi>V</mi>
                <mi>a</mi>
              </msub>
            </math>
            <span>(1)</span>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>
            V<sub>s</sub>: Volume of soil solids
          </p>
          <p>
            V<sub>v</sub>: Volume of voids
          </p>
          <p>
            V<sub>w</sub>: Volume of water in the voids
          </p>
          <p>
            V<sub>a</sub>: Volume of air in the voids
          </p>
          <p>Assuming that the weight of the air is negligible, we can give the total weight of the a sample as;</p>
          <p className="theoryEquation">
            <math display="block">
              <mi>W</mi>
              <mo>=</mo>
              <msub>
                <mi>W</mi>
                <mi>s</mi>
              </msub>
              <mo>+</mo>
              <msub>
                <mi>W</mi>
                <mi>v</mi>
              </msub>
            </math>
            <span>(2)</span>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>
            W<sub>s</sub>: Weight of soil solids
          </p>
          <p>
            W<sub>w</sub>: Weight of water
          </p>
          <p>
            The volume relationships commonly used for the three phases in a soil element are void ratio, porosity, and degree of
            saturation. Void ratio (e) is deﬁned as the ratio of the volume of voids to the volume of solids. Thus,
          </p>
          <p className="theoryEquation">
            <math display="block">
              <mi>e</mi>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>V</mi>
                  <mi>v</mi>
                </msub>
                <msub>
                  <mi>V</mi>
                  <mi>s</mi>
                </msub>
              </mfrac>
            </math>
            <span>(3)</span>
          </p>
          <p>Porosity (n) is deﬁned as the ratio of the volume of voids to the total volume, or</p>

          <p className="theoryEquation">
            <math display="block">
              <mi>n</mi>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>V</mi>
                  <mi>v</mi>
                </msub>
                <mi>V</mi>
              </mfrac>
            </math>
            <span>(4)</span>
          </p>
          <p>The degree of saturation (S) is deﬁned as the ratio of the volume of water to the volume of voids, or</p>
          <p className="theoryEquation">
            <math display="block">
              <mi>S</mi>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>V</mi>
                  <mi>w</mi>
                </msub>
                <msub>
                  <mi>V</mi>
                  <mi>v</mi>
                </msub>
              </mfrac>
            </math>
            <span>(5)</span>
          </p>
          <p>The relationship between void ratio and porosity can be derived from Eqs. (1), (3), and (4) as follows:</p>
          <p className="theoryEquation">
            <math display="block">
              <mi>e</mi>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>V</mi>
                  <mi>v</mi>
                </msub>
                <msub>
                  <mi>V</mi>
                  <mi>s</mi>
                </msub>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>V</mi>
                    <mi>v</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mi>V</mi>
                  <mo>-</mo>
                  <msub>
                    <mi>V</mi>
                    <mi>v</mi>
                  </msub>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mfrac>
                    <msub>
                      <mi>V</mi>
                      <mi>v</mi>
                    </msub>
                    <mi>V</mi>
                  </mfrac>
                </mrow>
                <mrow>
                  <mi>1</mi>
                  <mo>-</mo>
                  <mfrac>
                    <msub>
                      <mi>V</mi>
                      <mi>v</mi>
                    </msub>
                    <mi>V</mi>
                  </mfrac>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mi>n</mi>
                </mrow>
                <mrow>
                  <mn>1</mn>
                  <mo>-</mo>
                  <mi>n</mi>
                </mrow>
              </mfrac>
            </math>
            <span>(6)</span>
          </p>
          <p>Also from equation (6);</p>
          <p className="theoryEquation">
            <math display="block">
              <mi>n</mi>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mi>e</mi>
                </mrow>
                <mrow>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mi>e</mi>
                </mrow>
              </mfrac>
            </math>
            <span>(7)</span>
          </p>
          <p>
            The common terms used for weight relationships are moisture content and unit weight. Moisture content (w) is also
            referred to as water content and is deﬁned as the ratio of the weight of water to the weight of solids in a given
            volume of soil:
          </p>
          <p className="theoryEquation">
            <math display="block">
              <mi>w</mi>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>W</mi>
                  <mi>w</mi>
                </msub>
                <msub>
                  <mn>W</mn>
                  <mi>s</mi>
                </msub>
              </mfrac>
            </math>
            <span>(8)</span>
          </p>
          <p>Unit weight (&gamma;) is the weight of soil per unit volume. Thus,</p>
          <p className="theoryEquation">
            <math display="block">
              <mi>&gamma;</mi>
              <mo>=</mo>
              <mfrac>
                <mi>W</mi>
                <mn>V</mn>
              </mfrac>
            </math>
            <span>(9)</span>
          </p>
          <p>
            The unit weight can also be expressed in terms of the weight of soil solids, the moisture content, and the total
            volume. From Eqs. (2), (8), and (9),
          </p>
          <p className="theoryEquation">
            <math display="block">
              <mi>&gamma;</mi>
              <mo>=</mo>
              <mfrac>
                <mi>W</mi>
                <mn>V</mn>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>W</mi>
                    <mi>s</mi>
                  </msub>
                  <mo>+</mo>
                  <msub>
                    <mi>W</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mi>V</mi>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>W</mi>
                    <mi>s</mi>
                  </msub>
                  <mo>(</mo>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mfrac>
                    <msub>
                      <mi>W</mi>
                      <mi>w</mi>
                    </msub>
                    <msub>
                      <mi>W</mi>
                      <mi>s</mi>
                    </msub>
                  </mfrac>
                  <mo>)</mo>
                </mrow>
                <mrow>
                  <mi>V</mi>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>W</mi>
                    <mi>s</mi>
                  </msub>
                  <mo>(</mo>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mn>w</mn>
                  <mo>)</mo>
                </mrow>
                <mrow>
                  <mi>V</mi>
                </mrow>
              </mfrac>
            </math>
            <span>(10)</span>
          </p>
          <p>Soils engineers sometimes refer to the unit weight deﬁned by Eq. (9) as the moist unit weight</p>
          <p>
            Often, to solve earthwork problems, one must know the weight per unit volume of soil, excluding water. This weight is
            referred to as the dry unit weight, &gamma;<sub>d</sub>. Thus,
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&gamma;</mi>
                <mi>d</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>W</mi>
                  <mn>s</mn>
                </msub>
                <mi>V</mi>
              </mfrac>
            </math>
            <span>(11)</span>
          </p>
          <p>From Eqs. (10) and (11), the relationship of unit weight, dry unit weight, and moisture content can be given as;</p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&gamma;</mi>
                <mi>d</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mi>&gamma;</mi>
                </mrow>
                <mrow>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mi>w</mi>
                </mrow>
              </mfrac>
            </math>
            <span>(12)</span>
          </p>
          <p>
            Unit weight is expressed in English units (a gravitational system of measurement) as pounds per cubic foot (lb/ft
            <sup>3</sup> ). In SI (Système International), the unit used is kilo Newtons per cubic meter (kN/m<sup>3</sup>).
            Because the Newton is a derived unit, working with mass densities (&rho;) of soil may sometimes be convenient. The SI
            unit of mass density is kilograms per cubic meter (kg/m<sup>3</sup> ). We can write the density equations [similar to
            Eqs. (9) and (11)] as;
          </p>
          <p className="theoryEquation">
            <math display="block">
              <mi>&rho;</mi>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mi>M</mi>
                </mrow>
                <mrow>
                  <mi>V</mi>
                </mrow>
              </mfrac>
            </math>
            <span>(13)</span>
          </p>
          <p>and</p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&rho;</mi>
                <mi>d</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>M</mi>
                    <mi>s</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mi>V</mi>
                </mrow>
              </mfrac>
            </math>
            <span>(14)</span>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>
            &rho;: Density of soil (kg/m<sup>3</sup>)
          </p>
          <p>
            &rho;<sub>d</sub>: Dry density of soil (kg/m<sup>3</sup>)
          </p>
          <p>M: Total mass of the soil sample (kg)</p>
          <p>
            M<sub>s</sub>: Mass of soil solids in the sample (kg)
          </p>
          <p>
            V: Total volume (m<sup>3</sup>)
          </p>
          <h2>Relationships among Unit Weight, Void Ratio, Moisture Content, and Speciﬁc Gravity</h2>
          <p>
            To obtain a relationship among unit weight (or density), void ratio, and moisture content, let us consider a volume of
            soil in which the volume of the soil solids is one, as shown in Figure B. If the volume of the soil solids is one,
            then the volume of voids is numerically equal to the void ratio, e [from Eq. (3)]. The weights of soil solids and
            water can be given as;
          </p>
          <TransitionsModal
            image={figureB}
            title="Soil Element"
            description={'Three separate phases of a soil element with volume of soil solids equal to one'}
            buttonText={'Figure B'}
          ></TransitionsModal>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>W</mi>
                <mi>s</mi>
              </msub>
              <mo>=</mo>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
              <msub>
                <mi>&gamma;</mi>
                <mi>w</mi>
              </msub>
            </math>
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>W</mi>
                <mi>w</mi>
              </msub>
              <mo>=</mo>
              <mi>w</mi>
              <msub>
                <mi>W</mi>
                <mi>s</mi>
              </msub>
              <mo>=</mo>
              <mi>w</mi>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
              <msub>
                <mi>&gamma;</mi>
                <mi>w</mi>
              </msub>
            </math>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>
            G<sub>s</sub>: Speciﬁc gravity of soil solids
          </p>
          <p>w: Moisture content</p>
          <p>
            &gamma;<sub>w</sub>: Unit weight of water
          </p>
          <p>Now, using the deﬁnitions of unit weight and dry unit weight [Eqs. (9) and (11)], we can write;</p>
          <p className="theoryEquation">
            <math display="block">
              <mi>&gamma;</mi>
              <mo>=</mo>
              <mfrac>
                <mi>W</mi>
                <mi>V</mi>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>W</mi>
                    <mi>s</mi>
                  </msub>
                  <mo>+</mo>
                  <msub>
                    <mi>W</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mi>V</mi>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                  <mo>+</mo>
                  <mi>w</mi>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mn>e</mn>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mo>(</mo>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mi>w</mi>
                  <mo>)</mo>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mn>e</mn>
                </mrow>
              </mfrac>
            </math>
            <span>(15)</span>
          </p>
          <p>and</p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&gamma;</mi>
                <mi>d</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>W</mi>
                  <mi>s</mi>
                </msub>
                <mi>V</mi>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mi>1</mi>
                  <mo>+</mo>
                  <mn>e</mn>
                </mrow>
              </mfrac>
            </math>
            <span>(16)</span>
          </p>
          <p>or</p>
          <p className="theoryEquation">
            <math display="block">
              <mi>e</mi>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>d</mi>
                  </msub>
                </mrow>
              </mfrac>
              <mo>-</mo>
              <mi>1</mi>
            </math>
            <span>(17)</span>
          </p>
          <p>
            Because the weight of water for the soil element under consideration is wG<sub>s</sub>&gamma;<sub>w</sub> , the volume
            occupied by water is;
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>V</mi>
                <mi>s</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>W</mi>
                  <mi>w</mi>
                </msub>
                <msub>
                  <mi>&gamma;</mi>
                  <mi>w</mi>
                </msub>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mi>w</mi>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mi>w</mi>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
            </math>
          </p>
          <p>Hence, from the deﬁnition of degree of saturation [Eq. (5)],</p>
          <p className="theoryEquation">
            <math display="block">
              <mi>S</mi>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>V</mi>
                  <mi>w</mi>
                </msub>
                <msub>
                  <mi>V</mi>
                  <mi>v</mi>
                </msub>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mi>w</mi>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mi>e</mi>
                </mrow>
              </mfrac>
            </math>
          </p>
          <p className="theoryEquation">
            <math display="block">
              <mi>S</mi>
              <mi>e</mi>
              <mo>=</mo>
              <mi>w</mi>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
            </math>
            <span>(18)</span>
          </p>
          <p>This equation is useful for solving problems involving three-phase relationships.</p>
          <p>
            If the soil sample is saturated—that is, the void spaces are completely ﬁlled with water (Figure C)—the relationship
            for saturated unit weight (&gamma;<sub>sat</sub> ) can be derived in a similar manner:
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&gamma;</mi>
                <mi>sat</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <mi>W</mi>
                <mi>V</mi>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>W</mi>
                    <mi>s</mi>
                  </msub>
                  <mo>+</mo>
                  <msub>
                    <mi>W</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mi>V</mi>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                  <mo>+</mo>
                  <mi>e</mi>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mn>e</mn>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mo>(</mo>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <mo>+</mo>
                  <mi>e</mi>
                  <mo>)</mo>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mn>e</mn>
                </mrow>
              </mfrac>
            </math>
            <span>(19)</span>
          </p>
          <p>Also, from Eq. (18) with S = 1,</p>
          <p className="theoryEquation">
            <math display="block">
              <mi>e</mi>
              <mo>=</mo>
              <mi>w</mi>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
            </math>
            <span>(20)</span>
          </p>
          <p>
            As mentioned before, due to the convenience of working with densities in the SI system, the following equations,
            similar to unit-weight relationships given in Eqs. (15), (16), and (19), will be useful:
          </p>
          <TransitionsModal
            image={figureC}
            title="Soil Element"
            description={'Saturated soil element with volume of soil solids equal to one'}
            buttonText={'Figure C'}
          ></TransitionsModal>
          <p className="theoryEquation">
            <math display="block">
              <mi>&rho;</mi>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mo>(</mo>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mn>w</mn>
                  <mo>)</mo>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&rho;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mn>e</mn>
                </mrow>
              </mfrac>
            </math>
            <span>(21)</span>
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&rho;</mi>
                <mi>d</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&rho;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mn>e</mn>
                </mrow>
              </mfrac>
            </math>
            <span>(22)</span>
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&rho;</mi>
                <mi>sat</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mo>(</mo>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <mo>+</mo>
                  <mi>e</mi>
                  <mo>)</mo>
                  <msub>
                    <mi>&rho;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mn>e</mn>
                </mrow>
              </mfrac>
            </math>
            <span>(23)</span>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>&rho;: Density</p>
          <p>
            &rho;<sub>d</sub>: Dry density
          </p>
          <p>
            &rho;<sub>sat</sub>: Saturated density
          </p>
          <p>
            &rho;<sub>w</sub>: Density of water = 1000kg/m<sup>3</sup>
          </p>
          <p>
            Equation (21) may be derived by referring to the soil element shown in Figure D, in which the volume of soil solids is
            equal to 1 and the volume of voids is equal to e.
          </p>
          <TransitionsModal
            image={figureD}
            title="Soil Element"
            description={'Three separate phases of a soil element showing mass-volume relationship'}
            buttonText={'Figure D'}
          ></TransitionsModal>
          <p>
            Hence, the mass of soil solids, M<sub>s</sub> , is equal to G<sub>s</sub>&rho;<sub>w</sub> . The moisture content has
            been deﬁned in Eq. (8) as
          </p>
          <p className="theoryEquation">
            <math display="block">
              <mi>w</mi>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>W</mi>
                  <mi>w</mi>
                </msub>
                <msub>
                  <mi>W</mi>
                  <mi>s</mi>
                </msub>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mi>(mass of water).g</mi>
                <mi>(mass of solid).g</mi>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>M</mi>
                  <mi>w</mi>
                </msub>
                <msub>
                  <mi>M</mi>
                  <mi>s</mi>
                </msub>
              </mfrac>
            </math>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>
            M<sub>w</sub>: Mass of water
          </p>
          <p>
            Since the mass of soil in the element is equal to G<sub>s</sub>&rho;<sub>w</sub>, the mass of water;
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>M</mi>
                <mi>w</mi>
              </msub>
              <mo>=</mo>
              <mi>w</mi>
              <msub>
                <mi>M</mi>
                <mi>s</mi>
              </msub>
              <mo>=</mo>
              <mi>w</mi>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
              <msub>
                <mi>&rho;</mi>
                <mi>w</mi>
              </msub>
            </math>
          </p>
          <p>From Eq. (13), density;</p>
          <p className="theoryEquation">
            <math display="block">
              <mi>&rho;</mi>
              <mo>=</mo>
              <mfrac>
                <mi>M</mi>
                <mi>V</mi>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>M</mi>
                    <mi>s</mi>
                  </msub>
                  <mo>+</mo>
                  <msub>
                    <mi>M</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <msub>
                    <mi>V</mi>
                    <mi>s</mi>
                  </msub>
                  <mo>+</mo>
                  <msub>
                    <mi>V</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&rho;</mi>
                    <mi>w</mi>
                  </msub>
                  <mo>+</mo>
                  <mi>w</mi>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&rho;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mn>e</mn>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mo>(</mo>
                  <mi>1</mi>
                  <mo>+</mo>
                  <mi>w</mi>
                  <mo>)</mo>

                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&rho;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mn>1</mn>
                  <mo>+</mo>
                  <mn>e</mn>
                </mrow>
              </mfrac>
            </math>
          </p>
          <p>Equations (22) and (23) can be derived similarly.</p>
          <h2>Relationships among Unit Weight, Porosity and Moisture Content</h2>
          <p>
            The relationship among unit weight, porosity, and moisture content can be developed in a manner similar to that
            presented in the preceding section. Consider a soil that has a total volume equal to one, as shown in Figure E. From
            Eq. (4);
          </p>
          <p className="theoryEquation">
            <math display="block">
              <mi>n</mi>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>V</mi>
                  <mi>v</mi>
                </msub>
                <mi>V</mi>
              </mfrac>
            </math>
          </p>
          <TransitionsModal
            image={figureE}
            title="Soil Element"
            description={'Soil element with total volume equal to one'}
            buttonText={'Figure E'}
          ></TransitionsModal>
          <p>
            If V is equal to 1, then V<sub>v</sub> is equal to n, so V<sub>s</sub>=1-n. The weight of soil solids (W<sub>s</sub>)
            and the weight of water W<sub>w</sub> can then be expressed as follows:
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>W</mi>
                <mi>s</mi>
              </msub>
              <mo>=</mo>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
              <msub>
                <mi>&gamma;</mi>
                <mi>w</mi>
              </msub>
              <mo>(</mo>
              <mn>1</mn>
              <mo>-</mo>
              <mi>n</mi>
              <mo>)</mo>
            </math>
            <span>(24)</span>
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>W</mi>
                <mi>w</mi>
              </msub>
              <mo>=</mo>
              <mi>w</mi>
              <msub>
                <mi>W</mi>
                <mi>s</mi>
              </msub>
              <mo>=</mo>
              <mi>w</mi>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
              <msub>
                <mi>&gamma;</mi>
                <mi>w</mi>
              </msub>
              <mo>(</mo>
              <mn>1</mn>
              <mo>-</mo>
              <mi>n</mi>
              <mo>)</mo>
            </math>
            <span>(25)</span>
          </p>
          <p>So, the dry unit weight equals</p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&gamma;</mi>
                <mi>d</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>W</mi>
                  <mi>s</mi>
                </msub>
                <mi>V</mi>
              </mfrac>

              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                  <mo>(</mo>
                  <mn>1</mn>
                  <mo>-</mo>
                  <mi>n</mi>
                  <mo>)</mo>
                </mrow>
                <mrow>
                  <mn>1</mn>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
              <msub>
                <mi>&gamma;</mi>
                <mi>w</mi>
              </msub>
              <mi>(1 - n)</mi>
            </math>
            <span>(26)</span>
          </p>
          <p>The moist unit weight equals;</p>
          <p className="theoryEquation">
            <math display="block">
              <mi>&gamma;</mi>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>W</mi>
                    <mi>s</mi>
                  </msub>
                  <mo>+</mo>
                  <msub>
                    <mi>W</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mi>V</mi>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
              <msub>
                <mi>&gamma;</mi>
                <mi>w</mi>
              </msub>
              <mi>(1 - n)</mi>
              <mi>(1 + w)</mi>
            </math>
            <span>(27)</span>
          </p>
          <p>Figure F shows a soil sample that is saturated and has V = 1. According to this ﬁgure,</p>
          <TransitionsModal
            image={figureF}
            title="Soil Element"
            description={'Saturated soil element with total volume equal to one'}
            buttonText={'Figure F'}
          ></TransitionsModal>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&gamma;</mi>
                <mi>sat</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>W</mi>
                    <mi>s</mi>
                  </msub>
                  <mo>+</mo>
                  <msub>
                    <mi>W</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mi>V</mi>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mi>(1 - n)</mi>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
              <msub>
                <mi>&gamma;</mi>
                <mi>w</mi>
              </msub>
              <mo>+</mo>
              <mi>n</mi>
              <msub>
                <mi>&gamma;</mi>
                <mi>w</mi>
              </msub>
              <mo>=</mo>
              <mo>[</mo>
              <mo>(</mo>
              <mn>(1 - n)</mn>
              <mo>)</mo>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
              <mo>+</mo>
              <mi>n</mi>
              <mo>]</mo>
              <msub>
                <mi>&gamma;</mi>
                <mi>w</mi>
              </msub>
            </math>
            <span>(28)</span>
          </p>
          <p className="theoryEquation">
            <math display="block">
              <mo>=</mo>
              <mo>[</mo>
              <mo>(</mo>
              <mn>1</mn>
              <mo>-</mo>
              <mn>n</mn>
              <mo>)</mo>
              <msub>
                <mi>G</mi>
                <mi>s</mi>
              </msub>
              <mo>+</mo>
              <mi>n</mi>
              <mo>]</mo>
              <msub>
                <mi>&gamma;</mi>
                <mi>w</mi>
              </msub>
            </math>
          </p>
          <p>The moisture content of a saturated soil sample can be expressed as;</p>
          <p className="theoryEquation">
            <math display="block">
              <mi>w</mi>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>W</mi>
                  <mi>w</mi>
                </msub>
                <msub>
                  <mi>W</mi>
                  <mi>s</mi>
                </msub>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mi>n</mi>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mo>(</mo>
                  <mn>1</mn>
                  <mo>-</mo>
                  <mi>n</mi>
                  <mo>)</mo>
                  <msub>
                    <mi>&gamma;</mi>
                    <mi>w</mi>
                  </msub>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                </mrow>
              </mfrac>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mi>n</mi>
                </mrow>
                <mrow>
                  <mo>(</mo>
                  <mn>1</mn>
                  <mo>-</mo>
                  <mi>n</mi>
                  <mo>)</mo>
                  <msub>
                    <mi>G</mi>
                    <mi>s</mi>
                  </msub>
                </mrow>
              </mfrac>
            </math>
            <span>(29)</span>
          </p>
          <p className="citation">
            Principles of Geotechnical Engineering, Braja M. DAS (7<sup>th</sup> Edition)
          </p>
          <div className="theoryButtonContainer">
            <Link to={'/calculations/weightVolumeRelations'}>
              <Button variant="outlined" size="small">
                GO TO CALCULATOR
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WeightVolumeRelationsTheory;
