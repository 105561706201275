import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InputAdornment from '@mui/material/InputAdornment';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MuiSnackbar from '../../common/MuiSnackbar';
import CircularProgress from '@mui/material/CircularProgress';

import CalculateButton from '../../common/calculateButton';
import SaveButton from '../../common/saveButton';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Sidebar from '../../sidebar/Sidebar';
import DecisionModal from '../../modals/DecisionModal';
import PilerocksettleResult from './PilerocksettleResult';
import round from '../../../helpers/round';
import TransitionsModal from '../../modals/TransitionsModal';
import figure from '../../../assets/calculations/pilerocksettle/1.png';

import { useCalculationContext, CalculationContextProvider } from '../../context/calculationContext';

const PileRockSettle = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Immediate Settlement of Pile Foundations in Rock, AASHTO (2002)';
  }, []);

  const {
    MAX_NAME_LENGTH,
    isLoadingCalculate,
    isLoadingLoadAll,
    isLoadingLoad,
    isLoadingSave,
    result,
    calculations,
    lastData,
    showOutput,
    setShowOutput,
    calculateButtonDisabled,
    setCalculateButtonDisabled,
    saveButtonDisabled,
    setSaveButtonDisabled,
    errorMessage,
    displaySidebar,
    setDisplaySidebar,
    decisionModalIsOpen,
    decision,
    setDecision,
    handleSidebarDisplay,
    setDecisionModalIsOpenToFalse,
    showMuiSnack,
    open,
    setOpen,
    alertMessage,
    severity,
    onChangeWarningForPositiveDecimals,
    calculate,
    loadOneCalculation,
    loadAllCalculations,
    update,
    checkCalculationNameforSaving,
    checkCalculationNameforSavingAs,
    deleteCalculations,
    calculationCountReached,
    saveCountReached,
  } = useCalculationContext();

  //SET STATES FOR INPUT VARIABLES
  const [name, setName] = useState('');
  const [Q, setQ] = useState('');
  const [socketLength, setsocketLength] = useState('');
  const [socketDiameter, setsocketDiameter] = useState('');
  const [ec, setec] = useState('');
  const [em, setem] = useState('');
  const [ips, setips] = useState('');

  //SET STATE FOR FRONTEND CALCULATIONS\
  const [drOverBr, setDrOverBr] = useState('');
  const [ecOverEm, setEcOverEm] = useState('');

  //FRONTEND CALCULATIONS
  useEffect(() => {
    if (socketLength && socketDiameter) {
      setDrOverBr(round(socketLength / socketDiameter, 2));
    } else {
      setDrOverBr('');
    }
    if (ec && em) {
      setEcOverEm(round(ec / em, 2));
    } else {
      setEcOverEm('');
    }
  }, [socketLength, socketDiameter, ec, em]);

  // SET THE LOADED INPUTS
  const setInputs = (inputs) => {
    setName(inputs.name);
    setQ(inputs.Q);
    setsocketLength(inputs.socketLength);
    setsocketDiameter(inputs.socketDiameter);
    setec(inputs.ec);
    setem(inputs.em);
    setips(inputs.ips);
  };

  //INPUTS FOR CALCULATION
  const calculationInput = {
    name,
    Q,
    socketLength,
    socketDiameter,
    ec,
    em,
    ips,
  };

  // MANAGE SAVE & CALCULATE BUTTONS DISABLED/ENABLED STATES
  useEffect(() => {
    setCalculateButtonDisabled(false);
    setSaveButtonDisabled(false);

    if (
      (calculationInput.name || calculationInput.name === 0) &&
      (calculationInput.Q || calculationInput.Q === 0) &&
      (calculationInput.socketLength || calculationInput.socketLength === 0) &&
      (calculationInput.socketDiameter || calculationInput.socketDiameter === 0) &&
      (calculationInput.ec || calculationInput.ec === 0) &&
      (calculationInput.em || calculationInput.em === 0) &&
      (calculationInput.ips || calculationInput.ips === 0)
    ) {
      setCalculateButtonDisabled(false);
      setSaveButtonDisabled(false);
    } else {
      setCalculateButtonDisabled(true);
      setSaveButtonDisabled(true);
    }
  }, [
    calculationInput.name,
    calculationInput.Q,
    calculationInput.socketLength,
    calculationInput.socketDiameter,
    calculationInput.ec,
    calculationInput.em,
    calculationInput.ips,
    setCalculateButtonDisabled,
    setSaveButtonDisabled,
  ]);

  // Do not render output if any input parameter except name changes
  useEffect(() => {
    setShowOutput(false);
  }, [
    calculationInput.Q,
    calculationInput.socketLength,
    calculationInput.socketDiameter,
    calculationInput.ec,
    calculationInput.em,
    setShowOutput,
  ]);

  //CLEAR INPUTS AND CLEAR OUTPUT
  const createNewCalculation = () => {
    setName('');
    setQ('');
    setsocketLength('');
    setsocketDiameter('');
    setec('');
    setem('');
    setips('');
    setShowOutput(false);
  };

  //API URLS
  const URL = {
    CALCULATE: '/api/v1/calculations/pile-rock-settle-aashto2002',
    LOADONE: `/api/v1/calculations/pile-rock-settle-aashto2002/loadOne/`,
    LOADALL: '/api/v1/calculations/pile-rock-settle-aashto2002/loadAll',
    UPDATE: `/api/v1/calculations/pile-rock-settle-aashto2002/update/`,
    SAVE: '/api/v1/calculations/pile-rock-settle-aashto2002/save',
    CHECKUNIQUENAME: `/api/v1/calculations/pile-rock-settle-aashto2002/checkUniqueName/`,
    DELETE: '/api/v1/calculations/pile-rock-settle-aashto2002/delete',
  };

  const handleChangeName = (e) => {
    if (e.target.value.length >= MAX_NAME_LENGTH) {
      showMuiSnack(`Calculation name can be max ${MAX_NAME_LENGTH} characters.`, 'warning');
    }
    setName(e.target.value);
  };

  // RETURN INPUT AND OUTPUT JSX
  return (
    <>
      <Header />
      <div className="container">
        <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
        {displaySidebar ? (
          <Sidebar
            URL={URL}
            loadAllCalculations={loadAllCalculations}
            calculations={calculations}
            loadOneCalculation={loadOneCalculation}
            calculate={calculate}
            setInputs={setInputs}
            deleteCalculations={deleteCalculations}
            createNewCalculation={createNewCalculation}
            isLoading={isLoadingLoadAll}
            handleSidebarDisplay={handleSidebarDisplay}
            displaySidebar={displaySidebar}
            setDisplaySidebar={setDisplaySidebar}
          />
        ) : (
          <MenuOpenIcon
            color="primary"
            fontSize="large"
            className="sidebar-open-icon"
            alt="side bar menu icon"
            label="Show my calculations"
            onClick={handleSidebarDisplay}
          />
        )}
        <DecisionModal
          URL={URL}
          data={calculationInput}
          decisionModalIsOpen={decisionModalIsOpen}
          setDecisionModalIsOpenToFalse={setDecisionModalIsOpenToFalse}
          decision={decision}
          setDecision={setDecision}
          checkCalculationNameforSavingAs={checkCalculationNameforSavingAs}
          name={name}
          setName={setName}
          errorMessage={errorMessage}
          update={update}
          saveButtonDisabled={saveButtonDisabled}
        />
        <div className="right-calculation">
          <section className="input pilerocksettleInputs">
            <h1>Immediate Settlement of Pile Foundations in Rock, AASHTO (2002)</h1>

            <form>
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label="Name"
                variant="outlined"
                inputProps={{
                  maxLength: `${MAX_NAME_LENGTH}`,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={`Your calculation name (Max. ${MAX_NAME_LENGTH} characters)`}
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={name}
                onChange={handleChangeName}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label="Q (MN)"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Total axial compression load applied to shaft butt"
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={Q}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Q', setQ)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'dr'}>
                    D<sub>r</sub> (m)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Socket length." arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={socketLength}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Dr', setsocketLength)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'br'}>
                    B<sub>r</sub> (m)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Socket diameter." arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={socketDiameter}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Br', setsocketDiameter)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />

              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'droverbr'}>
                    D<sub>r</sub>/B<sub>r</sub>
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Socket length / Socket diameter ratio." arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                readOnly
                disabled={true}
                value={drOverBr}
              />

              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label={
                  <span key={'ec'}>
                    E<sub>c</sub> (MPa)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Elastic modulus of concrete shaft or reinforced shaft."
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={ec}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Ec', setec)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label={
                  <span key={'em'}>
                    E<sub>m</sub> (MPa)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Elastic modulus of rock mass." arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={em}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Em', setem)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'ecoverem'}>
                    E<sub>c</sub>/E<sub>m</sub>
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Ec / Em ratio." arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                readOnly
                disabled={true}
                value={ecOverEm}
              />
              <TextField
                size="small"
                style={{ minWidth: 80, maxWidth: 120 }}
                label={
                  <span key={'ips'}>
                    I<sub>ps</sub>
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Displacement influence factor for rock-socketed shafts loaded in compression. Ips should be selected from Figure 4.6.5.5.2A using Dr / Br and Ec / Em ratios."
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={ips}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Ips', setips)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TransitionsModal
                image={figure}
                title="Immediate Settlement of Pile Foundations in Rock, AASHTO (2002)"
                description={'Determine Ips using Dr/Br and Ec/Em ratios'}
                buttonText={'Open Figure 4.6.5.5.2A'}
              ></TransitionsModal>

              <div className="buttonContainer">
                <CalculateButton
                  calculationCountReached={calculationCountReached}
                  calculate={calculate}
                  calculationInput={calculationInput}
                  URL={URL}
                  calculateButtonDisabled={calculateButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
                {isLoadingLoad && <CircularProgress size={30} />}

                <SaveButton
                  saveCountReached={saveCountReached}
                  checkCalculationNameforSaving={checkCalculationNameforSaving}
                  calculationInput={calculationInput}
                  URL={URL}
                  saveButtonDisabled={saveButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
              </div>
            </form>
          </section>
          {showOutput && <PilerocksettleResult data={lastData} result={result} />}
          {isLoadingCalculate && (
            <div className="centerLoading">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

const PileRockSettleWithProvider = () => {
  return (
    <CalculationContextProvider>
      <PileRockSettle />
    </CalculationContextProvider>
  );
};
export default PileRockSettleWithProvider;
