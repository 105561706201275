import React from 'react';
import GroupIcon from '@mui/icons-material/Group';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';

import { CircularProgress } from '@mui/material';

const RoleSummary = ({ loading, userCounts }) => {
  return (
    <div className="UserSummary">
      <div className="summaryContainer">
        <div className="summary">
          <div className="number">{loading ? <CircularProgress size={30} /> : userCounts.totalGiveawayUsers}</div>
          <div className="text">Giveaway Users</div>
        </div>
        <div className="icon">
          <GroupIcon color="primary" fontSize="large" />
        </div>
      </div>
      <Divider />
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Basic:</div>{' '}
          <div>{loading ? <CircularProgress size={10} /> : userCounts.giveawayActiveBasics}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.giveawayActiveBasics / userCounts.totalGiveawayUsers) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.giveawayActiveBasics / userCounts.totalGiveawayUsers) * 100)}%`}
        />
      </div>
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Standard:</div>{' '}
          <div>{loading ? <CircularProgress size={10} /> : userCounts.giveawayActiveStandards}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.giveawayActiveStandards / userCounts.totalGiveawayUsers) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.giveawayActiveStandards / userCounts.totalGiveawayUsers) * 100)}%`}
        />
      </div>
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Premium:</div>{' '}
          <div>{loading ? <CircularProgress size={10} /> : userCounts.giveawayActivePremiums}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.giveawayActivePremiums / userCounts.totalGiveawayUsers) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.giveawayActivePremiums / userCounts.totalGiveawayUsers) * 100)}%`}
        />
      </div>
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Professional: </div>
          <div>{loading ? <CircularProgress size={10} /> : userCounts.giveawayActiveProfessionals}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.giveawayActiveProfessionals / userCounts.totalGiveawayUsers) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.giveawayActiveProfessionals / userCounts.totalGiveawayUsers) * 100)}%`}
        />
      </div>
    </div>
  );
};

export default RoleSummary;
