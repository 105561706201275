import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AdminSendEmailConfirmModal from '../../modals/AdminSendEmailConfirmModal';
import MuiSnackbar from '../../common/MuiSnackbar';
import axios from 'axios';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const SendReminderToNonCalculating = ({ lastSentTimes, lastSentTimesLoading }) => {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [total, setTotal] = useState();
  const [successCount, setSuccessCount] = useState();
  const [failCount, setFailCount] = useState();

  const sendEmailToNoncalculatingUsers = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: 'POST',
        url: '/api/v1/admin/sendEmailToNoncalculatingUsers',
      });
      setOpen(true);
      setAlertMessage(response.data.message);
      setTotal(response.data.total);
      setSuccessCount(response.data.successCount);
      setFailCount(response.data.failCount);
      setSeverity('success');
    } catch (err) {
      setOpen(true);
      setAlertMessage(err.response.data.message);
      setSeverity('error');
    }
    setLoading(false);
  };

  return (
    <div className="emailSendBox">
      <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
      <AdminSendEmailConfirmModal open={modalOpen} setOpen={setModalOpen} queryFunction={sendEmailToNoncalculatingUsers} />
      <h2>Non-calculating Users</h2>
      <div className="definition">
        Send reminder email to all the users who did not made a calculation for the last 7 days. Email will be sent to only
        'Basic' users.
      </div>
      <div className="button">
        <Button
          variant="contained"
          onClick={() => {
            setModalOpen(true);
          }}
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send Email'}
        </Button>
      </div>
      <Divider sx={{ marginTop: '10px' }} />
      <div className="mailSummary">
        <span>Total: {loading ? <CircularProgress size={10} /> : total ? total : '-'}</span>
        <span>Success: {loading ? <CircularProgress size={10} /> : successCount ? successCount : '-'}</span>
        <span>Fail: {loading ? <CircularProgress size={10} /> : failCount ? failCount : '-'}</span>
      </div>
      <Divider sx={{ marginTop: '10px' }} />
      <div className="mailSummary">
        <span>
          Last sent at:{' '}
          {lastSentTimesLoading ? (
            <CircularProgress size={10} />
          ) : lastSentTimes.nonCalculatingUsersLastSentDate ? (
            lastSentTimes.nonCalculatingUsersLastSentDate
          ) : (
            '-'
          )}
        </span>
      </div>
    </div>
  );
};

export default SendReminderToNonCalculating;
