import React, { useState, useContext, useEffect, forwardRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import EmailIcon from '@mui/icons-material/Email';

import { Context } from '../../App';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { apiSendContactMail, apiCaptchaVerification } from '../../helpers/apiRequests';

const Contact = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Contact';
  }, []);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [captchaFirstNumber, setCaptchaFirstNumber] = useState('');
  const [captchaSecondNumber, setCaptchaSecondNumber] = useState('');
  const [captchaSign, setCaptchaSign] = useState('');
  const [captchaResult, setCaptchaResult] = useState('');

  const captchaData = {
    captchaFirstNumber,
    captchaSecondNumber,
    captchaSign,
    captchaResult,
  };

  const refreshCaptcha = () => {
    setCaptchaFirstNumber(Math.floor(Math.random() * 9 + 1));
    setCaptchaSecondNumber(Math.floor(Math.random() * 9 + 1));
    setCaptchaSign(Math.random() > 0.5 ? '+' : 'x');
    setCaptchaResult('');
  };

  useEffect(() => {
    refreshCaptcha();
  }, []);

  const { user } = useContext(Context);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
    }
  }, [user]);

  const data = {
    name,
    email,
    subject,
    message,
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (regex.test(email)) {
      return true;
    }
    return false;
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
    if (e.target.value.length > 0) {
      setError((state) => ({ ...state, name: false }));
      setHelperText((state) => ({ ...state, name: '' }));
    } else {
      setError((state) => ({ ...state, name: true }));
      setHelperText((state) => ({ ...state, name: 'Please write your name.' }));
    }
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value.length > 0) {
      if (validateEmail(e.target.value)) {
        setError((state) => ({ ...state, email: false }));
        setHelperText((state) => ({ ...state, email: '' }));
      } else {
        setError((state) => ({ ...state, email: true }));
        setHelperText((state) => ({ ...state, email: 'Please write a valid email' }));
      }
    } else {
      setError((state) => ({ ...state, email: true }));
      setHelperText((state) => ({ ...state, email: 'Please write your email.' }));
    }
  };

  const handleChangeSubject = (e) => {
    setSubject(e.target.value);
    if (e.target.value.length > 0) {
      setError((state) => ({ ...state, subject: false }));
      setHelperText((state) => ({ ...state, subject: '' }));
    } else {
      setError((state) => ({ ...state, subject: true }));
      setHelperText((state) => ({ ...state, subject: 'Please write your subject.' }));
    }
  };

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
    if (e.target.value.length > 0) {
      setError((state) => ({ ...state, message: false }));
      setHelperText((state) => ({ ...state, message: '' }));
    } else {
      setError((state) => ({ ...state, message: true }));
      setHelperText((state) => ({ ...state, message: 'Please write your message.' }));
    }
  };

  const handleChangeCaptcha = (e) => {
    setCaptchaResult(e.target.value);
    if (e.target.value.length > 0) {
      setError((state) => ({ ...state, captcha: false }));
      setHelperText((state) => ({ ...state, captcha: '' }));
    } else {
      setError((state) => ({ ...state, captcha: true }));
      setHelperText((state) => ({ ...state, captcha: 'Please do the math.' }));
    }
  };

  const [error, setError] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
    captcha: false,
  });

  const [helperText, setHelperText] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    captcha: '',
  });

  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);

  useEffect(() => {
    if (
      loading ||
      error.name ||
      name.length === 0 ||
      error.email ||
      email.length === 0 ||
      error.subject ||
      subject.length === 0 ||
      error.message ||
      message.length === 0 ||
      error.captcha ||
      captchaResult.length === 0
    ) {
      setIsSendButtonDisabled(true);
    } else {
      setIsSendButtonDisabled(false);
    }
  }, [
    loading,
    error.name,
    error.email,
    error.subject,
    error.message,
    error.captcha,
    name.length,
    email.length,
    subject.length,
    message.length,
    captchaResult.length,
  ]);

  const sendContactMail = async (e) => {
    e.preventDefault();

    if (!name) {
      setError((state) => ({ ...state, name: true }));
      setHelperText((state) => ({ ...state, name: 'Please write your name.' }));
      return;
    }

    if (!email) {
      setError((state) => ({ ...state, email: true }));
      setHelperText((state) => ({ ...state, email: 'Please write your email.' }));
      return;
    } else if (!validateEmail(email)) {
      setError((state) => ({ ...state, email: true }));
      setHelperText((state) => ({ ...state, email: 'Please write a valid email.' }));
      return;
    }

    if (!subject) {
      setError((state) => ({ ...state, subject: true }));
      setHelperText((state) => ({ ...state, subject: 'Please write your subject.' }));
      return;
    }

    if (!message) {
      setError((state) => ({ ...state, message: true }));
      setHelperText((state) => ({ ...state, message: 'Please write your message.' }));
      return;
    }

    if (!captchaResult) {
      setError((state) => ({ ...state, captcha: true }));
      setHelperText((state) => ({ ...state, captcha: 'Please do the math.' }));
      return;
    }

    const response = await apiCaptchaVerification(captchaData);

    if (response.data) {
      try {
        setLoading(true);
        await apiSendContactMail(data);
        setLoading(false);
        showMuiSnack("Thanks for the message. We'll get back to you :)", 'success');
        refreshCaptcha();
        setSubject('');
        setMessage('');
      } catch (err) {
        showMuiSnack('Something is wrong!', 'error');
        refreshCaptcha();
      }
    } else {
      showMuiSnack('Result is wrong. Try again please.', 'error');
      setError((state) => ({ ...state, captcha: true }));
      setHelperText((state) => ({ ...state, captcha: 'Please do the math.' }));
      refreshCaptcha();
    }
  };

  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const showMuiSnack = (message, type) => {
    setAlertMessage(message);
    setSeverity(type);
    setOpen(true);
  };

  return (
    <div>
      <Header />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <div className="contact">
        <div className="contactContainer">
          <h1>Contact Us</h1>
          <p>Any questions or remarks? Just write us a message. Our team will get back to you within 24 hours.</p>
          <div className="contactInfo">
            <div className="infoBox">
              <div className="icon">
                <LocationOnIcon color="primary" fontSize="large" />
              </div>
              <div className="text"> Urla / İzmir / Turkey</div>
            </div>
            <div className="infoBox">
              <div className="icon">
                <EmailIcon color="primary" fontSize="large" />
              </div>
              <div className="text">info@geocalc.app</div>
            </div>
          </div>
          <div className="contactForm">
            <form id="contact-form">
              <TextField
                fullWidth
                size="small"
                error={error.name}
                helperText={helperText.name}
                label="Name"
                variant="outlined"
                value={name}
                placeholder="Your name here"
                onChange={handleChangeName}
              />
              <TextField
                fullWidth
                size="small"
                error={error.email}
                helperText={helperText.email}
                label="Email"
                variant="outlined"
                value={email}
                placeholder="user@example.com"
                onChange={handleChangeEmail}
              />
              <TextField
                fullWidth
                size="small"
                error={error.subject}
                helperText={helperText.subject}
                label="Subject"
                variant="outlined"
                value={subject}
                placeholder="What is it about?"
                onChange={handleChangeSubject}
              />
              <TextField
                inputProps={{ style: { textAlign: 'justify' } }}
                fullWidth
                size="small"
                error={error.message}
                helperText={helperText.message}
                label="Your message"
                variant="outlined"
                multiline
                rows={10}
                value={message}
                placeholder="Your message here"
                onChange={handleChangeMessage}
              />

              <div className="contactButtonContainer">
                <div className="captcha">
                  <div className="refreshImage">
                    <RefreshRoundedIcon fontSize="large" color="primary" className="refreshIcon" onClick={refreshCaptcha} />
                  </div>
                  <div className="numberContainer">
                    <span>{captchaFirstNumber} </span> <span>{captchaSign} </span>
                    <span>{captchaSecondNumber} </span> =
                  </div>
                  <TextField
                    size="small"
                    error={error.captcha}
                    helperText={helperText.captcha}
                    label="Result"
                    variant="outlined"
                    value={captchaResult}
                    onChange={handleChangeCaptcha}
                    placeholder="Result ?"
                  />
                </div>
                <Button variant="contained" fullWidth onClick={sendContactMail} disabled={isSendButtonDisabled}>
                  {loading ? 'Sending...' : 'Send'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
