import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InputAdornment from '@mui/material/InputAdornment';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MuiSnackbar from '../../common/MuiSnackbar';
import CircularProgress from '@mui/material/CircularProgress';

import CalculateButton from '../../common/calculateButton';
import SaveButton from '../../common/saveButton';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Sidebar from '../../sidebar/Sidebar';
import DecisionModal from '../../modals/DecisionModal';
import ClayEffectiveFrictionAngleResult from './ClayEffectiveFrictionAngleResult';

import { useCalculationContext, CalculationContextProvider } from '../../context/calculationContext';

const ClayEffectiveFrictionAngle = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Effective friction angle for clays';
  }, []);

  const {
    MAX_NAME_LENGTH,
    isLoadingCalculate,
    isLoadingLoadAll,
    isLoadingLoad,
    isLoadingSave,
    result,
    calculations,
    showOutput,
    setShowOutput,
    calculateButtonDisabled,
    setCalculateButtonDisabled,
    saveButtonDisabled,
    setSaveButtonDisabled,
    errorMessage,
    displaySidebar,
    setDisplaySidebar,
    decisionModalIsOpen,
    decision,
    setDecision,
    handleSidebarDisplay,
    setDecisionModalIsOpenToFalse,
    showMuiSnack,
    open,
    setOpen,
    alertMessage,
    severity,
    onChangeWarningForPositiveDecimals,
    calculate,
    loadOneCalculation,
    loadAllCalculations,
    update,
    checkCalculationNameforSaving,
    checkCalculationNameforSavingAs,
    deleteCalculations,
    calculationCountReached,
    saveCountReached,
  } = useCalculationContext();

  //SET STATES FOR INPUT VARIABLES
  const [name, setName] = useState('');
  const [pi, setPi] = useState('');

  // SET THE LOADED INPUTS
  const setInputs = (inputs) => {
    setName(inputs.name);
    setPi(inputs.pi);
  };

  //INPUTS FOR CALCULATION
  const calculationInput = {
    name,
    pi,
  };

  // MANAGE SAVE & CALCULATE BUTTONS DISABLED/ENABLED STATES
  useEffect(() => {
    setCalculateButtonDisabled(false);
    setSaveButtonDisabled(false);

    if ((calculationInput.name || calculationInput.name === 0) && (calculationInput.pi || calculationInput.pi === 0)) {
      setCalculateButtonDisabled(false);
      setSaveButtonDisabled(false);
    } else {
      setCalculateButtonDisabled(true);
      setSaveButtonDisabled(true);
    }
  }, [setCalculateButtonDisabled, setSaveButtonDisabled, calculationInput.name, calculationInput.pi]);

  // Do not render output if any input parameter except name changes
  useEffect(() => {
    setShowOutput(false);
  }, [setShowOutput, calculationInput.pi]);

  //CLEAR INPUTS AND CLEAR OUTPUT
  const createNewCalculation = () => {
    setName('');
    setPi('');
    setShowOutput(false);
  };

  //API URLS
  const URL = {
    CALCULATE: '/api/v1/calculations/clayEffectiveFrictionAngle',
    LOADONE: `/api/v1/calculations/clayEffectiveFrictionAngle/loadOne/`,
    LOADALL: '/api/v1/calculations/clayEffectiveFrictionAngle/loadAll',
    UPDATE: `/api/v1/calculations/clayEffectiveFrictionAngle/update/`,
    SAVE: '/api/v1/calculations/clayEffectiveFrictionAngle/save',
    CHECKUNIQUENAME: `/api/v1/calculations/clayEffectiveFrictionAngle/checkUniqueName/`,
    DELETE: '/api/v1/calculations/clayEffectiveFrictionAngle/delete',
  };

  const handleChangeName = (e) => {
    if (e.target.value.length >= MAX_NAME_LENGTH) {
      showMuiSnack(`Calculation name can be max ${MAX_NAME_LENGTH} characters.`, 'warning');
    }
    setName(e.target.value);
  };

  //---------------------------------------------//
  // RETURN INPUT AND OUTPUT JSX
  return (
    <>
      <Header />
      <div className="container">
        <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
        {displaySidebar ? (
          <Sidebar
            URL={URL}
            loadAllCalculations={loadAllCalculations}
            calculations={calculations}
            loadOneCalculation={loadOneCalculation}
            calculate={calculate}
            setInputs={setInputs}
            deleteCalculations={deleteCalculations}
            createNewCalculation={createNewCalculation}
            isLoading={isLoadingLoadAll}
            handleSidebarDisplay={handleSidebarDisplay}
            displaySidebar={displaySidebar}
            setDisplaySidebar={setDisplaySidebar}
          />
        ) : (
          <MenuOpenIcon
            color="primary"
            fontSize="large"
            className="sidebar-open-icon"
            alt="side bar menu icon"
            label="Show my calculations"
            onClick={handleSidebarDisplay}
          />
        )}
        <DecisionModal
          URL={URL}
          data={calculationInput}
          decisionModalIsOpen={decisionModalIsOpen}
          setDecisionModalIsOpenToFalse={setDecisionModalIsOpenToFalse}
          decision={decision}
          setDecision={setDecision}
          checkCalculationNameforSavingAs={checkCalculationNameforSavingAs}
          name={name}
          setName={setName}
          errorMessage={errorMessage}
          update={update}
          saveButtonDisabled={saveButtonDisabled}
        />
        <div className="right-calculation">
          <section className="input">
            <h1>PI vs &phi;' Relationships | Terzaghi, Peck, Mesri 1996 - Kenny 1959 - Gibson 1953</h1>
            <form>
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label="Name"
                variant="outlined"
                inputProps={{
                  maxLength: `${MAX_NAME_LENGTH}`,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={`Your calculation name (Max. ${MAX_NAME_LENGTH} characters)`}
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={name}
                onChange={handleChangeName}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                style={{ minWidth: 80, maxWidth: 120 }}
                size="small"
                label="PI"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={
                          <>
                            <div>Plasticity index of clay (%).</div>
                            <br />
                            <div>For Terzaghi, Peck, Mesri (1996) &rarr; valid PI values range between 5-1000. </div>
                            <br />
                            <div>For Kenny (1959) &rarr; valid PI values range between 7-115.</div>
                            <br />
                            <div>For Gibson (1953) &rarr; valid PI values range between 13-117.</div>
                            <br />
                            <div>If you use PI values out of those ranges, results may be inaccurate!</div>
                          </>
                        }
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={pi}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Plasticity Index', setPi)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />

              <div className="buttonContainer">
                <CalculateButton
                  calculationCountReached={calculationCountReached}
                  calculate={calculate}
                  calculationInput={calculationInput}
                  URL={URL}
                  calculateButtonDisabled={calculateButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
                {isLoadingLoad && <CircularProgress size={30} />}
                <SaveButton
                  saveCountReached={saveCountReached}
                  checkCalculationNameforSaving={checkCalculationNameforSaving}
                  calculationInput={calculationInput}
                  URL={URL}
                  saveButtonDisabled={saveButtonDisabled}
                  isLoadingCalculate={isLoadingCalculate}
                  isLoadingLoad={isLoadingLoad}
                  isLoadingSave={isLoadingSave}
                />
              </div>
            </form>
          </section>
          {showOutput && <ClayEffectiveFrictionAngleResult result={result} />}
          {isLoadingCalculate && (
            <div className="centerLoading">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

const ClayEffectiveFrictionAngleWithProvider = () => {
  return (
    <CalculationContextProvider>
      <ClayEffectiveFrictionAngle />
    </CalculationContextProvider>
  );
};
export default ClayEffectiveFrictionAngleWithProvider;
