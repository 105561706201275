import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';

const CalculationSearch = ({ calculations, setFilteredCalculations, selectedCalculations, isLoading, userRole }) => {
  //STATE FOR SEARCHBOX QUERY
  const [query, setQuery] = useState('');

  //STATE FOR CONTROLLING SEARCHBOX 'NO MATCHING RESULT' CONDITION
  const [matchingResult, setMatchingResult] = useState(false);

  useEffect(() => {
    const filteredCalculations = calculations.filter((calculation) => {
      if (query === '') {
        // if query is empty, add all calculations to filteredCalculations
        return true;
      } else if (calculation.name.toLowerCase().includes(query.toLowerCase())) {
        // add matching calculations to filteredCalculations
        return true;
      }
      //remove non-filtered calculations from selectedcalculations (so checkboxes unchecked)
      const index = selectedCalculations.indexOf(calculation._id);
      if (index > -1) {
        selectedCalculations.splice(index, 1); // 2nd parameter means remove one item only
      }
      return false;
    });

    //Control state for 'no matching result'
    if (filteredCalculations.length === 0 && query) {
      setMatchingResult(true);
    } else {
      setMatchingResult(false);
    }
    setFilteredCalculations(filteredCalculations);
  }, [query, calculations, setFilteredCalculations, selectedCalculations]);

  return (
    <div className="calculation-search">
      <TextField
        size="small"
        fullWidth
        label="Search Calculations"
        type="search"
        id="search"
        onChange={(e) => setQuery(e.target.value)}
        disabled={isLoading || userRole === 'basic'}
      ></TextField>
      {matchingResult && <div>No matching result</div>}
    </div>
  );
};

export default CalculationSearch;
