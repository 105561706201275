import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import PaidIcon from '@mui/icons-material/Paid';
import { CircularProgress } from '@mui/material';

const RoleSummary = ({ loading, userCounts }) => {
  return (
    <div className="UserSummary">
      <div className="summaryContainer">
        <div className="summary">
          <div className="number">{loading ? <CircularProgress size={30} /> : userCounts.totalPaidActiveUsers}</div>
          <div className="text">Paid Users</div>
        </div>
        <div className="icon">
          <PaidIcon color="primary" fontSize="large" />
        </div>
      </div>
      <Divider />
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Standard:</div>{' '}
          <div>{loading ? <CircularProgress size={10} /> : userCounts.paidActiveStandards}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.paidActiveStandards / userCounts.totalPaidActiveUsers) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.paidActiveStandards / userCounts.totalPaidActiveUsers) * 100)}%`}
        />
      </div>
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Premium:</div>{' '}
          <div>{loading ? <CircularProgress size={10} /> : userCounts.paidActivePremiums}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.paidActivePremiums / userCounts.totalPaidActiveUsers) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.paidActivePremiums / userCounts.totalPaidActiveUsers) * 100)}%`}
        />
      </div>
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Professional: </div>
          <div>{loading ? <CircularProgress size={10} /> : userCounts.paidActiveProfessionals}</div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (userCounts.paidActiveProfessionals / userCounts.totalPaidActiveUsers) * 100}
          title={loading ? 0 : `${Math.floor((userCounts.paidActiveProfessionals / userCounts.totalPaidActiveUsers) * 100)}%`}
        />
      </div>
      <Divider />
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle">Monthly Income: </div>
          <div>
            {loading ? (
              <CircularProgress size={10} />
            ) : (
              <p>
                {userCounts.paidActiveStandards * 7 +
                  userCounts.paidActivePremiums * 12 +
                  userCounts.paidActiveProfessionals * 21}{' '}
                USD
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleSummary;
