import React, { useState, useContext, forwardRef } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { useNavigate } from 'react-router-dom';
import { Context } from '../../../App';

const DeleteAccount = () => {
  const [disabled, setDisabled] = useState(true);
  const { setUser } = useContext(Context);

  const navigate = useNavigate();

  const logout = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: '/api/v1/users/logout',
      });

      if (response.data.status === 'success') {
        setUser(null);
        navigate('/');
      }
    } catch (err) {
      /* console.log('Error logging out, Try again!'); */
    }
  };

  const handleChange = (e) => {
    if (e.target.value === 'I want to delete my account') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const deleteAccount = async (e) => {
    e.preventDefault();
    try {
      const response = await axios({
        method: 'DELETE',
        url: '/api/v1/users/deleteMe',
      });

      if (response.data.status === 'success') {
        setAlertMessage('Your account is deleted successfully.');
        setSeverity('success');
        setOpen(true);
        setTimeout(() => {
          logout();
        }, 2000);
      }
    } catch (err) {
      setAlertMessage(err.response.data.message);
      setSeverity('error');
      setOpen(true);
    }
  };

  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="account-section">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <h3>Delete Your Account</h3>

      <h4>Please type: "I want to delete my account"</h4>
      <TextField style={{ width: 300 }} size="small" label="Confirm deletion" variant="outlined" onChange={handleChange} />

      <Button variant="contained" onClick={deleteAccount} style={{ width: 300 }} disabled={disabled} color={'error'}>
        Delete
      </Button>
    </div>
  );
};

export default DeleteAccount;
