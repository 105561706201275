import round from '../../../helpers/round';
import CalculationErrorMessage from '../../common/CalculationErrorMessage';

const HansenResultChecks = ({ result, isAllChecksOK }) => {
  const {
    fndWidth,
    fndLength,
    eccentricityX,
    eccentricityY,
    columnDimensionX,
    columnDimensionY,
    normal_v,
    slidingFactorOfSafety,
  } = result.input;

  const {
    eccentricityCondition,
    isEccentricityX_OK,
    isEccentricityY_OK,
    isInclinedLoading,
    minFndWidth,
    minFndLength,
    isMinFndWidth_OK,
    isMinFndlength_OK,
    phips,
    ca,
    effectiveFndArea,
    FMax,
    HMax,
    slidingStability,
    isSliding_OK,
  } = result.output;

  return (
    <div className="checks">
      <h3>Checks</h3>
      <div className="factors-wrapper">
        {/* //? Eccentricity Check  */}
        <div className="meyerhof-factors">
          <h4>Eccentricity</h4>
          <p>
            e<sub>x</sub> &nbsp;&#8804;&nbsp; B/6 ?
          </p>
          <p>
            {round(eccentricityX, 2)} &nbsp;&#8804;&nbsp; {round(fndWidth, 2)}/6= {round(eccentricityCondition, 2)}
            {isEccentricityX_OK ? <span className="enough"> (&#10004;)</span> : <span className="not-enough"> (&#10006;)</span>}
          </p>
          <p>
            e<sub>y</sub> &nbsp;&#8804;&nbsp; B/6 ?
          </p>
          <p>
            {round(eccentricityY, 2)} &nbsp;&#8804;&nbsp; {round(fndWidth, 2)}/6= {round(eccentricityCondition, 2)}
            {isEccentricityY_OK ? <span className="enough"> (&#10004;)</span> : <span className="not-enough"> (&#10006;)</span>}
          </p>
        </div>
        {/* //? Min. Foundation Width Check  */}
        <div className="meyerhof-factors">
          <h4>Min. Foundation Width</h4>
          <p>
            B<sub>min</sub> &nbsp;&#8804;&nbsp; B ?
          </p>
          <p>
            B<sub>min</sub> = 4(e<sub>y</sub>)+w<sub>y</sub> &nbsp;&#8804;&nbsp; B
          </p>
          <p>
            B<sub>min</sub> = 4({round(eccentricityY, 2)})+{round(columnDimensionY, 2)}&nbsp;&#8804;&nbsp; {round(fndWidth, 2)}
          </p>
          <p>
            B<sub>min</sub> = {round(minFndWidth, 2)} &nbsp;&#8804;&nbsp; {round(fndWidth, 2)}{' '}
            {isMinFndWidth_OK ? <span className="enough"> (&#10004;)</span> : <span className="not-enough"> (&#10006;)</span>}
          </p>
        </div>
        {/* //? Min. Foundation Length Check  */}
        <div className="meyerhof-factors">
          <h4>Min. Foundation Length</h4>
          <p>
            L<sub>min</sub> &nbsp;&#8804;&nbsp; L ?
          </p>
          <p>
            L<sub>min</sub> = 4(e<sub>x</sub>)+w<sub>x</sub> &nbsp;&#8804;&nbsp; L
          </p>
          <p>
            L<sub>min</sub> = 4({round(eccentricityX, 2)})+{round(columnDimensionX, 2)}
            &nbsp;&#8804;&nbsp; {round(fndLength, 2)}
          </p>
          <p>
            L<sub>min</sub> = {round(minFndLength, 2)} &nbsp;&#8804;&nbsp; {round(fndLength, 2)}
            {isMinFndlength_OK ? <span className="enough"> (&#10004;)</span> : <span className="not-enough"> (&#10006;)</span>}
          </p>
        </div>
        {/* //? Sliding Check  */}
        {isInclinedLoading && (
          <div className="meyerhof-factors">
            <h4>Sliding</h4>
            <p>
              H<sub>max</sub> / Max (H<sub>B</sub>, H<sub>L</sub>) &ge; FS<sub>Sliding</sub> ?
            </p>
            <p>
              H<sub>max</sub> = V tan&phi; + C<sub>a</sub> A<sub>f</sub>
            </p>
            <p>
              H<sub>max</sub> = ({round(normal_v, 2)}) (tan{round(phips, 2)}) + ({round(ca, 2)}) ({round(effectiveFndArea, 2)})
            </p>
            <p>
              H<sub>max</sub> = {round(FMax, 0)} kPa
            </p>
            <p>
              {round(FMax, 2)} / {round(HMax, 2)} &ge; {round(slidingFactorOfSafety, 2)} ?
            </p>
            <p>
              {round(slidingStability, 2)} &ge; {round(slidingFactorOfSafety, 2)} ?{' '}
              {isSliding_OK ? <span className="enough"> (&#10004;)</span> : <span className="not-enough"> (&#10006;)</span>}
            </p>
          </div>
        )}
      </div>
      {/* //? Error Messages  */}
      {!isAllChecksOK && (
        <p className="calculationErrorMessageHeader">Please fix the following issues to see further calculation results.</p>
      )}
      {!isEccentricityX_OK && (
        <CalculationErrorMessage
          message={
            'Eccentricity in x direction exceeds limits. Either increase foundation width or decrease eccentricity in x direction. '
          }
        />
      )}
      {!isEccentricityY_OK && (
        <CalculationErrorMessage
          message={
            'Eccentricity in y direction exceeds limits. Either increase foundation width or decrease eccentricity in y direction.'
          }
        />
      )}
      {!isMinFndWidth_OK && (
        <CalculationErrorMessage
          message={
            'Foundation width is less than minimum foundation width. Either increase foundation width or decrease eccentricity in y direction or column dimension in y direction.'
          }
        />
      )}
      {!isMinFndlength_OK && (
        <CalculationErrorMessage
          message={
            'Foundation length is less than minimum foundation length. Either increase foundation length or decrease eccentricity in x direction or column dimension in x direction.'
          }
        />
      )}
      {!isSliding_OK && (
        <CalculationErrorMessage
          message={
            'There is a problem with sliding stability. Either increase normal force, cohesion and foundation area or decrease horizontal force to reach desired factor of safety against sliding. '
          }
        />
      )}
    </div>
  );
};
export default HansenResultChecks;
