import React, { useContext } from 'react';
import { Context } from './App';
import { Navigate, Outlet } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const ProtectedRoutes = () => {
  const { user, isResolved } = useContext(Context);
  if (user) return <Outlet />;

  if (!isResolved)
    return (
      <div className="centerLoading">
        <CircularProgress />
      </div>
    );

  return <Navigate to="/login" />;
};

export default ProtectedRoutes;
