import TextField from '@mui/material/TextField';
import { convertFirstCharacterToUppercase } from '../../../helpers/convertStringToUpperLowerCase';

const ManageSubscription = ({ user }) => {
  return (
    <div className="account-section">
      <h3>Subscription Details</h3>
      <div className="subscriptionDetails">
        <TextField
          style={{ width: 300 }}
          size="small"
          label="Current subscription plan:"
          variant="outlined"
          disabled={true}
          readOnly
          value={convertFirstCharacterToUppercase(user.role) + ' Plan'}
        />
      </div>
    </div>
  );
};

export default ManageSubscription;
