import { Link } from 'react-router-dom';

import Newsletter from './Newsletter';
import instagram from '../../assets/icons/footer/instagram.png';
import linkedin from '../../assets/icons/footer/linkedin.png';
import twitter from '../../assets/icons/footer/twitter.png';
import youtube from '../../assets/icons/footer/youtube.png';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footerLine">
          <div className="footerSection">
            <div className="title">Quick Links</div>
            <div className="content">
              {/* <div className="linkWrapper">
                <Link to="/">Home</Link>
              </div> */}
              <div className="linkWrapper">
                <Link to="/about">About</Link>
              </div>
              <div className="linkWrapper">
                <Link to="/contact">Contact</Link>
              </div>
              <div className="linkWrapper">
                <Link to="/pricing">Pricing</Link>
              </div>
            </div>
          </div>
          <div className="footerSection">
            <div className="title">Information</div>
            <div className="content">
              <div className="linkWrapper">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
              <div className="linkWrapper">
                <Link to="/terms-of-use">Terms of Use</Link>
              </div>

              <div className="linkWrapper">
                <Link to="/disclaimer">Disclaimer</Link>
              </div>
            </div>
          </div>
          <div className="footerSection">
            <div className="title">Social</div>
            <div className="content social">
              <div className="social-wrapper">
                <div className="linkWrapper">
                  <a href="https://www.instagram.com/geocalc.app/" target="_blank" rel="noreferrer">
                    <img src={instagram} alt="instagram"></img>
                  </a>
                </div>
                <div className="linkWrapper">
                  <a href="https://www.linkedin.com/in/geotechnicalcalculations/" target="_blank" rel="noreferrer">
                    <img src={linkedin} alt="linkedin"></img>
                  </a>
                </div>
                <div className="linkWrapper">
                  <a href="https://twitter.com/geocalculations" target="_blank" rel="noreferrer">
                    <img src={twitter} alt="twitter"></img>
                  </a>
                </div>
                <div className="linkWrapper">
                  <a href="https://www.youtube.com/channel/UCseGd1ifHKw74SkHPo8L-VQ" target="_blank" rel="noreferrer">
                    <img src={youtube} alt="youtube"></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footerSection">
            <div className="title">Newsletter</div>

            <Newsletter />
          </div>
        </div>
        <div className="footerLine trademark">© Geotechnical Calculations, 2023</div>
        <div className="footerLine trademark">
          <span>v3.0.18</span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
