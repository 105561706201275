import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TextField from '@mui/material/TextField';

const style = {
  position: 'absolute',
  minWidth: '300px',
  maxWidth: '500px',
  lineHeight: '28px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'left',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 5,
};

const styleCloseIcon = {
  position: 'absolute',
  top: '5px',
  right: '5px',
  cursor: 'pointer',
};

export default function TransitionsModal({ open, setOpen, queryFunction }) {
  const handleClose = () => setOpen(false);
  const [disabled, setDisabled] = useState(true);

  const handleChange = (e) => {
    if (e.target.value === 'send email') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleClick = () => {
    handleClose();
    queryFunction();
    setDisabled(true);
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <CloseRoundedIcon sx={styleCloseIcon} onClick={handleClose} color={'primary'} />
          <div className="userModalContainer">
            <h2>Confirm sending emails</h2>
            <p>Type "send email" and click.</p>
            <TextField size="small" label="Confirm sending" variant="outlined" onChange={handleChange} />
            <Button
              className="pricingInfoModalButton"
              variant="contained"
              target={'blank'}
              onClick={handleClick}
              disabled={disabled}
            >
              SEND EMAIL
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
