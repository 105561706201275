import React from 'react';
import round from '../../../helpers/round';

const MeyerhofResult = ({ data, result }) => {
  const {
    /* name, */
    cohesion,
    phi,
    /* df, */
    fndWidth,
    fndLength,
    theta,
    /* gamma,  */
    /* gwt, */
    FsSt,
    FsSs,
    FSt,
    FSs,
  } = data;

  const {
    nq,
    nc,
    isPhiZero,
    isThetaZero,
    ngamma,
    kp,
    sc,
    sq,
    sgamma,
    dc,
    dq,
    dgamma,
    ic,
    iq,
    igamma,
    effectiveStress,
    gammaEffective,
    qUlt,
    qallSt,
    qallSs,
    isQallStEnough,
    isQallSsEnough,
  } = result;

  return (
    <section className="output">
      <h2>Output </h2>
      <div className="factors-wrapper">
        <div className="meyerhof-factors">
          <h3>
            N<sub>c</sub>, N<sub>q</sub>, N<sub>&gamma;</sub>: Bearing Capacity Factors
          </h3>
          N<sub>q</sub>=e<sup>&pi;tan&phi;</sup>tan<sup>2</sup>(45+&phi;/2) <br />N<sub>q</sub>=e
          <sup>&pi;tan&phi;</sup>tan<sup>2</sup>(45+{round(phi, 2)}/2) <br />N<sub>q</sub>=
          {round(nq, 2)} <br />
          <br />
          {!isPhiZero ? (
            <span>
              N<sub>c</sub>=(N<sub>q</sub>-1) cot&phi; <br />N<sub>c</sub>=({round(nq, 2)}-1)cot
              {round(phi, 2)} <br />N<sub>c</sub>={round(nc, 2)} <br />
              <br />
            </span>
          ) : (
            <span>
              N<sub>c</sub>=&pi;+2
              <br />N<sub>c</sub>={round(nc, 2)} <br />
              <br />
            </span>
          )}
          N<sub>&gamma;</sub>=(N<sub>q</sub>-1)tan(1.4&phi;) <br />N<sub>&gamma;</sub>=(
          {round(nq, 2)}-1)tan[(1.4)({round(phi, 2)})] <br />N<sub>&gamma;</sub>={round(ngamma, 2)}
        </div>
        <div className="meyerhof-factors">
          <h3>
            S<sub>c</sub>, S<sub>q</sub>, S<sub>&gamma;</sub>: Shape Factors
          </h3>
          K<sub>p</sub>=tan<sup>2</sup>(45+&phi;/2) <br />K<sub>p</sub>=tan<sup>2</sup>(45+
          {round(phi, 2)}/2) <br />K<sub>p</sub>={round(kp, 2)} <br />
          <br />s<sub>c</sub>=1+(0.2)(K<sub>p</sub>)(B/L) <br />s<sub>c</sub>=1+(0.2)({round(kp, 2)}
          )({round(fndWidth, 2)}/{round(fndLength, 2)}) <br />s<sub>c</sub>={round(sc, 2)} <br />
          <br />
          {!isPhiZero ? (
            <span>
              s<sub>q</sub>=s<sub>&gamma;</sub>=1+(0.1)(K<sub>p</sub>)(B/L) <br />s<sub>q</sub>=s
              <sub>&gamma;</sub>=1+(0.1)({round(kp, 2)})({round(fndWidth, 2)}/{round(fndLength, 2)}){' '}
              <br />s<sub>q</sub>=s<sub>&gamma;</sub>={round(sq, 2)} <br />
              <br />
            </span>
          ) : (
            <span>
              {' '}
              s<sub>q</sub>=s<sub>&gamma;</sub>={round(sq, 2)}{' '}
            </span>
          )}
        </div>
        <div className="meyerhof-factors">
          <h3>
            d<sub>c</sub>, d<sub>q</sub>, d<sub>&gamma;</sub>: Depth Factors
          </h3>
          K<sub>p</sub>=tan<sup>2</sup>(45+&phi;/2) <br />K<sub>p</sub>=tan<sup>2</sup>(45+
          {round(phi, 2)}/2) <br />K<sub>p</sub>={round(kp, 2)} <br />
          <br />d<sub>c</sub>=1+(0.2)(K<sub>p</sub>)<sup>1/2</sup>(B/L) <br />d<sub>c</sub>=1+(0.2)(
          {round(kp, 2)})<sup>1/2</sup>({round(fndWidth, 2)}/{round(fndLength, 2)}) <br />d
          <sub>c</sub>={round(dc, 2)} <br />
          <br />
          {!isPhiZero ? (
            <span ng-hide="phi_sifirmi">
              d<sub>q</sub>=d<sub>&gamma;</sub>=1+(0.1)(K<sub>p</sub>)<sup>1/2</sup>(B/L) <br />d
              <sub>q</sub>=d<sub>&gamma;</sub>=1+(0.1)({round(kp, 2)})<sup>1/2</sup>(
              {round(fndWidth, 2)}/{round(fndLength, 2)}) <br />d<sub>q</sub>=d<sub>&gamma;</sub>=
              {round(dq, 2)} <br />
              <br />
            </span>
          ) : (
            <span ng-show="phi_sifirmi">
              {' '}
              d<sub>q</sub>=d<sub>&gamma;</sub>={round(dq, 2)}{' '}
            </span>
          )}
        </div>
        <div className="meyerhof-factors">
          <h3>
            i<sub>c</sub>, i<sub>q</sub>, i<sub>&gamma;</sub>: Inclination Factors
          </h3>
          i<sub>c</sub>=i<sub>q</sub>=1-(&theta;/90)<sup>2</sup> <br />i<sub>c</sub>=i<sub>q</sub>
          =1-({round(theta, 2)}/90)<sup>2</sup> <br />i<sub>c</sub>=i<sub>q</sub>={round(ic, 2)}
          <br />
          <br />
          {!isPhiZero ? (
            <span>
              i<sub>&gamma;</sub>=1-(&theta;/&phi;)<sup>2</sup> <br />i<sub>&gamma;</sub>=1-(
              {round(theta, 2)}/{round(phi, 2)})<sup>2</sup> <br />i<sub>&gamma;</sub>=
              {round(igamma, 2)}
              <br />
              <br />
            </span>
          ) : (
            <span>
              i<sub>&gamma;</sub>={round(igamma, 2)}
            </span>
          )}
        </div>
      </div>
      <div className="meyerhof-result">
        <h3>
          Ultimate bearing capacity, q<sub>ult</sub>
        </h3>
        {isThetaZero ? (
          <div className="formulaBox">
            q<sub>ult</sub> = c N<sub>c</sub> s<sub>c</sub> d<sub>c</sub> + q N<sub>q</sub> s
            <sub>q</sub> d<sub>q</sub> + 0.5 &gamma;' B N<sub>&gamma;</sub> s<sub>&gamma;</sub> d
            <sub>&gamma;</sub>
          </div>
        ) : (
          <div className="formulaBox">
            q<sub>ult</sub> = c N<sub>c</sub> d<sub>c</sub> i<sub>c</sub> + q N<sub>q</sub> d
            <sub>q</sub> i<sub>q</sub> + 0.5 &gamma; B N<sub>&gamma;</sub> d<sub>&gamma;</sub> i
            <sub>&gamma;</sub>
          </div>
        )}

        {isThetaZero ? (
          <div className="formulaBox">
            q<sub>ult</sub> = ({round(cohesion, 2)})({round(nc, 2)})({round(sc, 2)})({round(dc, 2)})
            + ({round(effectiveStress, 2)})({round(nq, 2)})({round(sq, 2)})({round(dq, 2)}) + (0.5)(
            {round(gammaEffective, 2)})({round(fndWidth, 2)})({round(ngamma, 2)})({round(sgamma, 2)}
            )({round(dgamma, 2)})
          </div>
        ) : (
          <div className="formulaBox">
            q<sub>ult</sub> = ({round(cohesion, 2)})({round(nc, 2)})({round(dc, 2)})({round(ic, 2)})
            + ({round(effectiveStress, 2)})({round(nq, 2)})({round(dq, 2)})({round(iq, 2)}) + (0.5)(
            {round(gammaEffective, 2)})({round(fndWidth, 2)})({round(ngamma, 2)})({round(dgamma, 2)}
            )({round(igamma, 2)})
          </div>
        )}

        <div className="formulaBox">
          q<sub>ult</sub> = {round(qUlt, 0)} kPa
        </div>
      </div>
      <div className="meyerhof-result">
        <h3>
          Allowable bearing capacity for static case, q<sub>all - st</sub>
        </h3>
        <div className="formulaBox">
          q<sub>all - st</sub> = q<sub>ult</sub> / FS<sub>st</sub>
        </div>
        <div className="formulaBox">
          q<sub>all - st</sub> = {round(qUlt, 0)} / {round(FsSt, 2)}
        </div>
        <div className="formulaBox">
          {isQallStEnough ? (
            <span>
              q<sub>all - st</sub> = {round(qallSt, 0)} kPa &ge; {round(FSt, 0)} kPa
              <span className="enough"> (&#10004;)</span>
            </span>
          ) : (
            <span>
              q<sub>all - st</sub> = {round(qallSt, 0)} kPa &lt; {round(FSt, 0)} kPa
              <span className="not-enough"> (&#10006;)</span>
            </span>
          )}
        </div>
      </div>
      <div className="meyerhof-result">
        <h3>
          Allowable bearing capacity for seismic case, q<sub>all - ss</sub>
        </h3>
        <div className="formulaBox">
          q<sub>all - ss</sub> = q<sub>ult</sub> / FS<sub>ss</sub>
        </div>
        <div className="formulaBox">
          q<sub>all - ss</sub> = {round(qUlt, 0)} / {round(FsSs, 2)}
        </div>
        <div className="formulaBox">
          {isQallSsEnough ? (
            <span>
              q<sub>all - ss</sub> = {round(qallSs, 0)} kPa &ge; {round(FSs, 0)} kPa
              <span className="enough"> (&#10004;)</span>
            </span>
          ) : (
            <span>
              q<sub>all - ss</sub> = {round(qallSs, 0)} kPa &lt; {round(FSs, 0)} kPa
              <span className="not-enough"> (&#10006;)</span>
            </span>
          )}
        </div>
      </div>
    </section>
  );
};

export default MeyerhofResult;
