import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InputAdornment from '@mui/material/InputAdornment';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CircularProgress from '@mui/material/CircularProgress';

import { useCalculationContext, CalculationContextProvider } from '../../context/calculationContext';
import LayeredSoilSketch from '../../../assets/calculations/layeredSoil/layeredSoil.png';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import MuiSnackbar from '../../common/MuiSnackbar';
import Sidebar from '../../sidebar/Sidebar';
import DecisionModal from '../../modals/DecisionModal';
import OutputLayer from './OutputLayer';
import ButtonContainer from '../../common/buttonContainerForLayeredCalculations';
import LabelContainer from './LabelContainer';
import OutputLabelContainer from './OutputLabelContainer';
import InputLayer from './InputLayer';
import TransitionsModal from '../../modals/TransitionsModal';
import { useCheckMissingParamsForLayeredCalculations } from '../../../hooks/useCheckMissingParamsForLayeredCalculations';

const LayeredSoil = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Average cohesion and average friction angle calculations for layered soils';
  }, []);

  const {
    MAX_NAME_LENGTH,
    isLoadingCalculate,
    isLoadingLoadAll,
    isLoadingLoad,
    isLoadingSave,
    result,
    resultsArray,
    calculations,
    lastData,
    showOutput,
    setShowOutput,
    calculateButtonDisabled,
    setCalculateButtonDisabled,
    saveButtonDisabled,
    setSaveButtonDisabled,
    errorMessage,
    displaySidebar,
    setDisplaySidebar,
    decisionModalIsOpen,
    decision,
    setDecision,
    handleSidebarDisplay,
    setDecisionModalIsOpenToFalse,
    showMuiSnack,
    open,
    setOpen,
    alertMessage,
    severity,
    onChangeWarningForPositiveDecimals,
    calculate,
    loadOneCalculation,
    loadAllCalculations,
    update,
    checkCalculationNameforSaving,
    checkCalculationNameforSavingAs,
    deleteCalculations,
    calculationCountReached,
    saveCountReached,
  } = useCalculationContext();

  //SET STATES FOR INPUT VARIABLES
  const [name, setName] = useState('');
  const [foundationDepth, setFoundationDepth] = useState('');
  const [foundationWidth, setFoundationWidth] = useState('');
  const [layersFront, setLayersFront] = useState([]);

  // SET THE LOADED INPUTS
  const setInputs = (inputs) => {
    setName(inputs.name);
    setFoundationDepth(inputs.foundationDepth);
    setFoundationWidth(inputs.foundationWidth);
    setLayersFront(inputs.layersFront);
  };

  //INPUTS FOR CALCULATION
  const calculationInput = {
    name,
    foundationDepth,
    foundationWidth,
    layersFront,
  };

  //ADD LAYER
  const addLayer = (e) => {
    e.preventDefault();
    let z1;
    if (layersFront.length === 0) {
      z1 = foundationDepth;
    } else if (layersFront.length > 0) {
      z1 = layersFront[layersFront.length - 1].z2;
    }

    const layer = {
      id: layersFront.length + 1,
      formation: '',
      z1: z1,
      z2: '',
      hi: '',
      c: '',
      phi: '',
    };

    const newLayersFront = layersFront.slice();
    newLayersFront.push(layer);
    setLayersFront(newLayersFront);
  };

  //REMOVE LAYER
  const removeLayer = (e) => {
    e.preventDefault();
    const newLayersFront = layersFront.slice();
    newLayersFront.pop();
    setLayersFront(newLayersFront);
  };

  // MANAGE SAVE & CALCULATE BUTTONS DISABLED/ENABLED STATES
  useEffect(() => {
    setCalculateButtonDisabled(false);
    setSaveButtonDisabled(false);
    if (
      (calculationInput.name || calculationInput.name === 0) &&
      (calculationInput.foundationDepth || calculationInput.foundationDepth === 0) &&
      (calculationInput.foundationWidth || calculationInput.foundationWidth === 0)
    ) {
      setCalculateButtonDisabled(false);
      setSaveButtonDisabled(false);
    } else {
      setCalculateButtonDisabled(true);
      setSaveButtonDisabled(true);
    }
  }, [
    setCalculateButtonDisabled,
    setSaveButtonDisabled,
    calculationInput.name,
    calculationInput.foundationDepth,
    calculationInput.foundationWidth,
  ]);

  //check layer mising params and handle calculate button disabled/enabled status
  const doLayersHaveMissingParams = useCheckMissingParamsForLayeredCalculations(layersFront);
  useEffect(() => {
    setCalculateButtonDisabled(doLayersHaveMissingParams);
    setSaveButtonDisabled(doLayersHaveMissingParams);
  }, [doLayersHaveMissingParams, layersFront, setCalculateButtonDisabled, setSaveButtonDisabled]);

  // Do not render output if any input parameter except name changes
  useEffect(() => {
    setShowOutput(false);
  }, [
    setShowOutput,
    calculationInput.foundationDepth,
    calculationInput.foundationWidth,
    JSON.stringify(calculationInput.layersFront),
  ]);

  //CLEAR INPUTS AND CLEAR OUTPUT
  const createNewCalculation = () => {
    setName('');
    setFoundationDepth('');
    setFoundationWidth('');
    setLayersFront([]);
    setShowOutput(false);
  };

  //API URLS
  const URL = {
    CALCULATE: '/api/v1/calculations/layeredSoil',
    LOADONE: `/api/v1/calculations/layeredSoil/loadOne/`,
    LOADALL: '/api/v1/calculations/layeredSoil/loadAll',
    UPDATE: `/api/v1/calculations/layeredSoil/update/`,
    SAVE: '/api/v1/calculations/layeredSoil/save',
    CHECKUNIQUENAME: `/api/v1/calculations/layeredSoil/checkUniqueName/`,
    DELETE: '/api/v1/calculations/layeredSoil/delete',
  };

  const handleChangeName = (e) => {
    if (e.target.value.length >= MAX_NAME_LENGTH) {
      showMuiSnack(`Calculation name can be max ${MAX_NAME_LENGTH} characters.`, 'warning');
    }
    setName(e.target.value);
  };

  //CONVERT RESPONSE OBJECT TO ARRAYS FOR PRINTING PURPOSES
  const PrintIteration = result.PrintIteration;
  const PrintPhi = result.PrintPhi;
  const Printc = result.Printc;
  const PrinthEffective = result.PrinthEffective;

  // RETURN INPUT AND OUTPUT JSX
  return (
    <>
      <Header />
      <div className="container">
        <MuiSnackbar open={open} setOpen={setOpen} severity={severity} alertMessage={alertMessage} />
        {displaySidebar ? (
          <Sidebar
            URL={URL}
            loadAllCalculations={loadAllCalculations}
            calculations={calculations}
            loadOneCalculation={loadOneCalculation}
            calculate={calculate}
            setInputs={setInputs}
            deleteCalculations={deleteCalculations}
            createNewCalculation={createNewCalculation}
            isLoading={isLoadingLoadAll}
            handleSidebarDisplay={handleSidebarDisplay}
            displaySidebar={displaySidebar}
            setDisplaySidebar={setDisplaySidebar}
          />
        ) : (
          <MenuOpenIcon
            color="primary"
            fontSize="large"
            className="sidebar-open-icon"
            alt="side bar menu icon"
            label="Show my calculations"
            onClick={handleSidebarDisplay}
          />
        )}
        <DecisionModal
          URL={URL}
          data={calculationInput}
          decisionModalIsOpen={decisionModalIsOpen}
          setDecisionModalIsOpenToFalse={setDecisionModalIsOpenToFalse}
          decision={decision}
          setDecision={setDecision}
          checkCalculationNameforSavingAs={checkCalculationNameforSavingAs}
          name={name}
          setName={setName}
          errorMessage={errorMessage}
          update={update}
          saveButtonDisabled={saveButtonDisabled}
        />
        <div className="right-calculation">
          <section className="input ">
            <h1>Average cohesion and average friction angle calculations for layered soils</h1>
            {isLoadingLoad && (
              <div className="loadingAbsolute">
                <CircularProgress size={30} />
              </div>
            )}
            <form>
              <TextField
                sx={{ width: 200 }}
                size="small"
                label="Name"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={`Your calculation name (Max. ${MAX_NAME_LENGTH} characters)`}
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: `${MAX_NAME_LENGTH}`,
                }}
                value={name}
                onChange={handleChangeName}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                sx={{ width: 200 }}
                size="small"
                label={
                  <span>
                    D<sub>f</sub> (m)
                  </span>
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Foundation Depth (m). Measured from ground surface."
                        arrow
                        placement="bottom"
                        TransitionComponent={Zoom}
                      >
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={foundationDepth}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Foundation Depth', setFoundationDepth)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TextField
                sx={{ width: 200 }}
                size="small"
                label={'B (m)'}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Foundation Width (m)" arrow placement="bottom" TransitionComponent={Zoom}>
                        <InfoTwoToneIcon fontSize={'string'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                value={foundationWidth}
                onChange={(e) => onChangeWarningForPositiveDecimals(e, 'Foundation Width', setFoundationWidth)}
                disabled={isLoadingCalculate || isLoadingLoad || isLoadingSave}
              />
              <TransitionsModal
                image={LayeredSoilSketch}
                title="Layered Soils"
                description={'Soil parameters and dimensions are shown on the sketch'}
              ></TransitionsModal>
            </form>
            <form className="horizontalScroll">
              {layersFront.length > 0 && <LabelContainer />}
              {layersFront?.map((layer, index) => {
                if (index === 0) {
                  layersFront[index].z1 = foundationDepth;
                }
                if (index !== layersFront.length - 1) {
                  layersFront[index + 1].z1 = layersFront[index].z2;
                }
                layer.hi = layer.z2 - layer.z1;
                const updateLayer = (key, value) => {
                  const newLayer = {
                    ...layer,
                  };
                  newLayer[key] = value;
                  const newLayers = layersFront.slice();
                  newLayers.splice(index, 1, newLayer);
                  setLayersFront(newLayers);
                };
                return (
                  <InputLayer
                    key={index}
                    layer={layer}
                    updateLayer={updateLayer}
                    isLoadingCalculate={isLoadingCalculate}
                    isLoadingLoad={isLoadingLoad}
                    isLoadingSave={isLoadingSave}
                  />
                );
              })}
            </form>
          </section>
          <ButtonContainer
            URL={URL}
            addLayer={addLayer}
            removeLayer={removeLayer}
            calculate={calculate}
            checkCalculationNameforSaving={checkCalculationNameforSaving}
            calculationInput={calculationInput}
            layersFrontLength={layersFront.length}
            isLoadingCalculate={isLoadingCalculate}
            isLoadingLoad={isLoadingLoad}
            isLoadingSave={isLoadingSave}
            saveButtonDisabled={saveButtonDisabled}
            calculateButtonDisabled={calculateButtonDisabled}
            calculationCountReached={calculationCountReached}
            saveCountReached={saveCountReached}
          />
          {isLoadingCalculate && (
            <div className="loadingAbsolute">
              <CircularProgress />
            </div>
          )}
          {showOutput && (
            <section className="output layeredSoilOutputSection loadingRelative ">
              <h2>Output </h2>
              {PrintIteration && <OutputLabelContainer />}

              <div className="layeredSoilOutputContainer">
                <div className="layeredSoilOutput">
                  {PrintIteration?.map((iteration, index) => (
                    <OutputLayer key={index} iteration={iteration} />
                  ))}
                </div>
                <div className="layeredSoilOutput">
                  {PrintPhi?.map((phi, index) => (
                    <OutputLayer key={index} phi={phi} />
                  ))}
                </div>
                <div className="layeredSoilOutput">
                  {Printc?.map((c, index) => (
                    <OutputLayer key={index} c={c} />
                  ))}
                </div>
                <div className="layeredSoilOutput">
                  {PrinthEffective?.map((hEffective, index) => (
                    <OutputLayer key={index} hEffective={hEffective} />
                  ))}
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

const LayeredSoilWithProvider = () => {
  return (
    <CalculationContextProvider>
      <LayeredSoil />
    </CalculationContextProvider>
  );
};
export default LayeredSoilWithProvider;
