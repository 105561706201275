import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import Header from '../../common/Header';
import Footer from '../../common/Footer';

const TerzaghiTheory = () => {
  useEffect(() => {
    document.title = 'GeoCalc | Bearing Capacity of Shallow Foundations - Terzaghi (1943) Theory';
  }, []);

  return (
    <>
      <Header />
      <div className="theory">
        <div className="theoryContainer">
          <h1>Bearing Capacity of Shallow Foundations - Terzaghi (1943) | Theory & Formulations</h1>
          <p>
            The calculation tool given on this website for calculating bearing capacity of a shallow foundation follows the
            procedure given in "Principles of Foundation Engineering, 4th Edition (Braja M. Das) - Chapter 3.3 (Terzaghi's Bearing
            Capacity Theory). The details of the Terzaghi (1943) method for calculating bearing capacity of shallow foundations is
            given below.
          </p>
          <h2>Ultimate Bearing Capacity</h2>
          <p>The bearing capacity is calculated according to the following equations for Terzaghi (1943) method.</p>
          <p className="theoryEquation">
            q<sub>ult</sub> = cN<sub>c</sub>s<sub>c</sub> + qN<sub>q</sub> + 0.5&gamma;<sub>e</sub>BN<sub>&gamma;</sub>s
            <sub>&gamma;</sub>
          </p>
          <h3>Bearing Capacity Factors</h3>
          {/* Nq */}
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>N</mi>
                <mi>q</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                {/* NUMERATOR */}
                <mrow>
                  <msup>
                    <mi>e</mi>
                    <mi>
                      <mi>2(3&pi;/4 - &phi;/2)tan&phi;</mi>
                    </mi>
                  </msup>
                </mrow>
                {/* DENOMINATOR */}
                <mrow>
                  <msup>
                    <mi>2cos</mi>
                    <mi>2</mi>
                  </msup>
                  <mi>(45 + &phi; /2)</mi>
                </mrow>
              </mfrac>
            </math>
          </p>
          {/* Nc */}
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>N</mi>
                <mi>c</mi>
              </msub>
              <mo>=</mo>
              <mo>(</mo>
              <msub>
                <mi>N</mi>
                <mi>q</mi>
              </msub>
              <mo>-</mo>
              <mi>1</mi>
              <mo>)</mo>
              <mi>cot&phi;</mi>
            </math>
          </p>
          {/* Ngamma */}
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>N</mi>
                <mi>&gamma;</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                {/* NUMERATOR */}
                <mrow>
                  <mi>tan&phi;</mi>
                </mrow>
                {/* DENOMINATOR */}
                <mrow>
                  <mi>2</mi>
                </mrow>
              </mfrac>

              <mo>(</mo>
              <mfrac>
                {/* NUMERATOR */}
                <mrow>
                  <msub>
                    <mi>K</mi>
                    <mi>py</mi>
                  </msub>
                </mrow>
                {/* DENOMINATOR */}
                <mrow>
                  <msup>
                    <mi>cos</mi>
                    <mi>2</mi>
                  </msup>
                  <mi>&phi;</mi>
                </mrow>
              </mfrac>
              <mo>-</mo>
              <mi>1</mi>
              <mo>)</mo>
            </math>
          </p>
          <h3>Shape Factors</h3>
          <p>
            Shape factors s<sub>c</sub> and s<sub>&gamma;</sub> are selected according to shape of the foundation. For strip
            foundations s<sub>c</sub> = 1 and s<sub>&gamma;</sub> = 1, for round foundations s<sub>c</sub> = 1.3 and s
            <sub>&gamma;</sub> = 0.6 and for square foundations s<sub>c</sub> = 1.3 and s<sub>&gamma;</sub> = 0.8.
          </p>
          <h3>
            Effect of Water table, &gamma;<sub>e</sub> Term
          </h3>
          <p>
            To include the effect of water table on bearing capacity of a shallow foundation, the procedure given in "Foundation
            Analysis and Design, 5th Edition (Joseph E. Bowles) - Chapter 4 is followed.
          </p>
          <p>
            The average effective unit weight of the soil (&gamma;<sub>e</sub>) considering the depth to water table below base of
            footing (d<sub>w</sub>) and the depth of wedge zone (H) is calculated as follows;
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&gamma;</mi>
                <mi>e</mi>
              </msub>
              <mo>=</mo>
              <msub>
                <mi>&gamma;</mi>
                <mi>wet</mi>
              </msub>
              <mo>(</mo>
              <mi>2H</mi>
              <mo>-</mo>
              <msub>
                <mi>d</mi>
                <mi>w</mi>
              </msub>
              <mo>)</mo>
              <mfrac>
                {/* NUMERATOR */}
                <mrow>
                  <msub>
                    <mi>d</mi>
                    <mi>w</mi>
                  </msub>
                </mrow>
                {/* DENOMINATOR */}
                <mrow>
                  <msup>
                    <mi>H</mi>
                    <mi>2</mi>
                  </msup>
                </mrow>
              </mfrac>
              <mo>+</mo>
              <mfrac>
                {/* NUMERATOR */}
                <mrow>
                  <mi>&gamma;'</mi>
                </mrow>
                {/* DENOMINATOR */}
                <mrow>
                  <msup>
                    <mi>H</mi>
                    <mi>2</mi>
                  </msup>
                </mrow>
              </mfrac>
              <msup>
                <mrow>
                  <mo>(</mo>
                  <mi>H</mi>
                  <mo>-</mo>
                  <msub>
                    <mi>d</mi>
                    <mi>w</mi>
                  </msub>
                  <mo>)</mo>
                </mrow>
                <mrow>
                  <mi>2</mi>
                </mrow>
              </msup>
            </math>
          </p>
          <p className="theoryEquation">
            <math display="block">
              <mi>H</mi>
              <mo>=</mo>

              <mi>0.5Btan</mi>
              <mo>(</mo>
              <mi>45</mi>
              <mo>+</mo>
              <mfrac>
                <mi>&phi;</mi>
                <mi>2</mi>
              </mfrac>
              <mo>)</mo>
            </math>
          </p>
          <h3>Allowable Bearing Capacity</h3>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>q</mi>
                <mi>all</mi>
              </msub>
              <mo>=</mo>
              <mfrac>
                <msub>
                  <mi>q</mi>
                  <mi>ult</mi>
                </msub>
                <mi>FS</mi>
              </mfrac>
            </math>
          </p>
          <div className="theoryButtonContainer">
            <Link to={'/calculations/terzaghi'}>
              <Button variant="outlined" size="small">
                GO TO TERZAGHI CALCULATOR
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TerzaghiTheory;
