import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import CalculateIcon from '@mui/icons-material/Calculate';
import { CircularProgress } from '@mui/material';

const CalculationCounts = ({ loading, calculationCounts }) => {
  const Calculation = ({ calculation, totalCount }) => {
    return (
      <div className="activeUsersBar">
        <div className="barTexts">
          <div className="barTitle totalCalculationCountsTitles">
            <div>{calculation.name}:</div>
            <div className="number">{loading ? <CircularProgress size={10} /> : calculation.count}</div>
          </div>
        </div>
        <LinearProgress
          variant="determinate"
          value={loading ? 0 : (calculation.count / totalCount) * 100}
          title={loading ? 0 : `${Math.floor((calculation.count / totalCount) * 100)}%`}
        />
      </div>
    );
  };
  return (
    <div className="UserSummary totalCalculationCounts">
      <div className="summaryContainer">
        <div className="summary">
          <div className="number">
            {loading ? <CircularProgress size={30} /> : calculationCounts.results?.totalCalculationCount}
          </div>
          <div className="text">Total Calculation Count</div>
        </div>
        <div className="icon">
          <CalculateIcon color="primary" />
        </div>
      </div>
      <Divider />
      {calculationCounts?.individualCalculationCounts?.map((calculation) => {
        return (
          <Calculation
            calculation={calculation}
            totalCount={calculationCounts.results?.totalCalculationCount}
            key={calculation.name}
          />
        );
      })}
    </div>
  );
};

export default CalculationCounts;
