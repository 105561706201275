import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import PreventedRoutes from './PreventedRoutes';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { ThemeProvider, createTheme } from '@mui/material/styles';
/* import { MathJaxContext } from 'better-react-mathjax'; */
//CSS
import './App.css';
//PAGES
import Account from './components/pages/account/Account';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Disclaimer from './components/pages/Disclaimer';
import HomePage from './components/pages/homepage/HomePage';
import Login from './components/pages/Login';
import Signup from './components/pages/Signup';
import ForgotPassword from './components/pages/ForgotPassword';
import ResetPassword from './components/pages/ResetPassword';
import ConfirmEmail from './components/pages/ConfirmEmail';
import NotFound from './components/pages/NotFound';
import Pricing from './components/pages/pricing/Pricing';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsOfUse from './components/pages/TermsOfUse';
//CALCULATIONS
import Consolidation from './components/calculations/consolidation/Consolidation';
import LayeredSoil from './components/calculations/layered-soil/LayeredSoil';
import LiquefactionTbdy from './components/calculations/liquefaction-tbdy/LiquefactionTbdy';
import Meyerhof from './components/calculations/meyerhof/Meyerhof';
import Hansen from './components/calculations/hansen/Hansen';
import PileRockBc from './components/calculations/pile-rock-bc/PileRockBc';
import PileRockSettle from './components/calculations/pile-rock-settle/PileRockSettle';
import SptCorrection from './components/calculations/spt-corr/SptCorrection';
import Terzaghi from './components/calculations/terzaghi/Terzaghi';
import WeightVolumeRelations from './components/calculations/weightVolumeRelations/WeightVolumeRelations';
import Stroud from './components/calculations/stroud/Stroud';
import ClayEffectiveFrictionAngle from './components/calculations/clayEffectiveFrictionAngle/ClayEffectiveFrictionAngle';
import CvLLUsNavy from './components/calculations/cvLLUsNavy/CvVsLLUsNavy';
import StressIncrease from './components/calculations/stressIncrease/StressIncrease';

//CALCULATION THEORY
import SptcorrTheory from './components/pages/theory/SptcorrTheory';
import LayeredSoilTheory from './components/pages/theory/LayeredSoilTheory';
import PileRockBcTheory from './components/pages/theory/PileRockBcTheory';
import HansenTheory from './components/pages/theory/HansenTheory';
import MeyerhofTheory from './components/pages/theory/MeyerhofTheory';
import TerzaghiTheory from './components/pages/theory/TerzaghiTheory';
import StroudTheory from './components/pages/theory/StroudTheory';
import CvLLUsNavyTheory from './components/pages/theory/CvLLUsNavyTheory';
import ClayEffectiveFrictionAngleTheory from './components/pages/theory/ClayEffectiveFrictionAngleTheory';
import PileRockSettleAashto2002Theory from './components/pages/theory/PileRockSettleAashto2002Theory';
import ConsolidationTheory from './components/pages/theory/ConsolidationTheory';
import LiquefactionTheory from './components/pages/theory/LiquefactionTheory';
import StressIncreaseTheory from './components/pages/theory/StressIncreaseTheory';
import WeightVolumeRelationsTheory from './components/pages/theory/WeightVolumeRelationsTheory';

//ADMIN
import Admin from './components/admin/Admin';
const Context = React.createContext();

const App = () => {
  if (process.env.NODE_ENV === 'production') ReactGA.initialize('G-7951QT55VY');
  const [user, setUser] = useState();
  const [isResolved, setIsResolved] = useState(false);
  const [lastpage, setLastpage] = useState('');
  const [theme, setTheme] = useState('light');

  const themeMode = createTheme({
    palette: {
      mode: theme,
    },
  });

  const getCurrentUser = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: '/api/v1/users/me',
      });

      if (response.data.status === 'success') {
        setUser(response.data.data.data);
      }
      setIsResolved(true);
    } catch (err) {
      setIsResolved(true);
      /* console.log(err.response.data.message); */
    }
  };

  useEffect(() => {
    if (!user) getCurrentUser();
  }, [user]);

  return (
    <ThemeProvider theme={themeMode}>
      <Context.Provider value={{ user, setUser, isResolved, setIsResolved, lastpage, setLastpage, setTheme, theme }}>
        <Routes>
          {/* PAGES */}
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword/:token" element={<ResetPassword />} />
          <Route path="/confirmEmail/:token" element={<ConfirmEmail />} />
          {/* CALCULATION THEORY PAGES */}
          <Route path="/spt-corr-theory" element={<SptcorrTheory />} />
          <Route path="/layered-soil-theory" element={<LayeredSoilTheory />} />
          <Route path="/pile-rock-bc-theory" element={<PileRockBcTheory />} />
          <Route path="/hansen-theory" element={<HansenTheory />} />
          <Route path="/meyerhof-theory" element={<MeyerhofTheory />} />
          <Route path="/terzaghi-theory" element={<TerzaghiTheory />} />
          <Route path="/stroud-theory" element={<StroudTheory />} />
          <Route path="/cvLLUsNavy-theory" element={<CvLLUsNavyTheory />} />
          <Route path="/clayEffectiveFrictionAngle-theory" element={<ClayEffectiveFrictionAngleTheory />} />
          <Route path="/pile-rock-settle-aashto2002-theory" element={<PileRockSettleAashto2002Theory />} />
          <Route path="/consolidation-theory" element={<ConsolidationTheory />} />
          <Route path="/liquefaction-tbdy-theory" element={<LiquefactionTheory />} />
          <Route path="/stressIncrease-theory" element={<StressIncreaseTheory />} />
          <Route path="/weightVolumeRelations-theory" element={<WeightVolumeRelationsTheory />} />

          {/* PREVENTED ROUTES */}
          <Route element={<PreventedRoutes />}>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
          </Route>
          {/* PROTECTED ROUTES */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/account" element={<Account />} />
            {/* CALCULATIONS */}
            <Route path="/calculations/consolidation" element={<Consolidation />} />
            <Route path="/calculations/layered-soil" element={<LayeredSoil />} />
            <Route path="/calculations/liquefaction-tbdy" element={<LiquefactionTbdy />} />
            <Route path="/calculations/meyerhof" element={<Meyerhof />} />
            <Route path="/calculations/hansen" element={<Hansen />} />
            <Route path="/calculations/pile-rock-bc" element={<PileRockBc />} />
            <Route path="/calculations/pile-rock-settle-aashto2002" element={<PileRockSettle />} />
            <Route path="/calculations/spt-corr" element={<SptCorrection />} />
            <Route path="/calculations/terzaghi" element={<Terzaghi />} />
            <Route path="/calculations/weightVolumeRelations" element={<WeightVolumeRelations />} />
            <Route path="/calculations/stroud" element={<Stroud />} />
            <Route path="/calculations/clayEffectiveFrictionAngle" element={<ClayEffectiveFrictionAngle />} />
            <Route path="/calculations/cvLLUsNavy" element={<CvLLUsNavy />} />
            <Route path="/calculations/stressIncrease" element={<StressIncrease />} />
            {/* ADMIN */}
            <Route path="/admin" element={user?.role === 'admin' ? <Admin /> : <NotFound />} />
          </Route>
        </Routes>
      </Context.Provider>
      {/* </MathJaxContext> */}
    </ThemeProvider>
  );
};
export { Context };
export default App;
