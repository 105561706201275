import React, { useState, forwardRef, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import VerifiedIcon from '@mui/icons-material/Verified';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

const Profile = ({ user, setUser, name, setName, email, setEmail }) => {
  const [loading, setLoading] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={0} ref={ref} {...props} />;
  });

  const updateUser = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axios({
        method: 'PATCH',
        url: '/api/v1/users/updateMe',
        data: {
          name,
          email,
        },
      });

      if (response.data.status === 'success') {
        setUser(response.data.data.user);
        setButtonDisabled(true);
        setAlertMessage('Your profile is updated successfully');
        setSeverity('success');
        setOpen(true);
      }
    } catch (err) {
      setAlertMessage(err.response.data.message);
      setSeverity('error');
      setOpen(true);
    }
    setLoading(false);
  };

  const sendVerificationEmail = async (e) => {
    setLoadingVerify(true);
    e.preventDefault();
    try {
      const response = await axios({
        method: 'GET',
        url: '/api/v1/users/sendVerificationEmail',
        data: {
          name,
          email,
        },
      });

      if (response.data.status === 'success') {
        setButtonDisabled(true);
        setAlertMessage('A verification email has been sent!');
        setSeverity('success');
        setOpen(true);
      }
    } catch (err) {
      setAlertMessage(err.response.data.message);
      setSeverity('error');
      setOpen(true);
    }
    setLoadingVerify(false);
  };

  //MUI ALERT
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [nameChanged, setNameChanged] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onChangeName = (e) => {
    if (user.name === e.target.value) {
      setNameChanged(false);
      setName(e.target.value);
    } else {
      setNameChanged(true);
      setName(e.target.value);
    }
  };

  const onChangeEmail = (e) => {
    if (user.email === e.target.value) {
      setEmailChanged(false);
      setEmail(e.target.value);
    } else {
      setEmailChanged(true);
      setEmail(e.target.value);
    }
  };

  useEffect(() => {
    if (!name || !email) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [name, email]);

  useEffect(() => {
    if (nameChanged || emailChanged) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [nameChanged, emailChanged]);

  return (
    <div className="account-section">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <h3>Name & Email </h3>

      <div style={{ position: 'relative' }}>
        <TextField style={{ width: 300 }} size="small" label="Name" variant="outlined" value={name} onChange={onChangeName} />
        <Tooltip
          disableFocusListener
          disableTouchListener
          title={
            <div key={'name1'} className="tooltipContainer">
              <div>Your name should be minimum 2, maximum 50 characters long.</div>
              <div>Space characters are not allowed.</div>
            </div>
          }
        >
          <InfoTwoToneIcon sx={{ position: 'absolute', top: 9, left: 310 }} fontSize={'small'} color="primary" />
        </Tooltip>
      </div>
      <div style={{ position: 'relative' }}>
        <TextField style={{ width: 300 }} size="small" label="E-Mail" variant="outlined" value={email} onChange={onChangeEmail} />
        {user?.emailConfirmed ? (
          <Tooltip disableFocusListener disableTouchListener title="Your email is verified!">
            <VerifiedIcon sx={{ position: 'absolute', top: 8, left: 270 }} color="primary" />
          </Tooltip>
        ) : (
          <Tooltip disableFocusListener disableTouchListener title="Your email is not verified!">
            <GppMaybeIcon sx={{ color: '#ff9800', position: 'absolute', top: 8, left: 270 }} />
          </Tooltip>
        )}
        <Tooltip disableFocusListener disableTouchListener title="Provide a valid email address.">
          <InfoTwoToneIcon sx={{ position: 'absolute', top: 9, left: 310 }} fontSize={'small'} color="primary" />
        </Tooltip>
      </div>

      {!user?.emailConfirmed && (
        <Alert severity="warning" style={{ width: 300, textAlign: 'left' }}>
          <span>
            Your email has not been verified yet. We have already sent you an email about this. <br />
            <br />
            If the email did not reach you or 24 hours passed after you got the email, you can resend it below.
          </span>
          <Button variant="text" size="small" sx={{ textTransform: 'none', marginLeft: '-5px' }} onClick={sendVerificationEmail}>
            {loadingVerify ? <CircularProgress size={20} /> : 'Send Verification Email'}
          </Button>
        </Alert>
      )}
      <div style={{ position: 'relative' }}>
        <Button variant="contained" style={{ width: 300 }} onClick={updateUser} disabled={buttonDisabled || loading}>
          {loading ? <CircularProgress size={20} /> : 'Save'}
        </Button>

        <Tooltip disableFocusListener disableTouchListener title="You can update your name and/or email once a week.">
          <InfoTwoToneIcon sx={{ position: 'absolute', top: 9, left: 310 }} fontSize={'small'} color="primary" />
        </Tooltip>
      </div>
    </div>
  );
};

export default Profile;
