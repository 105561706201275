import { Link } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Button from '@mui/material/Button';

const CalculationLinkBox = ({ el, handleFavorite, favorites, user }) => {
  return (
    <div className={el.className}>
      <div className="title">{el.title}</div>
      {el.description && <div className="description">{el.description}</div>}
      {el.releaseDate && <div className="releaseDate">Release Date: {el.releaseDate}</div>}

      {!el.releaseDate && (
        <div className="homepageButtonContainer">
          <Link to={el.link}>
            <Button variant="outlined" size="small" style={{ fontSize: '12px' }}>
              Calculate
            </Button>
          </Link>
          <Link to={el.theoryLink}>
            <Button variant="outlined" size="small" style={{ fontSize: '12px' }}>
              Info
            </Button>
          </Link>
          {el.id !== -1 && user && (
            <div className="favoriteIcon">
              {favorites.includes(el.id) ? (
                <FavoriteIcon color="info" fontSize="medium" onClick={(e) => handleFavorite(e, el.id)} />
              ) : (
                <FavoriteBorderIcon color="info" fontSize="medium" onClick={(e) => handleFavorite(e, el.id)} />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CalculationLinkBox;
