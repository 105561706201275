import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import Header from '../../common/Header';
import Footer from '../../common/Footer';
import figure from '../../../assets/calculations/pilerocksettle/1.png';
import TransitionsModal from '../../modals/TransitionsModal';

const PileRockSettleAashto2002Theory = () => {
  // Google Ads & Set page title
  useEffect(() => {
    document.title = 'GeoCalc | Immediate Settlement of Pile Foundations in Rock, AASHTO (2002) | Theory';
  }, []);

  return (
    <>
      <Header />
      <div className="theory">
        <div className="theoryContainer">
          <h1>Immediate Settlement of Pile Foundations in Rock, AASHTO (2002) | Theory & Formulations</h1>
          <h2>Article 4.6.5.5.2, AASHTO, 2002</h2>
          <p>
            In estimating the dispacement of rock-socketed drilled shafts, the resistance to deformation provided by overlying
            soil deposits may be ignored. Otherwise, the load transfer to soil as a function of displacement may be estimated in
            accordance with Article 4.6.5.5.1
          </p>
          <p>
            The butt settlement( &rho;<sub>s</sub>) of drilled shafts fully socketed into rock may be determined using the
            following which is modified to include elastic shortening of the shaft:
          </p>
          <p className="theoryEquation">
            <math display="block">
              <msub>
                <mi>&rho;</mi>
                <mi>s</mi>
              </msub>
              <mo>=</mo>
              <mi>Q</mi>
              <mo>[</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>I</mi>
                    <mi>ps</mi>
                  </msub>
                </mrow>
                <mrow>
                  <msub>
                    <mi>B</mi>
                    <mi>r</mi>
                  </msub>
                  <msub>
                    <mi>E</mi>
                    <mi>m</mi>
                  </msub>
                </mrow>
              </mfrac>
              <mo>+</mo>
              <mfrac>
                <mrow>
                  <msub>
                    <mi>D</mi>
                    <mi>r</mi>
                  </msub>
                </mrow>
                <mrow>
                  <mi>
                    <mi>A</mi>
                  </mi>
                  <msub>
                    <mi>E</mi>
                    <mi>c</mi>
                  </msub>
                </mrow>
              </mfrac>
              <mo>]</mo>
            </math>
          </p>
          <p className="theoryEquation">Where;</p>
          <p>
            &rho;<sub>s</sub>: Total settlement displacement at butt for shaft with rock socket.
          </p>
          <p>Q: Total axial compression load applied to shaft butt.</p>
          <p>
            I<sub>ps</sub>: Displacement influence factor for rock socketed shafts loaded in compression. Refer to Figure
            4.6.5.5.2A to determine.
          </p>
          <TransitionsModal
            image={figure}
            title="Immediate Settlement of Pile Foundations in Rock, AASHTO (2002)"
            description={'Determine Ips using Dr/Br and Ec/Em ratios'}
            buttonText={'Figure 4.6.5.5.2A'}
          ></TransitionsModal>

          <p>
            B<sub>r</sub>: Diameter of socket.
          </p>
          <p>
            E<sub>m</sub>: Elastic modulus of rock mass.
          </p>
          <p>
            D<sub>r</sub>: Length of rock socket.
          </p>
          <p>A: Area of shaft.</p>
          <p>
            E<sub>c</sub>: Elastic modulus of concrete shaft or reinforced shaft.
          </p>
          <div className="theoryButtonContainer">
            <Link to={'/calculations/pile-rock-settle-aashto2002'}>
              <Button variant="outlined" size="small">
                GO TO SETTLEMENT CALCULATOR
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PileRockSettleAashto2002Theory;
