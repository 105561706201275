import { useState, useEffect } from 'react';
import SendReminderToUnconfirmed from './SendReminderToUnconfirmed';
import SendReminderToNonCalculating from './SendReminderToNonCalculating';
import axios from 'axios';

const SendEmail = () => {
  const [lastSentTimesLoading, setLastSentTimesLoading] = useState(false);
  const [lastSentTimes, setLastSentTimes] = useState(false);
  useEffect(() => {
    const sendEmailToNoncalculatingUsers = async () => {
      setLastSentTimesLoading(true);
      try {
        const response = await axios({
          method: 'GET',
          url: '/api/v1/admin/getLastEmailSentDates',
        });
        setLastSentTimes(response.data.lastSentEmailDates[0]);
      } catch (err) {
        console.log(err.data.message);
      }
      setLastSentTimesLoading(false);
    };
    sendEmailToNoncalculatingUsers();
  }, []);
  return (
    <div className="adminDashboard">
      <h1>Send Email</h1>
      <div className="emailSendBoxContainer">
        <SendReminderToUnconfirmed lastSentTimesLoading={lastSentTimesLoading} lastSentTimes={lastSentTimes} />
        <SendReminderToNonCalculating lastSentTimesLoading={lastSentTimesLoading} lastSentTimes={lastSentTimes} />
      </div>
    </div>
  );
};

export default SendEmail;
